import {
    OBTENER_DATOS_USUARIO_LOGIN,
    CARGANDO_BTN_LOGIN
} from "../../../Constantes/Login/Login";

const INIT_STATE = {
    rex_token_login : localStorage.getItem('token'),
    rex_LoginUsuario : {},
    rex_cargando_btn_login : false,    
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case OBTENER_DATOS_USUARIO_LOGIN: {
        return {
            ...state,
            rex_LoginUsuario : action.payload.usuario,
            rex_token_login : action.payload.token
        }
    }
    case CARGANDO_BTN_LOGIN: {
        return {
            ...state,
            rex_cargando_btn_login : action.payload
        }
    }
    default:
      return state;
  }
}