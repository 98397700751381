import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Collapse, Button, Switch, Select, Modal, Input, Skeleton } from 'antd';
import { useNavigate } from "react-router-dom";
import '../../../Estilos/Rutas/Administrador/Permisos.css';

import {
    CaretRightOutlined,
    ExclamationCircleOutlined,
    SearchOutlined,
    ArrowLeftOutlined
} from '@ant-design/icons';
import { 
    ObtenerPermisosTipoUsuarioAdministradorPermisosReducer,
    EditarPermisoTipoUsuarioPermisosReducer,
    EditarPermisosTipoUsuarioPermisoReducer,
    GuardarPermisosTipoUsuarioReducer
} from '../../../Redux/Actions/Administrador/Permisos/Permisos';
import {  
    ObtenerTpusAdministradorUsuariosReducer,
} from '../../../Redux/Actions/Administrador/TipoUsuarios/TipoUsuarios';

const Permisos = () => {

    let { tpuid, tpunombre } = useParams();
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const { Panel } = Collapse;
    const { Option } = Select;
    
    const { 
        rex_tipos_usuarios
    } = useSelector(({administradorTipoUsuarios}) => administradorTipoUsuarios);

    const { 
        rex_permisos_tipo_usuario
    } = useSelector(({administradorPermisos}) => administradorPermisos);

    const [ cargando, setCargando ] = useState(false);
    const [ enviando, setEnviando ] = useState(false);
    const [ listaSkeleton,  ] = useState([1,2,3,4,5,6,7]);
    const [ valorTpuid, setValorTpuid] = useState(tpunombre)
    const [ txtBuscar, setTxtBuscar ] = useState('');
    const [ cambiaTipoUsuario, setCambiaTipoUsuario ] = useState(false);


    const obtenerPermisos = async (tpuid) => {
        setCargando(true);
        await dispatch(ObtenerPermisosTipoUsuarioAdministradorPermisosReducer(tpuid));
        setCargando(false);
    }

    const guardarPermisosTipoUsuario = async () => {
        setEnviando(true);
        await dispatch(GuardarPermisosTipoUsuarioReducer(tpuid));
        setEnviando(false);
    }

    useEffect(() => {

        obtenerPermisos(tpuid);
        dispatch(ObtenerTpusAdministradorUsuariosReducer());
        
    }, []);
    
    useEffect(() => {
        obtenerPermisos(tpuid);
        setCambiaTipoUsuario(false);
    }, [cambiaTipoUsuario]);


    const iconoPanel = (indexTipo, checked) => (
        <div
            onClick={(event) => {
                event.stopPropagation();
            }}
        >
            <Switch
                className={checked ? 'Circulo-Activo': 'Circulo-Desactivo'}
                checked={checked}
                onChange={(valor) => {
                    dispatch(EditarPermisosTipoUsuarioPermisoReducer(indexTipo, valor))
                }}
            />
        </div>
    );

    const confirmarCambiarTipoUsuario = (valor, children) => {
        Modal.confirm({
          title: 'Alerta',
          icon: <ExclamationCircleOutlined />,
          content: `Se cambiarán los permisos de ${tpunombre} por los de ${children}`,
          onOk() {
            setCambiaTipoUsuario(true);
            navigate(`/permisos/${valor}/${children}`);
            setValorTpuid(valor)
          },
          onCancel() {
            setValorTpuid(tpunombre)
          },
        });
    };
          
    return (
            <Row className='Contenedor-Permisos'>
                <Col span={4}></Col>
                <Col span={16}>
                    <Row>
                        <Col span={24} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <ArrowLeftOutlined
                                onClick={() => navigate('/tipo-usuarios')}
                                style={{fontSize:'20px', margin:'0 10px', color:'black'}}
                            />
                            <h2 style={{marginBottom:'0'}}>Permisos de: {tpunombre}</h2>
                        </Col>
                        <Col
                            xl={8} lg={8} md={24} sm={24} xs={24}
                            style={{margin:'10px 0'}}
                        >
                            <Input
                                suffix={<SearchOutlined style={{margin:'0 5px'}} />}
                                size='large'
                                className='Input-Buscador-Permisos-Tipo-Usuario'
                                placeholder='Buscar'
                                value={txtBuscar}
                                onChange={(e) => {
                                    setTxtBuscar(e.target.value)
                                }}
                            />
                        </Col>
                        <Col span={24} className='Contenedor-Fila-Acciones'>
                            <div>
                                {'Ver permisos de: '}
                                <Select
                                    value={valorTpuid}
                                    style={{
                                        width:150
                                    }}
                                    onChange={(valor, {children}) => {
                                        confirmarCambiarTipoUsuario(valor, children)
                                    }}
                                >
                                    {
                                        rex_tipos_usuarios.map((tip) => 
                                            <Option disabled={tpuid == tip.tpuid} key={tip.tpuid} value={tip.tpuid}>{tip.tpunombre}</Option>
                                        )
                                    }
                                </Select>
                            </div>
                            <Button
                                type='primary'
                                style={{margin:'10px 0'}}
                                onClick={() => guardarPermisosTipoUsuario()}
                                loading={enviando}
                            >
                                Guardar
                            </Button>
                        </Col>
                        <Col span={24}>
                            {
                                cargando
                                ? <div>
                                    {listaSkeleton.map((ske, index) => 
                                        <Skeleton.Input key={index} style={{margin:'5px 0'}} active={true} size={'large'} block={true} />
                                    )}
                                  </div>
                                : rex_permisos_tipo_usuario.map((pemtip, indexTipo) => {

                                    let existeTipoPermiso = false;

                                    if(pemtip.tppnombre.toLowerCase().includes(txtBuscar.toLocaleLowerCase())){
                                        existeTipoPermiso = true;
                                    }else{
                                        pemtip.permisos.map((pem) => {
                                            if(
                                                (pem.pempermisos.toLowerCase().includes(txtBuscar.toLocaleLowerCase())
                                                || pem.pemdescripcion.toLowerCase().includes(txtBuscar.toLocaleLowerCase()))
                                                && txtBuscar.length > 0
                                            ){
                                                existeTipoPermiso = true
                                            }
                                        })
                                    }
                                    
                                    if(existeTipoPermiso){
                                    
                                        return <Collapse
                                            key={pemtip.tppid}
                                            className={`Contenedor-Permiso-Collapse ${pemtip.permisos.length == 0 && 'Collapse-Oculto-Permisos'}`}
                                            expandIcon={({ isActive }) => <CaretRightOutlined style={{fontSize:'120%'}} rotate={isActive ? 90 : 0} />}
                                        >
                                            <Panel
                                                header={pemtip.tppnombre}
                                                key={pemtip.tppid}
                                                extra={iconoPanel(indexTipo, pemtip.checked)}
                                            >
                                                {
                                                    pemtip.permisos.map((pem, indexPem) => {

                                                        let existePermiso = false;

                                                        if( (pem.pempermisos.toLowerCase().includes(txtBuscar.toLocaleLowerCase())
                                                            || pem.pemdescripcion.toLowerCase().includes(txtBuscar.toLocaleLowerCase()))
                                                            && txtBuscar.length > 0
                                                        ){
                                                            existePermiso = true;   
                                                        }
                                                        return <div key={pem.pemid} style={{padding:'10px 0'}}>
                                                            <div style={{display:'flex', justifyContent:'space-between'}}>
                                                                <div>
                                                                    <h4
                                                                        style={{
                                                                            marginBottom: '0',
                                                                            backgroundColor: existePermiso && 'yellow',
                                                                            borderRadius: '5px',
                                                                            padding: '0 3px'
                                                                            }}
                                                                    >
                                                                        {pem.pempermisos}
                                                                    </h4>
                                                                </div>
                                                                <div>
                                                                    <Switch
                                                                        checked={
                                                                            pem.checked
                                                                        }
                                                                        className={pem.checked ? 'Circulo-Activo': 'Circulo-Desactivo'}
                                                                        onChange={(valor) => 
                                                                            dispatch(EditarPermisoTipoUsuarioPermisosReducer(indexTipo, indexPem, valor))
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <p className='Texto-Descripcion-Permiso'>{pem.pemdescripcion}</p>
                                                        </div>
                                                    })
                                                }
                                            </Panel>
                                        </Collapse>
                                    }
                                })
                            }
                        </Col>
                    </Row>
                </Col>
                <Col span={4}></Col>
            </Row>
        )
}

export default Permisos