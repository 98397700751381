import React, { useState, useEffect } from 'react';
import { Input, Button, Form, Select, Upload, Row, Col, Switch, Tag, Modal } from 'antd';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch} from 'react-redux';
import '../../../Estilos/Rutas/Usuarios/Usuarios.css';
import {
    LoadingOutlined,
    ArrowLeftOutlined
} from '@ant-design/icons';
import config from '../../../config';
import { 
    ObtenerPaisesAdministradorCuentasReducer,
    ObtenerCuentasPaisesUsuariosAdministradorCuentasReducer
} from '../../../Redux/Actions/Administrador/Cuentas/Cuentas';
import {
    RegistrarUsuariosAdministradorUsuariosReducer 
} from '../../../Redux/Actions/Administrador/Usuarios/Usuarios';
import {
    ObtenerTpusAdministradorUsuariosReducer,
} from '../../../Redux/Actions/Administrador/TipoUsuarios/TipoUsuarios';

const RegistroUsuarios = () => {

    const { Option, OptGroup} = Select;
    
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const { 
        rex_tipos_usuarios,
    } = useSelector(({administradorTipoUsuarios}) => administradorTipoUsuarios);
    
    const { 
        rex_paises,
        rex_pacids
    } = useSelector(({administradorCuentas}) => administradorCuentas);
    
    const [usudatos, setUsudatos] = useState({
        tpuid:1,
        estado:true,
        imagen:null,
        paisescuentas:[]
    });
    const [enviando, setEnviando] = useState(false);
    const [ modalImagen, setModalImagen ] = useState(false);
    
    const obtenerTpusAdministradorRegistro = () => {
        dispatch(ObtenerTpusAdministradorUsuariosReducer());
    };

    useEffect(() =>{

    },[usudatos]);

    useEffect(()=>{
        obtenerTpusAdministradorRegistro();
        dispatch(ObtenerPaisesAdministradorCuentasReducer());
        dispatch(ObtenerCuentasPaisesUsuariosAdministradorCuentasReducer());
    },[])

    const registrarUsuario = async (usudatos) => {

        setEnviando(true);
        let estado = await dispatch(RegistrarUsuariosAdministradorUsuariosReducer(usudatos));
        estado && navigate("/usuarios", { replace: true });
        setEnviando(false);
    }

    const cambiaEstado = (valor) => {
        valor ? setUsudatos({...usudatos, estado:1}) : setUsudatos({...usudatos, estado:2})
    }

    const agregaPermiso = (valor) => {

        const usuv = {...usudatos};

        const arrayNuevo = rex_pacids.find((cue) => cue.pacid === valor);
        const esta = usudatos.paisescuentas.find((cue) => cue.pacid === valor);

        if(!esta){
            usuv.paisescuentas.push(arrayNuevo);
            setUsudatos(usuv);
        }
    }

    const eliminaTag = (pai) => {
        const { pacid } = pai;
        setUsudatos({
            ...usudatos, paisescuentas:usudatos.paisescuentas.filter(cue => cue.pacid !== pacid) 
        });
    }

    const vistaModalImagen = () => setModalImagen(true);
    const ocultarModalImagen = () => setModalImagen(false);

    return (
        <div className='Contenedor-Usuarios'>

            <Row>
                <Col span={24} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <ArrowLeftOutlined
                        onClick={() => navigate('/usuarios')}
                        style={{fontSize:'20px', margin:'0 10px', color:'black'}}
                    />
                    <h2 style={{marginBottom:'0'}}>Registrar usuario</h2>
                </Col>
            </Row>

            <div id='Contenedor-Registro'>
                

                <Form
                    autoComplete="off"
                    onFinish={() => registrarUsuario(usudatos)}
                    layout="vertical"
                >
                    <Row>
                        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
                            <Form.Item
                                className='Input-Usuario'
                                label='Correo:'
                                name='correo'
                                rules={[{ required: true, message: 'El correo es requerido' }]}
                            >
                                <Input
                                    name='correo'
                                    onChange={(e) => 
                                        setUsudatos({
                                            ...usudatos,[e.target.name]:e.target.value
                                        })
                                    }
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
                            <Form.Item
                                className='Input-Usuario'
                                label='Usuario:'
                                name='usuario'
                                rules={[
                                    { required: true, message: 'El usuario es requerido' },
                                    { min: 6, message: 'El usuario es muy corto o invalido' }
                                ]}
                            >
                                <Input
                                    autoComplete='off'
                                    name='usuario'
                                    onChange={(e) => 
                                        setUsudatos({
                                            ...usudatos,[e.target.name]:e.target.value
                                        })
                                    }
                                    className='Input-Registro'
                                />

                            </Form.Item>
                        </Col>

                        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
                            <Form.Item
                                className='Input-Editar-Password'
                                label='Contraseña:'
                                name='contrasenia'
                                rules={[{ required: true, message: 'La contraseña es requerida' }]}
                            >
                                <Input.Password
                                    name='contrasenia'
                                    autoComplete='off'
                                    type='password'
                                    onChange={(e) => 
                                        setUsudatos({
                                            ...usudatos,[e.target.name]:e.target.value
                                        })
                                    }
                                    size='large'
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
                            <Form.Item
                                label='Tipo de usuario:'
                                className='Input-Usuario'
                            >
                                <Select
                                    defaultValue='Gerente'
                                    className='Select-Tipo-Usuario'
                                    onChange={(valor) => 
                                        setUsudatos({
                                            ...usudatos,tpuid:valor
                                        })
                                    }
                                >
                                    {rex_tipos_usuarios.map((tp) => (
                                        <Select.Option key={tp.tpuid} value={tp.tpuid}>{tp.tpunombre}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        
                        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>
                            <Form.Item
                                className='Input-Usuario'
                                    label='Nombres:'
                                    name='req_nombre'
                                    rules={[{ required: true, message: 'El nombre es requerido' }]}
                                >
                                    <Input
                                        name='req_nombre'
                                        onChange={(e) => 
                                            setUsudatos({
                                                ...usudatos,[e.target.name]:e.target.value
                                            })
                                        }
                                        className='Input-Registro'
                                    />

                            </Form.Item>
                        </Col>

                        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>

                            <Form.Item
                                className='Input-Usuario'
                                label='Apellido paterno:'
                                name='req_paterno'
                                rules={[
                                        { required: true, message: 'El apellido paterno es requerido' },
                                        { min: 3, message: 'El apellido es muy corto o invalido' }
                                    ]}
                            >
                                <Input
                                    name='req_paterno'
                                    onChange={(e) => 
                                        setUsudatos({
                                            ...usudatos,[e.target.name]:e.target.value
                                        })
                                    }
                                />

                            </Form.Item>
                        </Col>

                        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>

                            <Form.Item
                                className='Input-Usuario'
                                label='Apellido materno:'
                                name='req_materno'
                            >
                                <Input
                                    name='req_materno'
                                    onChange={(e) => 
                                        setUsudatos({
                                            ...usudatos,[e.target.name]:e.target.value
                                        })
                                    }
                                />

                            </Form.Item>
                        </Col>

                        <Col xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}>

                            <Form.Item
                                className='Input-Usuario'
                                label='Estado:'
                            >
                                <Switch
                                    onChange={cambiaEstado}
                                    className={usudatos.estado == 1 ? 'Circulo-Activo': 'Circulo-Desactivo'}
                                    defaultChecked
                                />
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row style={{display:'flex', justifyContent:'center'}}>
                        
                        <Col  style={{display:'flex', justifyContent:'center'}} xs={{span:24}} sm={{span:24}} md={{span:4}} lg={{span:4}} xl={{span:4}}>
                        
                            <Form.Item
                                label='Imagen:'
                                className='Input-Usuario Input-Imagen-Usuario'
                            >
                                <div>
                                    <Upload
                                        listType='picture-card'
                                        action={config.api}
                                        onPreview={vistaModalImagen}
                                        onRemove={() => setUsudatos({...usudatos, imagen: null})}
                                        beforeUpload={(file)=>{
                                            let reader = new FileReader();
                                            reader.readAsDataURL(file);
                                            reader.onload = function(){
                                                let base64 = reader.result;
                                                setUsudatos({
                                                    ...usudatos,imagen:base64
                                                });
                                            }
                                            return false;
                                        }
                                        }
                                    >
                                        {usudatos.imagen != null ?null:<Button>Cargar Imagen</Button>}
                                    </Upload>
                                </div>
                            </Form.Item>

                        </Col>

                        <Col
                            xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}} style={{display:'flex', flexDirection:'column',justifyContent:'flex-end', textAlign:'right'}}
                        >

                        <Form.Item
                                className='Input-Usuario'
                                label='Permisos a cuentas:'
                            >
                                <Select
                                    defaultValue="Seleccionar"
                                    style={{
                                    }}
                                    onChange={agregaPermiso}
                                >

                                    {rex_paises && 
                                        rex_paises.map((pai) => (
                                            
                                            <OptGroup label={pai.painombre}>
                                            {   rex_pacids.filter((cue) => cue.painombre === pai.painombre)
                                                    .map((cue) => {
                                                        let estapais = usudatos.paisescuentas.find((cuep) => cuep.pacid === cue.pacid);
                                                        if(estapais){
                                                            return <Option disabled value={cue.pacid}>{cue.cuenombre}</Option>
                                                        }else{
                                                            return <Option value={cue.pacid}>{cue.cuenombre}</Option>
                                                        }
                                                    })}

                                            </OptGroup>
                                        ))
                                    }

                                </Select>

                            </Form.Item>

                            <div style={{display:'flex',flexDirection:'column',height:'150px',overflowY:'scroll'}}>
                                {
                                    usudatos.paisescuentas&&
                                    usudatos.paisescuentas.map(pai => (
                                        <Tag
                                            key={pai.pacid}
                                            className='Tag-Cuenta-Usuario-Registro'
                                            closable
                                            onClose={()=> eliminaTag(pai)}
                                            style={{borderRadius:'5px'}}
                                            color='blue'
                                            >
                                            {pai.cuenombre} {' - '} { pai.painombre}
                                        </Tag>
                                    ))
                                }
                            </div>

                        </Col>
                    
                    </Row>
                    
                    <div className='Contenedor-Boton-Registro-Usuario'>
                        {enviando ?
                            <Button disabled htmlType="submit" type="primary"><LoadingOutlined /> Enviando</Button>
                        :
                            <Button htmlType="submit" type="primary">Guardar usuario</Button>
                        }
                    </div>

                </Form>

            </div>
            <Modal open={modalImagen} footer={null} onCancel={ocultarModalImagen}>
                <img
                    style={{
                        width: '100%',
                    }}
                src={usudatos.imagen}
                />
            </Modal>
                    
        </div>
    )
}

export default RegistroUsuarios