import {
    SELECCIONAR_FILA_DATA_TABLA_STATUS,
    MOSTRAR_MODAL_DETALLE_STATUS
} from '../../../Constantes/Status/Status'

export const SeleccionarFilaDataStatusReducer = (fila) => async (dispatch, getState) => {

    const rex_mostrar_modal_detalle_status = getState().tablaStatus.rex_mostrar_modal_detalle_status

    dispatch({
        type: SELECCIONAR_FILA_DATA_TABLA_STATUS,
        payload: fila
    })

    dispatch({
        type: MOSTRAR_MODAL_DETALLE_STATUS,
        payload: !rex_mostrar_modal_detalle_status
    })
}
