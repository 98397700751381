
import config from "../../../../config";
import {
    OBTENER_PERMISOS_TIPO_USUARIO_ADMINISTRADOR,
    OBTENER_PERMISOS_ADMINISTRADOR,
    EDITAR_PERPERMISOS_ADMINISTRADOR,
    AGREGAR_PERPERMISOS_ADMINISTRADOR,
    FILTRAR_PERMISOS_ADMINISTRADOR,
    OCULTAR_MODAL_TIPOS_PERMISOS_ADMINISTRADOR,
    OBTENER_TIPOS_PERMISOS_ADMINISTRADOR
} from "../../../../Constantes/Administrador/Permisos/Permisos";
import { estadoRequestReducer } from "../../Comunes/EstadoRequest";
import { 
    exitoNotificacion,
    alertaNotificacion
} from "../../../../Funciones/Notificaciones/Notificaciones";

export const ObtenerPermisosTipoUsuarioAdministradorPermisosReducer = (tpuid) => async (dispatch, getState) => {
    await fetch(config.api+'obtener-permisos-tipo-usuario',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({tpuid}),
        }
    )
    .then( async res => {
            await dispatch(estadoRequestReducer(res.status))
            return res.json()
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){
            dispatch({
                type: OBTENER_PERMISOS_TIPO_USUARIO_ADMINISTRADOR,
                payload: data.permisos
            })
        }
    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}

export const ObtenerPemPermisosAdministradorPermisosReducer = () => async (dispatch, getState) => {

    await fetch(config.api+'obtener-pempermisos',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
        }
    )
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        return res.json();
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request
        if(estadoRequest == true){
            dispatch({
                type: OBTENER_PERMISOS_ADMINISTRADOR,
                payload: {
                    permisos           : data.permisos,
                }
            })

            dispatch({
                type    : FILTRAR_PERMISOS_ADMINISTRADOR,
                payload : data.permisos
            })
        }
    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}

export const EliminarPemPermisoAdministradorPermisosReducer = (pemid) => async (dispatch, getState) => {

    await fetch(config.api+'eliminar-permiso',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({pemid}),
        }
    )
    .then( async res => {
            await dispatch(estadoRequestReducer(res.status))
            return res.json()
    })
    .then(data => {

        const estadoRequest = getState().estadoRequest.init_request;

        if(estadoRequest == true){
            dispatch(ObtenerPemPermisosAdministradorPermisosReducer());
            exitoNotificacion(data.mensaje);
        }else{
            alertaNotificacion(data.mensaje);
        }

    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido  error en el servidor');
    });
}

export const AgregarPermisoAdministradorPermisoReducer = (permn) => async (dispatch, getState) => {

    let estado = false;

    await fetch(config.api + 'agregar-permiso',{
          mode: 'cors',
          method: 'POST',
          body: JSON.stringify(permn),
          headers: {
              'Accept' : 'application/json',
              'Content-type' : 'application/json',
              'api_token'	   : localStorage.getItem('usutoken')
            }
    })
    .then( async res => {
            await dispatch(estadoRequestReducer(res.status))
            return res.json()
    })
    .then(data => {

        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){

            dispatch(OcultarModalAgregarPermisosAdministradorReducer(false))
            exitoNotificacion(data.mensaje);
            dispatch(ObtenerPemPermisosAdministradorPermisosReducer());
            dispatch(ObtenerTiposPermisosAdministradorPermisosReducer());

            estado = true;

        }else{

            if(data.errores){
                data.errores.map(err=> alertaNotificacion(err));
            }else{
                alertaNotificacion(data.mensaje);
            }
        }

    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });

    return estado;
}

export const OcultarModalAgregarPermisosAdministradorReducer =  ( estado ) => async (dispatch, getState) => {

    dispatch({
        type: AGREGAR_PERPERMISOS_ADMINISTRADOR,
        payload: estado
    })
}

export const OcultarModalEditarPermisosAdministradorReducer = ( estado, perme ) => async (dispatch, getState) => {
    dispatch({
        type: EDITAR_PERPERMISOS_ADMINISTRADOR,
        payload: {
            permisose: perme,
            modaleditarpermisos: estado
        }
    })
}

export const OcultarModalTiposPermisosAdministradorReducer = ( estado ) => async (dispatch, getState) => {
    dispatch({
        type: OCULTAR_MODAL_TIPOS_PERMISOS_ADMINISTRADOR,
        payload: estado
    })
}

export const FiltrarPermisosAdministradorReducer = (txtBuscar, tppid) => async ( dispatch, getState ) => {

    let listaPerpermisos  = getState().administradorPermisos.rex_permisos;

    if(txtBuscar != "" || tppid){

        let perpermisosFiltrados = listaPerpermisos.filter((per) => 
            (
                per.tppnombre.toLocaleLowerCase().includes(txtBuscar.toLocaleLowerCase())
                || per.pempermisos.toLocaleLowerCase().includes(txtBuscar.toLocaleLowerCase())
                || per.pemslug.toLocaleLowerCase().includes(txtBuscar.toLocaleLowerCase())
                || per.pemruta.toLocaleLowerCase().includes(txtBuscar.toLocaleLowerCase())
            )
            && (tppid != 0 ? per.tppid == tppid : typeof per.tppid == 'number' )
        )

        dispatch({
            type    : FILTRAR_PERMISOS_ADMINISTRADOR,
            payload : perpermisosFiltrados
        })

    }else{
        dispatch({
            type    : FILTRAR_PERMISOS_ADMINISTRADOR,
            payload : listaPerpermisos
        })
    }
} 

export const AgregarPermisosTipoUsuarioReducer = (tpuid, pemids) => async (dispatch, getState) => {

    await fetch(config.api + 'agregar-permisos-tipo-usuario',{
        mode: 'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'api_token'	   : localStorage.getItem('usutoken')
        },
        body: JSON.stringify({
            "tpuid" : tpuid,
            "pemids": pemids 
        }),
    })
    .then( async res => {
            await dispatch(estadoRequestReducer(res.status))
            return res.json()
    })
    .then(data => {

        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){
            exitoNotificacion(data.mensaje);
            dispatch(ObtenerPermisosTipoUsuarioAdministradorPermisosReducer(tpuid))
        }else{
            alertaNotificacion(data.mensaje);
        }

    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}



export const EditarPermisosAdministradorPermisosReducer = (perme) => async (dispatch, getState) => {

    await fetch(config.api + 'editar-pempermiso',{
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify(perme),
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'api_token'	   : localStorage.getItem('usutoken')
        }
    })
    .then( async res => {
            await dispatch(estadoRequestReducer(res.status))
            return res.json()
    })
    .then(data => {

        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){

            dispatch(OcultarModalEditarPermisosAdministradorReducer(false,[]))

            exitoNotificacion(data.mensaje);
            dispatch(ObtenerPemPermisosAdministradorPermisosReducer());

        }else{

            if(data.errores){
                data.errores.map(err=> alertaNotificacion(err));
            }else{
                alertaNotificacion(data.mensaje);
            }
        }

    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}

export const EliminarTipoPermisoAdministradorPermisosReducer = ( tppid ) => async (dispatch, getState) =>  {

    await fetch(config.api + 'eliminar-tipo-permiso',{
        mode: 'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'api_token'	   : localStorage.getItem('usutoken')
        },
        body: JSON.stringify({tppid:tppid}),
    })
    .then( async res => {
            await dispatch(estadoRequestReducer(res.status))
            return res.json()
    })
    .then(data => {

        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){
            dispatch(ObtenerTiposPermisosAdministradorPermisosReducer())
            exitoNotificacion( data.mensaje);
        }else{
            alertaNotificacion(data.mensaje);
        }

    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}

export const ObtenerTiposPermisosAdministradorPermisosReducer = () => async (dispatch, getState) =>  {

    await fetch(config.api + 'obtener-tipos-permisos',{
        mode: 'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'api_token'	   : localStorage.getItem('usutoken')
        },
    })
    .then( async res => {
            await dispatch(estadoRequestReducer(res.status))
            return res.json()
    })
    .then(data => {

        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){
            dispatch({
                type: OBTENER_TIPOS_PERMISOS_ADMINISTRADOR,
                payload: data.tpp
            })
        }else{
            alertaNotificacion(data.mensaje);
        }

    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}

export const EditarTipoPermisoAdministradorPermisosReducer = (tppe) => async (dispatch, getState) =>  {

    let respuesta = false;

    await fetch(config.api + 'editar-tipo-permiso',{
        mode: 'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'api_token'	   : localStorage.getItem('usutoken')
        },
        body: JSON.stringify({
            "tppid"     : tppe.tppid,
            "tppnombre" : tppe.tppnombre,
        }),
    })
    .then( async res => {
            await dispatch(estadoRequestReducer(res.status))
            return res.json()
    })
    .then(data => {

        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){
            respuesta = true
            dispatch(ObtenerTiposPermisosAdministradorPermisosReducer())
            exitoNotificacion(data.mensaje)
        }else{

            if(data.errores){
                data.errores.map(err=> alertaNotificacion(err));
            }else{
                alertaNotificacion(data.mensaje);
            }
        }

    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });

    return respuesta;
}

export const EditarPermisosTipoUsuarioPermisoReducer = (tppIndex, valor) => async ( dispatch, getState) => {

    let listaPermisosTiposUsuarios  = getState().administradorPermisos.rex_permisos_tipo_usuario;

    listaPermisosTiposUsuarios[tppIndex]['checked'] = valor;

    listaPermisosTiposUsuarios[tppIndex]['permisos'].map((tip, indexPem) => {
        listaPermisosTiposUsuarios[tppIndex]['permisos'][indexPem]['checked'] = valor;
    })

    dispatch({
        type: OBTENER_PERMISOS_TIPO_USUARIO_ADMINISTRADOR,
        payload: listaPermisosTiposUsuarios
    })
}

export const EditarPermisoTipoUsuarioPermisosReducer = (tppIndex, pemIndex, valor) => async ( dispatch, getState ) => {

    let listaPermisosTiposUsuarios  = getState().administradorPermisos.rex_permisos_tipo_usuario;

    let tamanioPermisos = listaPermisosTiposUsuarios[tppIndex]['permisos'].length;
    
    listaPermisosTiposUsuarios[tppIndex]['permisos'][pemIndex]['checked'] = valor;

    let listaPermisos = listaPermisosTiposUsuarios[tppIndex]['permisos'].filter((tip) => tip.checked == valor);

    listaPermisosTiposUsuarios[tppIndex]['checked'] = listaPermisos.length == tamanioPermisos && valor;
    
    dispatch({
        type: OBTENER_PERMISOS_TIPO_USUARIO_ADMINISTRADOR,
        payload: listaPermisosTiposUsuarios
    })
}

export const GuardarPermisosTipoUsuarioReducer = (tpuid) => async (dispatch, getState) => {

    let listaPermisosTiposUsuarios  = getState().administradorPermisos.rex_permisos_tipo_usuario;

    let listaPemids = [];

    let pemids = listaPermisosTiposUsuarios.map((pem) => pem.permisos.filter((pemid) => pemid.checked == true)).filter((pem) => pem.length > 0);

    pemids.map((pem) => pem.map((pemid) => listaPemids.push(pemid.pemid)));

    await dispatch(AgregarPermisosTipoUsuarioReducer(tpuid, listaPemids));
}