import React, { useEffect } from 'react';
import { Tabs } from 'antd';
import { useDispatch } from 'react-redux';
import '../../../Estilos/Rutas/Administrador/CargaArchivos.css';
import Canales from '../../../Componentes/Administrador/CargaArchivos/Canales';
import CanalesCuentas from '../../../Componentes/Administrador/CargaArchivos/CanalesCuentas';
import CategoriaCargaArchivos from '../../../Componentes/Administrador/CargaArchivos/CategoriaCargaArchivos';
import TipoCargaArchivos from '../../../Componentes/Administrador/CargaArchivos/TipoCargaArchivos';
import SubtipoCargaArchivos from '../../../Componentes/Administrador/CargaArchivos/SubtipoCargaArchivos';
import {
	ObtenerCanalesAdministradorCargaArchivosReducer,
	ObtenerUnicamenteTiposCargaArchivosAdministradorCargaArchivosReducer,
	ObtenerUnicamenteCategoriasCargaArchivosAdministradorCargaArchivosReducer
} from '../../../Redux/Actions/Administrador/CargaArchivos/CargaArchivos';
import { 
    ObtenerPaisesAdministradorCuentasReducer,
	ObtenerCuentasAdministradorCuentasReducer
} from '../../../Redux/Actions/Administrador/Cuentas/Cuentas';

const CargaArchivosAdministrador = () => {

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(ObtenerCanalesAdministradorCargaArchivosReducer())
		dispatch(ObtenerPaisesAdministradorCuentasReducer());
		dispatch(ObtenerCuentasAdministradorCuentasReducer());
		dispatch(ObtenerUnicamenteTiposCargaArchivosAdministradorCargaArchivosReducer())
		dispatch(ObtenerUnicamenteCategoriasCargaArchivosAdministradorCargaArchivosReducer())
	},[]);

    return (
    	<div
          className='Contenedor-Principal-Carga-Archivos'
      	>	<h2 className='Titulo-Principal-Carga-Archivos'>Carga de archivos</h2>
          	<Tabs
				style={{marginTop:'20px', userSelect:'none'}}
            	defaultActiveKey="1"
				tabPosition='left'
            	items={[
					{
						label: 'Canales',
						key: '1',
						children: <Canales/>,
					},
					{
						label: 'Cuentas',
						key: '2',
						children: <CanalesCuentas/>,
					},
					{
						label: 'Categorias',
						key: '3',
						children: <CategoriaCargaArchivos/>,
					},
					{
						label: 'Tipo de carga',
						key: '4',
						children: <TipoCargaArchivos/>,
					},
					{
						label: 'Archivos',
						key: '5',
						children: <SubtipoCargaArchivos/>,
					},
				]}
          	/>
			
    	</div>
    )
}

export default CargaArchivosAdministrador