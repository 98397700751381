import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Input, Select, Switch, TimePicker, Button, Tooltip, Checkbox, Divider, Space, Affix } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { 
	EditarSubTipoAdministradorCargaArchivosReducer,
} from '../../../Redux/Actions/Administrador/CargaArchivos/CargaArchivos';
import '../../../Estilos/Rutas/Administrador/CargaArchivos.css'
import FooterModalCargaArchivos from '../../../Funciones/componentes/FooterModalCargaArchivos';
import { MinusCircleOutlined, PlusOutlined, QuestionCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';

import { ObtenerDistribuidorasDisponiblesPaisCuentaReducer } from '../../../Redux/Actions';
import { useNavigate } from 'react-router-dom';
import Moment from 'moment';

const EditarSubTipo = () => {

    const dispatch = useDispatch();
    const { Option } = Select;
    const navigate = useNavigate()

    const {
        rex_subtipe_editar_administrador,
        rex_canales_administrador,
        rex_categorias_unicos,
        rex_tipo_cargas_unicos
    } = useSelector(({administradorCargaArchivos}) => administradorCargaArchivos);

    const {
		rex_data_modal_dts_carga_archivos,
    } = useSelector(({modalDtsCargaArchivos}) => modalDtsCargaArchivos);
    
    const {
        rex_paises,
        rex_cuentas
    } = useSelector(({administradorCuentas}) => administradorCuentas);

    const [ enviando, setEnviando ]                         = useState(false);
    const [ horarioSeleccionado, setHorarioSeleccionado]    = useState()
    const [ horariosDefault, setHorariosDefault ]           = useState()
    const [ dtsSeleccionada, setDtsSeleccionada ]           = useState(null)
    const [ dtsIdSeleccionada, setDtsIdSeleccionada ]       = useState(null)
    const [ opcionArchivoUno, setOpcionArchivoUno ]         = useState()
    const [ opcionArchivoDos, setOpcionArchivoDos ]         = useState()
    const [ selectBucket, setSelectBucket]                  = useState([rex_subtipe_editar_administrador.stcbucketaws])


    const editarSubTipo = async() => {
        setEnviando(true);
        let respuesta = await dispatch(EditarSubTipoAdministradorCargaArchivosReducer(stce, dtsIdSeleccionada, dtsSeleccionada));
        // respuesta && navigate(-1)
        setEnviando(false)
    }

    const [ stce, setStce] = useState({
        ...rex_subtipe_editar_administrador,
        paiid           : rex_subtipe_editar_administrador.paicue[0].paiid,
        cueid           : rex_subtipe_editar_administrador.paicue[0].cueid,
        stcrangofecha   : rex_subtipe_editar_administrador.tienerangofechas,
    })

    const guardarHorario = (index, values) => {

        const horariosAntiguos = stce.horarios.slice();
        horariosAntiguos.map(hor => {
            if(hor.index == index){
                hor['hstrangoinicio'] = Moment(values[0]).format(("hh:mma"))
                hor['hstrangofinal'] = Moment(values[1]).format(("hh:mma"))
            }
        })

        setStce({...stce, horarios : horariosAntiguos})
    }

    const actualizarReglaDts = (e) => {
        setDtsSeleccionada({...dtsSeleccionada, [e.target.name] : e.target.value })
    }

    const eliminarHorario = (index) => {

        let horariosAntiguos = stce.horarios.filter(hor => hor.index != index)
        setStce({...stce, horarios: horariosAntiguos})
        horariosAntiguos.length == 0 && setHorariosDefault()
        if(horariosAntiguos.length == 0 || horarioSeleccionado == index){
            setHorariosDefault([])
        }
    }

    const seleccionarDistribuidora = ( value ) => {
        
        let dtss = [...rex_data_modal_dts_carga_archivos]
        let { reglas } = dtss.find(dts => dts.dtsid == value)
        if(!reglas){
            reglas = {
                rstnombrecsv    : '', 
                rstnombrexlsx   : '', 
                rstcontienecsv  : '',
                rstcontienexlsx : '',
                dtsid           : value
            }
        }
        
        if(reglas.rstnombrecsv){
            setOpcionArchivoUno(1)
        }else if(reglas.rstcontienecsv){
            setOpcionArchivoUno(2)
        }else{
            setOpcionArchivoUno(null)
        }

        if(reglas.rstnombrexlsx){
            setOpcionArchivoDos(1)
        }else if(reglas.rstcontienexlsx){
            setOpcionArchivoDos(2)
        }else{
            setOpcionArchivoDos(null)
        }

        setDtsIdSeleccionada(value)
        setDtsSeleccionada(reglas)
    }

    const actualizarDistribuidoras = (canid) => {
        dispatch(ObtenerDistribuidorasDisponiblesPaisCuentaReducer(stce.cannombre, stce.stcid, stce.pacid, canid))
    }

    const cambiaDiasHorario = (values) => {

        setHorariosDefault(values)
        const newData = { ...stce };
        const horarioIndex = newData.horarios.findIndex(horario => horario.index === horarioSeleccionado);
        if (horarioIndex !== -1) {
            const horario = newData.horarios[horarioIndex];
            const updatedHorario = { ...horario, dias: values };
            newData.horarios[horarioIndex] = updatedHorario;
            setStce(newData);
        }
    }

    const mostrarDiasHorario = (index) => {

        let dias = stce.horarios.find( hor => hor.index == index)
        let diasSemana
        if(dias){
            diasSemana = dias['dias']
        }else{
            diasSemana = []
        }
        // let diasSemana = dias['dias'].includes(0) ? [] : dias['dias']
        setHorariosDefault(diasSemana)
        setHorarioSeleccionado(index)
    }

    const diasHorarios = [
        {value : 1, label : 'Lunes'},
        {value : 2, label : 'Martes'},
        {value : 3, label : 'Miercoles'},
        {value : 4, label : 'Jueves'},
        {value : 5, label : 'Viernes'},
        {value : 6, label : 'Sábado'},
        {value : 7, label : 'Domingo'},
    ]

    useEffect(() => {
        dispatch(ObtenerDistribuidorasDisponiblesPaisCuentaReducer(stce.cannombre, stce.stcid, stce.pacid, null))
    }, [])
    
    return (

        <div
            className='Contenedor-Principal-Carga-Archivos'
        >
            <Form
                layout='vertical'
                autoComplete='off'
                onFinish={editarSubTipo}
            >
                <ArrowLeftOutlined 
                    onClick={() => navigate('/administrador/carga-archivos')}
                    style={{fontSize: '25px', cursor:'pointer'}}
                />
                <h2 onClick={() => console.log(stce)} className='Text-Center-Carga-Archivos'>
                    Editar archivo
                </h2>
                <Divider orientation="left" plain>
                    <h2>Datos principales:</h2>
                </Divider>
                <Row>
                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}
                    >
                        <Form.Item
                            className='Input-Usuario'
                            label='Nombre:'
                        >
                            <Input
                                name='stcnombre'
                                onChange={(e) => setStce({...stce, stcnombre: e.target.value})}
                                value={stce.stcnombre}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}
                    >
                        <Form.Item
                            className='Input-Usuario'
                            label='Tipo de carga:'
                        >
                            <Select
								onChange={(valor) => setStce({...stce, tcanombre:valor})}
								size='large'
								placeholder='Seleccionar'
								className='Input-Editar'
                                defaultValue={stce.tcanombre}
							>
								{
									rex_tipo_cargas_unicos.map((tca) => 
										<Option
										key={tca.tcanombre}
										value={tca.tcanombre}
									>
											{tca.tcanombre}
										</Option>
									)
								}
							</Select>
                        </Form.Item>
                    </Col>

                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}
                    >
                        <Form.Item
                            className='Input-Usuario'
                            label='Categoria:'
                        >
                            <Select
								onChange={(valor) => setStce({...stce, ccanombre:valor})}
								size='large'
								placeholder='Seleccionar'
								className='Input-Editar'
                                defaultValue={stce.ccanombre}
							>
								{
									rex_categorias_unicos.map((cca) => 
										<Option
										key={cca.ccanombre}
										value={cca.ccanombre}
									>
											{cca.ccanombre}
										</Option>
									)
								}
							</Select>
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}
                    >
                        <Form.Item
                            className='Input-Usuario'
                            label='Canal:'
                        >
                            <Select
								onChange={(valor) => {
                                    setStce({...stce, canid:valor})
                                    actualizarDistribuidoras(valor)
                                }}
								size='large'
								placeholder='Seleccionar'
                                className='Input-Editar'
                                defaultValue={stce.canid}
							>
								{rex_canales_administrador.map((can)=> (
									<Option
										key={can.canid}
										value={can.canid}
									>
										{can.cannombre}
									</Option>
								))}
							</Select>

                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}
                    >
                        <Form.Item
                            className='Input-Usuario'
                            label='Empresa:'
                        >
                            <Select
								onChange={(valor) => setStce({...stce, cueid:valor})}
								size='large'
								placeholder='Seleccionar'
                                defaultValue={stce.cueid}
							>
								{rex_cuentas.map((cue)=> (
									<Option
										key={cue.cueid}
										value={cue.cueid}
									>
										{cue.cuenombre}
									</Option>
								))}
							</Select>
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}
                    >
                        <Form.Item
                            className='Input-Usuario'
                            label='Pais:'
                        >
                            <Select
								onChange={(valor) => setStce({...stce, paiid: valor})}
								size='large'
								placeholder='Seleccionar'
                                defaultValue={stce.paiid}
							>
								{rex_paises.map((pai)=> (
									<Option
										key={pai.paiid}
										value={pai.paiid}
									>
										{pai.painombre}
									</Option>
								))}
							</Select>
                        </Form.Item>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={3}>
                        <Form.Item
                            className='Input-Usuario'
                            label='Estado:'
                        >
                            <Switch
                                defaultChecked = {stce.stcestado}
                                className={stce.stcestado ? 'Circulo-Activo': 'Circulo-Desactivo'}
                                onChange={(valor) => setStce({...stce, stcestado: valor})}
                                size='large'
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            className='Input-Usuario'
                            label='Seleccionar distribuidora:'
                        >
                            <Switch
                                checkedChildren="Si"
                                unCheckedChildren="No"
                                defaultChecked = {stce.stcfiladistribuidora}
                                className={stce.stcfiladistribuidora ? 'Circulo-Activo': 'Circulo-Desactivo'}
                                onChange={(valor) => setStce({...stce, stcfiladistribuidora: valor})}
                                size='large'
                            />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item
                            className='Input-Usuario'
                            label='Rango de fechas:'
                        >
                            <Switch
                                checkedChildren="Si"
                                unCheckedChildren="No"
                                defaultChecked = {stce.stcrangofecha}
                                className={stce.stcrangofecha ? 'Circulo-Activo': 'Circulo-Desactivo'}
                                onChange={(valor) => setStce({...stce, stcrangofecha: valor})}
                                size='large'
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left" plain>
                    <h2>Personalizar almacenamiento S3</h2>
                    
                </Divider>
                <Row>
                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:2}} lg={{span:2}} xl={{span:2}}
                    >
                        <Form.Item
                            className='Input-Usuario'
                            label='Editar ruta S3:'
                        >
                            <Tooltip
                                title={`${stce.stcrutaaws ? 'Al desactivar el switch los archivos se guardarán en la ruta por defecto': 'Al activar el switch los archivos se guardarán en la ruta indicada en "Path"'}`}
                                placement="bottom"
                            >
                                <Switch
                                    checkedChildren="Si"
                                    unCheckedChildren="No"
                                    defaultChecked = {stce.stcrutaaws}
                                    className={stce.stcrutaaws ? 'Circulo-Activo': 'Circulo-Desactivo'}
                                    onChange={(valor) => setStce({...stce, stcrutaaws: valor})}
                                    size='large'
                                />
                            </Tooltip>
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}} xl={{span:12}}
                    >
                        <Form.Item
                            className='Input-Usuario Select-Bucket-Input'
                            label={`Bucket/path:`}
                        >
                            <div className='Contenedor-Select-Bucket-Ruta'>
                                <Tooltip
                                    title='Si no se agrega alguno, se guardará en el bucket por defecto'
                                >
                                    <QuestionCircleOutlined
                                        style={{marginRight: '5px'}}
                                    />
                                </Tooltip>
                                <Space.Compact
                                    size='large'
                                >
                                    <Select
                                        mode="tags"
                                        placeholder="Bucket"
                                        value={stce.stcrutaaws ? selectBucket : 'Por defecto'}
                                        disabled={!stce.stcrutaaws}
                                        onChange={(value) =>{
                                            console.log(value)
                                            setSelectBucket(value)
                                            setStce({...stce, stcbucketaws: value.slice(-1)[0]})
                                        }}
                                        onSelect={(value) => {
                                            setSelectBucket(value)
                                        }}
                                        onDeselect={() => {
                                            setSelectBucket([])
                                            setStce({...stce, stcbucketaws: ''})

                                        }}
                                    >
                                        {stce.stcbuckets.map(buc => (
                                            <Option key={buc.value} value={buc.value}>{buc.value}</Option>
                                        ))}
                                    </Select>

                                    <Input
                                        name='stcpathaws'
                                        onChange={(e) => setStce({...stce, stcpathaws: e.target.value})}
                                        value={stce.stcrutaaws ? stce.stcpathaws : 'Por defecto'}
                                        disabled={!stce.stcrutaaws}
                                        placeholder='Ej: archivos/carga'
                                    />
                                </Space.Compact>
                                <Tooltip
                                    title='Si no se agrega alguna, se guardará en la ruta por defecto'
                                >
                                    <QuestionCircleOutlined
                                        style={{marginLeft: '5px'}}
                                    />
                                </Tooltip>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>

                <Divider orientation="left" plain>
                    <h2>Restricción de carga:</h2>
                </Divider>

                <Row>
                    <Col span={4}>
                        <Form.Item
                            className='Input-Usuario'
                            label='Restringir carga:'
                        >
                            <Switch
                                checkedChildren="Si"
                                unCheckedChildren="No"
                                defaultChecked = {stce.stchorario}
                                className={stce.stchorario ? 'Circulo-Activo': 'Circulo-Desactivo'}
                                onChange={(valor) => {
                                    if(stce.horarios.length == 0){
                                        let horariosAntiguos = []
                                        horariosAntiguos.push({
                                            index: (Math.random() + 1).toString(36).substring(7),
                                            hstrangoinicio: Moment('00:01pm', 'HH:mm'),
                                            hstrangofinal : Moment('23:59pm', 'HH:mm')
                                        })
                                        setStce({...stce, horarios: horariosAntiguos, stchorario :  valor})
                                    }else{
                                        setStce({...stce, stchorario: valor})
                                    }
                                }}
                                size='large'
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={'Rango no disponible:'}
                            className='Input-Usuario'
                            style={{display: stce.horarios.length == 0 && 'none'}}
                        >
                            {stce.horarios.map(hor  => (
                                <Row key={hor.index} className='Fila-Rango-No-Disponible-Editar-Sub-Tipo'>
                                    <Col span={20}>
                                        <TimePicker.RangePicker
                                            disabled={!stce.stchorario}
                                            format={'HH:mm'}
                                            defaultValue={[
                                                Moment(hor.hstrangoinicio, 'HH:mm'),
                                                Moment(hor.hstrangofinal, 'HH:mm')
                                            ]}
                                            onChange={(values) => {
                                                guardarHorario( hor.index, values )
                                            }}
                                        />
                                    </Col>
                                    <Col span={2}>
                                        <Tooltip title='Eliminar' placement='left'>
                                            <MinusCircleOutlined
                                                className="dynamic-delete-button"
                                                onClick={() => {
                                                    eliminarHorario( hor.index )
                                                }}
                                            />
                                        </Tooltip>
                                    </Col>
                                    <Col
                                        span={2}
                                    >
                                        {
                                            stce.stchorario
                                            ? <Tooltip title='Ver días' placement='right'>
                                                    <Switch
                                                        checked={horarioSeleccionado == hor.index ? true : false}
                                                        onChange={() => {
                                                            mostrarDiasHorario(hor.index)
                                                        }}
                                                        size='small'
                                                    />
                                                </Tooltip>
                                            : null
                                        }
                                    </Col>
                                </Row>
                            ))}
                        </Form.Item>
                        {stce.stchorario ?                                         
                            <Form.Item
                                className='Input-Usuario'
                            >
                                <Button
                                    type="default"
                                    className='Boton-Agregar-Rango-Editar-Sub-Tipo'
                                    onClick={() => {
                                        let horariosAntiguos = [...stce.horarios]
                                        horariosAntiguos.push({
                                            index: (Math.random() + 1).toString(36).substring(7),
                                            hstrangoinicio: Moment('00:01pm', 'HH:mm'),
                                            hstrangofinal : Moment('23:59pm', 'HH:mm'),
                                            dias : []

                                        })
                                        setStce({...stce, horarios: horariosAntiguos})
                                    }}
                                    icon={<PlusOutlined />}
                                >
                                    Agregar rango
                                </Button>
                            </Form.Item>
                        :null}
                    </Col>
                    <Col span={1}></Col>
                    <Col span={8}>
                        {stce.stchorario && stce.horarios.length > 0
                        ? <Form.Item
                                className='Input-Usuario-Dias'
                                label={
                                    <div>
                                        Días restringidos: {' '}
                                        <Tooltip
                                            placement="bottomLeft"
                                            title='Si no se selecciona ningún día se considerarán a los 7 días de la semana para el horario seleccionado'
                                        >
                                            <QuestionCircleOutlined />
                                        </Tooltip>
                                    </div>
                                }           
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    disabled={horarioSeleccionado && stce.stchorario? false : true}
                                    placeholder="Días"
                                    value={horariosDefault == 0 ? [] : horariosDefault}
                                    onChange={(values) => cambiaDiasHorario(values)}
                                    options={diasHorarios}
                                />
                        </Form.Item>
                        :null}
                    </Col>
                </Row>

                <Divider orientation="left" plain>
                    <h2>Reglas para nombre de archivo:</h2>
                </Divider>
                <Row>
                    <Col span={10}>
                        <Form.Item
                            label={
                                <div>
                                    Reglas para distribuidora: {' '}
                                    <Tooltip
                                        placement="bottomLeft"
                                        title='Agrega restricciones para los nombres de los archivos que se suban'
                                    >
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </div>
                            }                             
                            className='Input-Usuario'
                        >
                            <Select
                                defaultValue={dtsIdSeleccionada}
                                placeholder='Seleccionar'
                                onChange={(value) => seleccionarDistribuidora(value)}
                            >
                                {rex_data_modal_dts_carga_archivos.map(dts => (
                                        <Option
                                            key={dts.dtsid}
                                            value={dts.dtsid}
                                        >
                                            {dts.dtsclientesubhml}
                                        </Option>
                                ))}
                                </Select>
                        </Form.Item>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={12}>
                        <Row gutter={16}>
                            <Col span={12}>
                                <h3>Archivo .csv o .zip</h3>
                                <Form.Item
                                    className='Input-Usuario'
                                    label={
                                        <div>
                                            Nombre obligatorio: {' '}
                                            <Tooltip
                                                placement="bottomLeft"
                                                title='El nombre del archivo debe ser igual al ingresado'
                                            >
                                                <QuestionCircleOutlined />
                                            </Tooltip>
                                        </div>
                                    }
                                >   
                                    <div className='Fila-Buscador-Boton-Carga-Archivos'>
                                        <Checkbox
                                            disabled={!dtsIdSeleccionada}
                                            checked={opcionArchivoUno == 1 ? true : false}
                                            style={{marginRight:'10px'}}
                                            onChange={() => {
                                                if(opcionArchivoUno == 1){
                                                    setDtsSeleccionada({...dtsSeleccionada, rstnombrecsv:''})
                                                    setOpcionArchivoUno(null)
                                                }else{
                                                    setDtsSeleccionada({...dtsSeleccionada, rstcontienecsv:''})
                                                    setOpcionArchivoUno(1)
                                                }
                                            }}
                                        />
                                        <Input
                                            name='rstnombrecsv'
                                            disabled={
                                                dtsIdSeleccionada 
                                                ? opcionArchivoUno != 1? true : false 
                                                : true
                                            }
                                            placeholder='Ej: archivo.csv, archivo.zip'
                                            value={dtsSeleccionada ? dtsSeleccionada.rstnombrecsv : ''}
                                            onChange={(e) => actualizarReglaDts(e)}
                                        />
                                    </div>
                                </Form.Item>
                                <Form.Item
                                        className='Input-Usuario'
                                        label={
                                            <div>
                                                Debe contener: {' '}
                                                <Tooltip
                                                    placement="bottomLeft"
                                                    title='El nombre del archivo debe contener lo ingresado'
                                                >
                                                    <QuestionCircleOutlined />
                                                </Tooltip>
                                            </div>
                                        }
                                    >
                                        <div
                                            className='Fila-Buscador-Boton-Carga-Archivos'
                                        >
                                            <Checkbox
                                                disabled={!dtsIdSeleccionada}
                                                checked={opcionArchivoUno == 2 ? true : false}
                                                style={{marginRight:'10px'}}
                                                onChange={() => {
                                                    if(opcionArchivoUno == 2){
                                                        setDtsSeleccionada({...dtsSeleccionada, rstcontienecsv:''})
                                                        setOpcionArchivoUno(null)
                                                    }else{
                                                        setDtsSeleccionada({...dtsSeleccionada, rstnombrecsv:''})
                                                        setOpcionArchivoUno(2)
                                                    }
                                                }}
                                            />
                                            <Input
                                                name='rstcontienecsv'
                                                disabled={dtsIdSeleccionada ? opcionArchivoUno != 2 ? true : false : true}
                                                placeholder='Ej: -2023.xlsx, data-'
                                                value={dtsSeleccionada ? dtsSeleccionada.rstcontienecsv : ''}
                                                onChange={(e) => actualizarReglaDts(e)}
                                            />
                                        </div>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <h3>Archivo xlsx</h3>
                                <Form.Item
                                    className='Input-Usuario'
                                    label={
                                        <div>
                                            Nombre obligatorio: {' '}
                                            <Tooltip
                                                placement="bottomLeft"
                                                title='El nombre del archivo debe ser igual al ingresado'
                                            >
                                                <QuestionCircleOutlined />
                                            </Tooltip>
                                        </div>
                                    }
                                >
                                    <div
                                        className='Fila-Buscador-Boton-Carga-Archivos'
                                    >
                                        <Checkbox
                                            disabled={!dtsIdSeleccionada}
                                            checked={opcionArchivoDos == 1 ? true : false}
                                            style={{marginRight:'10px'}}
                                            onChange={() => {
                                                if(opcionArchivoDos == 1){
                                                    setDtsSeleccionada({...dtsSeleccionada, rstnombrexlsx:''})
                                                    setOpcionArchivoDos(null)
                                                }else{
                                                    setDtsSeleccionada({...dtsSeleccionada, rstcontienexlsx:''})
                                                    setOpcionArchivoDos(1)
                                                }
                                            }}
                                        />
                                        <Input
                                            name='rstnombrexlsx'
                                            disabled={dtsIdSeleccionada ? opcionArchivoDos != 1 ? true : false : true}
                                            placeholder='Ej: archivo.xlsx'
                                            value={dtsSeleccionada ? dtsSeleccionada.rstnombrexlsx : ''}
                                            onChange={(e) => actualizarReglaDts(e)}
                                        />
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    className='Input-Usuario'
                                    label={
                                        <div>
                                            Debe contener: {' '}
                                            <Tooltip
                                                placement="bottomLeft"
                                                title='El nombre del archivo debe contener lo ingresado'
                                            >
                                                <QuestionCircleOutlined />
                                            </Tooltip>
                                        </div>
                                    }
                                >
                                    <div
                                        className='Fila-Buscador-Boton-Carga-Archivos'
                                    >
                                        <Checkbox
                                            disabled={!dtsIdSeleccionada}
                                            checked={opcionArchivoDos == 2 ? true : false}
                                            style={{marginRight:'10px'}}
                                            onChange={() => {
                                                if(opcionArchivoDos == 2){
                                                    setOpcionArchivoDos(null)
                                                    setDtsSeleccionada({...dtsSeleccionada, rstcontienexlsx:''})
                                                }else{
                                                    setOpcionArchivoDos(2)
                                                    setDtsSeleccionada({...dtsSeleccionada, rstnombrexlsx:''})
                                                }
                                            }}
                                        />
                                        <Input
                                            name='rstcontienexlsx'
                                            disabled={dtsIdSeleccionada ? opcionArchivoDos != 2 ? true : false : true}
                                            placeholder='Ej: -2023.xlsx, data-'
                                            value={dtsSeleccionada ? dtsSeleccionada.rstcontienexlsx : ''}
                                            onChange={(e) => actualizarReglaDts(e)}
                                        />
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div style={{padding:'10px'}}>
                    <FooterModalCargaArchivos cancelar={() => navigate(-1)} enviando={enviando}/>
                </div>
            </Form>
        </div>
    )
}

export default EditarSubTipo