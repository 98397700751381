import config from '../../../config'
import { estadoRequestReducer } from "../Comunes/EstadoRequest"
import {
    OBTENER_DATA_PAISES_NEGOCIO_COMPLEMENTOS,
    SELECCIONAR_PAIS_NEGOCIO_COMPLEMENTOS,
    SELECCIONAR_CANAL_NEGOCIO_COMPLEMENTOS
} from '../../../Constantes/Complementos/Complementos'
import {
    ObtenerCargaArchivosReducer
} from '../CargaArchivos/CargaArchivos'

export const ObtenerPaisesComplementosReducer = () => async (dispatch, getState) => {

    
    const rex_negocio_seleccionado = getState().seleccionarNegocio.rex_negocio_seleccionado

    await fetch(config.api+'complementos/obtener-paises-cuentas',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                "req_cuenta": rex_negocio_seleccionado
            }),
        }
    )
    .then( async res => {
            await dispatch(estadoRequestReducer(res.status))
            return res.json()
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request
        
        if(estadoRequest == true){
            if(data.respuesta == true){
                dispatch({
                    type: OBTENER_DATA_PAISES_NEGOCIO_COMPLEMENTOS,
                    payload: data.datos
                })
            }    
        }

    }).catch((error)=> {
        console.log(error)
        // dispatch(RegistrarErrorReducer(error, config.api+'login', datos))
    });

}

export const SeleccionarPaisNegocioComplementosReducer = (paiid, pais,) => async (dispatch, getState) => {

    const rex_data_paises_complementos = getState().complementos.rex_data_paises_complementos

    let paisSeleccionado = {}

    if(paiid == 0){
        paisSeleccionado = pais
    }else{
        await rex_data_paises_complementos.map((pais, pos) => {
            if(paiid == 0){
                if(pos == 0){
                    paisSeleccionado = pais
                }
            }else if(pais.paiid == paiid){
                paisSeleccionado = pais
            }
        })
    }

    await dispatch({
        type: SELECCIONAR_PAIS_NEGOCIO_COMPLEMENTOS,
        payload : paisSeleccionado
    })

    return true
}

export const SeleccionarCanalNegocioComplementosReducer = (canid) => async (dispatch, getState) => {

    await dispatch({
        type: SELECCIONAR_CANAL_NEGOCIO_COMPLEMENTOS,
        payload : canid
    })
}