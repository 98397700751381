import { Modal } from 'antd';
import React from 'react'
import {useSelector, useDispatch} from "react-redux";
import { 
    CheckCircleOutlined
} from '@ant-design/icons'
import { 
    ModalNotificacionPython
} from '../../Redux/Actions/CargaArchivos/TablaCargaArchivos';
import '../../Estilos/Componentes/CargaArchivos/ModalNotificacion.css'


const ModalNotificacion = () => {

    const dispatch = useDispatch();

    let { 
        rex_mostrar_modal_notificacion_tabla_carga_archivos
    } = useSelector(({tablaCargaArchivos}) => tablaCargaArchivos);

    const cerrarModalNotificacion = () => {
        dispatch(ModalNotificacionPython(false));
    }

    return (
        <Modal
            title={null}
            cancelText={null}
            closeIcon={<div></div>}
            footer={null}
            centered={true}
            onCancel={() => cerrarModalNotificacion()}
            open={rex_mostrar_modal_notificacion_tabla_carga_archivos}
            width={350}
            className='Modal-Notificacion-Carga-Archivo-Contenedor'
            
        >
            <div
                onClick={()=> cerrarModalNotificacion()}
            >
                <div
                    className='Contenedor-Modal-Notificacion-Carga-Archivo'
                >
                    <div className='Check-Modal-Centrado-Carga-Archivo'>
                        <CheckCircleOutlined style={{color:'black', fontSize:'40px'}} />
                    </div>
                    <h3 className='Texto-Modal-Notificacion-Carga-Archivo'>Sus archivos fueron cargados con éxito</h3>
                </div>
            </div>

        </Modal>
    )
}

export default ModalNotificacion