import React, { useEffect, useState } from 'react'
import { Table, Tooltip, Button, Input, Row, Col, Pagination, Select, Modal, Popover } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import {
	FiltrarSubTipoAdministradorCargaArchivoReducer,
	ObtenerSubTiposCargaArchivosAdministradorCargaArchivosReducer,
	MostrarModalAgregarSubTipoCargaCargaArchivos,
	MostrarModalEditarSubTipoCargaCargaArchivos,
	EliminarSubTipoCargaArchivosAdministradorCargaArchivosReducer,
	MostrarModalEditarCorreoSubTipoAdministradorCargaArchivosReducer
} from '../../../Redux/Actions/Administrador/CargaArchivos/CargaArchivos'
import EditarSubTipo from './EditarSubTipo'
import AgregarSubTipo from './AgregarSubTipo'
import Moment from 'moment'
import { 
	EditOutlined,
	SearchOutlined,
	DeleteOutlined,
	ExclamationCircleOutlined,
	MailOutlined,
	EyeOutlined,
	ReloadOutlined,
	PlusCircleOutlined
} from '@ant-design/icons'
import '../../../Estilos/Rutas/Administrador/CargaArchivos.css'
import EditarCorreoSubTipo from './EditarCorreoSubTipo'
import { useNavigate } from 'react-router-dom'

const SubtipoCargaArchivos = () => {

	const dispatch = useDispatch();
	const { Option } = Select;
	const { confirm } = Modal;
	const navigate = useNavigate()

    const [pageTableSubTipoArchivo, setPageTableSubTipoArchivo] = useState(1)
    const [pageSizeTableSubTipoArchivo, setPageSizeTableSubTipoArchivo] = useState(10)
	const [ cargando, setCargando ] = useState(false);
	const [ txtBuscar, setTxtBuscar ] = useState('');
	const [ selectCan, setSelectCan ] = useState(0)
	const [ selectPai, setSelectPai ] = useState(0)
	const [ selectCue, setSelectCue ] = useState(0)

	const { 
		rex_modal_agregar_subtipo_administrador,
		rex_modal_editar_subtipo_administrador,
		rex_canales_administrador,
		rex_filtro_subtipo,
		rex_modal_editar_correo_subtipo
    } = useSelector(({administradorCargaArchivos}) => administradorCargaArchivos);
	
	const {
		rex_paises,
		rex_cuentas
    } = useSelector(({administradorCuentas}) => administradorCuentas);

	useEffect(() => {
		obtenerSubTiposAdministrador();
	},[]);

	const obtenerSubTiposAdministrador = async () => {
		setCargando(true);
		await dispatch(ObtenerSubTiposCargaArchivosAdministradorCargaArchivosReducer());
		setCargando(false);
	}

	const buscarSubTipo = (txt, paiid, cueid, canid) => {
		dispatch(FiltrarSubTipoAdministradorCargaArchivoReducer(txt, paiid, cueid, canid))
	}

	const limpiarFiltro = () => {
		setTxtBuscar('');
		setSelectCan(0);
		setSelectPai(0);
		setSelectCue(0);
	}

	const listaCorreos = (lista) => {
		
		return lista.map((cor) => <p key={cor.corid}>{cor.corcorreo}</p>)
	}

	const confirmarEliminarSubTipo = ( record ) => {
        confirm({
            title: `¿Eliminar ${record.stcnombre}?`,
            icon: <ExclamationCircleOutlined />,
            okText: 'Eliminar',
            content: <div>Se eliminará toda la información de la cuenta incluyendo:<br/><br/>
				- Registro en carga de archivos<br/>
				- Registro en distribuidoras<br/>
				- Registro en control archivos<br/><br/>

				<p>En este módulo:</p>
				- Registro con algún canal<br/>
				- Registro con alguna cuenta<br/>
				- Registro con alguna categoria<br/>
				- Registro con algún tipo de archivo<br/>
			</div>,
        
            async onOk() {
                
                let respuesta = await dispatch(EliminarSubTipoCargaArchivosAdministradorCargaArchivosReducer(record.stcid));
				respuesta && limpiarFiltro();
            },
        
            onCancel() {},
        });
    }

	const columns = [
		{
			title: 'Item',
			key: 'stcid',
			align: 'center',
			width:'50px',
			render: (text, record, index) => (pageTableSubTipoArchivo - 1) * pageSizeTableSubTipoArchivo + index + 1,
		},
		{
			title: 'Estado',
			key: 'stcnombre',
			align: 'center',
			width:'50px',
			render: (_, record) => (
				<div style={{display:'flex', justifyContent:'center'}}>

					<Tooltip placement="top" title={record.stcestado ? 'Activado' : 'Desactivado'}>
						<div className={`Circulo ${record.stcestado? 'Circulo-Activo': 'Circulo-Desactivo'}`}>
						</div>
					</Tooltip>	
				</div>
			)
		},
		{
			title: 'Canal',
			dataIndex: 'cannombre',
			key: 'cannombre',
			align: 'center',
			width:'100px'
		},
		{
			title: 'Categoria',
			dataIndex: 'ccanombre',
			key: 'ccanombre',
			align: 'center',
			width:'150px',
		},
		{
			title: 'Tipo de archivo',
			dataIndex: 'tcanombre',
			key: 'tcanombre',
			align: 'center',
			width:'150px',
		},
		{
			title: 'Archivo',
			dataIndex: 'stcnombre',
			key: 'stcnombre',
			align: 'center',
			width:'160px',
		},
		{
			title: 'Seleccionar distribuidora',
			key: 'stcfiladistribuidora',
			align: 'center',
			width:'80px',
			render: (_, record) => (
				<div style={{display:'flex', justifyContent:'center'}}>

					<Tooltip placement="top" title={record.stcfiladistribuidora ? 'Activado' : 'Desactivado'}>
						<div className={`Circulo ${record.stcfiladistribuidora? 'Circulo-Activo': 'Circulo-Desactivo'}`}>
						</div>
					</Tooltip>	
				</div>
			)
		},
		{
			title: 'Rango fechas',
			key: 'tienerangofechas',
			align: 'center',
			width:'100px',
			render: (_, record) => (
				<div style={{display:'flex', justifyContent:'center'}}>

					<Tooltip placement="top" title={record.tienerangofechas ? 'Activado' : 'Desactivado'}>
						<div className={`Circulo ${record.tienerangofechas? 'Circulo-Activo': 'Circulo-Desactivo'}`}>
						</div>
					</Tooltip>	
				</div>
			)
		},
		{
			title: 'Empresa',
			key: 'cuenombre',
			align: 'center',
			width:'100px',
			render: (_,record) => (
				<div>{record.paicue[0].cuenombre}</div>
			)
		},
		{
			title: 'Pais',
			key: 'painombre',
			align: 'center',
			width:'100px',
			render: (_,record) => (
				<Tooltip title={record.paicue[0].painombre} placement="right">
					<img
						style={{width:'30px', height:'30px'}}
						src={record.paicue[0].paiicono}
					/>
				</Tooltip>
			)
		},
		{
			title: 'Correos destinatarios',
			align: 'center',
			width:'70px',
			render: (_,record)=> {

				const correosDestinatarios = record.correosTotales.filter((dest) => dest.scotipodestinatario == 1)
				const tieneCorreos = correosDestinatarios.length > 0 ? true : false;
				return	<Popover
							title={<span>Destinatarios:</span>}
							placement="bottom" trigger="hover"
							content={<div className='Contenedor-Correos'>{tieneCorreos
								? listaCorreos(correosDestinatarios)
								: 'Sin asignar'}</div>}
							>
							<EyeOutlined style={{fontSize:'24px', color: tieneCorreos ? 'green':'black'}}/>
						</Popover >
			}
		},
		{
			title: 'Correos copias',
			key: 'cannombre',
			align: 'center',
			width:'70px',
			render: (_,record) => {

				const correosSecundarios= record.correosTotales.filter((dest) => dest.scotipodestinatario == 0)
				const tieneCorreos = correosSecundarios.length > 0 ? true : false;
				return	<Popover
							title={<span>Enviar copias a:</span>}
							placement="bottom" trigger="hover"
							content={<div className='Contenedor-Correos'>{tieneCorreos
								? listaCorreos(correosSecundarios)
								: 'Sin asignar'}</div>}
							>
							<EyeOutlined style={{fontSize:'24px', color: tieneCorreos ? 'green':'black'}}/>
						</Popover >
			}
		},
		{
			title: 'Fecha de creación',
			key: 'created_at',
			align: 'center',
			width:'150px',
			render: (_, record) => (
				<>{record.created_at?Moment(record.created_at).format('LLL'):'Sin registro'}</>
			)
		},
		{
			title: 'Fecha de actualización',
			key: 'updated_at',
			align: 'center',
			width:'150px',
			render: (_, record) => (
				<>{record.updated_at?Moment(record.updated_at).format('LLL'):'Sin registro'}</>
			),
		},
		{
			title: 'Acción',
			key: 'editar',
			align: 'center',
			width: '100px',
			fixed:'right',
			render: (_,record ) => (
				<div className='Contenedor-Acciones-Iconos'>
					<Tooltip placement='top' title='Editar'>
						<Button
							className='Boton-Accion-Editar'
							onClick={() => {
								navigate('/administrador/carga-archivos/editar')
								dispatch(MostrarModalEditarSubTipoCargaCargaArchivos(false, record))
							}}
						>
							<EditOutlined
								className='Icono-Accion-Editar'
							/>
						</Button>
					</Tooltip>
					<Tooltip placement='top' title='Asignar correos'>
						<Button
							className='Boton-Accion'
							onClick={() => dispatch(MostrarModalEditarCorreoSubTipoAdministradorCargaArchivosReducer(true, record))}
						>
							<MailOutlined
								className='Icono-Accion'
							/>
						</Button>
					</Tooltip>
					<Tooltip placement='top' title='Eliminar'>
						<Button
							className='Boton-Accion-Eliminar'
							onClick={()=> confirmarEliminarSubTipo(record)}
						>
							<DeleteOutlined
								className='Icono-Accion-Eliminar'
							/>
						</Button>
					</Tooltip>				
				</div>
			)
		},
	];

    return (
      
		<div className='Contenedor-Carga-Archivos-Tabla'>
			<Row className='Fila-Buscador-Boton-Carga-Archivos'>
				<Col
					xl={8} lg={12} md={24} sm={24} xs={24}
				>
					<Row>
						<Col span={20}>
							<Input
								suffix={<SearchOutlined className='Icono-Input-Buscador'/>}
								size='large'
								className='Input-Buscador-Carga-Archivos'
								placeholder='Buscar'
								value={txtBuscar}
								onChange={(e) => {
									setTxtBuscar(e.target.value)
									buscarSubTipo(e.target.value, selectPai,selectCue, selectCan)
								}}
							/>
						</Col>
						<Col style={{display:'flex',alignItems:'center'}} span={4}>
							<Tooltip title='Actualizar'>
								<div
									className='Boton-Actualizar'
									onClick={async ()=> {
										setCargando(true);
										await dispatch(ObtenerSubTiposCargaArchivosAdministradorCargaArchivosReducer());
										setCargando(false);
									}}
								>
									<ReloadOutlined
										className='Icono-Actualizar'
									/>
								</div>
							</Tooltip>
						</Col>
					</Row>
				</Col>
				<Row>
					<Col>
						<Button
							onClick={() => dispatch(MostrarModalAgregarSubTipoCargaCargaArchivos(true))}
							className='Boton-Crear-Carga-Archivos'
						>Crear<PlusCircleOutlined/></Button>
					</Col>
				</Row>
			</Row>
			
			<Row className='Contenedor-Filtro-Carga-Archivos'>
				<Row>
					<Col>
						<Select
							value={selectCue}
							className='Select-Filtro-Carga-Archivos'
							onChange={(valor) => {
								setSelectCue(valor)
								buscarSubTipo(txtBuscar, selectPai, valor, selectCan)
							}}
						>
							<Option value={0}>Seleccionar Empresa</Option>
							{
								rex_cuentas.map((cue)=> (
									<Option key={cue.cueid} value={cue.cueid}>{cue.cuenombre}</Option>
								))
							}
						</Select>
					</Col>
					<Col>
						<Select
							value={selectPai}
							className='Select-Filtro-Carga-Archivos'
							onChange={(valor) => {
								setSelectPai(valor)
								buscarSubTipo(txtBuscar, valor, selectCue, selectCan)
							}}
						>
							<Option value={0}>Seleccionar Pais</Option>
							{
								rex_paises.map((pai) => (
									<Option key={pai.paiid} value={pai.paiid}>{pai.painombre}</Option>
								))
							}
						</Select>
					</Col>
					<Col>
						<Select
							value={selectCan}
							className='Select-Filtro-Carga-Archivos'
							onChange={(valor) => {
								setSelectCan(valor)
								buscarSubTipo(txtBuscar, selectPai, selectCue, valor)
							}}
						>
							<Option value={0}>Seleccionar Canal</Option>
							{
								rex_canales_administrador.map((can)=> (
									<Option key={can.canid} value={can.canid}>{can.cannombre}</Option>
								))
							}
						</Select>
					</Col>
				</Row>
				<Row>
					<Col
						style={{display:'flex', justifyContent:'flex-end', margin:'5px 0'}}
					>
						<Pagination
							defaultCurrent={pageTableSubTipoArchivo}
							current={pageTableSubTipoArchivo}
							total={rex_filtro_subtipo.length}
							onChange={(page, pageSize) => {
								setPageTableSubTipoArchivo(page == 0? 1 : page)
								setPageSizeTableSubTipoArchivo(pageSize)
							}}
					/>
					</Col>
				</Row>
				
			</Row>

			<Table
				dataSource={rex_filtro_subtipo} 
				columns={columns}
				loading={cargando}
				pagination={{
					defaultCurrent: pageTableSubTipoArchivo,
					current: pageTableSubTipoArchivo,
					pageSize: pageSizeTableSubTipoArchivo,
					position: ['topRight'],
				}}
				scroll={{
					x:2500
				}}
				size='small'
			/>
			
			{ rex_modal_agregar_subtipo_administrador
				&& <AgregarSubTipo
					limpiarFiltro={limpiarFiltro}
			/> }
			{ rex_modal_editar_correo_subtipo 
				&& <EditarCorreoSubTipo
					limpiarFiltro={limpiarFiltro}
			/>}
			
		</div>
    )
}

export default SubtipoCargaArchivos