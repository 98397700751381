import config from '../../../config'
import { estadoRequestReducer } from "../Comunes/EstadoRequest"
import {
    OBTENER_DATA_TABLA_CARGA_ARCHIVOS,
    OBTENER_DATA_MODAL_DTS_CARGA_ARCHIVOS
} from '../../../Constantes/CargaArchivos/CargaArchivos'

export const ObtenerCargaArchivosReducer = () => async (dispatch, getState) => {
    
    const rex_seleccionar_pais_complementos = getState().complementos.rex_seleccionar_pais_complementos

    await fetch(config.api+'carga-archivos/obtener-datos-cargaarchivos',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                "req_pacid": rex_seleccionar_pais_complementos.pacid
            }),
        }
    )
    .then( async res => {
            await dispatch(estadoRequestReducer(res.status))
            return res.json()
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request
        
        if(estadoRequest == true){
            if(data.respuesta == true){
                
                dispatch({
                    type: OBTENER_DATA_TABLA_CARGA_ARCHIVOS,
                    payload: data.datos
                })

                dispatch({
                    type : OBTENER_DATA_MODAL_DTS_CARGA_ARCHIVOS,
                    payload : []
                })

            }
        }

    }).catch((error)=> {
        console.log(error)
        // dispatch(RegistrarErrorReducer(error, config.api+'login', datos))
    });

}