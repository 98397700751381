import React, { useState } from 'react'
import { Modal, Form, Row, Col, Input, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { 
	AgregarCanalAdministradorCargaArchivos,
	MostrarModalAgregarCanalCargaArchivos
} from '../../../Redux/Actions/Administrador/CargaArchivos/CargaArchivos';
import '../../../Estilos/Rutas/Administrador/CargaArchivos.css'
import FooterModalCargaArchivos from '../../../Funciones/componentes/FooterModalCargaArchivos';


const AgregarCanales = (props) => {

	const dispatch = useDispatch();

	const {
		rex_modal_agregar_canal_administrador
    } = useSelector(({administradorCargaArchivos}) => administradorCargaArchivos);

	const [ cann, setCann ] = useState({canestado:true});
	const [ enviando, setEnviando ] = useState(false);

	const agregarCanal = async () => {
		setEnviando(true);
		await dispatch(AgregarCanalAdministradorCargaArchivos(cann));
		props.setTxtBuscar('')
		setEnviando(false);
	}

	const cancelar = () => {
		dispatch(MostrarModalAgregarCanalCargaArchivos(false));
	}

	return (
        <Modal
			centered={true}
			open={rex_modal_agregar_canal_administrador}
			onCancel = {cancelar}
			width={500}
			alignItems='center'
			className='ant-modal-content'
			getContainer="#root"
			footer={null}
		>
			<Form
				autoComplete="off"
				layout="vertical"
				onFinish={agregarCanal}
			>
				<h2 className='Text-Center-Carga-Archivos'>Agregar Canal</h2>

				<Row 
				>
					<Col
						xs={{span:20}} sm={{span:20}} md={{span:20}} lg={{span:20}} xl={{span:20}}
					>
						<Form.Item
                            label='Nombre:'
                            rules={[
								{ required: true, message: 'El nombre de canal es requerido' },
								{ min: 4, message: 'El nombre es muy corto' }
							]}
                            className='Input-Usuario'
							name='cannombre'
                        >
                            <Input
                                name='cannombre'
                                onChange={(e)=> setCann({...cann, cannombre: e.target.value})}
                                className='Input-Editar'
                                value={cann}
                                size='large'
                            />
                        </Form.Item>
					</Col>
					<Col
						xs={{span:4}} sm={{span:4}} md={{span:4}} lg={{span:4}} xl={{span:4}}
						style={{display:'flex',alignItems:'center'}}
					>
						<Form.Item
                            label='Estado:'
                            className='Input-Usuario'
							name='canestado'
                        >
							<Switch
								defaultChecked
								className={cann.canestado ? 'Circulo-Activo': 'Circulo-Desactivo'}
								onChange={(valor)=> setCann({...cann, canestado: valor})}
							/>
                        </Form.Item>
					</Col>
				</Row>
				<FooterModalCargaArchivos cancelar={cancelar} enviando={enviando} nombre='Canal'/>
			</Form>
		</Modal>
    )
}

export default AgregarCanales