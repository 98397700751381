import React from 'react'

const SpiderWeb = () => {
    return (
        <div>

            <div style={{marginTop:'60px'}}>

                <iframe 
                    src={"https://app.powerbi.com/view?r=eyJrIjoiOTFjNWY1MTQtYWVmNy00NDI5LWExMmYtZjMyODZlYmFjNTI4IiwidCI6IjFkZjQ2ODhjLWI5MTUtNDEwMy05OGMwLTNhMzY4ZmIyOTNlOCJ9"} 
                    title="GODA Dashboards"
                    width="100%" height="1000px" 
                    style={{border:'none'}}
                >

                </iframe>

            </div>


        </div>
    )
}

export default SpiderWeb