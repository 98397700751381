import React from 'react'
import '../../Estilos/Componentes/Top/Top.css'
import { Row, Col, Menu } from 'antd';
import IconoCargaArchivos from '../../Assets/Imagenes/Componentes/Top/icono-carga.png'
import IconoStatus from '../../Assets/Imagenes/Componentes/Top/icono-status.png'
import IconoDataDriven from '../../Assets/Imagenes/Componentes/Top/datadriven.png'
import {Link} from "react-router-dom";
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';


const Top = () => {
    return (
        <div
            id='Contenedor-Top'
        >
            <Row style={{height:'100%'}}>
                <Col xl={12}></Col>

                <Col xl={12} style={{alignSelf: "center", float:'right', paddingRight: "30px"}}>

                    <Menu mode="horizontal" defaultSelectedKeys={['mail']} style={{float:'right', background:'transparent'}}>

                        {
                            localStorage.getItem('token') == "wvNDJsJ75a5asUfNYZT0fyZNvCyL4fKhcm2JWRsYolscZzpZLhGRNuhGd8mv"
                            ?null
                            :<Menu.Item key="controlpanel" icon={<img src={IconoStatus} className="Icono-Status-Top" />}>
                                <Link to="/panel-control">
                                    <span className='W600-S14-H19-C000000'>Panel de Control</span>
                                </Link>
                            </Menu.Item>
                            // <Menu.SubMenu key="datadrivenperformance" title={<span className='W600-S14-H19-C000000'>Data Driven Performance</span>} icon={<img src={IconoDataDriven} className="Icono-Status-Top" />}>
                            //     <Menu.Item key="tradicional" icon={null}>
                            //         <Link to="/data-driven-performance">
                            //             Canal Tradicional
                            //         </Link>
                            //     </Menu.Item>
                            //     <Menu.Item key="spiderweb" icon={null}>
                            //         <Link to="/spider-web">
                            //             SpiderWeb
                            //         </Link>
                            //     </Menu.Item>
                            //     <Menu.Item key="statusdtkc" icon={null}>
                            //         <Link to="/status-dt-kc">
                            //             Status DT KC
                            //         </Link>
                            //     </Menu.Item>
                            // </Menu.SubMenu>
                        }
                        <Menu.Item key="status" icon={<img src={IconoStatus} className="Icono-Status-Top" />}>
                            <Link to="/home">
                                <span className='W600-S14-H19-C000000'>Status</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="cargararchivos" icon={<img src={IconoCargaArchivos} className="Icono-Carga-Archivos-Top" />}>
                            <Link to="/carga-archivos">
                                <span className='W600-S14-H19-C000000'>Carga de Archivos</span>
                            </Link>
                        </Menu.Item>

                        <Menu.SubMenu key="administrador" title={<span className='W600-S14-H19-C000000'>Administrador</span>}>
                            <Menu.Item key="usuarios" icon={null}>
                                <Link to="/usuarios">
                                Usuarios
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="tipousuarios" icon={null}>
                                <Link to="/tipo-usuarios">
                                Tipo de usuarios
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="permisos" icon={null}>
                                <Link to="/permisos">
                                    Permisos
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="cuentas" icon={null}>
                                <Link to="/cuentas-paises">
                                    Cuentas
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="administradorcarga-archivos" icon={null}>
                                <Link to="/administrador/carga-archivos">
                                    Carga archivos
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="administradorcarga-dts" icon={null}>
                                <Link to="/administrador/dts">
                                    Distribuidoras
                                </Link>
                            </Menu.Item>
                        </Menu.SubMenu>

                        <Menu.Item 
                            key="Salir" icon={null}
                            onClick={() => {
                                localStorage.removeItem('token')
                                localStorage.removeItem('usutoken')
                                window.location.reload()
                            }}
                        >
                            <span className='W600-S14-H19-C000000'>Salir</span>
                        </Menu.Item>
                    </Menu>
                </Col>
            </Row>
        </div>
    )
}

export default Top