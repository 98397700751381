import {
    OBTENER_DISTRIBUIDORAS_DTS_ADMINISTRADOR,
    MODAL_EDITAR_DISTRIBUIDORAS_DTS_ADMINISTRADOR,
    MODAL_AGREGAR_DISTRIBUIDORAS_DTS_ADMINISTRADOR,
} from "../../../../Constantes/DtsDistribuidoras/DtsDistribuidoras";

const INIT_STATE = {

    rex_distribuidoras_administrador                : [],
    rex_modal_editar_distribuidoras_administrador   : false,
    rex_modal_agregar_distribuidoras_administrador  : false,
    rex_editar_dts_distribuidoras_administrador     : [],
}

export default (state = INIT_STATE, action) => {

    switch (action.type) {

        case OBTENER_DISTRIBUIDORAS_DTS_ADMINISTRADOR: {

            return {
                ...state,
                rex_distribuidoras_administrador    : action.payload
            }
        }

        case MODAL_EDITAR_DISTRIBUIDORAS_DTS_ADMINISTRADOR: {

            return {
                ...state,
                rex_modal_editar_distribuidoras_administrador   : action.payload.modal,
                rex_editar_dts_distribuidoras_administrador     : action.payload.dts

            }
        }

        case MODAL_AGREGAR_DISTRIBUIDORAS_DTS_ADMINISTRADOR: {

            return {
                ...state,
                rex_modal_agregar_distribuidoras_administrador   : action.payload,
            }
        }

        default:
            return state;
    }
}