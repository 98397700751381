import React, { useEffect, useState } from 'react'
import { Table, Row, Col, Popconfirm, Tooltip, Button, Pagination, Input, Select } from 'antd';
import '../../../Estilos/Rutas/DtsDistribuidoras/DtsDistribuidoras.css'
import { useDispatch, useSelector } from 'react-redux';
import { 
    ObtenerDtsDistribuidorasAdministradorDistribuidorasReducer,
    ModalEditarDtsDistribuidoraAdministradorDistribuidorasReducer,
    EliminarDtsDistribuidorasAdministradorDistribuidorasReducer,
    ModalAgregarDtsDistribuidoraAdministradorDistribuidorasReducer,
    DescargarDataDtsAdministradorDistribuidorasReducer
} from '../../../Redux/Actions/Administrador/DtsDistribuidoras/DtsDistribuidoras';
import { 
	ObtenerCanalesAdministradorCargaArchivosReducer
} from '../../../Redux/Actions/Administrador/CargaArchivos/CargaArchivos'
import { 
    ObtenerCuentasAdministradorCuentasReducer,
    ObtenerPaisesAdministradorCuentasReducer
} from '../../../Redux/Actions/Administrador/Cuentas/Cuentas';
import EditarDtsDistribuidoras from '../../../Componentes/Administrador/DtsDistribuidoras/EditarDtsDistribuidoras';
import AgregarDtsDistribuidoras from '../../../Componentes/Administrador/DtsDistribuidoras/AgregarDtsDistribuidoras';
import { 
	EditOutlined,
    DeleteOutlined,
    PlusCircleOutlined,
    SearchOutlined,
    LoadingOutlined,
    DownloadOutlined
} from '@ant-design/icons';
import Moment from 'moment';

const DtsDistribuidoras = () => {
    
    const { Option } = Select;

    const dispatch = useDispatch();
    const [ selectPai, setSelectPai ] = useState(0);
	const [ selectCue, setSelectCue ] = useState(0);
	const [ selectCan, setSelectCan ] = useState(0);
	const [ txtBuscar, setTxtBuscar] = useState('');
    const [ pageTableDistribuidoras, setPageTableDistribuidoras ] = useState(1);
    const [ pageSizeTableDistribuidoras, setPageSizeTableDistribuidoras ] = useState(10);
    const [ cargando, setCargando ] = useState(false);
    const [ descargaData, setDescargaData ] = useState(false);

    const {
        rex_modal_editar_distribuidoras_administrador,
        rex_modal_agregar_distribuidoras_administrador,
        rex_distribuidoras_administrador,
    } = useSelector(({administradorDistribuidoras}) => administradorDistribuidoras);

    const {
        rex_cuentas,
        rex_paises,
    } = useSelector(({administradorCuentas}) => administradorCuentas);

    const {
        rex_canales_administrador
    } = useSelector(({administradorCargaArchivos}) => administradorCargaArchivos);

    useEffect(() => {

        const timeOutInactividad = setTimeout(() => obtenerDtsDistribuidoras(), 2000);
        return () => clearTimeout(timeOutInactividad);

    }, [txtBuscar])

    useEffect(() => {        
        obtenerDtsDistribuidoras()
        dispatch(ObtenerCanalesAdministradorCargaArchivosReducer())
        dispatch(ObtenerCuentasAdministradorCuentasReducer())
        dispatch(ObtenerPaisesAdministradorCuentasReducer())
    }, [])

    const obtenerDtsDistribuidoras = async () => {
        setCargando(true);
        await dispatch(ObtenerDtsDistribuidorasAdministradorDistribuidorasReducer(txtBuscar, selectPai, selectCue, selectCan, 1, 10))
        setCargando(false);
    }

    const buscarDistribuidora = async (txtBuscar, selectPai, selectCue, selectCan, page) => {
        setCargando(true);
        await dispatch(ObtenerDtsDistribuidorasAdministradorDistribuidorasReducer(txtBuscar, selectPai, selectCue, selectCan, page, pageSizeTableDistribuidoras))
        setCargando(false);
    }

    const columns = [
        {
            title: 'Item',
            dataIndex: 'dtsid',
            key: 'dtsid',
            width: '50px',
            align: 'center',
            render: (text, record, index) => (pageTableDistribuidoras - 1) * pageSizeTableDistribuidoras + index + 1,
        },
        {
            title: 'Estado',
            key: 'dtsestado',
            width: '50px',
            align: 'center',
            render: (_,record) => (
				<div className='Contenedor-Centrado'>
                    <Tooltip placement="top" title={record.dtsestado ? 'Activado' : 'Desactivado'}>
                        <div className={`Circulo ${record.dtsestado? 'Circulo-Activo': 'Circulo-Desactivo'}`}>
                        </div>
                    </Tooltip>	
                </div>
            )
        },
        {
            title: 'Extractor',
            key: 'dtsextractor',
            width: '50px',
            align: 'center',
            render: (_,record) => (
				<div className='Contenedor-Centrado'>
                    <Tooltip placement="top" title={record.dtsextractor ? 'Activado' : 'Desactivado'}>
                        <div className={`Circulo ${record.dtsextractor? 'Circulo-Activo': 'Circulo-Desactivo'}`}>
                        </div>
                    </Tooltip>	
                </div>
            )
        },
        {
            title: 'Empresa',
            dataIndex: 'cuenombre',
            key: 'cuenombre',
            width: '100px',
            align: 'center'
        },
        {
            title: 'Pais',
            key: 'paiicono',
            width: '80px',
            align: 'center',
            render:(_, record) => (
                <Tooltip title={record.painombre}>
                    <img
                        className='Imagen-Pais'
                        src={record.paiicono}
                        />
                </Tooltip>
            )
        },
        {
            title: 'Canal',
            dataIndex: 'cannombre',
            key: 'cannombre',
            width: '150px',
            align: 'center'
        },
        {
            title: 'Distribuidora',
            key: 'dtsclientesubhml',
            width: '100px',
            align: 'center',
            render:(_, record) => (
                <div title={record.dtsclientesubhml} className="Celda-Tabla-Dts-Distribuidora">
                    {record.dtsclientesubhml}
                </div>
            )
        },
        {
            title: 'Fecha de creación',
            key: 'created_at',
            width: '180px',
            align: 'center',
            render:(_,record) => (
                <>{record.created_at?Moment(record.created_at).format('LLL'):'Sin registro' }</>
            )
        },
        {
            title: 'Fecha de actualización',
            key: 'updated_at',
            width: '180px',
            align: 'center',
            render:(_,record) => (
                <>{record.updated_at?Moment(record.updated_at).format('LLL'):'Sin registro' }</>
            )
        },
        {
            title: 'Acciones',
            key:'dtsid',
            width:'80px',
            align:'center',
            render:(_, record) => (
                <div className='Contenedor-Acciones-Dts'>
                    <Tooltip title='Editar'>
                        <Button
                            className='Boton-Accion-Editar'
                            onClick={() => dispatch(ModalEditarDtsDistribuidoraAdministradorDistribuidorasReducer(true, record))}
                        >
                            <EditOutlined
                                className='Icono-Accion-Editar'
                            />
                        </Button>
                    </Tooltip>
                    <Popconfirm
                        title='¿Eliminar Distribuidora?'
                        onConfirm={()=> dispatch(EliminarDtsDistribuidorasAdministradorDistribuidorasReducer(record.dtsid))}
                    >
                        <Tooltip title='Eliminar'>
                            <Button
                                className='Boton-Accion Boton-Accion-Eliminar'
                            >
                                <DeleteOutlined
                                    className='Icono-Accion-Eliminar'
                                />
                            </Button>
                        </Tooltip>
                    </Popconfirm>
                </div>
            )
        }
    ]

    const exportarDataExcel = async () => {
        
        setDescargaData(true);
       await dispatch(DescargarDataDtsAdministradorDistribuidorasReducer(txtBuscar, selectPai, selectCue, selectCan));
        setDescargaData(false);
    }
    
    return (
        <div className='Contenedor-Principal-Dts'>
            <Row>
                <Col span={24}>
                    <h2 className='Text-Center-Dts'>Distribuidoras</h2>
                    
                </Col>
            </Row>

            <Row style={{margin:'10px 0', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                <Col
					xl={8} lg={8} md={24} sm={24} xs={24}
				>
					<Input
						suffix={<SearchOutlined style={{margin:'0 5px'}} />}
						size='large'
						style={{borderRadius:'5px', height:'45px'}}
						placeholder='Buscar'
						value={txtBuscar}
						onChange={(e) => {
							setTxtBuscar(e.target.value)
						}}
					/>
				</Col>

                <Col className='Contenedor-Centrado'>
                    <Button
                        type='primary'
                        onClick={() => exportarDataExcel()}
                    >
                        Distribuidoras
                        {
                            descargaData
                            ? <LoadingOutlined/>
                            : <DownloadOutlined/>
                        }
                    </Button>
                    <Button
                        className='Boton-Crear-Distribuidora'
                        onClick={() => dispatch(ModalAgregarDtsDistribuidoraAdministradorDistribuidorasReducer(true))}
                    >Crear<PlusCircleOutlined/>
                    </Button>
                </Col>
            </Row>
            <Row style={{display:'flex', justifyContent:'space-between', margin:'5px 0', alignItems:'center'}}>
                <Row>
                    <Col>
						<Select
							value={selectCue}
							style={{width:'150px', margin:'3px 3px'}}
							onChange={(valor) => {
								setSelectCue(valor)
								buscarDistribuidora(txtBuscar, selectPai, valor, selectCan)
							}}
						>
							<Option value={0}>Seleccionar Empresa</Option>
							{
								rex_cuentas && rex_cuentas.map((cue) => (
									<Option key={cue.cueid} value={cue.cueid}>{cue.cuenombre}</Option>
								))
							}
						</Select>
					</Col>
                    <Col>
                        <Select
                            value={selectPai}
                            style={{width:'150px', margin:'3px 3px'}}
                            onChange={(valor)=> {
                                setSelectPai(valor)
                                buscarDistribuidora(txtBuscar, valor, selectCue, selectCan)
                            }}
                        >
                            <Option value={0}>Seleccionar Pais</Option>
                            {
                                rex_paises.map((pai)=> (
                                    <Option key={pai.paiid} value={pai.paiid}>{pai.painombre}</Option>
                                ))
                            }
                        </Select>
                    </Col>
                    <Col>
                        <Select
                            value={selectCan}
                            style={{width:'150px', margin:'3px 3px'}}
                            onChange={(valor)=> {
                                setSelectCan(valor)
                                buscarDistribuidora(txtBuscar, selectPai, selectCue, valor)
                            }}
                        >
                            <Option value={0}>Seleccionar Canal</Option>
                            {
                                rex_canales_administrador.map((can)=> (
                                    <Option key={can.canid} value={can.canid}>{can.cannombre}</Option>
                                ))
                            }
                        </Select>
                    </Col>
                </Row>
                <Row style={{margin:'10px 0'}}>
                    <Col>
                        <Pagination
                            total={rex_distribuidoras_administrador.total}
                            onChange={ async (page,pageSize) => {
                                setCargando(true)
                                await dispatch(ObtenerDtsDistribuidorasAdministradorDistribuidorasReducer(txtBuscar, selectPai, selectCue, selectCan, page, pageSize))
                                setCargando(false)
                                setPageTableDistribuidoras(page);
                                setPageSizeTableDistribuidoras(pageSize)
                            }}
                        />
                    </Col>
                </Row>
            </Row>
            <Table
                loading={cargando}
                columns={columns}
                dataSource={rex_distribuidoras_administrador.data}
                pagination={false}
				size={'small'}
            />
            { rex_modal_editar_distribuidoras_administrador && <EditarDtsDistribuidoras/>}
            { rex_modal_agregar_distribuidoras_administrador && <AgregarDtsDistribuidoras/>}
        </div>
        
    )
}

export default DtsDistribuidoras