import { Form, Input, Tooltip, Popconfirm, Table, Row, Col, Modal, Spin, Button, Pagination } from 'antd';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { 
    EliminarTipoUsuarioAdministradorUsuarioReducer, 
    GuardarTipoUsuarioAdministradorUsuariosReducer, 
    ObtenerTpusAdministradorUsuariosReducer,
    ModalAgregarTipoUsuarioAdministradorReducer,
    FiltrarTiposUsuariosAdministradorReducer
} from '../../../Redux/Actions/Administrador/TipoUsuarios/TipoUsuarios';
import { 
    PlusCircleOutlined, 
    DeleteOutlined, 
    EditOutlined, 
    ExclamationCircleOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    SearchOutlined
} from '@ant-design/icons';
import '../../../Estilos/Rutas/Usuarios/TiposUsuarios.css';
import AgregarTipoUsuarios from '../../../Componentes/Administrador/TipoUsuarios/AgregarTipoUsuarios';
import { antIcon } from '../../../Funciones/componentes/antd';
import Moment from 'moment';

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
    
    const inputNode =  <Input />;

    return (
        <td {...restProps}>
        {editing ? (
            <Form.Item
            name={dataIndex}
            style={{
                margin: 0,
                alignItems:'center'
            }}
            rules={[
                {
                    min: 4,
                    message: 'El campo es muy corto',
                },
            ]}
            >
            {inputNode}
            </Form.Item>
        ) : (
            children
        )}
        </td>
    );
};

const TipoUsuarios = () => {

    let navigate = useNavigate();

    const [ form ] = Form.useForm();
    const [ editingKey, setEditingKey ] = useState('');
    const [ enviando, setEnviando ] = useState(false);
    const [ cargando, setCargando ] = useState(false);    	
    const [pageTableTipoUsuarios, setPageTableTipoUsuarios] = useState(1)
    const [pageSizeTableTipoUsuarios, setPageSizeTableTipoUsuarios] = useState(10);
    const [ txtBuscar, setTxtBuscar ] = useState('');

    const { confirm } = Modal;

    const dispatch = useDispatch();

    const { 
        rex_modal_agregar_tipo_usuario,
        rex_filtrar_tipos_usuarios,
    } = useSelector(({administradorTipoUsuarios}) => administradorTipoUsuarios);

    const isEditing = (record) => record.tpuid === editingKey;

    useEffect(() => {

        obtenerTpus();
    
    },[]);

    const obtenerTpus = async ()=> {
        setCargando(true);
        await dispatch(ObtenerTpusAdministradorUsuariosReducer());
        setCargando(false);
    }

    const editarTipo = (record) => {


        form.setFieldsValue({
        tpunombre: '',
        tpuprivilegio: '',
        ...record,
        });
        setEditingKey(record.tpuid);
    };

    const cancelar = () => setEditingKey('');

    const eliminarTipoUsu = (record) => {

        confirm({
            title: `¿Eliminar tipo ${record.tpunombre}?`,
            icon: <ExclamationCircleOutlined />,
            okText: 'Eliminar',
            content: 'Al pulsar eliminar se eliminará completamente el tipo de usuario.',
        
            async onOk() {
                
                return await dispatch(EliminarTipoUsuarioAdministradorUsuarioReducer(record));
            },
        
            onCancel() {},
          });
    };

    const guardarTipo = async (key) => {
        
        setEnviando(true);

        const { tpunombre, tpuprivilegio } = await form.validateFields();

        let restpue = await dispatch(GuardarTipoUsuarioAdministradorUsuariosReducer(key, tpunombre, tpuprivilegio));

        restpue && setEditingKey('');

        setEnviando(false);
    };

    
    const columns = [
    {
        title: 'Item',
        key: 'tpuid',
        width: '30px',
        align: 'center',
        render: (_, record, index) => (pageTableTipoUsuarios - 1) * pageSizeTableTipoUsuarios + index + 1,
        
    },
    {
        title: 'Nombre',
        key: 'tpunombre',
        dataIndex: 'tpunombre',
        width: '200px',
        align: 'center',
        editable: true,
        render: (_,record) => (
                <div
                    className='Enlace-Permisos-Usuario'
                    onClick={() => vistaPermisos(record.tpuid, record.tpunombre)}
                >
                    {record.tpunombre}
                </div>
        )
    },
    {
      title: 'Privilegio',
      dataIndex: 'tpuprivilegio',
      key: 'tpuprivilegio',
      width: '200px',
      align: 'center',
      editable: true,
    },
    {
        title: 'Fecha de creacion',
        key: 'created_at',
        width: '200px',
        align: 'center',
        render: (_, record) => (
            <>{record.created_at?Moment(record.created_at).format('LLL'):'Sin Registro'}</>
        ),
    },
    {
        title: 'Fecha de actualización',
        key: 'updated_at',
        width: '200px',
        align: 'center',
        render: (_, record) => (
            <>{record.updated_at?Moment(record.updated_at).format('LLL'):'Sin Registro'}</>
        ),
    },
    {
        title: 'Acciones',
        key:'tpuid',
        align: 'center',
        width:'100px',
        render: (_, record) => {
            const editable = isEditing(record);
            return editable ?
                    enviando ? <Spin indicator={antIcon} /> :   (
                        <div className='Contenedor-Acciones-Iconos'>
                            <Tooltip placement='top' title='Guardar'>
                                <Button
                                    onClick={() => guardarTipo(record.tpuid)}
                                    className='Boton-Accion-Editar'
                                    >
                                    <CheckCircleOutlined
                                        className='Icono-Accion-Editar'
                                    />
                                </Button>
                            </Tooltip>
                            <Popconfirm title="Cancelar la edición?" onConfirm={cancelar}>
                                <Button 
                                    danger
                                    className='Boton-Accion-Eliminar'
                                >
                                    <CloseCircleOutlined />
                                </Button>
                            </Popconfirm>
                        </div>
                    )
            : (
            <div className='Contenedor-Acciones-Iconos'>
                <Tooltip placement="top" title='Editar'>
                    <Button
                        onClick={() => editarTipo(record)}
                        className='Boton-Accion-Editar'
                    >
                        <EditOutlined
                            disabled={editingKey !== ''}
                            className='Icono-Accion-Editar'
                        />
                    </Button>
                </Tooltip>
                <Tooltip placement="top" title='Eliminar'>
                    <Button
                        onClick={()=> eliminarTipoUsu(record)}
                        className='Boton-Accion-Eliminar'
                    >
                        <DeleteOutlined
                            className='Icono-Accion-Eliminar'
                        />
                    </Button>
                </Tooltip>
            </div>
            );
        },
        },
    ];

    const totalColumnas = columns.map((col) => {

        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                key:col.dataIndex,
                editing: isEditing(record),
            }),
        };
    });

    const vistaPermisos = (tpuid, tpunombre) => {
        navigate(`/permisos/${tpuid}/${tpunombre}`);
    }

    return (
        <div className='Contenedor-Tipo-Usuarios'>
            <Row>
                <Col span={24}>
                    <h2 className='Text-Center'>Tipos de usuarios</h2>
                </Col>
            </Row>

            <Row className='Contenedor-Fila-Boton-Agregar'>
                <Col>
                    <Button
                        type='primary'
                        onClick={() => dispatch(ModalAgregarTipoUsuarioAdministradorReducer(true))}
                    >
                        Crear<PlusCircleOutlined />
                    </Button>
                </Col>
            </Row>
            <Row className='Contenedor-Fila-Buscar-Permiso'>
                <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                    <Input
                        suffix={<SearchOutlined style={{margin:'0 5px'}} />}
                        size='large'
                        style={{borderRadius:'5px', height:'45px'}}
                        placeholder='Buscar'
                        value={txtBuscar}
                        onChange={(e) => {
                            setTxtBuscar(e.target.value)
                            dispatch(FiltrarTiposUsuariosAdministradorReducer(e.target.value))
                        }}
                    />
                </Col>
                <Col>
                    <Pagination
                        defaultCurrent={pageTableTipoUsuarios}
                        current={pageTableTipoUsuarios}
                        total={rex_filtrar_tipos_usuarios.length}
                        onChange={(page, pageSize) => {
                            setPageTableTipoUsuarios(page == 0? 1 : page)
                            setPageSizeTableTipoUsuarios(pageSize)
                        }}
                    />
                </Col>
            </Row>
            <Form
                form={form}
                component={false}
                autoComplete="off"
            >
                <Table
                    components={{
                    body: {
                        cell: EditableCell,
                    },
                    }}
                    pagination={{
                        defaultCurrent: pageTableTipoUsuarios,
                        current: pageTableTipoUsuarios,
                        pageSize: pageSizeTableTipoUsuarios,
                        position: ['topRight'],
                    }}
                    loading={cargando}
                    dataSource={rex_filtrar_tipos_usuarios}
                    columns={totalColumnas}
                    size={'small'}
                />
            </Form>

            { rex_modal_agregar_tipo_usuario && <AgregarTipoUsuarios/> }
        </div>

  );
};

export default TipoUsuarios;