import config from "../../../../config";
import { 
    OBTENER_CANALES_ADMINISTRADOR_CARGAARCHIVOS,
    OBTENER_CANALES_CUENTAS_ADMINISTRADOR_CARGAARCHIVOS,
    OBTENER_CATEGORIAS_CARGA_ADMINISTRADOR_CARGAARCHIVOS,
    OBTENER_TIPOS_CARGA_ADMINISTRADOR_CARGAARCHIVOS,
    OBTENER_SUB_TIPOS_CARGA_ADMINISTRADOR_CARGAARCHIVOS,
    OBTENER_UNICAMENTE_CATEGORIAS_SELECT_ADMINISTRADOR_CARGAARCHIVOS,
    OBTENER_UNICAMENTE_TIPOS_CARGA_SELECT_ADMINISTRADOR_CARGAARCHIVOS,

    AGREGAR_CANAL_ADMINISTRADOR_CARGAARCHIVOS,
    AGREGAR_CANAL_CUENTA_ADMINISTRADOR_CARGAARCHIVOS,
    AGREGAR_CATEGORIA_ADMINISTRADOR_CARGAARCHIVOS,
    AGREGAR_TIPO_ADMINISTRADOR_CARGAARCHIVOS,
    AGREGAR_SUBTIPO_ADMINISTRADOR_CARGAARCHIVOS,
    
    EDITAR_CANAL_CUENTA_ADMINISTRADOR_CARGAARCHIVOS,
    EDITAR_CATEGORIA_ADMINISTRADOR_CARGAARCHIVOS,
    EDITAR_TCATIPO_ADMINISTRADOR_CARGAARCHIVOS,
    EDITAR_SUBTIPO_ADMINISTRADOR_CARGAARCHIVOS,
    
    FILTRAR_CANAL_ADMINISTRADOR_CARGAARCHIVOS,
    FILTRAR_CANAL_CUENTA_ADMINISTRADOR_CARGAARCHIVOS,
    FILTRAR_CATEGORIA_ADMINISTRADOR_CARGAARCHIVOS,
    FILTRAR_TIPO_CARGA_ADMINISTRADOR_CARGAARCHIVOS,
    FILTRAR_SUBTIPO_CARGA_ADMINISTRADOR_CARGAARCHIVOS,
    
    EDITAR_CORREO_SUBTIPO_CARGA_ADMINISTRADOR_CARGAARCHIVOS,
    MODIFICAR_UNICAMENTE_CORREOS_CARGA_ADMINISTRADOR_CARGAARCHIVOS,
    EDITAR_CORREOS_ENVIAR_SUBTIPO_CARGA_ADMINISTRADOR_CARGAARCHIVOS
} from "../../../../Constantes/Administrador/CargaArchivos/CargaArchivos";
import { OBTENER_DATA_MODAL_DTS_CARGA_ARCHIVOS } from "../../../../Constantes/CargaArchivos/CargaArchivos";
import { estadoRequestReducer } from "../../Comunes/EstadoRequest";
import { 
    errorNotificacion, 
    exitoNotificacion, 
    alertaNotificacion
} from "../../../../Funciones/Notificaciones/Notificaciones";

export const ObtenerCanalesAdministradorCargaArchivosReducer = () => async (dispatch, getState) => {
    
    await fetch(config.api+'administrador/obtener-canales',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
        }
    )
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        return res.json()
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){

            dispatch({
                type: OBTENER_CANALES_ADMINISTRADOR_CARGAARCHIVOS,
                payload: {
                    canales     : data.cancanales,
                }
            })
            dispatch({
                type: FILTRAR_CANAL_ADMINISTRADOR_CARGAARCHIVOS,
                payload: data.cancanales
            })
            
            dispatch(FiltrarCanalAdministradorCargaArchivoReducer(''))
        }
    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}



export const ObtenerCanalesCuentasAdministradorCargaArchivosReducer = () => async (dispatch, getState) => {

    await fetch(config.api+'administrador/obtener-canales-cuentas',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
        }
    )
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        return res.json()
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){

            dispatch({
                type: OBTENER_CANALES_CUENTAS_ADMINISTRADOR_CARGAARCHIVOS,
                payload: {
                    canalescuentas      : data.cancanalescuentas,
                }
            })
            dispatch({
                type: FILTRAR_CANAL_CUENTA_ADMINISTRADOR_CARGAARCHIVOS,
                payload: data.cancanalescuentas
            })
        }
    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}
export const ObtenerUnicamenteCategoriasCargaArchivosAdministradorCargaArchivosReducer = () => async (dispatch, getState) => {

    await fetch(config.api+'administrador/obtener-unicamente-categorias-carga-archivos',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
        }
    )
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        return res.json()
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request
        if(estadoRequest == true){
            dispatch({
                type: OBTENER_UNICAMENTE_CATEGORIAS_SELECT_ADMINISTRADOR_CARGAARCHIVOS,
                payload: data.ccaselect
            })
        }else{
            alertaNotificacion(data.mensaje)
        }
    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}
export const ObtenerCategoriasCargaArchivosAdministradorCargaArchivosReducer = () => async (dispatch, getState) => {

    await fetch(config.api+'administrador/obtener-categorias-carga-archivos',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
        }
    )
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        return res.json()
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){

            dispatch({
                type: OBTENER_CATEGORIAS_CARGA_ADMINISTRADOR_CARGAARCHIVOS,
                payload: {
                    catcargaarchivos     : data.catcargaarchivos,
                }
            })
            dispatch({
                type: FILTRAR_CATEGORIA_ADMINISTRADOR_CARGAARCHIVOS,
                payload: data.catcargaarchivos
            })

            dispatch(FiltrarCategoriaAdministradorCargaArchivosReducer())
        }
    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}


export const ObtenerUnicamenteTiposCargaArchivosAdministradorCargaArchivosReducer = () => async (dispatch, getState) => {

    await fetch(config.api+'administrador/obtener-unicamente-tipos-carga-archivos',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
        }
    )
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        return res.json()
    })
    .then(data => {

        const estadoRequest = getState().estadoRequest.init_request;

        if(estadoRequest == true){
            dispatch({
                type: OBTENER_UNICAMENTE_TIPOS_CARGA_SELECT_ADMINISTRADOR_CARGAARCHIVOS,
                payload: data.tcaselect
            })
        }else{
            alertaNotificacion(data.mensaje)
        }

    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}

export const ObtenerTiposCargaArchivosAdministradorCargaArchivosReducer = () => async (dispatch, getState) => {

    await fetch(config.api+'administrador/obtener-tipos-carga-archivos',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
        }
    )
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        return res.json()
    })
    .then(data => {

        const estadoRequest = getState().estadoRequest.init_request;

        if(estadoRequest == true){

            dispatch({
                type: OBTENER_TIPOS_CARGA_ADMINISTRADOR_CARGAARCHIVOS,
                payload: {
                    tiposcargaarch      : data.tiposcargaarchivos,
                }
            })
            dispatch({
                type: FILTRAR_TIPO_CARGA_ADMINISTRADOR_CARGAARCHIVOS,
                payload: data.tiposcargaarchivos
            })

            dispatch(FiltrarTipoCargaAdministradorCargaArchivoReducer())
        }

    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}

export const ObtenerSubTiposCargaArchivosAdministradorCargaArchivosReducer = () => async (dispatch, getState) => {

    await fetch(config.api+'administrador/obtener-sub-tipos-carga-archivos',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
        }
    )
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        return res.json()
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){
            
            dispatch({
                type: OBTENER_SUB_TIPOS_CARGA_ADMINISTRADOR_CARGAARCHIVOS,
                payload: {
                    subtiposcargaarch       : data.stc,
                    corcorreos              : data.cor
                }
            })
            dispatch({
                type: FILTRAR_SUBTIPO_CARGA_ADMINISTRADOR_CARGAARCHIVOS,
                payload: data.stc
            })
            dispatch(FiltrarSubTipoAdministradorCargaArchivoReducer())
        }
    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////
export const AgregarCanalAdministradorCargaArchivos = (cann) => async (dispatch, getState) => {

    await fetch(config.api+'administrador/agregar-canal',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
            body: JSON.stringify(cann),
        }
    )
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        return res.json()
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){
            exitoNotificacion(data.mensaje)
            dispatch(ObtenerCanalesAdministradorCargaArchivosReducer());
            dispatch(MostrarModalAgregarCanalCargaArchivos(false))
        }else{
            if(data.errores){
                data.errores.map(err=> alertaNotificacion(err));
            }else{
                alertaNotificacion(data.mensaje);
            }
        }
    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}

export const AgregarCanalCuentaAdministradorCargaArchivosReducer = ( cancuen ) => async (dispatch, getState) => {

    await fetch(config.api + 'administrador/agregar-canal-cuenta',{
        mode: 'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'api_token'	   : localStorage.getItem('usutoken')
        },
        body: JSON.stringify(cancuen),
    })
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        return res.json()
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){

            exitoNotificacion(data.mensaje)
            dispatch(ObtenerCanalesCuentasAdministradorCargaArchivosReducer());
            dispatch({
                type: AGREGAR_CANAL_CUENTA_ADMINISTRADOR_CARGAARCHIVOS,
                payload: false
            })
        }else{
            alertaNotificacion(data.mensaje);
        }
    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}

export const AgregarCategoriaAdministradorCargaArchivosReducer = ( ccan ) => async (dispatch, getState) => {

    await fetch(config.api + 'administrador/agregar-categoria-carga-archivos',{
        mode: 'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'api_token'	   : localStorage.getItem('usutoken')
        },
        body: JSON.stringify(ccan),
    })
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        return res.json()
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){

            exitoNotificacion(data.mensaje)
            dispatch(ObtenerCategoriasCargaArchivosAdministradorCargaArchivosReducer());
            dispatch(MostrarModalAgregarCategoriaCargaArchivos(false))
        }else{
        
            if(data.errores){
                data.errores.map(err=> alertaNotificacion(err));
            }else{
                alertaNotificacion(data.mensaje);
            }
        }
    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}

export const AgregarTipoCargaAdministradorCargaArchivosReducer = ( tcan ) => async (dispatch, getState) => {

    await fetch(config.api + 'administrador/agregar-tipo-carga-archivos',{
        mode: 'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'api_token'	   : localStorage.getItem('usutoken')
        },
        body: JSON.stringify(tcan),
    })
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        return res.json()
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){

            exitoNotificacion(data.mensaje)
            dispatch(ObtenerTiposCargaArchivosAdministradorCargaArchivosReducer());
            dispatch(MostrarModalAgregarTipoCargaCargaArchivos(false))
        }else{
        
            if(data.errores){
                data.errores.map(err=> alertaNotificacion(err));
            }else{
                alertaNotificacion(data.mensaje);
            }
        }
    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}

export const AgregarSubTipoAdministradorCargaArchivosReducer = ( stcn ) => async (dispatch, getState) => {

    await fetch(config.api + 'administrador/agregar-subtipo-carga-archivos',{
        mode: 'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'api_token'	   : localStorage.getItem('usutoken')
        },
        body: JSON.stringify(stcn),
    })
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        return res.json()
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){
            exitoNotificacion(data.mensaje)
            dispatch(ObtenerSubTiposCargaArchivosAdministradorCargaArchivosReducer());
            dispatch({
                type: AGREGAR_SUBTIPO_ADMINISTRADOR_CARGAARCHIVOS,
                payload: false
            })
        }else{
            if(data.mensaje){
                alertaNotificacion(data.mensaje);
            }else{
                data.errores.map(err=> alertaNotificacion(err));
            }
        }
    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////
export const EditarCanalAdministradorCargaArchivos = (cane) => async (dispatch, getState) => {

    let editado = true;

    await fetch(config.api + 'administrador/editar-canal',{
        mode: 'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'api_token'	   : localStorage.getItem('usutoken')
        },
        body: JSON.stringify(cane),
    })
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        return res.json()
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){

            exitoNotificacion('Canal editado con éxito')
            dispatch(ObtenerCanalesAdministradorCargaArchivosReducer());

        }else{
            editado = false;
            if(data.errores.length > 0){

                data.errores.map(err=> alertaNotificacion(err));
            }else{
                
                alertaNotificacion('Ha ocurrido un error');
            }
        }
    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
    return editado;
}

export const EditarCanalCuentaAdministradorCargaArchivos = ( cancuee ) => async (dispatch, getState) => {

    await fetch(config.api + 'administrador/editar-canal-cuenta',{
        mode: 'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'api_token'	   : localStorage.getItem('usutoken')
        },
        body: JSON.stringify(cancuee),
    })
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        return res.json()
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){

            exitoNotificacion(data.mensaje)
            dispatch(ObtenerCanalesCuentasAdministradorCargaArchivosReducer());
            dispatch(MostrarModalEditarCanalCuentaCargaArchivos(false, []))
        }else{
                
            if(data.errores){
                data.errores.map(err=> alertaNotificacion(err));
            }
            else{
                alertaNotificacion(data.mensaje);
            }
        }
    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}

export const EditarCategoriaAdministradorCargaArchivosReducer = (ccacate) => async (dispatch, getState) => {

    await fetch(config.api + 'administrador/editar-categoria-carga-archivos',{
        mode: 'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'api_token'	   : localStorage.getItem('usutoken')
        },
        body: JSON.stringify(ccacate),
    })
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        return res.json()
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){

            exitoNotificacion(data.mensaje);
            dispatch(ObtenerCategoriasCargaArchivosAdministradorCargaArchivosReducer());
            dispatch(MostrarModalEditarCategoriaCargaArchivos(false, []))
        }else{
                
            if(data.errores){
                data.errores.map(err=> alertaNotificacion(err));
            }else{
                alertaNotificacion(data.mensaje);
            }
        }
    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}

export const EditarTipoAdministradorCargaArchivosReducer = ( tcatipe ) => async (dispatch, getState) => {

    await fetch(config.api + 'administrador/editar-tipo-carga-archivos',{
        mode: 'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'api_token'	   : localStorage.getItem('usutoken')
        },
        body: JSON.stringify({
            "tcaid"     : tcatipe.tcaid,
            "tcanombre" : tcatipe.tcanombre,
            "tcaestado" : tcatipe.tcaestado,
            "ccanombre" : tcatipe.ccanombre,
            "canid"     : tcatipe.canid,
            "cueid"     : tcatipe.cueid,
            "paiid"     : tcatipe.paiid
        }),
    })
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        return res.json()
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){

            exitoNotificacion(data.mensaje)
            dispatch(ObtenerTiposCargaArchivosAdministradorCargaArchivosReducer());
            dispatch(MostrarModalEditarTipoCargaCargaArchivos(false, []))
        }else{
                
            if(data.errores){
                data.errores.map(err=> alertaNotificacion(err));
            }else{
                errorNotificacion(data.mensaje);
            }
        }
    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}

export const EditarSubTipoAdministradorCargaArchivosReducer = ( stce, dtsSeleccionada, dtsDistribuidora ) => async (dispatch, getState) => {

    let respuesta = false

    await fetch(config.api + 'administrador/editar-sub-tipo-carga-archivos',{
        mode: 'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'api_token'	   : localStorage.getItem('usutoken')
        },
        body: JSON.stringify({
            "stcid"                 : stce.stcid,
            "stcnombre"             : stce.stcnombre,
            "tcanombre"             : stce.tcanombre,
            "ccanombre"             : stce.ccanombre,
            "canid"                 : stce.canid,
            "cueid"                 : stce.cueid,
            "paiid"                 : stce.paiid,
            "stcestado"             : stce.stcestado,
            "stcrangofecha"         : stce.stcrangofecha,
            "stcfiladistribuidora"  : stce.stcfiladistribuidora,
            "horarios"              : stce.horarios,
            "stchorario"            : stce.stchorario,
            "stcrutaaws"            : stce.stcrutaaws,
            "stcpathaws"            : stce.stcpathaws,
            "stcreglasarchivos"     : dtsDistribuidora,
            "stcdtsseleccionada"    : dtsSeleccionada,
            "stcbucketaws"          : stce.stcbucketaws
        }),
    })
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        return res.json()
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){

            exitoNotificacion(data.mensaje)
            dispatch(ObtenerSubTiposCargaArchivosAdministradorCargaArchivosReducer());
            // dispatch({
            //     type: EDITAR_SUBTIPO_ADMINISTRADOR_CARGAARCHIVOS,
            //     payload: {
            //         modal_editar	: false,
            //         subtipe			: []
            //     }
            // })

            respuesta = true

        }else{
            if(data.errores){
                data.errores.map(err=> alertaNotificacion(err));
            }else{
                alertaNotificacion(data.mensaje);
            }
        }
    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });

    return respuesta
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const EliminarCanalAdministradorCargaArchivosReducer = (canid) => async ( dispatch, getState) => {

    await fetch(config.api+'administrador/eliminar-canal-carga-archivos',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({canid}),
        }
    )
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        return res.json()
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){
            dispatch(ObtenerCanalesAdministradorCargaArchivosReducer())
            exitoNotificacion(data.mensaje);
        }else{
            alertaNotificacion(data.mensaje)
        }
    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}

export const EliminarCanalCuentaAdministradorCargaArchivosReducer = ( cacid ) => async ( dispatch, getState ) => {

    let respuesta = false;

    await fetch(config.api+'administrador/eliminar-canal-cuenta-carga-archivos',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({cacid}),
        }
    )
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        return res.json()
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){
            dispatch(ObtenerCanalesCuentasAdministradorCargaArchivosReducer())
            exitoNotificacion(data.mensaje);
            respuesta = true;
        }else{
            alertaNotificacion(data.mensaje)
        }
    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });

    return respuesta;
}
export const EliminarCategoriaAdministradorCargaArchivosReducer = ( ccaid ) => async ( dispatch, getState ) => {

    let respuesta = false;

    await fetch(config.api+'administrador/eliminar-categoria-carga-archivos',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({ccaid}),
        }
    )
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        return res.json()
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){
            dispatch(ObtenerCategoriasCargaArchivosAdministradorCargaArchivosReducer())
            exitoNotificacion(data.mensaje);

            respuesta = true;
        }else{
            alertaNotificacion(data.mensaje)
        }
    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });

    return respuesta;
}
export const EliminarTipoCargaAdministradorCargaArchivosReducer = ( tcaid ) => async ( dispatch, getState ) => {

    let respuesta = false;

    await fetch(config.api+'administrador/eliminar-tipo-carga-carga-archivos',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({tcaid}),
        }
    )
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        return res.json()
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){
            dispatch(ObtenerTiposCargaArchivosAdministradorCargaArchivosReducer())
            exitoNotificacion(data.mensaje);
            respuesta = true;
        }else{
            alertaNotificacion(data.mensaje)
        }
    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });

    return respuesta;
}

export const EliminarSubTipoCargaArchivosAdministradorCargaArchivosReducer = ( stcid ) => async (dispatch, getState) => {
    
    await fetch(config.api+'administrador/eliminar-sub-tipo-carga-archivos',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({stcid}),
        }
    )
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        return res.json()
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){
            dispatch(ObtenerSubTiposCargaArchivosAdministradorCargaArchivosReducer())
            exitoNotificacion(data.mensaje);
        }else{
            alertaNotificacion(data.mensaje)
        }
    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const EditarCorreosStcAdministradorCargaArchivosReducer = ( stcid ) => async ( dispatch , getState ) => {

    let stcCorreosEditar    = getState().administradorCargaArchivos.rex_correos_carga_enviar_administrador;

    await fetch(config.api+'administrador/editar-correo-sub-tipo-carga-archivos',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                stcid               : stcid,
                stcCorreosEditar    : stcCorreosEditar
            }),
        }
    )
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        return res.json()
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){
            exitoNotificacion(data.mensaje);
            dispatch(MostrarModalEditarCorreoSubTipoAdministradorCargaArchivosReducer(false,[]));
            dispatch(ObtenerSubTiposCargaArchivosAdministradorCargaArchivosReducer());
        }else{
            alertaNotificacion(data.mensaje)
        }
    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////7
export const MostrarModalAgregarCanalCargaArchivos = (estado) => async ( dispatch, getState ) => {

    dispatch({
        type: AGREGAR_CANAL_ADMINISTRADOR_CARGAARCHIVOS,
        payload: estado
    })
}

export const MostrarModalAgregarCanalCuentaCargaArchivos = (estado) => async ( dispatch, getState ) => {

    dispatch({
        type: AGREGAR_CANAL_CUENTA_ADMINISTRADOR_CARGAARCHIVOS,
        payload: estado
    })
}

export const MostrarModalAgregarCategoriaCargaArchivos = (estado) => async ( dispatch, getState ) => {

    dispatch({
        type: AGREGAR_CATEGORIA_ADMINISTRADOR_CARGAARCHIVOS,
        payload: estado
    })
}

export const MostrarModalAgregarTipoCargaCargaArchivos = (estado) => async ( dispatch, getState ) => {
    
    dispatch({
        type: AGREGAR_TIPO_ADMINISTRADOR_CARGAARCHIVOS,
        payload: estado
    })
}

export const MostrarModalAgregarSubTipoCargaCargaArchivos = (estado) => async ( dispatch, getState ) => {
    
    dispatch({
        type: AGREGAR_SUBTIPO_ADMINISTRADOR_CARGAARCHIVOS,
        payload: estado
    })
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const MostrarModalEditarCanalCuentaCargaArchivos = (estado, cancuee) => async ( dispatch, getState ) => {

    dispatch({
        type: EDITAR_CANAL_CUENTA_ADMINISTRADOR_CARGAARCHIVOS,
        payload: {
            modal_editar	: estado,
			cancuee			: cancuee	
        }
    })
}

export const MostrarModalEditarCategoriaCargaArchivos = (estado, ccacate) => async ( dispatch, getState ) => {

    dispatch({
        type: EDITAR_CATEGORIA_ADMINISTRADOR_CARGAARCHIVOS,
        payload: {
            modal_editar: estado,
            ccacate		: ccacate
        }
    })
}

export const MostrarModalEditarTipoCargaCargaArchivos = (estado, tcatipe) => async ( dispatch, getState ) => {
    
    dispatch({
        type: EDITAR_TCATIPO_ADMINISTRADOR_CARGAARCHIVOS,
        payload: {
            modal_editar	: estado,
            tcatipe			: tcatipe
        }
    })
}

export const MostrarModalEditarSubTipoCargaCargaArchivos = (estado, subtipe) => async ( dispatch, getState ) => {
    
    dispatch({
        type: EDITAR_SUBTIPO_ADMINISTRADOR_CARGAARCHIVOS,
        payload: {
            modal_editar	: estado,
            subtipe			: subtipe
        }
    })
}

export const MostrarModalEditarCorreoSubTipoAdministradorCargaArchivosReducer = ( estado, subtipe ) => async( dispatch, getState) => {

    let copia;

    if(subtipe.correosTotales){
        copia = [...subtipe.correosTotales];
    }else{
        copia = []
    }
    dispatch({
        type: EDITAR_CORREO_SUBTIPO_CARGA_ADMINISTRADOR_CARGAARCHIVOS,
        payload: {
            modal_editar	: estado,
            subtipe			: subtipe,
        }
    })

    dispatch({
        type: EDITAR_CORREOS_ENVIAR_SUBTIPO_CARGA_ADMINISTRADOR_CARGAARCHIVOS,
        payload: copia
    })
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const AgregarCorreoInputAdministradorCargaArchivosReducer = ( corcorreo, principal ) => async ( dispatch, getState ) => {

    let listaCorreos        = getState().administradorCargaArchivos.rex_correos_carga_administrador;
    let stceditar           = getState().administradorCargaArchivos.rex_correos_carga_enviar_administrador;
    let existeCorreoEnviar  = stceditar.find(( cor ) => cor.corcorreo == corcorreo);

    let respuesta = true;

    if(existeCorreoEnviar){

        if(existeCorreoEnviar.scotipodestinatario == principal || existeCorreoEnviar.scotipodestinatario == 2){
            respuesta = false;
        }else{
            
            let indexCorreo = stceditar.findIndex(( cor ) => cor.corcorreo == corcorreo);
            stceditar[indexCorreo]['scotipodestinatario'] = 2;
        }
    }else{
        
        let existeCorreo  = listaCorreos.find(( cor ) => cor.corcorreo == corcorreo);

        if(existeCorreo){
            stceditar.push({...existeCorreo, scotipodestinatario: principal ? 1 : 0});        
        }else{

            const idCorreo = Math.floor(Math.random() * (99999 - 10) + 10);
            const nuevoCorreo = { "corid" : idCorreo, "corcorreo" : corcorreo }
            listaCorreos.push(nuevoCorreo)

            dispatch({
                type: MODIFICAR_UNICAMENTE_CORREOS_CARGA_ADMINISTRADOR_CARGAARCHIVOS,
                payload: listaCorreos
            })

            stceditar.push({...nuevoCorreo, scotipodestinatario: principal ? 1 : 0});
        }        
    }

    dispatch({
        type: EDITAR_CORREOS_ENVIAR_SUBTIPO_CARGA_ADMINISTRADOR_CARGAARCHIVOS,
        payload: stceditar
    })

    return respuesta;
}

export const agregarCorreoSelectAdministradorCargaArchivosReducer = ( corid, corcorreo, tag, tipo ) => async (dispatch, getState) => {

    let stceditar        = getState().administradorCargaArchivos.rex_correos_carga_enviar_administrador;
    let listaCorreos     = getState().administradorCargaArchivos.rex_correos_carga_administrador;

    let existeCorreo    = stceditar.findIndex(( cor ) => cor.corid == corid);
    let correo          = listaCorreos.find(( cor ) => cor.corid == corid);

    if(tag != null ){

        let correosActuales = stceditar.filter((cor) => cor.corid != corid)
        stceditar = correosActuales;
    }else{

        if(existeCorreo != -1){
            stceditar[existeCorreo]['scotipodestinatario'] = tipo;
            
        }else{
            const nuevoCorreo = {...correo, scotipodestinatario: tipo}
            stceditar.push(nuevoCorreo);
        }
    }

    dispatch({
        type: EDITAR_CORREOS_ENVIAR_SUBTIPO_CARGA_ADMINISTRADOR_CARGAARCHIVOS,
        payload: stceditar
    })
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const FiltrarCanalAdministradorCargaArchivoReducer = (txtBuscar) => (dispatch, getState) => {

    let listaCanales     = getState().administradorCargaArchivos.rex_canales_administrador;

    if(txtBuscar){

        let canalesFiltrados = listaCanales.filter((can) => 
            can.cannombre.toLowerCase().includes(txtBuscar.toLocaleLowerCase())
        )
        dispatch({
            type    : FILTRAR_CANAL_ADMINISTRADOR_CARGAARCHIVOS,
            payload : canalesFiltrados
        })
    }else{
        dispatch({
            type    : FILTRAR_CANAL_ADMINISTRADOR_CARGAARCHIVOS,
            payload : listaCanales
        });
    }
}

export const FiltrarCanalCuentaAdministradorCargaArchivosReducer = (txtBuscar, canid, cueid) => (dispatch, getState) => {
    let listaCanalesCuentas     = getState().administradorCargaArchivos.rex_canales_cuentas_administrador;

    if(txtBuscar || canid || cueid){

        let canalescuentasFiltradas = listaCanalesCuentas.filter((ccu) => 
            (
                (ccu.cannombre.toLocaleLowerCase().includes(txtBuscar.toLocaleLowerCase()))
                || (ccu.cuenombre.toLocaleLowerCase().includes(txtBuscar.toLocaleLowerCase()))
            )
            && (canid != 0 ? ccu.canid == canid : typeof ccu.canid == 'number' )
            && (cueid != 0 ? ccu.cueid == cueid : typeof ccu.cueid == 'number' )
        )

        dispatch({
            type    : FILTRAR_CANAL_CUENTA_ADMINISTRADOR_CARGAARCHIVOS,
            payload : canalescuentasFiltradas
        })

    }else{
        dispatch({
            type    : FILTRAR_CANAL_CUENTA_ADMINISTRADOR_CARGAARCHIVOS,
            payload : listaCanalesCuentas
        })
    }
}

export const FiltrarCategoriaAdministradorCargaArchivosReducer = (txtBuscar, paiid, cueid, canid) => ( dispatch, getState) => {

    let listaCategorias     = getState().administradorCargaArchivos.rex_categorias_carga_administrador;
    
    if(txtBuscar || paiid || cueid || canid){

        let categoriasFiltradas = listaCategorias.filter((cat) => 
            (
                (cat.ccanombre.toLowerCase().includes(txtBuscar.toLocaleLowerCase()))
                || (cat.cuenombre.toLowerCase().includes(txtBuscar.toLocaleLowerCase()))
                || (cat.painombre.toLowerCase().includes(txtBuscar.toLocaleLowerCase()))
                || (cat.cannombre.toLowerCase().includes(txtBuscar.toLocaleLowerCase()))
            )
            && (paiid != 0 ? cat.paiid == paiid : typeof cat.paiid == 'number' )
            && (cueid != 0 ? cat.cueid == cueid : typeof cat.cueid == 'number' )
            && (canid != 0 ? cat.canid == canid : typeof cat.canid == 'number' )
        )
        dispatch({
            type    : FILTRAR_CATEGORIA_ADMINISTRADOR_CARGAARCHIVOS,
            payload : categoriasFiltradas
        })
    }else{

        dispatch({
            type    : FILTRAR_CATEGORIA_ADMINISTRADOR_CARGAARCHIVOS,
            payload : listaCategorias
        });
    }
}

export const FiltrarTipoCargaAdministradorCargaArchivoReducer = (txtBuscar = '', paiid = 0, cueid = 0, canid = 0, ccanombre= '') => (dispatch, getState) => {

    let listaTipoCarga     = getState().administradorCargaArchivos.rex_tipos_carga_administrador;

    if(txtBuscar || paiid >= 0 || cueid >= 0 || canid >= 0 || ccanombre != 0){

        let tipoCargaFiltradas = listaTipoCarga.filter((tip) => 
           (
            (tip.tcanombre != "" && tip.tcanombre.toLowerCase().includes(txtBuscar.toLocaleLowerCase()))
            || (tip.ccanombre != "" && tip.ccanombre.toLowerCase().includes(txtBuscar.toLocaleLowerCase()))
            || (tip.cuenombre.toLowerCase().includes(txtBuscar.toLocaleLowerCase()))
            || (tip.painombre.toLowerCase().includes(txtBuscar.toLocaleLowerCase()))
            || (tip.cannombre.toLowerCase().includes(txtBuscar.toLocaleLowerCase()))
           )
            && (tip.ccanombre != "" && (ccanombre != 0 ? tip.ccanombre == ccanombre : typeof tip.ccanombre == 'string'))
            && (cueid != 0 ? tip.cueid == cueid : typeof tip.cueid == 'number' )
            && (canid != 0 ? tip.canid == canid : typeof tip.canid == 'number' )
            && (paiid != 0 ? tip.paiid == paiid : typeof tip.paiid == 'number' )
        )
        dispatch({
            type    : FILTRAR_TIPO_CARGA_ADMINISTRADOR_CARGAARCHIVOS,
            payload : tipoCargaFiltradas
        })
    }else{
        dispatch({
            type    : FILTRAR_TIPO_CARGA_ADMINISTRADOR_CARGAARCHIVOS,
            payload : listaTipoCarga
        });
    }
}

export const SeleccionarDtsEditarSubtipoCargaArchivoReducer  = ( dtsid ) => ( dispatch, getState ) => {

    let dts = getState().modalDtsCargaArchivos.rex_data_modal_dts_carga_archivos

    dts.map((dt, index_dt) => {

        dts[index_dt]['seleccionado'] = false
        if(dt.dtsid == dtsid){
            dts[index_dt]['seleccionado'] = true
        }
    })
    dispatch({
        type    : OBTENER_DATA_MODAL_DTS_CARGA_ARCHIVOS,
        payload : dts
    })
}

export const FiltrarSubTipoAdministradorCargaArchivoReducer = (txtBuscar = '', paiid = 0, cueid = 0, canid = 0) => (dispatch, getState) => {

    let listaSubtipo     = getState().administradorCargaArchivos.rex_sub_tipos_carga_administrador;

    if(txtBuscar || paiid >= 0 || cueid >= 0 || canid >= 0){

        let subtipoFiltrados = listaSubtipo.filter((sub) => 
            (   sub.stcnombre.toLowerCase().includes(txtBuscar.toLocaleLowerCase())
                || sub.tcanombre.toLowerCase().includes(txtBuscar.toLocaleLowerCase())
                || sub.ccanombre.toLowerCase().includes(txtBuscar.toLocaleLowerCase())
                || sub.stcnombre.toLowerCase().includes(txtBuscar.toLocaleLowerCase())
                || sub.paicue[0].painombre.toLowerCase().includes(txtBuscar.toLocaleLowerCase())
                || sub.paicue[0].cuenombre.toLowerCase().includes(txtBuscar.toLocaleLowerCase())
            )
            && (paiid != 0 ? sub.paicue[0]['paiid'] == paiid : typeof sub.paicue[0]['paiid'] == 'number' )
            && (cueid != 0 ? sub.paicue[0]['cueid'] == cueid : typeof sub.paicue[0]['paiid'] == 'number' )
            && (canid != 0 ? sub.canid == canid : typeof sub.canid == 'number' )
        )
        dispatch({
            type    : FILTRAR_SUBTIPO_CARGA_ADMINISTRADOR_CARGAARCHIVOS,
            payload : subtipoFiltrados
        })
    }else{
        dispatch({
            type    : FILTRAR_SUBTIPO_CARGA_ADMINISTRADOR_CARGAARCHIVOS,
            payload : listaSubtipo
        });
    }

}