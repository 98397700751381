import { Modal, Row, Col, Radio, Button } from 'antd'
import React, {useState} from 'react'

const ModalDts = (props) => {

    const rex_mostrar_modal_seleccionar_dt_tabla_carga_archivos = props.rex_mostrar_modal_seleccionar_dt_tabla_carga_archivos
    const SeleccionarDtFilaTablaCargaArchivoReducer = props.SeleccionarDtFilaTablaCargaArchivoReducer
    const rex_data_modal_dts_carga_archivos = props.rex_data_modal_dts_carga_archivos
    const SeleccionarDtCargaArchivoReducer = props.SeleccionarDtCargaArchivoReducer
    const rex_fila_data_seleccionado_tabla_carga_archivos = props.rex_fila_data_seleccionado_tabla_carga_archivos

    const [txtBuscar, setTxtBuscar] = useState("")
    const [dtseleccionada, setDtseleccionada] = useState(null)

    return (
        <>
            <Modal
                centered={true}
                title={null}
                footer={null}
                className="Modal-Dts-Carga-Archivos"
                closeIcon={<div></div>}
                visible={rex_mostrar_modal_seleccionar_dt_tabla_carga_archivos}
                width={"90%"} 
                onCancel={() => {
                    SeleccionarDtFilaTablaCargaArchivoReducer(false, null)
                }}
            >
                <div>

                    <div 
                        className='W600-S15-H20-C787878'
                        style={{
                            textAlignLast: "center",
                            marginBottom:'20px'
                        }}
                    >
                        Seleccionar {rex_fila_data_seleccionado_tabla_carga_archivos.canal == 'Tradicional' ? ' la Sucural':'el Holding ' } que deseas cargar
                    </div>

                    <div
                        style={{
                            textAlign: "-webkit-center",
                            marginBottom:'20px'
                        }}
                    >
                        <div
                            className='Input-Buscar-Dt-Carga-Archivos'
                        >
                            <input 
                                placeholder='Hola, ¿qué estás buscando?' 
                                onChange={(e) => {
                                    setTxtBuscar(e.target.value)
                                }}
                            />
                        </div>
                    </div>

                    <Row
                        style={{
                            // textAlign: "center",
                            height: "55vh",
                            overflow: "auto",
                        }}
                    >
                        {
                            rex_data_modal_dts_carga_archivos.map((dat, pos) => {
                                return (
                                    dat.dtsclientesubhml.toLowerCase().includes(txtBuscar.toLowerCase())
                                    ?<Col 
                                        xl={6} 
                                        style={{
                                            marginBottom:'8px',
                                        }}
                                        key={dat.dtsid}
                                    >
                                        
                                        <div
                                            title={dat.dtsclientesubhml}
                                            style={{
                                                
                                            }}
                                        >
                                            <Radio 
                                                checked={dat.seleccionado}
                                                onChange={() => {
                                                    setDtseleccionada(dat)
                                                    SeleccionarDtCargaArchivoReducer(pos)
                                                }}
                                            >
                                                <span className="W400-S12-H16-C1E1E1E">
                                                    {
                                                        dat.dtsclientesubhml
                                                    }
                                                </span>
                                                
                                            </Radio>
                                        </div>
                                    </Col>
                                    :null
                                )
                            })
                        }
                    </Row>



                    <Row
                        style={{textAlign: "center", marginTop:'20px'}}
                    >
                        <Col xl={6}></Col>
                        <Col xl={6}></Col>
                        <Col xl={6}></Col>
                        <Col xl={6}>
                            <Button
                                type='primary'
                                onClick={() => {
                                    SeleccionarDtFilaTablaCargaArchivoReducer(false, dtseleccionada)
                                }}
                            >
                                Hecho
                            </Button>
                        </Col>
                    </Row>
                </div>

            </Modal>
        </>
    )
}

export default ModalDts