import React, { useState, useEffect } from 'react'
import { Modal, Table, Button, Popconfirm, Input } from 'antd'
import { 
	OcultarModalTiposPermisosAdministradorReducer,
	EliminarTipoPermisoAdministradorPermisosReducer,
	ObtenerTiposPermisosAdministradorPermisosReducer,
	EditarTipoPermisoAdministradorPermisosReducer
} from '../../../Redux/Actions/Administrador/Permisos/Permisos';
import '../../../Estilos/Rutas/Administrador/TiposPermisos.css';
import { useSelector, useDispatch } from 'react-redux';
import { 
	EditOutlined,
    DeleteOutlined,
	CheckCircleOutlined,
	CloseCircleOutlined,
	LoadingOutlined
} from '@ant-design/icons'

const TiposPermisos = () => {

	const dispatch = useDispatch();

	const { 
        rex_modal_tipos_permisos,
		rex_tipos_permisos
    } = useSelector(({administradorPermisos}) => administradorPermisos);

	const [ tppe, setTppe ] = useState({tppid:null});
	const [ eliminando, setEliminando ] = useState(false);
	const [ cargando, setCargando ] = useState(false);
	const [ editando, setEditando ] = useState(false);
	
	useEffect(() => {
		obtenerTiposPermisos()
	}, [])

	const editarTipoUsuario = async () => {
		setEditando(true)
		let respuesta = await dispatch(EditarTipoPermisoAdministradorPermisosReducer(tppe))
		setEditando(false)

		respuesta && setTppe({tppeid: null})
	}
		
	const eliminarTipoUsuario = async ( tppid ) => {
		setEliminando(true);
		await dispatch(EliminarTipoPermisoAdministradorPermisosReducer(tppid));
		setEliminando(false);
	}

	const obtenerTiposPermisos = async () => {
		setCargando(true);
		await dispatch(ObtenerTiposPermisosAdministradorPermisosReducer())
		setCargando(false)
	}

    const columns = [
		{
            title: 'Nombre',
            key: 'tppnombre',
            width: '150px',
            align: 'center',
			render: (_, record) => (
				tppe.tppid == record.tppid
				? <Input
					defaultValue={record.tppnombre}
					className='Text-Center'
					required
					onChange={(e) => {
						setTppe({...tppe, tppnombre: e.target.value})
					}}
				  />

				: <p className='Text-Center'>{record.tppnombre}</p>
			)
        },
		{
            title: 'Acciones',
            dataIndex: 'tppid',
            key: 'tppnombre',
            width: '80px',
            align: 'center',
			render: (_, record) => (
				tppe.tppid == record.tppid
				? <div>
					{
						editando
						? <Button>
							<LoadingOutlined />
						  </Button>
						: <>
							<Button
								className='Boton-Accion-Editar'
								onClick={()=> editarTipoUsuario()}
							>
								<CheckCircleOutlined
									className='Icono-Accion-Editar'
								/>
							</Button>
							<Button
								className='Boton-Accion-Eliminar'
								onClick={() => setTppe({tppid: null})}
							>
								<CloseCircleOutlined
									className='Icono-Accion-Eliminar'
								/>
							</Button>
						  </>
					}
				  </div>
				: <div>
					<Button
						className='Boton-Accion-Editar'
						onClick={() => setTppe({tppid: record.tppid, tppnombre: record.tppnombre})}
					>
						<EditOutlined
							className='Icono-Accion-Editar'
						/>
					</Button>
					<Popconfirm
						title='¿Eliminar tipo de permiso?'
						okText='Aceptar'
						onConfirm={() => eliminarTipoUsuario(record.tppid)}
						okButtonProps={{
							loading: eliminando,
						}}
					>
						<Button
							className='Boton-Accion-Eliminar'
						>
							<DeleteOutlined
								className='Icono-Accion-Eliminar'
							/>
						</Button>
					</Popconfirm>
				  </div>
			)
        },
	];
	
	return (
		<Modal
			open={rex_modal_tipos_permisos}
			onCancel = {() => dispatch(OcultarModalTiposPermisosAdministradorReducer(false))}
			okText='Guardar'
			width={500}
			alignItems='center'
			footer={null}
		>
			<h2 className='Text-Center-Carga-Archivos'>Tipos de permisos</h2>
			<div className='Tabla-Tipos-Permisos'>
				<Table
					columns={columns}
					loading={cargando}
					dataSource={rex_tipos_permisos}
					pagination={{
						position:['none','none']
					}}
					bordered={false}
					showHeader={false}
					size='small'
				/>
			</div>
		</Modal>
	)
}

export default TiposPermisos