import {
    OBTENER_CANALES_ADMINISTRADOR_CARGAARCHIVOS,
    OBTENER_CANALES_CUENTAS_ADMINISTRADOR_CARGAARCHIVOS,
    OBTENER_CATEGORIAS_CARGA_ADMINISTRADOR_CARGAARCHIVOS,
    OBTENER_TIPOS_CARGA_ADMINISTRADOR_CARGAARCHIVOS,
    OBTENER_UNICAMENTE_CATEGORIAS_SELECT_ADMINISTRADOR_CARGAARCHIVOS,
    OBTENER_UNICAMENTE_TIPOS_CARGA_SELECT_ADMINISTRADOR_CARGAARCHIVOS,
    AGREGAR_CANAL_ADMINISTRADOR_CARGAARCHIVOS,
    EDITAR_CANAL_CUENTA_ADMINISTRADOR_CARGAARCHIVOS,
    EDITAR_CATEGORIA_ADMINISTRADOR_CARGAARCHIVOS,
    AGREGAR_CANAL_CUENTA_ADMINISTRADOR_CARGAARCHIVOS,
    AGREGAR_CATEGORIA_ADMINISTRADOR_CARGAARCHIVOS,
    OBTENER_SUB_TIPOS_CARGA_ADMINISTRADOR_CARGAARCHIVOS,
    EDITAR_TCATIPO_ADMINISTRADOR_CARGAARCHIVOS,
    AGREGAR_TIPO_ADMINISTRADOR_CARGAARCHIVOS,
    AGREGAR_SUBTIPO_ADMINISTRADOR_CARGAARCHIVOS,
    EDITAR_SUBTIPO_ADMINISTRADOR_CARGAARCHIVOS,
    FILTRAR_CANAL_CUENTA_ADMINISTRADOR_CARGAARCHIVOS,
    FILTRAR_CATEGORIA_ADMINISTRADOR_CARGAARCHIVOS,
    FILTRAR_TIPO_CARGA_ADMINISTRADOR_CARGAARCHIVOS,
    FILTRAR_CANAL_ADMINISTRADOR_CARGAARCHIVOS,
    FILTRAR_SUBTIPO_CARGA_ADMINISTRADOR_CARGAARCHIVOS,
    EDITAR_CORREO_SUBTIPO_CARGA_ADMINISTRADOR_CARGAARCHIVOS,
    MODIFICAR_UNICAMENTE_CORREOS_CARGA_ADMINISTRADOR_CARGAARCHIVOS,
    EDITAR_CORREOS_ENVIAR_SUBTIPO_CARGA_ADMINISTRADOR_CARGAARCHIVOS,
    EDITAR_SUBTIPO_REGLAS_ADMINISTRADOR
} from '../../../../Constantes/Administrador/CargaArchivos/CargaArchivos'

const INIT_STATE = {

    rex_canales_administrador                   : [],
    rex_canales_cuentas_administrador           : [],
    rex_categorias_carga_administrador          : [],
    rex_tipos_carga_administrador               : [],
    rex_sub_tipos_carga_administrador           : [],
    
    rex_categorias_unicos                       : [],
    rex_tipo_cargas_unicos                      : [],
    
    rex_correos_carga_administrador             : [],
    rex_correos_carga_enviar_administrador      : [],


    rex_modal_agregar_canal_administrador       : false,
    rex_modal_agregar_cancue_administrador      : false,
    rex_modal_agregar_categoria_administrador   : false,
    rex_modal_agregar_tcatipo_administrador     : false,
    rex_modal_agregar_subtipo_administrador     : false,
    rex_modal_editar_cancue_administrador       : false,
    rex_modal_editar_tcatipo_administrador      : false,
    rex_modal_editar_categoria_administrador    : false,
    rex_modal_editar_subtipo_administrador      : false,
    rex_modal_editar_correo_subtipo             : false,
    
    rex_cancue_editar_administrador             : [],
    rex_ccacat_editar_administrador             : [],
    rex_tcatipe_editar_administrador            : [],
    rex_subtipe_editar_administrador            : [],

    rex_filtro_canales_cuentas                  : [],
    rex_filtro_categorias                       : [],
    rex_filtro_tipos_carga                      : [],
    rex_filtro_canal                            : [],
    rex_filtro_subtipo                          : [],
    rex_subtipe_editar_reglas_administrador     : [],
};

export default (state = INIT_STATE, action) => {

    switch (action.type) {

        case EDITAR_SUBTIPO_REGLAS_ADMINISTRADOR: {

            return {
                ...state,
                rex_subtipe_editar_reglas_administrador       : action.payload.canales,
            }
        }
        case OBTENER_CANALES_ADMINISTRADOR_CARGAARCHIVOS: {

            return {
                ...state,
                rex_canales_administrador       : action.payload.canales,
            }
        }

        case OBTENER_CANALES_CUENTAS_ADMINISTRADOR_CARGAARCHIVOS: {

            return {
                ...state,
                rex_canales_cuentas_administrador       : action.payload.canalescuentas,
            }
        }

        case OBTENER_CATEGORIAS_CARGA_ADMINISTRADOR_CARGAARCHIVOS: {

            return {
                ...state,
                rex_categorias_carga_administrador      : action.payload.catcargaarchivos,
            }
        }

        case OBTENER_TIPOS_CARGA_ADMINISTRADOR_CARGAARCHIVOS: {

            return {
                ...state,
                rex_tipos_carga_administrador       : action.payload.tiposcargaarch,
            }
        }

        case OBTENER_SUB_TIPOS_CARGA_ADMINISTRADOR_CARGAARCHIVOS: {

            return {
                ...state,
                rex_sub_tipos_carga_administrador       : action.payload.subtiposcargaarch,
                rex_correos_carga_administrador         : action.payload.corcorreos
            }
        }

        case OBTENER_UNICAMENTE_CATEGORIAS_SELECT_ADMINISTRADOR_CARGAARCHIVOS: {

            return {
                ...state,
                rex_categorias_unicos            : action.payload
            }
        }
        case OBTENER_UNICAMENTE_TIPOS_CARGA_SELECT_ADMINISTRADOR_CARGAARCHIVOS: {

            return {
                ...state,
                rex_tipo_cargas_unicos            : action.payload
            }
        }

        case AGREGAR_CANAL_ADMINISTRADOR_CARGAARCHIVOS: {

            return {
                ...state,
                rex_modal_agregar_canal_administrador       : action.payload,
            }
        }

        case AGREGAR_CANAL_CUENTA_ADMINISTRADOR_CARGAARCHIVOS: {

            return {
                ...state,
                rex_modal_agregar_cancue_administrador      : action.payload
            }
        }

        case AGREGAR_CATEGORIA_ADMINISTRADOR_CARGAARCHIVOS: {

            return {
                ...state,
                rex_modal_agregar_categoria_administrador   : action.payload
            }
        }

        case AGREGAR_TIPO_ADMINISTRADOR_CARGAARCHIVOS: {

            return {
                ...state,
                rex_modal_agregar_tcatipo_administrador      : action.payload
            }
        }
        
        case AGREGAR_SUBTIPO_ADMINISTRADOR_CARGAARCHIVOS: {

            return {
                ...state,
                rex_modal_agregar_subtipo_administrador     : action.payload
            }
        }

        case EDITAR_CANAL_CUENTA_ADMINISTRADOR_CARGAARCHIVOS: {

            return {
                ...state,
                rex_modal_editar_cancue_administrador       : action.payload.modal_editar,
                rex_cancue_editar_administrador             : action.payload.cancuee,
            }
        }

        case EDITAR_CATEGORIA_ADMINISTRADOR_CARGAARCHIVOS: {

            return {
                ...state,
                rex_modal_editar_categoria_administrador    : action.payload.modal_editar,
                rex_ccacat_editar_administrador             : action.payload.ccacate,
            }
        }

        case EDITAR_TCATIPO_ADMINISTRADOR_CARGAARCHIVOS: {

            return {
                ...state,
                rex_modal_editar_tcatipo_administrador      : action.payload.modal_editar,
                rex_tcatipe_editar_administrador            : action.payload.tcatipe,
            }
        }

        case EDITAR_SUBTIPO_ADMINISTRADOR_CARGAARCHIVOS: {

            return {
                ...state,
                rex_modal_editar_subtipo_administrador      : action.payload.modal_editar,
                rex_subtipe_editar_administrador            : action.payload.subtipe,
            }
        }

        case EDITAR_CORREOS_ENVIAR_SUBTIPO_CARGA_ADMINISTRADOR_CARGAARCHIVOS: {
            return {
                ...state,
                rex_correos_carga_enviar_administrador  : action.payload
            }
        }

        case MODIFICAR_UNICAMENTE_CORREOS_CARGA_ADMINISTRADOR_CARGAARCHIVOS: {
            return {
                ...state,
                rex_correos_carga_administrador     : action.payload
            }
        }

        case EDITAR_CORREO_SUBTIPO_CARGA_ADMINISTRADOR_CARGAARCHIVOS: {

            return {
                ...state,
                rex_subtipe_editar_administrador    : action.payload.subtipe,
                rex_modal_editar_correo_subtipo     : action.payload.modal_editar,
            }
        }

        case FILTRAR_CANAL_CUENTA_ADMINISTRADOR_CARGAARCHIVOS: {
            return {
                ...state,
                rex_filtro_canales_cuentas      : action.payload
            }
        }

        case FILTRAR_CATEGORIA_ADMINISTRADOR_CARGAARCHIVOS: {
            return {
                ...state,
                rex_filtro_categorias       : action.payload
            }
        }

        case FILTRAR_TIPO_CARGA_ADMINISTRADOR_CARGAARCHIVOS: {
            return {
                ...state,
                rex_filtro_tipos_carga      : action.payload
            }
        }

        case FILTRAR_SUBTIPO_CARGA_ADMINISTRADOR_CARGAARCHIVOS: {
            return {
                ...state,
                rex_filtro_subtipo      : action.payload
            }
        }

        case FILTRAR_CANAL_ADMINISTRADOR_CARGAARCHIVOS: {
            return {
                ...state,
                rex_filtro_canal        : action.payload
            }
        }

        default:
            return state;
    }
}