import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Table, Tooltip, Button, Row, Col, Input, Pagination, Select, Modal } from 'antd'
import '../../../Estilos/Rutas/Administrador/CargaArchivos.css'
import {
	FiltrarCategoriaAdministradorCargaArchivosReducer,
	ObtenerCategoriasCargaArchivosAdministradorCargaArchivosReducer,
	MostrarModalAgregarCategoriaCargaArchivos,
	MostrarModalEditarCategoriaCargaArchivos,
	EliminarCategoriaAdministradorCargaArchivosReducer
} from '../../../Redux/Actions/Administrador/CargaArchivos/CargaArchivos'
import { 
	EditOutlined,
	SearchOutlined,
	ExclamationCircleOutlined,
	DeleteOutlined,
	ReloadOutlined,
	PlusCircleOutlined
} from '@ant-design/icons'
import AgregarCategoria from './AgregarCategoria'
import EditarCategoria from './EditarCategoria'
import Moment from 'moment'

const CategoriaCargaArchivos = () => {

	const dispatch = useDispatch();
	const { Option } = Select;
	const { confirm } = Modal;
	
    const [pageTableCategoria, setPageTableCategoria] = useState(1)
    const [pageSizeTableCategoria, setPageSizeTableCategoria] = useState(10)
	const [ cargando, setCargando ] = useState(false);
	const [ txtBuscar, setTxtBuscar ] = useState('');
	const [ selectCan, setSelectCan ] = useState(0)
	const [ selectCue, setSelectCue ] = useState(0)
	const [ selectPai, setSelectPai ] = useState(0)
	
	const {
		rex_modal_agregar_categoria_administrador,
		rex_modal_editar_categoria_administrador,
		rex_filtro_categorias,
		rex_canales_administrador,
    } = useSelector(({administradorCargaArchivos}) => administradorCargaArchivos);

	const {
        rex_paises,
		rex_cuentas
    } = useSelector(({administradorCuentas}) => administradorCuentas);

	useEffect(() => {
		obtenerCategoriasAdministrador();
	}, []);

	const obtenerCategoriasAdministrador = async () => {
		setCargando(true);
		await dispatch(ObtenerCategoriasCargaArchivosAdministradorCargaArchivosReducer());
		setCargando(false);
	}

	const buscarCategoria = (txt, paiid, cueid, canid) => {
		dispatch(FiltrarCategoriaAdministradorCargaArchivosReducer(txt, paiid, cueid, canid))
	}

	const limpiarFiltro = () => {
		setTxtBuscar('');
		setSelectCan(0);
		setSelectCue(0);
		setSelectPai(0);
	}

	const confirmarEliminarCategoria = ( {ccaid, ccanombre} ) => {
        confirm({
            title: `¿Eliminar categoria ${ccanombre}?`,
            icon: <ExclamationCircleOutlined />,
            okText: 'Eliminar',
            content: <div>Se eliminará toda la información de la categoría incluyendo:<br/><br/>
				- Registro con algún canal<br/>
				- Registro con alguna cuenta<br/>
				- Registro con algún tipo de archivo<br/>
				- Registro con algún archivo<br/>
		</div>,
        
            async onOk() {
                
                let respuesta =  await dispatch(EliminarCategoriaAdministradorCargaArchivosReducer(ccaid));
				respuesta && limpiarFiltro();
            },
        
            onCancel() {},
        });
    }

	const columns = [
		{
			title: 'Item',
			key: 'ccacid',
			align: 'center',
			width:'80px',
			render: (text, record, index) => (pageTableCategoria - 1) * pageSizeTableCategoria + index + 1,
		},
		{
			title: 'Estado',
			key: 'ccaestado',
			align: 'center',
			width:'80px',
			render: (_, record) => (

				<div style={{display:'flex', justifyContent:'center'}}>
					<Tooltip placement="top" title={record.ccaestado ? 'Activado':'Desactivado'}>
						<div className={`Circulo ${record.ccaestado? 'Circulo-Activo': 'Circulo-Desactivo'}`}>
						</div>
					</Tooltip>
				</div>
			)
		},
		{
			title: 'Empresa',
			dataIndex: 'cuenombre',
			key: 'cuenombre',
			align: 'center',
			width:'200px'
		},
		{
			title: 'Pais',
			key: 'paiicono',
			align: 'center',
			width:'100px',
			render:(_,record) => (
				<Tooltip title={record.painombre} placement="right">
					<img
						style={{width:'30px', height:'30px'}}
						src={record.paiicono}
						/>
				</Tooltip>
			)
		},
		{
			title: 'Canal',
			dataIndex: 'cannombre',
			key: 'cannombre',
			align: 'center',
			width:'200px'
		},
		{
			title: 'Categoria',
			dataIndex: 'ccanombre',
			key: 'ccanombre',
			align: 'center',
			width:'200px'
		},
		{
			title: 'Fecha de creación',
			key: 'created_at',
			align: 'center',
			width:'250px',
			render: (_, record) => (
				<>{record.created_at?Moment(record.created_at).format('LLL'):'Sin registro'}</>
			),
		},
		{
			title: 'Fecha de actualización',
			key: 'updated_at',
			align: 'center',
			width:'250px',
			render: (_, record) => (
				<>{record.updated_at?Moment(record.updated_at).format('LLL'):'Sin registro'}</>
			),
		},
		{
			title: 'Acción',
			key: 'editar',
			align: 'center',
			width: '100px',
			fixed:'right',
			render: (_,record ) => (

				<div className='Contenedor-Acciones-Iconos'>
					<Tooltip placement="top" title='Editar'>
						<Button
							className='Boton-Accion-Editar'
							onClick={()=> dispatch(MostrarModalEditarCategoriaCargaArchivos(true, record))}
						>
							<EditOutlined
								className='Icono-Accion-Editar'
							/>
						</Button>
					</Tooltip>
					<Tooltip placement='top' title='Eliminar'>
						<Button
							className='Boton-Accion-Eliminar'
							onClick={()=> confirmarEliminarCategoria(record)}
						>
							<DeleteOutlined
								className='Icono-Accion-Eliminar'
							/>
						</Button>
					</Tooltip>
				</div>
			)
		},
	];

	return (
		<div className='Contenedor-Carga-Archivos-Tabla'>

			<Row className='Fila-Buscador-Boton-Carga-Archivos'>
				<Col
					xl={8} lg={8} md={24} sm={24} xs={24}
				>
					<Row>
						<Col span={20}>
							<Input
								suffix={<SearchOutlined className='Icono-Input-Buscador'/>}
								size='large'
								className='Input-Buscador-Carga-Archivos'
								placeholder='Buscar'
								value={txtBuscar}
								onChange={(e) => {
									setTxtBuscar(e.target.value)
									buscarCategoria(e.target.value, selectPai, selectCue, selectCan)
								}}
							/>
						</Col>
						<Col span={4} style={{display:'flex',alignItems:'center'}}>
							<Tooltip title='Actualizar'>
								<div
									className='Boton-Actualizar'
									onClick={async ()=> {
										setCargando(true);
										await dispatch(ObtenerCategoriasCargaArchivosAdministradorCargaArchivosReducer());
										setCargando(false);
									}}
								>
									<ReloadOutlined
										className='Icono-Actualizar'
									/>
								</div>
							</Tooltip>
						</Col>
					</Row>
				</Col>
				<Row>
					<Col>
						<Button
							onClick={() => dispatch(MostrarModalAgregarCategoriaCargaArchivos(true))}
							className='Boton-Crear-Carga-Archivos'
						>Crear<PlusCircleOutlined/></Button>
					</Col>
				</Row>
			</Row>

			<Row className='Contenedor-Filtro-Carga-Archivos'>
				<Row>
					<Col>
						<Select
							value={selectCue}
							className='Select-Filtro-Carga-Archivos'
							onChange={(valor) => {
								setSelectCue(valor)
								buscarCategoria( txtBuscar, selectPai, valor, selectCan)
							}}
						>
							<Option value={0}>Seleccionar Empresa</Option>
							{
								rex_cuentas.map((cue)=> (
									<Option key={cue.cueid} value={cue.cueid}>{cue.cuenombre}</Option>
								))
							}
						</Select>
					</Col>
					<Col>
						<Select
							value={selectPai}
							className='Select-Filtro-Carga-Archivos'
							onChange={(valor) => {
								setSelectPai(valor)
								buscarCategoria( txtBuscar, valor, selectCue, selectCan)
							}}
						>
							<Option value={0}>Seleccionar Pais</Option>
							{
								rex_paises.map((pai) => (
									<Option key={pai.paiid} value={pai.paiid}>{pai.painombre}</Option>
								))
							}
						</Select>
					</Col>
					<Col>
						<Select
							value={selectCan}
							className='Select-Filtro-Carga-Archivos'
							onChange={(valor)=> {
								setSelectCan(valor)
								buscarCategoria( txtBuscar, selectPai, selectCue, valor)
							}}
						>
							<Option value={0}>Seleccionar Canal</Option>
							{
								rex_canales_administrador.map((can)=> (
									<Option key={can.canid} value={can.canid}>{can.cannombre}</Option>
								))
							}
						</Select>
					</Col>
				</Row>
				<Row>
					<Col
						style={{display:'flex', justifyContent:'flex-end', margin:'5px 0'}}
					>
						<Pagination
							defaultCurrent={pageTableCategoria}
							current={pageTableCategoria}
							total={rex_filtro_categorias.length}
							defaultPageSize={pageSizeTableCategoria}
							onChange={(page, pageSize) => {
								setPageTableCategoria(page == 0? 1 : page)
								setPageSizeTableCategoria(pageSize)
							}}
						/>
					</Col>
				</Row>
			</Row>

			<Table
				dataSource={rex_filtro_categorias} 
				columns={columns}
				loading={cargando}
				pagination={{
					defaultCurrent: pageTableCategoria,
					current: pageTableCategoria,
					pageSize: pageSizeTableCategoria,
					position: ['topRight'],
				}}
				scroll={{
					x:1000
				}}
				size='small'
			/>

			{ rex_modal_agregar_categoria_administrador
				&& <AgregarCategoria
					limpiarFiltro = {limpiarFiltro}
					/> }

			{ rex_modal_editar_categoria_administrador
				&& <EditarCategoria
					limpiarFiltro = {limpiarFiltro}
				/> }
		</div>
	)

}

export default CategoriaCargaArchivos