import React, {useEffect, useState} from 'react'
import { Row, Col, Skeleton } from 'antd';
import '../../Estilos/Componentes/Seleccionarnegocio/Seleccionarnegocio.css'
import {useSelector, useDispatch} from "react-redux";
import {
    ObtenerNegociosReducer,
    SeleccionarNegocioReducer,
    ReiniciarVariablesEstadosSeleccionarNegocioReducer,
    OcultarModuloSeleccionarNegocioReducer
} from '../../Redux/Actions/Componentes/SeleccionarNegocio'

const Seleccionarnegocio = (props) => {

    const mostrarCuentasStaticas = props.mostrarCuentasStaticas
    const cuentasStaticas = props.cuentasStaticas
    const setCuentasStaticas = props.setCuentasStaticas

    // const [ocultarModulo, setOcultarModulo] = useState()
    
    const dispatch = useDispatch()

    const { 
        rex_data_negocios_componentes,
        rex_cargando_componente_seleccionar_negocio,
        rex_negocio_seleccionado,
        rex_ocultar_modulo_seleccionar_negocio
    } = useSelector(({seleccionarNegocio}) => seleccionarNegocio);

    useEffect(() => {

        // if(rex_data_negocios_componentes.length == 0 && rex_cargando_componente_seleccionar_negocio == false){
        //     dispatch(ObtenerNegociosReducer())
        // }

        dispatch(ReiniciarVariablesEstadosSeleccionarNegocioReducer())

    },[])

    return (
        <div
            className={
                rex_negocio_seleccionado
                ?"Contenedor-Seleccionar-Negocio Animacion-Desaparecer-Card-Negocio-Seleccionado"
                :"Contenedor-Seleccionar-Negocio"
            }
            style={rex_ocultar_modulo_seleccionar_negocio == true?{display:'none'}:{}}
        >
            <div
                style={{
                    paddingRight:'40px', paddingLeft:'40px', marginTop:'20px', width:'100%', paddingTop:'150px'
                }}
            >

                <div 
                    className='W600-S15-H20-C787878' style={{textAlignLast: "center", marginBottom:'50px'}}
                >
                    Seleccionar la Cuenta que deseas visitar
                </div>

                <div>
                    
                    <Row
                        style={{
                            textAlign: "-webkit-center"
                        }}
                    >
                        {
                            mostrarCuentasStaticas == true
                            ?cuentasStaticas.map((cuenta, pos) => {
                                return(
                                    <Col 
                                        xl={6} 
                                        key={pos}
                                    >
                                        <div
                                            className='Card-Negocio-Seleccionar-Negocio'
                                            onClick={() => {
                                                setTimeout (function(){
                                                    // setOcultarModulo(true)
                                                    dispatch(OcultarModuloSeleccionarNegocioReducer(true))
                                                },1900);
                                                dispatch(SeleccionarNegocioReducer(1))
                                                setCuentasStaticas(cuenta)
                                            }}
                                        >
                                            <img 
                                                src={
                                                    cuenta == "Kimberly"
                                                    ?"https://backend-goda.growanalytics.app/Sistema/Imagenes/Cuentas/kimberly.png"
                                                    :cuenta == "Softys"
                                                        ?"https://backend-goda.growanalytics.app/Sistema/Imagenes/Cuentas/softys.png"
                                                        :cuenta == "Clorox"
                                                            ?"https://backend-goda.growanalytics.app/Sistema/Imagenes/Cuentas/clorox.png"
                                                            :cuenta == "Palmas"
                                                                ?"https://backend-goda.growanalytics.app/Sistema/Imagenes/Cuentas/palmas.png"
                                                                :cuenta == "Grupo Romero"
                                                                    ?"https://backend-goda.growanalytics.app/Sistema/Imagenes/Cuentas/gruporomero.png"
                                                                    :cuenta == "Najar"
                                                                        ?"https://backend-goda.growanalytics.app/Cuentas/cueNajar.png"
                                                                        :cuenta == "Oceano Foods"
                                                                            ?"https://backend-goda.growanalytics.app/Sistema/Imagenes/Cuentas/ocenfoods.png"
                                                                            :null
                                                } 
                                                className="Logo-Seleccionar-Negocio"
                                                style={
                                                    cuenta == "Palmas"
                                                    ?{width:'105px'}
                                                    :{}
                                                }
                                            />
                                        </div>
                                        <div 
                                            style={{paddingTop:'20px'}}
                                            className="W600-S14-H19-C000000"
                                        >
                                            {cuenta}
                                        </div>
                                    </Col>
                                )
                            })
                            :rex_cargando_componente_seleccionar_negocio == true
                            ?[{},{},{},{}].map((negocio, key) => {
                                return (
                                    <Col 
                                        xl={6} 
                                        key={key}
                                    >
                                        <Skeleton.Button
                                            active
                                            size={'default'} 
                                            block={false}
                                            loading={false}
                                        />
                                    </Col>
                                )
                            })
                            :rex_data_negocios_componentes.map((negocio, pos) => {
                                return (
                                    <Col 
                                        xl={6} 
                                        key={negocio.cueid}
                                    >
                                        <div
                                            className='Card-Negocio-Seleccionar-Negocio'
                                            onClick={() => {
                                                setTimeout (function(){
                                                    // setOcultarModulo(true)
                                                    dispatch(OcultarModuloSeleccionarNegocioReducer(true))
                                                },1900);
                                                dispatch(SeleccionarNegocioReducer(negocio.cueid))
                                            }}
                                        >
                                            <img 
                                                src={negocio.cueimagen} 
                                                className="Logo-Seleccionar-Negocio"
                                            />
                                        </div>
                                        <div 
                                            style={{paddingTop:'20px'}}
                                            className="W600-S14-H19-C000000"
                                        >
                                            {negocio.cuenombre}
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </Row>

                </div>

                {/* <button
                    onClick={() => {
                        setMostrarNegocio(!mostrarNegocio)
                    }}
                >
                    click
                </button> */}

            </div>
        </div>
    )
}

export default Seleccionarnegocio