import {
    OBTENER_DATA_TABLA_CARGA_ARCHIVOS,
    ACTUALIZAR_COLUMNAS_TABLA_CARGA_ARCHIVOS,
    SELECCIONAR_FILA_DATA_TABLA_CARGA_ARCHIVOS,
    MOSTRAR_MODAL_CONFIRMACION_TABLA_CARGA_ARCHIVOS,
    MOSTRAR_MODAL_SELECCIONAR_DT_TABLA_CARGA_ARCHIVOS,
    MOSTRAR_MODAL_GUARDANDO_ARCHIVOS_PYTHON_TABLA_CARGA_ARCHIVOS,
    PETICION_GUARDANDO_PYTHON_CARGA_ARCHIVOS,
    PETICION_OBTENIDA_PYTHON_CARGA_ARCHIVOS,
    MOSTRAR_MODAL_TIEMPO_PETICION_TABLA_CARGA_ARCHIVOS,
    MOSTRAR_MODAL_NOTIFICACION_TABLA_CARGA_ARCHIVOS,
    MOSTRAR_MODAL_RANGO_TIEMPO_INVALIDO_CARGA_ARCHIVOS,
    HORARIOS_MODAL_RANGO_TIEMPO_INVALIDO_CARGA_ARCHIVOS
} from '../../../Constantes/CargaArchivos/CargaArchivos'
import config from '../../../config'
import { estadoRequestReducer } from "../Comunes/EstadoRequest"
import axios from 'axios'
import {
    ObtenerDistribuidorasDisponiblesPaisCuentaReducer
} from './ModalDtsCargaArchivos'
import Moment from 'moment';
import {
    ObtenerCargaArchivosReducer
} from './CargaArchivos'
import { alertaNotificacion, alertaNotificacionLeft } from '../../../Funciones/Notificaciones/Notificaciones'


export const EliminarArchivoSeleccionadoTablaCargaArchivoReducer = (posicion, archivo, eliminartodo) => async (dispatch, getState) => {

    let rex_data_tabla_carga_archivos = getState().tablaCargaArchivos.rex_data_tabla_carga_archivos
    const rex_columnas_tabla_carga_archivos = getState().tablaCargaArchivos.rex_columnas_tabla_carga_archivos

    if(eliminartodo == true){
        
        rex_data_tabla_carga_archivos[posicion]['archivoprincipal'] = null
        rex_data_tabla_carga_archivos[posicion]['archivosecundario'] = null
        rex_data_tabla_carga_archivos[posicion]['fechasmoment'] = null

    }else if(archivo == "principal"){
        rex_data_tabla_carga_archivos[posicion]['archivoprincipal'] = null
    }else{
        rex_data_tabla_carga_archivos[posicion]['archivosecundario'] = null
    }

    let nueva_data_tabla_carga_archivos = []

    await rex_data_tabla_carga_archivos.map((data) => {
        nueva_data_tabla_carga_archivos.push({...data})
    })

    await dispatch({
        type: ACTUALIZAR_COLUMNAS_TABLA_CARGA_ARCHIVOS,
        payload : {
            columnas: rex_columnas_tabla_carga_archivos,
            data : nueva_data_tabla_carga_archivos
        }
    })

}

export const SeleccionarArchivoTablaCargaArchivo = ( file, posicion, archivo ) => async (dispatch, getState) => {

    
    let rex_data_tabla_carga_archivos = getState().tablaCargaArchivos.rex_data_tabla_carga_archivos
    let rex_columnas_tabla_carga_archivos = getState().tablaCargaArchivos.rex_columnas_tabla_carga_archivos

    if(archivo == "principal"){
        rex_data_tabla_carga_archivos[posicion]['archivoprincipal'] = file
    }else{
        rex_data_tabla_carga_archivos[posicion]['archivosecundario'] = file
    }

    let nueva_data_tabla_carga_archivos = []

    await rex_data_tabla_carga_archivos.map((data) => {
        nueva_data_tabla_carga_archivos.push({...data})
    })

    await dispatch({
        type: ACTUALIZAR_COLUMNAS_TABLA_CARGA_ARCHIVOS,
        payload : {
            columnas: rex_columnas_tabla_carga_archivos,
            data : nueva_data_tabla_carga_archivos
        }
    })

    // await dispatch({
    //     type: OBTENER_DATA_TABLA_CARGA_ARCHIVOS,
    //     payload: rex_data_tabla_carga_archivos
    // })

}

export const SeleccionarRangoFechaCargaArchivoReducer = (fechas, posicion) => async (dispatch, getState) => {

    let rex_data_tabla_carga_archivos = getState().tablaCargaArchivos.rex_data_tabla_carga_archivos
    let rex_columnas_tabla_carga_archivos = getState().tablaCargaArchivos.rex_columnas_tabla_carga_archivos

    rex_data_tabla_carga_archivos[posicion]['fecha'] = fechas
    rex_data_tabla_carga_archivos[posicion]['fechasmoment'] = [Moment(fechas[0], 'YYYY-MM'), Moment(fechas[1], 'YYYY-MM')]

    let nueva_data_tabla_carga_archivos = []

    await rex_data_tabla_carga_archivos.map((data) => {
        nueva_data_tabla_carga_archivos.push({...data})
    })

    await dispatch({
        type: ACTUALIZAR_COLUMNAS_TABLA_CARGA_ARCHIVOS,
        payload : {
            columnas: rex_columnas_tabla_carga_archivos,
            data : nueva_data_tabla_carga_archivos
        }
    })
}

export const SeleccionarFechaSFTPCargaArchivoReducer = (fecha, posicion) => async (dispatch, getState ) => {

    let rex_data_tabla_carga_archivos = getState().tablaCargaArchivos.rex_data_tabla_carga_archivos
    let rex_columnas_tabla_carga_archivos = getState().tablaCargaArchivos.rex_columnas_tabla_carga_archivos

    rex_data_tabla_carga_archivos[posicion]['fechaSFTP'] = fecha

    let nueva_data_tabla_carga_archivos = []

    await rex_data_tabla_carga_archivos.map((data) => {
        nueva_data_tabla_carga_archivos.push({...data})
    })

    await dispatch({
        type: ACTUALIZAR_COLUMNAS_TABLA_CARGA_ARCHIVOS,
        payload : {
            columnas: rex_columnas_tabla_carga_archivos,
            data : nueva_data_tabla_carga_archivos
        }
    })
}

export const SeleccionaTipoProcesamientoSFTPCargaArchivoReducer = (tipoProc, posicion) => async (dispatch, getState ) => {

    let rex_data_tabla_carga_archivos = getState().tablaCargaArchivos.rex_data_tabla_carga_archivos
    let rex_columnas_tabla_carga_archivos = getState().tablaCargaArchivos.rex_columnas_tabla_carga_archivos

    rex_data_tabla_carga_archivos[posicion]['tipoproc'] = tipoProc

    let nueva_data_tabla_carga_archivos = []

    await rex_data_tabla_carga_archivos.map((data) => {
        nueva_data_tabla_carga_archivos.push({...data})
    })

    await dispatch({
        type: ACTUALIZAR_COLUMNAS_TABLA_CARGA_ARCHIVOS,
        payload : {
            columnas: rex_columnas_tabla_carga_archivos,
            data : nueva_data_tabla_carga_archivos
        }
    })
}


export const AgregarFilaTablaCargaArchivoReducer = (posicion) => async (dispatch, getState) => {

    let rex_data_tabla_carga_archivos = getState().tablaCargaArchivos.rex_data_tabla_carga_archivos
    
    let nuevacolumna = {
        canal     : rex_data_tabla_carga_archivos[posicion]['canal'],
        ccaid     : rex_data_tabla_carga_archivos[posicion]['ccaid'],
        ccanombre : rex_data_tabla_carga_archivos[posicion]['ccanombre'],
        pacid     : rex_data_tabla_carga_archivos[posicion]['pacid'],
        stcid     : rex_data_tabla_carga_archivos[posicion]['stcid'],
        stcnombre : rex_data_tabla_carga_archivos[posicion]['stcnombre'],
        tcaid     : rex_data_tabla_carga_archivos[posicion]['tcaid'],
        tcanombre : rex_data_tabla_carga_archivos[posicion]['tcanombre'],
        stcrutaaws: rex_data_tabla_carga_archivos[posicion]['stcrutaaws'],
        stcpathaws: rex_data_tabla_carga_archivos[posicion]['stcpathaws'],
        subitem   : true,
        selecciononombre: false,
        itempadre : rex_data_tabla_carga_archivos[posicion]['item'],
        tienerangofechas : true
    }

    rex_data_tabla_carga_archivos.splice(posicion+1, 0, nuevacolumna)

    let nueva_data_tabla_carga_archivos = []

    await rex_data_tabla_carga_archivos.map((data, pos) => {

        let nuevadata = {...data}
        nuevadata.key = pos

        nueva_data_tabla_carga_archivos.push(nuevadata)
    })

    await dispatch({
        type: OBTENER_DATA_TABLA_CARGA_ARCHIVOS,
        payload: nueva_data_tabla_carga_archivos
    })


}

export const EliminarFilaTablaCargaArchivoReducer = (posicion) => async (dispatch, getState) => {

    let rex_data_tabla_carga_archivos = getState().tablaCargaArchivos.rex_data_tabla_carga_archivos

    rex_data_tabla_carga_archivos.splice(posicion, 1)

    let nueva_data_tabla_carga_archivos = []

    await rex_data_tabla_carga_archivos.map((data, pos) => {
        let nuevadata = {...data}
        nuevadata.key = pos
        nueva_data_tabla_carga_archivos.push(nuevadata)
    })

    await dispatch({
        type: OBTENER_DATA_TABLA_CARGA_ARCHIVOS,
        payload: nueva_data_tabla_carga_archivos
    })

}

export const SeleccionarDtFilaTablaCargaArchivoReducer = (estado, data, dtseleccionada) => async (dispatch, getState) => {

    if(estado == true){
        dispatch({
            type: SELECCIONAR_FILA_DATA_TABLA_CARGA_ARCHIVOS,
            payload: data
        })
    }else{

        let eliminaArchivoPrincipal    = false
        let eliminaArchivoSecundario   = false 

        // dtseleccionada

        let rex_data_tabla_carga_archivos = getState().tablaCargaArchivos.rex_data_tabla_carga_archivos
        let rex_fila_data_seleccionado_tabla_carga_archivos = getState().tablaCargaArchivos.rex_fila_data_seleccionado_tabla_carga_archivos
        
        let filaExtractorSFTP = rex_data_tabla_carga_archivos.filter(fila => fila.pacid == 2 && fila.ccaid == 21 && fila.tcaid == 41);
        
        rex_data_tabla_carga_archivos[rex_fila_data_seleccionado_tabla_carga_archivos.key]['dtseleccionada'] = dtseleccionada


        if(dtseleccionada?.reglas){

            let eliminaNombreCsvPrincipal = await dispatch(ValidarNombreArchivoCargaArchivos(rex_fila_data_seleccionado_tabla_carga_archivos.archivoprincipal,dtseleccionada.reglas.rstnombrecsv, 1 ))
            let eliminaXlsxSecundario = await dispatch(ValidarNombreArchivoCargaArchivos(rex_fila_data_seleccionado_tabla_carga_archivos.archivosecundario,dtseleccionada.reglas.rstnombrexlsx, 1 ))
    
            let eliminaContieneCsvPrincipal = await dispatch(ValidarNombreArchivoCargaArchivos(rex_fila_data_seleccionado_tabla_carga_archivos.archivoprincipal,dtseleccionada.reglas.rstcontienecsv, 2 ))
            let eliminaContieneXlsxSecundario = await dispatch(ValidarNombreArchivoCargaArchivos(rex_fila_data_seleccionado_tabla_carga_archivos.archivosecundario,dtseleccionada.reglas.rstcontienexlsx, 2 ))
    
            if( eliminaNombreCsvPrincipal || eliminaContieneCsvPrincipal ){
                rex_data_tabla_carga_archivos[rex_fila_data_seleccionado_tabla_carga_archivos.key]['archivoprincipal']    = null
            }
    
            if( eliminaXlsxSecundario || eliminaContieneXlsxSecundario ){
                rex_data_tabla_carga_archivos[rex_fila_data_seleccionado_tabla_carga_archivos.key]['archivosecundario']   = null
            }
        }

        let vecesKetal = 0;
        filaExtractorSFTP.map(fila => {
            if(fila.dtseleccionada){
                if(fila.dtseleccionada['dtsclientesubhml'] == "KETAL")
                vecesKetal = vecesKetal + 1
            }
        })

        vecesKetal > 1 && alertaNotificacion('Solo puede subir 1 archivo para KETAL (Cierre o Diario)')

        if(vecesKetal < 2){
            let nueva_data_tabla_carga_archivos = []

            await rex_data_tabla_carga_archivos.map((data, pos) => {
                let nuevadata = {...data}
                nueva_data_tabla_carga_archivos.push(nuevadata)
            })
            
            await dispatch({
                type: OBTENER_DATA_TABLA_CARGA_ARCHIVOS,
                payload: nueva_data_tabla_carga_archivos
            })
    
            dispatch({
                type: SELECCIONAR_FILA_DATA_TABLA_CARGA_ARCHIVOS,
                payload: null
            })
        }
    }
    
    dispatch({
        type: MOSTRAR_MODAL_SELECCIONAR_DT_TABLA_CARGA_ARCHIVOS,
        payload: estado
    })

    dispatch(ObtenerDistribuidorasDisponiblesPaisCuentaReducer(data.canal, data.stcid))
}

export const ValidarNombreArchivoCargaArchivos = ( archivoPrincipal, reglas, tipo ) => async ( dispatch, getState ) => {

    let respuesta = false

    if(tipo == 1){
        if(archivoPrincipal && reglas != null){
            if(archivoPrincipal.name !== reglas){
                alertaNotificacionLeft(`Se ha eliminado el archivo csv, el archivo debe llamarse: ${reglas}`)
            }
        }
        respuesta = true
    }else{
        if(archivoPrincipal && reglas != null){
            if(!archivoPrincipal.name.includes(reglas)){
                alertaNotificacionLeft(`Se ha eliminado el archivo xlsx, el archivo debe contener: ${reglas} dentro de su nombre`)
            }
        }
        respuesta = true
    }

    return respuesta
}

export const NotificacionRangoTiempoInvalidoReducer = ( record ) => async ( dispatch, getState) => {
    
    let respuesta = true;

    if(record.stchorario){
        
        let horaActual = Moment().format("HH:mma");
        let horariosDia = []
        for(const hora of record.stchorario){
            
            let diaActual = false
            if(hora.hstdias != "" && hora.hstdias != null){
                diaActual = hora.hstdias.split(',').includes(String(Moment().isoWeekday())) ? true : false
            }else{
                diaActual = true
            }

            if(horaActual >= Moment("01-01-2000 "+ hora.hstrangoinicio).format("HH:mma") 
                && horaActual <= Moment("01-01-2000 " + hora.hstrangofinal).format("HH:mma")
                && diaActual == true
            ){
                respuesta = false
                horariosDia = record.stchorario
                break
            }
        }
        
        let horariosHoy = []
        if(respuesta == false){
            horariosDia.map(hor => {
                if(hor.hstdias == null || hor.hstdias == ''){
                    horariosHoy.push(hor)
                }else{
                    if(hor.hstdias.split(',').includes(String(Moment().isoWeekday())) == true){
                        horariosHoy.push(hor)
                    }
                }
            })
            
            dispatch(MostrarModalRangoTiempoInvalidoReducer(true, horariosHoy));
        }
    }
    
    return respuesta;
}

export const MostrarModalRangoTiempoInvalidoReducer = ( mostrar, horario ) => async (dispatch, getState) => {
    
    dispatch({
        type: MOSTRAR_MODAL_RANGO_TIEMPO_INVALIDO_CARGA_ARCHIVOS,
        payload: mostrar
    })
    dispatch({
        type: HORARIOS_MODAL_RANGO_TIEMPO_INVALIDO_CARGA_ARCHIVOS,
        payload: horario
    })
}

export const CargandoBtnEnviarArchivoReducer = (data, cargando) => async (dispatch, getState) => {

    if(cargando == true){
        dispatch({
            type: SELECCIONAR_FILA_DATA_TABLA_CARGA_ARCHIVOS,
            payload: data
        })

        dispatch({
            type: MOSTRAR_MODAL_CONFIRMACION_TABLA_CARGA_ARCHIVOS,
            payload: true
        })

    }else{
        dispatch({
            type: SELECCIONAR_FILA_DATA_TABLA_CARGA_ARCHIVOS,
            payload: null
        })

        dispatch({
            type: MOSTRAR_MODAL_CONFIRMACION_TABLA_CARGA_ARCHIVOS,
            payload: false
        })
    }

    let rex_data_tabla_carga_archivos = getState().tablaCargaArchivos.rex_data_tabla_carga_archivos
    rex_data_tabla_carga_archivos[data.key]['cargando'] = cargando
    let nueva_data_tabla_carga_archivos = []

    await rex_data_tabla_carga_archivos.map((data) => {
        nueva_data_tabla_carga_archivos.push({...data})
    })
    await dispatch({
        type: OBTENER_DATA_TABLA_CARGA_ARCHIVOS,
        payload: nueva_data_tabla_carga_archivos
    })

}

export const EnviarArchivoCargadoTablaCargaArchivoReducer = (data, comentario, fecha, tipoproc, pacid) => async (dispatch, getState) => {

    let respuesta = false;
    let prefixApi = config.api;

    dispatch(CargandoBtnEnviarArchivoReducer(data, false))

    const formData = new FormData();
    formData.append('req_archivo_primero', data.archivoprincipal)
    if(data.archivosecundario != undefined){
        formData.append('req_archivo_segundo', data.archivosecundario )
    }

    let pacidCliProKC = [
        {
            "type" : ["Maestra de Clientes"],
            "stcid": [19],
        },
        {
            "type" : ["Maestra de Productos"],
            "stcid": [1]
        },
    ]

    formData.append('req_ccanombre', data.ccanombre)
    formData.append('req_tcanombre', data.tcanombre)
    formData.append('req_stcnombre', data.stcnombre)
    formData.append('req_stcid', data.stcid)
    formData.append('req_texto', comentario)
    formData.append('req_tipoproc', tipoproc)
    formData.append('req_pacid', pacid)
    formData.append('req_canal', data.canal)
    formData.append('req_horario', JSON.stringify(data.horario))
    formData.append('req_stcrutaaws', data.stcrutaaws)
    formData.append('req_stcbucketaws', data.stcbucketaws)
    formData.append('req_stcpathaws', data.stcpathaws)

    if(data.tcanombre == 'SFTP' && pacid == 2){

        prefixApi = config.scriptApi;
        formData.append('req_fecha', fecha)

    }else{
        if(data.fecha != undefined){
            let nuevafecha = [ '"'+data.fecha[0]+'"' +","+ '"'+data.fecha[1]+'"' ];
            formData.append('req_fecha', data.fecha)
        }
    }

    console.log(prefixApi)
    
    if(data.dtseleccionada){
        formData.append('req_distribuidora', data.dtseleccionada.dtsclientesubhml)
    }else{
        // formData.append('req_distribuidora', null)
    }
    
    await axios.post(prefixApi+'carga-archivos/enviar-archivos', formData,{
        mode:'cors',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'api-token'	   : localStorage.getItem('usutoken'),
            'api_token'	   : localStorage.getItem('usutoken')
        }
    })
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        return res
    })
    .then(async rpta => {

        let nuevadata = rpta.data

        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){
            let rpta_spider = true
            respuesta = true;

            const findStcid = pacidCliProKC.find(pac => pac.stcid.includes(data.stcid))

            if(findStcid){
                let updateStatusCorporate = await dispatch(ActualizarStatusCorporativaReducer(findStcid, data.stcid))
                // if(data.archivosecundario){
                //     let sendFileSmartview = await dispatch(EnviarArchivoMicroServiceSmartviewReducer(data.stcid, data.archivosecundario))
                // }
            }
        
            if(data.stcid == 73 || data.stcid == 2){
                rpta_spider = await dispatch(EnviarArchivosSpiderReducer(formData, data.stcid))
            }

            if(rpta_spider == true){
                dispatch(EliminarArchivoSeleccionadoTablaCargaArchivoReducer(data.key, '', true));
                if(pacid == 2 && data.tcanombre == 'SFTP'){
                    //Sigue cargando archivos
                }else{
                    dispatch({
                        type:MOSTRAR_MODAL_NOTIFICACION_TABLA_CARGA_ARCHIVOS,
                        payload: true
                    })
                }
            }else{
                respuesta = false;
            }
        }

    }).catch((error)=> {

        let res = JSON.parse(error.request.response)
        if(pacid == 2 && data.tcanombre == 'SFTP' && res.rangosubida == false){
            dispatch(MostrarModalRangoTiempoInvalidoReducer(true, data.horario));
        }else{
            alertaNotificacion(res.mensaje[0]);
        }
    });

    return respuesta;
}

export const ActualizarStatusCorporativaReducer = ( infoStc, stcid ) => async (dispatch) => {

    let response = false

    await fetch(config.apiCorporate+'public/status/update-database',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
            },
            body: JSON.stringify({
                req_usucorreo       : localStorage.getItem("usutoken"),
                req_espbasedatos    : infoStc.type,
                req_stcid           : stcid,
                req_plataforma      : "GODA"
            })
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.response){
            response = true
        }
    }).catch((error)=> {
        console.log(error)
    });

    return response
}

export const EnviarArchivoMicroServiceSmartviewReducer = (stcid, archivo = null) => async (dispatch) => {

    let respuesta = true

    const formData = new FormData();
    let urlApi = ""

    if(stcid == 1){
        if(archivo != undefined){
            formData.append('maestra_productos', archivo)
        }
        urlApi = config.apiSmartview + 'file-upload/master-products'
    }else{
        if(archivo != undefined){
            formData.append('maestra_cliente', archivo)
        }
        urlApi = config.apiSmartview + 'file-upload/master-clients'
    }


    await axios.post(urlApi, formData,{
        mode:'cors',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'api-token'	   : localStorage.getItem('usutoken'),
            'api_token'	   : localStorage.getItem('usutoken')
        }
    })
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        return res
    })
    .then(async rpta => {
        console.log(rpta)

    }).catch((error)=> {
        console.log(error)
        respuesta = false
    });

    return respuesta
}

export const EnviarArchivosSpiderReducer = (formData, stcid) => async (dispatch) => {


    let respuesta = false;
    const urlUpload = stcid == 73 ?config.apiNodeSpider+'upload/bo/utt/si':config.apiNodeSpider+'upload/bo/utt/materiales'

    await axios.post(urlUpload, formData,{
        mode:'cors',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'api-token'	   : localStorage.getItem('usutoken'),
            'api_token'	   : localStorage.getItem('usutoken')
        }
    })
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        return res
    })
    .then(rpta => {

        if(rpta.data){
            if(rpta.data.rpta == true){
                respuesta = true;
            }
        }

    }).catch((error)=> {
        console.log("error de Spider")
        console.log(error)
        alertaNotificacion("Lo sentimos, ocurrio en error en el servidor");
    });

    return respuesta

}

export const ModalGuardandoArchivosPythonTablaCargaArchivoReducer = (mostrar) => async (dispatch, getState) => {
    dispatch({
        type: MOSTRAR_MODAL_GUARDANDO_ARCHIVOS_PYTHON_TABLA_CARGA_ARCHIVOS,
        payload: mostrar
    })
}

export const GuardarArchivosPythonTablaCargaArchivoReducer = (fecha, tipo) => async (dispatch, getState) => {

    const formData = new FormData();
    formData.append('fecha', fecha)
    formData.append('tipo', tipo)

    await axios.post(config.api+'carga-archivos/guardar-archivos-python', formData ,{
        mode:'cors',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'api-token'	   : localStorage.getItem('usutoken'),
            'api_token'	   : localStorage.getItem('usutoken')
        },
    })
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request

        dispatch({
            type: PETICION_OBTENIDA_PYTHON_CARGA_ARCHIVOS,
            payload: true
        })

        let rex_peticion_guardando = getState().tablaCargaArchivos.rex_peticion_guardando_python_carga_archivos
        
        if(rex_peticion_guardando == true){
            if(estadoRequest == true){

                dispatch({
                    type:MOSTRAR_MODAL_NOTIFICACION_TABLA_CARGA_ARCHIVOS,
                    payload: true
                })

            }else{
                alertaNotificacion('Ha ocurrido un error al guardar los archivos, por favor vuelva a cargarlos')
            }
        }
    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error al guardar los archivos, por favor vuelva a cargarlos');
    });

    dispatch(ModalGuardandoArchivosPythonTablaCargaArchivoReducer(false))
}

export const ModalNotificacionPython = (mostrar) => async (dispatch, getState) => {
    dispatch({
        type:MOSTRAR_MODAL_NOTIFICACION_TABLA_CARGA_ARCHIVOS,
        payload: mostrar
    })
}

export const PeticionGuardandoPythonTablaCargaArchivoReducer = ( estado ) => async (dispatch, getState) => {
    dispatch({
        type: PETICION_GUARDANDO_PYTHON_CARGA_ARCHIVOS,
        payload: estado
    })
}

export const EnviarVariosArchivosTablaCargaArchivoReducer = (data, comentario) => async (dispatch, getState) => {

    let respuestaItemPadre;

    const rex_data_tabla_carga_archivos = getState().tablaCargaArchivos.rex_data_tabla_carga_archivos

    let fechaFormato = data.fechaSFTP != undefined ? data.fechaSFTP.replace('-','') : '';

    let tipoproc = "diario";
    if(data.tipoproc == undefined || data.tipoproc == false){
        tipoproc = "cierre";
    }

    dispatch(ModalGuardandoArchivosPythonTablaCargaArchivoReducer(true))

    respuestaItemPadre = await dispatch(EnviarArchivoCargadoTablaCargaArchivoReducer(data, comentario, fechaFormato, tipoproc, data.pacid));
    respuestaItemPadre && await dispatch(EnviarVariosArchivosReducer(rex_data_tabla_carga_archivos, data, comentario, fechaFormato, tipoproc, respuestaItemPadre,data.pacid))

    dispatch(ModalGuardandoArchivosPythonTablaCargaArchivoReducer(false))

    dispatch(ModalNotificacionPython(true))
    
    dispatch(ObtenerCargaArchivosReducer())

    return true

}

const EnviarVariosArchivosReducer  = (rex_data_tabla_carga_archivos, data, comentario, fechaFormato, tipoproc, subirdata, pacid) => async (dispatch, getState) => {
    
    for (let dat of rex_data_tabla_carga_archivos){
        if(data.item == dat.itempadre){
            await dispatch(EnviarArchivoCargadoTablaCargaArchivoReducer(dat, comentario, fechaFormato, tipoproc, pacid))
        }
    }
    return true
}