
import { notification  } from 'antd';

export const exitoNotificacion = (message) => {
    notification.success({
      message     : 'Exito',
      description : message,
      placement   :'topRight',
    });
};

export const errorNotificacion = (message) => {
    notification.error({
      message     : 'Error',
      description : message,
      placement   :'topRight',
    });
};

export const alertaNotificacion = (message) => {
  notification.warning({
    message     : 'Advertencia',
    description : message,
    placement   :'topRight',
  });
};
export const alertaNotificacionLeft = (message) => {
  notification.warning({
    message     :'Advertencia',
    description : message,
    placement   :'topLeft',
    duration    : 5
  });
};

