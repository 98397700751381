import React, { useState } from 'react';
import { Modal, Form, Row, Col, Input, Select, Switch } from 'antd';
import '../../../Estilos/Rutas/Administrador/Permisos.css'
import { useSelector, useDispatch } from 'react-redux';
import { 
    EditarPermisosAdministradorPermisosReducer,
    OcultarModalEditarPermisosAdministradorReducer
} from '../../../Redux/Actions/Administrador/Permisos/Permisos';

const EditarPermisos = () => {
    
    const dispatch = useDispatch();
    const { Option } = Select;
    const { TextArea } = Input;

    const {
        rex_modal_editar_permisos,
        rex_editar_permisos,
        rex_tipos_permisos
    } = useSelector(({administradorPermisos}) => administradorPermisos);

    const [ perme, setPerme ] = useState({...rex_editar_permisos});

    const [ enviando, setEnviando ] = useState(false);

    const editarPermiso = async (perme) => {
        setEnviando(true);
        await dispatch(EditarPermisosAdministradorPermisosReducer(perme));
        setEnviando(false);
    }

    return (
        <Modal
            centered={true}
            open={rex_modal_editar_permisos}
            onCancel = {() => dispatch(OcultarModalEditarPermisosAdministradorReducer(false, []))}
            onOk = {() => editarPermiso(perme)}
            confirmLoading={enviando}
            okText='Guardar'
            width={700}
            alignItems='center'
            className='ant-modal-content'
        >
            <Form
                layout='vertical'
                autoComplete='off'
            >
                <h2 className='Text-Center-Permisos'>
                    Editar Permiso
                </h2>                
                <Row>
                    <Col
                        xs={{span:24}} sm={{span:8}} md={{span:8}} lg={{span:8}} xl={{span:8}}
                    >
                        <Form.Item
                            label='Permiso:'
                            rules={[{ required: true, message: 'El nombre es requerido' }]}
                            className='Input-Usuario'
                        >
                            <Input
                                name='pempermisos'
                                onChange={(e) => setPerme({
                                        ...perme,[e.target.name]:e.target.value
                                    })
                                }
                                className='Input-Editar'
                                value={perme.pempermisos}
                                size='large'
                            />

                        </Form.Item>
                    </Col>

                    <Col
                        xs={{span:24}} sm={{span:8}} md={{span:8}} lg={{span:8}} xl={{span:8}}
                    >
                        <Form.Item
                            label='Slug:'
                            rules={[{ required: true, message: 'El slug es requerido' }]}
                            className='Input-Usuario'
                        >
                            <Input
                                name='pemslug'
                                onChange={(e) => setPerme({
                                        ...perme,[e.target.name]:e.target.value
                                    })
                                }
                                className='Input-Editar'
                                value={perme.pemslug}
                                size='large'
                            />

                        </Form.Item>
                    </Col>
                    <Col
                        xs={{span:24}} sm={{span:8}} md={{span:8}} lg={{span:8}} xl={{span:8}}
                    >
                        <Form.Item
                            label='Ruta:'
                            rules={[{ required: true, message: 'La ruta es requerido' }]}
                            className='Input-Usuario'
                        >
                            <Input
                                name='pemruta'
                                onChange={(e) => setPerme({
                                        ...perme,[e.target.name]:e.target.value
                                    })
                                }
                                className='Input-Editar'
                                value={perme.pemruta}
                                size='large'
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col
                        xs={{span:24}} sm={{span:16}} md={{span:16}} lg={{span:16}} xl={{span:16}}
                    >
                    <Form.Item
                        label='Descripción:'
                        className='Input-Usuario'
                    >
                        <TextArea
                            rows={3}
                            name='pemdescripcion'
                            onChange={(e)=> setPerme({
                                ...perme,[e.target.name]:e.target.value
                                })
                            }
                            className='Input-Editar'
                            value={perme.pemdescripcion}
                            size='large'
                        />
                    </Form.Item>
                    </Col>
                    <Col
                        xs={{span:24}} sm={{span:8}} md={{span:8}} lg={{span:8}} xl={{span:8}}
                    >
                        <Form.Item
                            label='Tipo:'
                            className='Input-Usuario'
                        >
                            <Select
                                defaultValue={perme.tppid}
                                style={{
                                    width: 120,
                                }}
                                size='large'
                                className='Input-Editar'
                                onChange={(valor) => 
                                    setPerme({
                                        ...perme, tppid: valor
                                    })
                                }>
                                {
                                    rex_tipos_permisos.map((tpp) => (
                                        <Option key={tpp.tppid} value={tpp.tppid}>{tpp.tppnombre}</Option>
                                    ))
                                }    
                                
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{span:24}} sm={{span:8}} md={{span:8}} lg={{span:8}} xl={{span:8}}
                    >
                        <Form.Item
                            label='Permiso especial:'
                            className='Input-Usuario'
                        >
                            <Switch
                                defaultChecked = {perme.pempermisoespecial}
                                className={perme.pempermisoespecial ? 'Circulo-Activo': 'Circulo-Desactivo'}
                                onChange={(valor) => setPerme({...perme, pempermisoespecial: valor})}
                                size='large'
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

        </Modal>
    )
}

export default EditarPermisos