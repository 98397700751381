
import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Tooltip, Spin, Button, Pagination, Input, Select } from 'antd';
import '../../../Estilos/Rutas/Administrador/TiposPermisos.css';
import { useSelector, useDispatch } from 'react-redux';
import { 
    EliminarPemPermisoAdministradorPermisosReducer,
    ObtenerPemPermisosAdministradorPermisosReducer,
    OcultarModalAgregarPermisosAdministradorReducer,
    OcultarModalEditarPermisosAdministradorReducer,
    FiltrarPermisosAdministradorReducer,
    OcultarModalTiposPermisosAdministradorReducer,
    ObtenerTiposPermisosAdministradorPermisosReducer
} from '../../../Redux/Actions/Administrador/Permisos/Permisos';
import {
    DeleteOutlined,
    EditOutlined,
    PlusCircleOutlined,
    SearchOutlined,
    UnorderedListOutlined
} from '@ant-design/icons';
import EditarPermisos from '../../../Componentes/Administrador/Permisos/EditarPermisos';
import AgregarPermisos from '../../../Componentes/Administrador/Permisos/AgregarPermisos';
import { antIcon } from '../../../Funciones/componentes/antd';
import TiposPermisos from '../../../Componentes/Administrador/Permisos/TiposPermisos';


const PermisosTipos = () => {

    const dispatch = useDispatch();
    const { Option } = Select;

    const [ txtBuscar, setTxtBuscar ] = useState('');
    const [ selectTipo, setSelectTipo ] = useState(0);
    const [ cargando, setCargando ] = useState(false);
    const [ enviando, setEnviando ] = useState(false); 
    const [ idEnviando, setIdenviando ] = useState('');
    const [ pageTablePermisos, setPageTablePermisos ] = useState(1);
    const [ pageSizeTablePermisos, setPageSizeTablePermisos ] = useState(10);
    
    const { 
        rex_modal_editar_permisos,
        rex_modal_agregar_permisos,
        rex_tipos_permisos,
        rex_filtrar_permisos,
        rex_modal_tipos_permisos
    } = useSelector(({administradorPermisos}) => administradorPermisos);

    const obtenerPerPermisos = async () => {
        setCargando(true);
        await dispatch(ObtenerPemPermisosAdministradorPermisosReducer());
        await dispatch(ObtenerTiposPermisosAdministradorPermisosReducer())
        setCargando(false);
    }

    useEffect(() => {
      
        obtenerPerPermisos();
    
    }, []);
    
    const eliminarPerPermiso = async ({ pemid }) => {
        setIdenviando(pemid);
        setEnviando(true);
        await dispatch(EliminarPemPermisoAdministradorPermisosReducer(pemid));
        setIdenviando('');
        setEnviando(false);
    }

    const columns = [
        {
            title: 'Item',
            key: 'pemid',
            width: '30px',
            align: 'center',
            render: (text, record, index) => (pageTablePermisos - 1) * pageSizeTablePermisos + index + 1,
        },
        {
            title: 'Permiso especial',
            key: 'pempermisoespecial',
            width: '100px',
            align: 'center',
            render: (_, record) => (
                <div style={{display:'flex', justifyContent:'center'}}>
					<Tooltip placement="top" title={record.pempermisoespecial ? 'Activado':'Desactivado'}>
						<div className={`Circulo ${record.pempermisoespecial? 'Circulo-Activo': 'Circulo-Desactivo'}`}>
						</div>
					</Tooltip>
				</div>
            )
        },
        {
            title: 'Tipo',
            dataIndex: 'tppnombre',
            key: 'tppnombre',
            width: '100px',
            align: 'center',
        },
        {
            title: 'Titulo',
            dataIndex: 'pempermisos',
            key: 'tpuid',
            width: '150px',
            align: 'center',
        },
        {
            title: 'Descripcion',
            key: 'pemdescripcion',
            width: '100px',
            align: 'center',
            render:(_, record) => (
                <div title={record.pemdescripcion} className="Celda-Tabla-Permisos">
                    {record.pemdescripcion}
                </div>
            )
        },
        {
            title: 'Slug',
            dataIndex: 'pemslug',
            key: 'tpuid',
            width: '100px',
            align: 'center',
        },
        {
            title: 'Ruta',
            dataIndex: 'pemruta',
            key: 'tpuid',
            width: '100px',
            align: 'center',
        },
        {
            title: 'Acción',
            key: 'tpuid',
            width: '100px',
            align: 'center',
            render:(_,record) => {

                return idEnviando == record.pemid && enviando?

                    <Spin indicator={antIcon} />
                :
                    <div className='Contenedor-Centrado'>
                        <Tooltip placement="top" title='Editar'>
                            <Button className='Boton-Accion-Editar'
                                onClick={() => dispatch(OcultarModalEditarPermisosAdministradorReducer(true, record))}
                            >
                                <EditOutlined
                                    className='Icono-Accion-Editar'
                                />
                            </Button>
                        </Tooltip>
                        <Tooltip placement="top" title='Eliminar'>
                            <Button
                                onClick={()=> eliminarPerPermiso(record)}
                                className='Boton-Accion-Eliminar'
                            >
                                <DeleteOutlined
                                    className='Icono-Accion-Eliminar'
                                />
                            </Button>
                        </Tooltip>
                    </div>
            }
        },

    ]

    return (
        <div className='Contenedor-Permisos-Tipos'>
            <Row>
                <Col span={24}>
                    <h2 className='Text-Center'>Permisos</h2>
                </Col>
            </Row>

            <Row className='Contenedor-Fila-Agregar'>
                <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                    <Input
						suffix={<SearchOutlined style={{margin:'0 5px'}} />}
						size='large'
						style={{borderRadius:'5px', height:'45px'}}
						placeholder='Buscar'
						value={txtBuscar}
						onChange={(e) => {
							setTxtBuscar(e.target.value)
                            dispatch(FiltrarPermisosAdministradorReducer(e.target.value, selectTipo))
						}}
					/>
                </Col>
                <Col>
                    <Button
                        className='Boton-Agregar'
                        onClick={() => dispatch(OcultarModalTiposPermisosAdministradorReducer(true))}
                    >
                        <UnorderedListOutlined />Tipos de permisos
                    </Button>
                    <Button
                        className='Boton-Agregar'
                        onClick={() => dispatch(OcultarModalAgregarPermisosAdministradorReducer(true))}>
                        Crear<PlusCircleOutlined />
                    </Button>
                </Col>
            </Row>
            <Row style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'10px 0'}}>
                <Col>
                    <Select
                        value={selectTipo}
                        style={{width:'150px', margin:'3px 3px'}}
                        onChange={(valor) => {
                                setSelectTipo(valor)
                                dispatch(FiltrarPermisosAdministradorReducer(txtBuscar, valor))
                            }
                        }
                        className='Select-Filtro'
                            >
                            <Option value={0}>Seleccionar Tipo</Option>

                            {rex_tipos_permisos && rex_tipos_permisos.map((tpp)=> (
                                <Option key={tpp.tppid} value={tpp.tppid}>{tpp.tppnombre}</Option>
                            ))}
                    </Select>
                </Col>
                <Col>
                    <Pagination
                        defaultCurrent={pageTablePermisos}
                        current={pageTablePermisos}
                        total={rex_filtrar_permisos.length}
                        onChange={(page, pageSize) => {
                            setPageTablePermisos(page == 0? 1 : page)
                            setPageSizeTablePermisos(pageSize)

                        }}
                    />
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Table
                        columns={columns}
                        dataSource={rex_filtrar_permisos}
                        loading={cargando}
                        pagination={{
                            defaultCurrent: pageTablePermisos,
                            current: pageTablePermisos,
                            pageSize: pageSizeTablePermisos,
                        }}
                        size={'small'}
                    >

                    </Table>
                </Col>
            </Row>

            {rex_modal_editar_permisos && <EditarPermisos/> }
            {rex_modal_agregar_permisos && <AgregarPermisos/>}
            {rex_modal_tipos_permisos && <TiposPermisos/>}

        </div>
    )
}

export default PermisosTipos