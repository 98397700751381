import { Button } from 'antd'
import React from 'react'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

const AccionesAgregarCorreoCargaArchivos = (props) => {

    return (
        <div style={{display:'flex'}}>
            <Button  style={{margin:'0 2px'}}  type='primary'
                onClick={() => props.validarCorreo(props.correo, props.tipo)}
            >
                <CheckCircleOutlined/>
            </Button>
            <Button style={{margin:'0 2px'}} type='danger' onClick={() => props.setAgregarCorreo(false)}><CloseCircleOutlined/></Button>
        </div>
    )
}

export default AccionesAgregarCorreoCargaArchivos