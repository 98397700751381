import React, { useState } from 'react'
import Modal from 'antd/lib/modal/Modal'
import { Row, Col, Input, Form, Select, Switch } from 'antd'
import '../../../Estilos/Rutas/DtsDistribuidoras/DtsDistribuidoras.css'
import { useSelector, useDispatch } from 'react-redux'
import { 
    AgregarDtsDistribuidorasAdministradorDistribuidorasReducer,
    ModalAgregarDtsDistribuidoraAdministradorDistribuidorasReducer 
} from '../../../Redux/Actions/Administrador/DtsDistribuidoras/DtsDistribuidoras'

const AgregarDtsDistribuidoras = () => {

    const dispatch = useDispatch();
    const { Option } = Select;

    const {
        rex_modal_agregar_distribuidoras_administrador,
    } = useSelector(({administradorDistribuidoras}) => administradorDistribuidoras);

    const {
        rex_cuentas,
        rex_paises,
    } = useSelector(({administradorCuentas}) => administradorCuentas);

    const {
        rex_canales_administrador
    } = useSelector(({administradorCargaArchivos}) => administradorCargaArchivos);

    const [ dtsn, setDtsn ] = useState({dtsextractor: true})
    const [ agregandoDts, setEditandoDts ] = useState(false)

    return (
        <Modal
            open={rex_modal_agregar_distribuidoras_administrador}
            onCancel = {() => dispatch(ModalAgregarDtsDistribuidoraAdministradorDistribuidorasReducer(false))}
            onOk = {async () => {
                setEditandoDts(true)
                await dispatch(AgregarDtsDistribuidorasAdministradorDistribuidorasReducer(dtsn))
                setEditandoDts(false)
            }}
            confirmLoading={agregandoDts}
            okText='Guardar'
            width={600}
        >
            <h2 className='Text-Center-Agregar-Cuenta'>Agregar Distribuidora</h2>
            <Form
                layout='vertical'
                autoComplete='off'
            >
                <Row>
                    <Col xs={{span:24}} sm={{span:12}} md={{span:12}} lg={{span:12}} xl={{span:12}}>
                        <Form.Item
                            label='Nombre:'
                            className='Input-Usuario'
                            name='dtsclientesubhml'
                            rules={[
                                { required: true, message: 'El nombre es requerido' },
                                { min: 1, message: 'El nombre es muy corto' }
                            ]}
                        >
                            <Input
                                name='dtsclientesubhml'
                                autoComplete='off'
                                onChange={(e) => setDtsn({
                                        ...dtsn,[e.target.name]:e.target.value
                                    })
                                }
                                className='Input-Editar'
                                value={dtsn.dtsclientesubhml}
                                size='large'
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={{span:24}} sm={{span:12}} md={{span:12}} lg={{span:12}} xl={{span:12}}>
                        <Form.Item
                            label='Canal:'
                            className='Input-Usuario'
                            required
                        >
                            <Select
                            placeholder='Seleccionar'
                                className='Select-Dts-Administrador'
                                onChange={(valor)=> setDtsn({...dtsn, canid: valor})}
                            >
                                {
                                    rex_canales_administrador.map((can) => (
                                        <Option
                                            key={can.canid}
                                            value={can.canid}
                                        >
                                            {can.cannombre}
                                        </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{span:24}} sm={{span:4}} md={{span:4}} lg={{span:4}} xl={{span:4}}>
                        <Form.Item
                            label='Extractor:'
                            className='Input-Usuario'
                        >
                            <Switch
                                defaultChecked
                                className={dtsn.dtsextractor ? 'Circulo-Activo': 'Circulo-Desactivo'}
                                onChange={(valor)=> setDtsn({...dtsn, dtsextractor: valor})}
                            />

                        </Form.Item>
                    </Col>
                    <Col xs={{span:24}} sm={{span:10}} md={{span:10}} lg={{span:10}} xl={{span:10}}>
                        <Form.Item
                            label='Cuenta:'
                            rules={[{ required: true, message: 'La cuenta es requerida' }]}
                            className='Input-Usuario'
                            required
                        >
                            <Select
                                placeholder='Seleccionar'
                                className='Select-Dts-Administrador'
                                onChange={(valor)=> setDtsn({...dtsn, cueid: valor})}
                            >
                                {
                                    rex_cuentas.map((cue) => (
                                        <Option
                                            key={cue.cueid}
                                            value={cue.cueid}
                                        >
                                            {cue.cuenombre}
                                        </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{span:24}} sm={{span:10}} md={{span:10}} lg={{span:10}} xl={{span:10}}>
                        <Form.Item
                            label='Pais:'
                            className='Input-Usuario'
                            required
                        >
                            <Select
                                placeholder='Seleccionar'
                                className='Select-Dts-Administrador'
                                onChange={(valor)=> setDtsn({...dtsn, paiid: valor})}
                            >
                                {
                                    rex_paises.map((pai) => (
                                        <Option
                                            key={pai.paiid}
                                            value={pai.paiid}
                                        >
                                            {pai.painombre}
                                        </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

        </Modal>
    )
}

export default AgregarDtsDistribuidoras