import React from 'react'
import {
    CAMBIAR_FECHA_STATUS
} from '../../../Constantes/Status/Status'

const fechaactual = new Date();

const INIT_STATE = {
    rex_anio_seleccionado_status : fechaactual.getFullYear(),
    rex_mes_numero_seleccionado_status : fechaactual.getMonth()+1
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CAMBIAR_FECHA_STATUS: {
            return {
                ...state,
                rex_anio_seleccionado_status : action.payload.anio,
                rex_mes_numero_seleccionado_status : action.payload.mes,
            }
        }
        default:
            return state;
    }
}
