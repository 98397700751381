import React from 'react'
import {
    OBTENER_DATA_TABLA_DETALLE_STATUS,
    SELECCIONAR_FILA_DATA_TABLA_DETALLE_STATUS,
    MOSTRAR_MODAL_CONFIRMACION_DETALLE_STATUS,
    EDITAR_O_ELIMINAR_STATUS_DETALLE_STATUS,
    CARGANDO_MODAL_CONFIRMACION_STATUS_DETALLE_STATUS,
    ACTUALIZAR_COLUMMNAS_TABLA_DETALLE_STATUS
} from '../../../Constantes/Status/Status'
import { CheckCircleTwoTone, DeleteOutlined } from '@ant-design/icons';
import { useDispatch} from "react-redux";
import {
    EditarStatusArchivoSubidoReducer,
    SeleccionarFilaDetalleStatusReducer
} from '../../Actions/Status/TablaDetalleStatus'
import Moment from 'moment';

export const IconoEditarStatus = (props) => {

    const dispatch = useDispatch()

    const record = props.record

    return (
        <div className='Contenedor-Iconos-Detalle-Status'>
            <div
                style={{
                    paddingLeft: "10px",
                    fontSize: "18px",
                    cursor: "pointer"
                }}
                onClick={() => {
                    dispatch(
                        SeleccionarFilaDetalleStatusReducer(record, "editar")
                    )
                }}
            >
                <CheckCircleTwoTone twoToneColor="#2FDA36" />
            </div>

            <div
                style={{
                    paddingLeft: "10px",
                    fontSize: "18px",
                    cursor: "pointer"
                }}
                onClick={() => {
                    dispatch(
                        SeleccionarFilaDetalleStatusReducer(record, "eliminar")
                    )
                }}
            >
                <DeleteOutlined  twoToneColor="#9C9B9B" />
            </div>
        </div>
    )
}


const INIT_STATE = {
    rex_data_tabla_detalle_status : [],
    rex_fila_data_seleccionado_tabla_detalle_status : null,
    rex_mostrar_modal_confirmacion_staus_detalle_status : false,
    rex_cargando_modal_confirmacion_staus_detalle_status : false,
    rex_editar_o_eliminar_status_detalle_status : null,
    rex_columnas_tabla_detalle_status: [
        {
            title: 'Item',
            dataIndex: 'item',
            key: 'item',
            width: 65,
            align:'center',
            render: (text, record) => (
                <div title={text} className={`Celda-Tabla-Carga-Archivo ${record.mesanterior?'W400-S11-H16-C1E1E1E-Red':'W400-S11-H16-C1E1E1E'}`}>
                    {text}
                </div>
            ),
        },
        {
            title: 'Tipo Doc',
            dataIndex: 'tcanombre',
            key: 'tcanombre',
            align:'center',
            render: (text, record) => (
                <div title={text} className={`Celda-Tabla-Carga-Archivo ${record.mesanterior?'W400-S11-H16-C1E1E1E-Red':'W400-S11-H16-C1E1E1E'}`}>
                    {text}
                </div>
            ),
        },
        {
            title: 'Archivo CSV',            
            key: 'stcnombre',
            align:'center',
            render: (_, record) => (
                <div 
                    title={
                        record.cardistribuidora
                        ?record.cardistribuidora
                        :record.stcnombre
                    } 
                    className="Celda-Tabla-Carga-Archivo W400-S11-H16-C1E1E1E"
                    style={{display:'flex'}}
                >
                    <div>
                        <a
                            href={record.carcsvurl}
                            download
                        >
                            {
                                record.cardistribuidora
                                ?record.cardistribuidora
                                :record.stcnombre
                            }
                        </a>
                    </div>
                </div>
            ),
        },
        {
            title: 'Archivo XLSX',
            key: 'stcnombre',
            align:'center',
            render: (_, record) => (
                <div 
                    title={
                        record.cardistribuidora
                        ?record.cardistribuidora
                        :record.stcnombre
                    } 
                    className="Celda-Tabla-Carga-Archivo W400-S11-H16-C1E1E1E"
                    style={{display:'flex'}}
                >
                    <div>
                        <a
                            href={record.carexcelurl}
                            download
                        >
                            {
                                record.cardistribuidora
                                ?record.cardistribuidora
                                :record.stcnombre
                            }
                        </a>
                    </div>
                </div>
            ),
        },
        {
            title: 'Tiempo',
            dataIndex: 'hstfechasubida',
            key: 'hstfechasubida',
            align:'center',
            render: (text, record) => (
                <div title={text} className={`Celda-Tabla-Carga-Archivo ${record.mesanterior?'W400-S11-H16-C1E1E1E-Red':'W400-S11-H16-C1E1E1E'}`}>
                    {
                        text == 'undefined'
                        ?""
                        :text
                    }

                </div>
            ),
        },
        {
            title: 'Usuario',
            dataIndex: 'pernombrecompleto',
            key: 'pernombrecompleto',
            align:'center',
            render: (_, record) => (
                <div
                    title={record.pernombrecompleto}
                    className={`Celda-Tabla-Carga-Archivo ${record.mesanterior?'W400-S11-H16-C1E1E1E-Red':'W400-S11-H16-C1E1E1E'}`}
                >
                    {record.pernombrecompleto}
                </div>
            ),
        },
        {
            title: 'Fecha de Carga',
            key: 'created_at',
            dataIndex: 'created_at',
            align:'center',
            render: (_, record) => (
            // render: (text) => (
                <div title={Moment(record.created_at).format('D MMMM YYYY')} className={`Celda-Tabla-Carga-Archivo ${record.mesanterior?'W400-S11-H16-C1E1E1E-Red':'W400-S11-H16-C1E1E1E'}`}>
                    
                    {
                        record.created_at
                        ?Moment(record.created_at).format('D MMMM YYYY')
                        :''
                    }
                </div>
            ),
        },
        {
            title: 'Hora',
            dataIndex: 'carhoracarga',
            key: 'carhoracarga',
            align:'center',
            render: (text, record) => (
                <div title={text} className={`Celda-Tabla-Carga-Archivo ${record.mesanterior?'W400-S11-H16-C1E1E1E-Red':'W400-S11-H16-C1E1E1E'}`}>
                    {text}
                </div>
            ),
        },
        {
            title: 'Status',
            key: 'statususuario',
            align:'center',
            render: (_, record) => (
                <div 
                    className="Celda-Tabla-Carga-Archivo W400-S11-H16-C1E1E1E"
                >
                    <span
                        title='Cargado'
                        style={{color:'#2FDA36', fontWeight:'600'}}
                    >Cargado</span>
                </div>
            ),
        },
        {
            title: 'Responsable',
            dataIndex: 'responsable',
            align:'center',
            key: 'responsable',
            width:'150px',
            render: (text, record) => (
                <div title={text} className={`Celda-Tabla-Carga-Archivo ${record.mesanterior?'W400-S11-H16-C1E1E1E-Red':'W400-S11-H16-C1E1E1E'}`}>
                    {text}
                </div>
            ),
        },
        {
            title: 'Fecha de Carga',
            dataIndex: 'carfechaproductivo',
            key: 'carfechaproductivo',
            align:'center',
            render: (text, record) => (
                <div title={Moment(text).format('D MMMM YYYY')} className={`Celda-Tabla-Carga-Archivo ${record.mesanterior?'W400-S11-H16-C1E1E1E-Red':'W400-S11-H16-C1E1E1E'}`}>
                    
                    {
                        text
                        ?Moment(text).format('D MMMM YYYY')
                        :''
                    }
                </div>
            ),
        },
        {
            title: 'Tipo de proceso',
            dataIndex: 'cartipproc',
            key: 'cartipproc',
            align:'center',
            render: (cartipproc) => (
                <div title={cartipproc} className="Celda-Tabla-Carga-Archivo W400-S11-H16-C1E1E1E">
                    {cartipproc}
                </div>
            ),
        },
        {
            title: 'Días de Retraso',
            key: 'diasretraso',
            render: (_, record) => (
                <div 
                    title={record.diasretraso} 
                    className={`Celda-Tabla-Carga-Archivo ${record.mesanterior?'W400-S11-H16-C1E1E1E-Red':'W400-S11-H16-C1E1E1E'}`}
                    style={{display:'flex', float: "right", paddingRight: '10px'}}
                >
                    <div>
                        {record.diasretraso} días
                    </div>
                    <div 
                        className='Circulo-Representativo-Status'
                        style={
                            record.diasretraso <= 0
                            ?{background:'#2FDA36'}
                            :{}
                        }
                    >

                    </div>
                </div>
            ),
        },
        {
            title: 'Status',
            key: '',
            render: (_, record) => (
                <div 
                    title={""} className="Celda-Tabla-Carga-Archivo W400-S11-H16-C1E1E1E"
                    style={{display:'flex'}}
                >
                    <div 
                        className={
                            record.estid == 5 || record.estid == 8
                            ?''
                            :'Txt-Staus-Detalle-Status'
                        }
                    >
                        {
                            record.estid == 4
                            ?<span 
                                title='No actualizado' 
                                style={{color:'#F65555', fontWeight:'600'}}
                            >No actualizado</span>
                            :record.estid == 5
                                ?<span 
                                    title='Procesado'
                                    style={{color:'#2FDA36', fontWeight:'600'}}
                                >
                                    Procesado
                                </span>
                                :record.estid == 6
                                    ?<span 
                                        title='No Procesado'
                                        style={{color:'#F65555', fontWeight:'600'}}
                                    >
                                        No Procesado
                                    </span>
                                    :record.estid == 8
                                        ? <span 
                                            title='Error al procesar' 
                                            style={{color:'#F65555', fontWeight:'600'}}
                                        >
                                            Error al procesar
                                        </span>
                                        :<span title={"Sin Estado"}>{"Sin Estado"}</span>
                        }
                    </div>
                    {
                        record.estid == 5 || record.estid == 8
                        ?null
                        :<IconoEditarStatus 
                            record = {record}
                        />
                    }
                </div>
            ),
        },
    ]
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case OBTENER_DATA_TABLA_DETALLE_STATUS: {
            return {
                ...state,
                rex_data_tabla_detalle_status : action.payload
            }
        }
        case SELECCIONAR_FILA_DATA_TABLA_DETALLE_STATUS: {
            return {
                ...state,
                rex_fila_data_seleccionado_tabla_detalle_status : action.payload
            }
        }
        case MOSTRAR_MODAL_CONFIRMACION_DETALLE_STATUS: {
            return {
                ...state,
                rex_mostrar_modal_confirmacion_staus_detalle_status : action.payload
            }
        }
        case EDITAR_O_ELIMINAR_STATUS_DETALLE_STATUS: {
            return {
                ...state,
                rex_editar_o_eliminar_status_detalle_status : action.payload
            }
        }
        case CARGANDO_MODAL_CONFIRMACION_STATUS_DETALLE_STATUS: {
            return {
                ...state,
                rex_cargando_modal_confirmacion_staus_detalle_status : action.payload
            }
        }
        case ACTUALIZAR_COLUMMNAS_TABLA_DETALLE_STATUS: {
            return {
                ...state,
                rex_columnas_tabla_detalle_status : action.payload
            }
        }
        default:
            return state;
    }
}
