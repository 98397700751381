import {
    OBTENER_CUENTAS_PAISES,
    EDITAR_CUENTA_PAIS_ADMINISTRADOR,
    AGREGAR_PAIS_ADMINISTRADOR,
    AGREGAR_CUENTA_ADMINISTRADOR,
    FILTRAR_CUENTAS_PAISES,
    OBTENER_CUENTAS_CUENTAS,
    OBTENER_PAISES_CUENTAS,
    OBTENER_PACIDS_PAISES_CUENTAS
} from "../../../../Constantes/Administrador/Cuentas/Cuentas";


const INIT_STATE = {

    rex_cuentas_paises_cuentas  : [],
    rex_filtro_paises_cuentas   : [],
    rex_paises_cuentas          : [],
    rex_modal_editar_cuenta     : false,
    rex_modal_agregar_cuenta    : false,
    rex_modal_agregar_pais      : false,
    rex_cuenta_paises_editar    : [],
    rex_paises_cue_inicial      : [],

    rex_paises                  : [],
    rex_cuentas                 : [],
    rex_pacids                  : [],
};

export default (state = INIT_STATE, action) => {

    switch (action.type) {

        case OBTENER_CUENTAS_PAISES: {

            return {
                ...state,
                rex_cuentas_paises_cuentas      : action.payload.cuentaspaises,
            }
        }

        case OBTENER_CUENTAS_CUENTAS: {
            return {
                ...state,
                rex_cuentas      : action.payload
            }
        }
        
        case OBTENER_PAISES_CUENTAS: {
            return {
                ...state,
                rex_paises      : action.payload
            }
        }

        case OBTENER_PACIDS_PAISES_CUENTAS: {
            return {
                ...state,
                rex_pacids      : action.payload
            }
        }

        case FILTRAR_CUENTAS_PAISES: {

            return {
                ...state,
                rex_filtro_paises_cuentas      : action.payload
            }
        }

        case EDITAR_CUENTA_PAIS_ADMINISTRADOR: {

            return {
                ...state,
                rex_cuenta_paises_editar             : action.payload.cuepais,
                rex_modal_editar_cuenta              : action.payload.modalcuee,
                rex_paises_cue_inicial               : action.payload.paisescue
            }
        }
        case AGREGAR_CUENTA_ADMINISTRADOR: {

            return {
                ...state,
                rex_modal_agregar_cuenta             : action.payload,
            }
        }

        case AGREGAR_PAIS_ADMINISTRADOR: {

            return {
                ...state,
                rex_modal_agregar_pais               : action.payload
            }
        }

        default:
            return state;
    }
}