import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Form, Row, Col, Input, Switch, AutoComplete } from 'antd';
import '../../../Estilos/Rutas/Administrador/Permisos.css'
import { 
  AgregarPermisoAdministradorPermisoReducer,
  OcultarModalAgregarPermisosAdministradorReducer
} from '../../../Redux/Actions/Administrador/Permisos/Permisos';

const AgregarPermisos = () => {

    const dispatch = useDispatch();
    const { TextArea } = Input;

    const {
      rex_modal_agregar_permisos,
      rex_tipos_permisos
    } = useSelector(({administradorPermisos}) => administradorPermisos);

    const [ enviando, setEnviando ] = useState(false);
    const [ options, setOptions ] = useState([...rex_tipos_permisos]);
    const [ permn, setPermn ] = useState({
      pempermisos     : '',
      pemruta         : '',
      pemslug         : '',
      tppnombre       : '',
      pemdescripcion  : '',
      pemespecial     : true
    })

    const agregarPermiso = async (permn) => {
        setEnviando(true);
        let respperm = await dispatch(AgregarPermisoAdministradorPermisoReducer(permn));
        setEnviando(respperm);
    }

  return (
    <Modal
      centered={true}
      open={rex_modal_agregar_permisos}
      onCancel = {() => dispatch(OcultarModalAgregarPermisosAdministradorReducer(false))}
      onOk = {()=> agregarPermiso(permn)}
      confirmLoading={enviando}
      okText='Guardar'
      width={700}
      alignItems='center'
      className='ant-modal-content'
    >
        <Form
          layout='vertical'
          autoComplete='off'
        >
          <h2 className='Text-Center-Permisos'>
            Agregar Permiso
          </h2>

          <Row>
            <Col
                xs={{span:24}} sm={{span:8}} md={{span:8}} lg={{span:8}} xl={{span:8}}
            >
              <Form.Item
                  label='Permiso:'
                  rules={[
                      { required: true, message: 'El nombre es requerido' },
                      { min: 4, message: 'El nombre es muy corto' }
                    
                  ]}
                  className='Input-Usuario'
                  name='pempermisos'
              >
                  <Input
                      name='pempermisos'
                      onChange={(e) =>setPermn({
                          ...permn,[e.target.name]:e.target.value
                        })
                      }
                      className='Input-Editar'
                      value={permn.pempermisos}
                      size='large'
                  />

              </Form.Item>
            </Col>
            <Col
                xs={{span:24}} sm={{span:8}} md={{span:8}} lg={{span:8}} xl={{span:8}}
            >
              <Form.Item
                  label='Slug:'
                  name='pemslug'
                  rules={[
                    { required: true, message: 'El slug es requerido' },
                    { min: 4, message: 'El slug es muy corto' }
                ]}
                  className='Input-Usuario'
              >
                  <Input
                      name='pemslug'
                      onChange={(e) => setPermn({
                          ...permn,[e.target.name]:e.target.value
                        })
                      }
                      className='Input-Editar'
                      value={permn.pemslug}
                      size='large'
                  />

              </Form.Item>
            </Col>
            <Col
                xs={{span:24}} sm={{span:8}} md={{span:8}} lg={{span:8}} xl={{span:8}}
            >
              <Form.Item
                  label='Ruta:'
                  name='pemruta'
                  rules={[
                    { required: true, message: 'La ruta es requerida' },
                    { min: 4, message: 'La ruta es muy corta' }
                  
                  ]}
                  className='Input-Usuario'
              >
                  <Input
                      name='pemruta'
                      onChange={(e)=> setPermn({
                          ...permn,[e.target.name]:e.target.value
                        })
                      }
                      className='Input-Editar'
                      value={permn.pemruta}
                      size='large'
                  />
              </Form.Item>
            </Col>
            
          </Row>
          <Row>
            <Col
                xs={{span:24}} sm={{span:16}} md={{span:16}} lg={{span:16}} xl={{span:16}}
            >
              <Form.Item
                  label='Descripción:'
                  name='pemdescripcion'
                  className='Input-Usuario'
              >
                  <TextArea
                      rows={3}
                      name='pemdescripcion'
                      onChange={(e)=> setPermn({
                          ...permn,[e.target.name]:e.target.value
                        })
                      }
                      className='Input-Editar'
                      value={permn.pemdescripcion}
                      size='large'
                  />
              </Form.Item>
            </Col>
            <Col
              xs={{span:24}} sm={{span:8}} md={{span:8}} lg={{span:8}} xl={{span:8}}
            >
              <Form.Item
                  label='Tipo:'
                  className='Input-Usuario'
              >
                <AutoComplete
                  options={options}
                  placeholder='Tipo de permiso'
                  className='Input-Editar'
                  filterOption={(inputValue, option) =>
                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                  value={permn.tppnombre}
                  onChange={
                    (valor) => setPermn({...permn, tppnombre: valor})
                  }
                />
              </Form.Item>
            </Col>
            <Col
              xs={{span:24}} sm={{span:8}} md={{span:8}} lg={{span:8}} xl={{span:8}}
            >
              <Form.Item
                  label='Permiso especial:'
                  className='Input-Usuario'
              >
                <Switch
                  defaultChecked
                  className={permn.pemespecial ? 'Circulo-Activo': 'Circulo-Desactivo'}
                  onChange={(valor) => setPermn({...permn, pemespecial: valor})}
                  size='large'
                />
              </Form.Item>
            </Col>
            
          </Row>
        </Form>

    </Modal>
  )
}

export default AgregarPermisos