
import {
    OBTENER_USUARIOS_ADMINISTRADOR_USUARIOS,
    FILTRAR_USUARIOS_ADMINISTRADOR_USUARIOS,
    EDITAR_USUARIO_ADMINISTRADOR_USUARIOS,
    CARGANDO_TABLA_USUARIOS_ADMINISTRADOR,
    OCULTAR_MODAL_PERMISOS_ESPECIALES_ADMINISTRADOR_USUARIOS,
    OBTENER_PERMISOS_ESPECIALES_ADMINISTRADOR_USUARIOS,
} from "../../../../Constantes/Administrador/Usuarios/Usuarios";

const INIT_STATE = {

    rex_usuarios: [],
    rex_filtro_usuarios: [],
    rex_editar_usuario: {}, 
    rex_modal_editar_usuario: false,
    rex_cargando_tabla_usuarios: false,
    rex_modal_permisos_especiales_usuarios: false,
    rex_permisos_especiales_usuarios : [],
    
};

export default (state = INIT_STATE, action) => {

    switch (action.type) {

        case OBTENER_USUARIOS_ADMINISTRADOR_USUARIOS: {

            return {
                ...state,
                rex_usuarios : action.payload,
            }
        }
        case FILTRAR_USUARIOS_ADMINISTRADOR_USUARIOS: {

            return {
                ...state,
                rex_filtro_usuarios : action.payload,
            }
        }

        case CARGANDO_TABLA_USUARIOS_ADMINISTRADOR: {
            return {
                ...state,
                rex_cargando_tabla_usuarios: action.payload
            }
        }

        case EDITAR_USUARIO_ADMINISTRADOR_USUARIOS: {

            return {
                ...state,
                rex_editar_usuario          : action.payload.usue,
                rex_modal_editar_usuario    : action.payload.modalv,
            }
        }

        case OCULTAR_MODAL_PERMISOS_ESPECIALES_ADMINISTRADOR_USUARIOS: {
            
            return {
                ...state,
                rex_modal_permisos_especiales_usuarios  : action.payload
            }
        }

        case OBTENER_PERMISOS_ESPECIALES_ADMINISTRADOR_USUARIOS: {
            
            return {
                ...state,
                rex_permisos_especiales_usuarios  : action.payload
            }
        }

        default:
            return state;
    }
}