import React from 'react'
import {
    OBTENER_DATA_TABLA_STATUS,
    SELECCIONAR_FILA_DATA_TABLA_STATUS,
    MOSTRAR_MODAL_DETALLE_STATUS
} from '../../../Constantes/Status/Status'
import IconoLupaAzul from '../../../Assets/Imagenes/Rutas/Status/lupaazul.png'
import { useDispatch} from "react-redux";
import {
    SeleccionarFilaDataStatusReducer
} from '../../Actions/Status/TablaStatus'
import {
    EditarColumnaTipoProcesoDetalleStatusReducer
} from '../../Actions/Status/TablaDetalleStatus'
import Moment from 'moment';

const IconoLupaDetalle = (props) => {
    
    const dispatch = useDispatch()

    const fila = props.fila
    
    return (
        <div
            onClick={() => {
                dispatch(SeleccionarFilaDataStatusReducer(fila))
                dispatch(EditarColumnaTipoProcesoDetalleStatusReducer(fila))
            }}
        >
            <img src={IconoLupaAzul} className="Icono-Lupa-Status" />
        </div>
    )
}

const INIT_STATE = {

    rex_fila_data_seleccionado_tabla_status : null,
    rex_mostrar_modal_detalle_status : false,

    rex_data_tabla_status : [],
    rex_columnas_tabla_status: [
        {
            title: 'Item',
            dataIndex: 'item',
            key: 'item',
            width: 65,
            align:'center',
        },
        {
            title: 'Categoría',
            dataIndex: 'ccanombre',
            key: 'ccanombre',
            width: 100,
            align:'center',
            render: (text) => (
                <div title={text} className="Celda-Tabla-Carga-Archivo W400-S11-H16-C1E1E1E">
                    {text}
                </div>
            ),
        },
        {
            title: 'Tipo Doc',
            dataIndex: 'tcanombre',
            key: 'tcanombre',
            align:'center',
            width: 100,
            render: (text) => (
                <div title={text} className="Celda-Tabla-Carga-Archivo W400-S11-H16-C1E1E1E">
                    {text}
                </div>
            ),
        },
        {
            title: 'Archivo CSV',
            key: 'stcnombre',
            align:'center',
            render: (_, record) => (
                <div 
                    title={
                        record.cardistribuidoraexcel 
                        ? record.cardistribuidoraexcel
                        : record.cardistribuidoracsv 
                            ? record.cardistribuidoracsv
                            : !record.fechaCsv && record.agregarnuevasfilas ? 'Holding' : record.stcnombre
                    } className="Celda-Tabla-Carga-Archivo W400-S11-H16-C1E1E1E"
                    style={{display:'flex'}}
                >
                    <IconoLupaDetalle
                        fila = {record}
                    />
                    <div>
                        <a
                            href={record.carcsvurl}
                            download
                        >
                            {
                                record.cardistribuidoraexcel 
                                ? record.cardistribuidoraexcel
                                : record.cardistribuidoracsv 
                                    ? record.cardistribuidoracsv
                                    : !record.fechaCsv && record.agregarnuevasfilas ? 'Holding' : record.stcnombre
                            }
                        </a>
                    </div>
                </div>
            ),
        },
        {
            title: 'Archivo XLSX',
            dataIndex: 'stcnombre',
            key: 'stcnombre',
            align:'center',
            render: (_, record) => (
                <div 
                    title={
                        record.cardistribuidoraexcel 
                        ? record.cardistribuidoraexcel
                        : record.cardistribuidoracsv 
                            ? record.cardistribuidoracsv
                            : !record.fechaCsv && record.agregarnuevasfilas ? 'Holding' : record.stcnombre
                    } className="Celda-Tabla-Carga-Archivo W400-S11-H16-C1E1E1E"
                    style={{display:'flex'}}
                >
                    <IconoLupaDetalle
                        fila = {record}
                    />
                    <div>
                        <a
                            href={record.carexcelurl}
                            download
                        >
                            {
                                record.cardistribuidoraexcel 
                                ? record.cardistribuidoraexcel
                                : record.cardistribuidoracsv 
                                    ? record.cardistribuidoracsv
                                    : !record.fechaCsv && record.agregarnuevasfilas ? 'Holding' : record.stcnombre
                            }
                        </a>
                    </div>
                </div>
            ),
        },
        {
            title: 'Responsable',
            dataIndex: 'pernombrecompletoresponsable',
            key: 'pernombrecompletoresponsable',
            align:'center',
            render: (text) => (
                <div title={text} className="Celda-Tabla-Carga-Archivo W400-S11-H16-C1E1E1E">
                    {text}
                </div>
            ),
        },
        {
            title: 'Usuario',
            dataIndex: 'pernombrecompletosubida',
            key: 'pernombrecompletosubida',
            align:'center',
            render: (_,record) => (
                <div title={record.pernombrecompletosubida} className="Celda-Tabla-Carga-Archivo W400-S11-H16-C1E1E1E">
                    {record.pernombrecompletosubida == null ? record.pernombrecompletosubidaexcel : record.pernombrecompletosubida}
                </div>
            ),
        },
        {
            title: 'Fecha de Carga',
            dataIndex: 'fechaCsv',
            align:'center',
            key: 'fechaCsv',
            render: (text) => (
                <div title={Moment(text).format('D MMM YYYY')} className="Celda-Tabla-Carga-Archivo W400-S11-H16-C1E1E1E">
                    {
                        text
                        ?Moment(text).format('D MMMM YYYY')
                        :''
                    }
                </div>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align:'center',
            render: (_, record) => (
                <div 
                    className="Celda-Tabla-Carga-Archivo W400-S11-H16-C1E1E1E"
                >
                    {
                        record.carcsvid
                        ?<span
                            title='Cargado'
                            style={{color:'#2FDA36', fontWeight:'600'}}
                        >Cargado</span>
                        :<span
                            title='No actualizado'
                            style={{color: '#F65555', fontWeight:'600'}}
                        >No actualizado</span>
                    }
                </div>
            ),
        },
        {
            title: 'Responsable',
            dataIndex: 'usurespcategoria',
            key: 'usurespcategoria',
            align:'center',
            render: (text) => (
                <div title={text} className="Celda-Tabla-Carga-Archivo W400-S11-H16-C1E1E1E">
                    {text}
                </div>
            ),
        },
        {
            title: 'Fecha de Carga',
            dataIndex: 'carcsvfecprod',
            key: 'carcsvfecprod',
            align:'center',
            render: (text) => (
                <div title={Moment(text).format('D MMMM YYYY')} className="Celda-Tabla-Carga-Archivo W400-S11-H16-C1E1E1E">
                    
                    {
                        text
                        ?Moment(text).format('D MMMM YYYY')
                        :''
                    }
                </div>
            ),
        },
        {
            title: 'Días de Retraso',
            key: 'diasretraso_confirmar',
            align:'center',
            render: (_, record) => (
                <div 
                    title={record.diasretraso} 
                    className="Celda-Tabla-Carga-Archivo W400-S11-H16-C1E1E1E"
                    style={{display:'flex', float: "right", paddingRight: '10px'}}
                >
                    <div>
                        {record.diasretraso} días
                    </div>
                    <div 
                        className='Circulo-Representativo-Status'
                        style={
                            record.diasretraso <= 0
                            ?{background:'#2FDA36'}
                            :{}
                        }
                    >

                    </div>
                </div>
            ),
        },
        {
            title: 'Status',
            key: 'status_confirmar',
            align:'center',
            render: (_, record) => (
                <div 
                    className="Celda-Tabla-Carga-Archivo W400-S11-H16-C1E1E1E"
                >
                    {
                        record.estid == 4
                        ?<span 
                            title='No actualizado' 
                            style={{color:'#F65555', fontWeight:'600'}}
                        >No actualizado</span>
                        :record.estid == 5
                            ?<span 
                                title='Procesado'
                                style={{color:'#2FDA36', fontWeight:'600'}}
                            >
                                Procesado
                            </span>
                            :record.estid == 6
                                ?<span 
                                    title='No Procesado'
                                    style={{color:'#F65555', fontWeight:'600'}}
                                >
                                    No Procesado
                                </span>
                                :<span title={record.estestado}>{record.estestado}</span>
                    }
                </div>
            ),
        },
    ]
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case OBTENER_DATA_TABLA_STATUS: {
            return {
                ...state,
                rex_data_tabla_status : action.payload
            }
        }
        case SELECCIONAR_FILA_DATA_TABLA_STATUS: {
            return {
                ...state,
                rex_fila_data_seleccionado_tabla_status : action.payload                
            }
        }
        case MOSTRAR_MODAL_DETALLE_STATUS: {
            return {
                ...state,
                rex_mostrar_modal_detalle_status : action.payload                
            }
        }
        default:
            return state;
    }
}
