import React, { useState } from 'react'
import { Modal, Form, Row, Col, Input, Select, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { 
	AgregarTipoCargaAdministradorCargaArchivosReducer,
	MostrarModalAgregarTipoCargaCargaArchivos
} from '../../../Redux/Actions/Administrador/CargaArchivos/CargaArchivos';
import '../../../Estilos/Rutas/Administrador/CargaArchivos.css'
import FooterModalCargaArchivos from '../../../Funciones/componentes/FooterModalCargaArchivos';

const AgregarTipoCarga = (props) => {

    const dispatch = useDispatch();
    const { Option } = Select;

    const {
        rex_modal_agregar_tcatipo_administrador,
		rex_canales_administrador,
		rex_categorias_unicos
    } = useSelector(({administradorCargaArchivos}) => administradorCargaArchivos);

	const {
        rex_paises,
		rex_cuentas
    } = useSelector(({administradorCuentas}) => administradorCuentas);

    const [ enviando, setEnviando ] = useState(false);
    const [ tcan, setTcan ] = useState({tcaestado:true});
     
    const agregarTipoCarga = async () => {
        setEnviando(true);
        await dispatch(AgregarTipoCargaAdministradorCargaArchivosReducer(tcan));
		props.limpiarFiltro();
        setEnviando(false);
    }

	const cancelar = () => {
		dispatch(MostrarModalAgregarTipoCargaCargaArchivos(false))
	}

    return (
        <Modal
            centered={true}
            open={rex_modal_agregar_tcatipo_administrador}
            onCancel = {cancelar}
            width={700}
            alignItems='center'
            className='ant-modal-content'
            getContainer="#root"
			footer={null}
		>
            <Form
                autoComplete='off'
                layout='vertical'
				onFinish={agregarTipoCarga}
			>
                <h2 className='Text-Center-Carga-Archivos'>Agregar Tipo Carga</h2>
                <Row>
					<Col
						xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}} xl={{span:12}}
					>
						<Form.Item
							label='Tipo de carga:'
							className='Input-Usuario'
                            name='tcanombre'
                            rules={[{ required: true, message: 'El nombre es requerido' }]}
						>
							<Input
                                name='tcanombre'
                                onChange={(e) => setTcan({...tcan, tcanombre:e.target.value})}
                                className='Input-Editar'
                                value={tcan.tcanombre}
                                size='large'
                            />
						</Form.Item>

					</Col>
					<Col
						xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}} xl={{span:12}}
					>
						<Form.Item
							label='Categoria:'
							className='Input-Usuario'
                            name='ccanombre'
                            rules={[{ required: true, message: 'La categoría es requerida' }]}
						>
							<Select
								onChange={(valor) => setTcan({...tcan, ccanombre:valor})}
								size='large'
								placeholder='Seleccionar'
								className='Input-Editar'
							>
								{
									rex_categorias_unicos.map((cca) => 
										<Option
										key={cca.ccanombre}
										value={cca.ccanombre}
									>
											{cca.ccanombre}
										</Option>
									)
								}
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col
						xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}}
					>
						<Form.Item
							label='Canal:'
							className='Input-Usuario'
							rules={[{ required: true, message: 'El canal es requerido' }]}
							name='canid'
						>
							<Select
								onChange={(valor) => setTcan({...tcan, canid:valor})}
								size='large'
								placeholder='Seleccionar'
							>
								{rex_canales_administrador.map((can)=> (
									<Option
										key={can.canid}
										value={can.canid}
									>
										{can.cannombre}
									</Option>
								))}
							</Select>

						</Form.Item>
					</Col>
					<Col
						xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}}
					>
						<Form.Item
							label='Empresa:'
							className='Input-Usuario'
							rules={[{ required: true, message: 'La empresa es requerida' }]}
							name='cueid'
						>
							<Select
								onChange={(valor) => setTcan({...tcan, cueid:valor})}
								size='large'
								placeholder='Seleccionar'
							>
								{rex_cuentas.map((cue)=> (
									<Option
										key={cue.cueid}
										value={cue.cueid}
									>
										{cue.cuenombre}
									</Option>
								))}
							</Select>

						</Form.Item>
					</Col>
					<Col
						xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}}
					>
						<Form.Item
							label='Pais:'
							className='Input-Usuario'
							rules={[{ required: true, message: 'El pais es requerido' }]}
							name='paiid'
						>
							<Select
								onChange={(valor) => setTcan({...tcan, paiid:valor})}
								size='large'
								placeholder='Seleccionar'
							>
								{rex_paises.map((pai)=> (
									<Option
										key={pai.paiid}
										value={pai.paiid}
									>
										{pai.painombre}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					<Col
						xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}}
					>
						<Form.Item
							label='Estado:'
							className='Input-Usuario'
						>
							<Switch
								defaultChecked
								className={tcan.tcaestado ? 'Circulo-Activo': 'Circulo-Desactivo'}
								onChange={(valor) => setTcan({...tcan, tcaestado:valor})}
							/>
						</Form.Item>
					</Col>
				</Row>
                <FooterModalCargaArchivos cancelar={cancelar} enviando={enviando} nombre='Tipo de carga'/>
            </Form>
        </Modal>
    )
}

export default AgregarTipoCarga