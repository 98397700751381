import { Modal, Table, Button } from 'antd'
import React, {useEffect} from 'react'
import {useSelector, useDispatch} from "react-redux";
import {
    SeleccionarFilaDataStatusReducer,
} from '../../Redux/Actions/Status/TablaStatus'
import {
    ObtenerDetalleStatusReducer,
    SeleccionarFilaDetalleStatusReducer,
    EditarStatusArchivoSubidoReducer,
    EliminarDetalleStatusSubidoReducer
} from '../../Redux/Actions/Status/TablaDetalleStatus'

const ModalDetalle = (props) => {

    const mostrarModal = props.mostrarModal
    const fila_seleccionada = props.fila_seleccionada

    const dispatch = useDispatch()

    const { 
        rex_columnas_tabla_detalle_status,
        rex_data_tabla_detalle_status,
        rex_mostrar_modal_confirmacion_staus_detalle_status,
        rex_editar_o_eliminar_status_detalle_status,
        rex_cargando_modal_confirmacion_staus_detalle_status
    } = useSelector(({tablaDetalleStatus}) => tablaDetalleStatus);

    useEffect(() => {
        if(fila_seleccionada != null){
            dispatch(ObtenerDetalleStatusReducer())
        }
        
    },[fila_seleccionada])
    
    return (
        <div>
            <Modal
                visible={mostrarModal}
                footer={null}
                title={null}
                closeIcon={<div></div>}
                centered={true}
                width={"95%"} 
                onCancel={() => {
                    dispatch(SeleccionarFilaDataStatusReducer(null))
                }}
            >
                <div>
                    <div 
                        className='W600-S15-H20-C787878' 
                        style={{      
                            textAlign: "center",
                            marginBottom:'20px'
                        }}
                    >
                        
                        {
                            fila_seleccionada
                            ?fila_seleccionada.agregarnuevasfilas ? 'Historial de Holding' :'Historial de ' + fila_seleccionada.stcnombre
                            :null
                        }
                    </div>
                    <div
                        className='Contenedor-Tabla-Carga-Archivos'
                    >
                        <Table
                            columns={[...rex_columnas_tabla_detalle_status]}
                            dataSource={[...rex_data_tabla_detalle_status]}
                            rowClassName={() => 'editable-row Fila-Detalle-Status'}
                            pagination={{ pageSize: 10 }}
                            scroll={{ y: 505 }}
                        />
                    </div>
                </div>

                <Modal
                    visible={rex_mostrar_modal_confirmacion_staus_detalle_status}
                    centered
                    title={null}
                    footer={null}
                    className="Modal-Confirmacion-Carga-Archivo"
                    width={"310px"}
                    closeIcon={<div></div>}
                >
                    <div
                        style={{
                            textAlign: "-webkit-center",
                            borderRadius: "8px"
                        }}
                    >
                        <div 
                            className='W600-S12-H16-C1E1E1E' style={{marginBottom:'10px'}}
                        >
                            {
                                rex_editar_o_eliminar_status_detalle_status == "editar"
                                ?<>¿Está seguro que desea confirmar?</>
                                :<>¿Está seguro que desea eliminar?</>
                            }
                        </div>
                        <div className='W400-S12-H16-C1E1E1E'>
                            {
                                rex_editar_o_eliminar_status_detalle_status == "editar"
                                ?<>Recuerde que una vez confirmado se enviara un correo inmediato</>
                                :<>Recuerde que una vez eliminado no podrá recuperar el archivo</>
                            }
                        </div>

                        <div
                            style={{
                                display:'flex',
                                justifyContent: "center",
                                marginTop:'20px'
                            }}
                        >
                            <Button
                                type='primary'
                                style={{
                                    marginRight:'22px'
                                }}
                                className="W600-S12-H16-CFFFFFF-l0015"
                                onClick={async () => {
                                    if(rex_editar_o_eliminar_status_detalle_status == "editar"){
                                        dispatch(EditarStatusArchivoSubidoReducer())
                                    }else{
                                        dispatch(EliminarDetalleStatusSubidoReducer())
                                    }
                                }}
                                loading={rex_cargando_modal_confirmacion_staus_detalle_status}
                            >
                                Confirmar
                            </Button>
                            <Button
                                type='secondary'
                                className='Btn-Desactivado-Carga-Archivo W600-S12-H16-CFFFFFF-l0015'
                                onClick={() => {
                                    dispatch(SeleccionarFilaDetalleStatusReducer(null, null))
                                }}
                            >
                                Cancelar
                            </Button>
                        </div>
                    </div>
                </Modal>
            </Modal>            
        </div>
    )
}

export default ModalDetalle