import { Modal, Form, Row, Col, Upload, AutoComplete } from 'antd';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { 
    AgregarPaisAdministradorCuentaReducer,
    MostrarModalAgregarPaisReducer
} from '../../../Redux/Actions/Administrador/Cuentas/Cuentas';
import { alertaNotificacion } from '../../../Funciones/Notificaciones/Notificaciones';
import '../../../Estilos/Rutas/Administrador/Cuentas.css'
import config from '../../../config'
import { 
    PlusOutlined
} from '@ant-design/icons';


const AgregarPais = () => {

    const dispatch = useDispatch();

    const {
        rex_modal_agregar_pais,
        rex_paises
    } = useSelector(({administradorCuentas}) => administradorCuentas);

    const [ paisn, setPaisn ] = useState({paisnombre:'',paisimagen:null});
    const [ enviando, setEnviando ] = useState(false);
    const [ value, setValue ] = useState('');
    const [ options, setOptions ] = useState();
    const [ abrirModal, setAbrirModal ] = useState(false);
    const [ urlImagenPrevia, setUrlImagenPrevia ] = useState('')

    const onSearch = (txtInput) => {

        let paisesTotales = [...rex_paises];
        let paisesFiltrados = paisesTotales.filter((pai) => pai.value.toUpperCase().startsWith(txtInput.toUpperCase()));
        setOptions(
            !txtInput ? rex_paises : paisesFiltrados
        )
    };

    const agregarPais = async (paisn) => {
    
        if(rex_paises.find((pai) => pai.painombre.toUpperCase() == value.toUpperCase()) && paisn.paisimagen == null){
            alertaNotificacion('Sin cambios por hacer')
        }
        else{
            setEnviando(true)
            await dispatch(AgregarPaisAdministradorCuentaReducer(paisn));
            setEnviando(false)
        }
    }

    const botonCargarImagen = (accion) => (
        <div>
            <PlusOutlined />
            <div
                className='Boton-Accion-Imagen'
            >
            <div>{accion}</div>
            <div>{'Imagen'}</div>
          </div>
        </div>
    );

    return (
        <Modal
            centered={true}
            open={rex_modal_agregar_pais}
            onCancel = {() => dispatch(MostrarModalAgregarPaisReducer(false))}
            onOk = {() => agregarPais(paisn)}
            confirmLoading={enviando}
            okText='Guardar'
            width={650}
            alignItems='center'
            className='ant-modal-content'
            getContainer="#root"
        >
            
            <Form
                layout='vertical'
                autoComplete='off'
            >
                <h2 className='Text-Center-Agregar-Pais'>Agregar Pais</h2>
                <Row>
                    <Col
                        xs={{span:24}} sm={{span:12}} md={{span:12}} lg={{span:12}} xl={{span:12}}
                    >
                        <Form.Item
                            label='Nombre:'
                            name='paisnombre'
                            rules={[
                                { required: true, message: 'El país es requerido' },
                                { min: 3, message: 'El país es muy corto' }
                            ]}
                            className='Input-Usuario'
                        >
                            <AutoComplete
                                value={value}
                                options={options}
                                className='Input-Editar'
                                onSearch={onSearch}
                                onChange={(valor) => {
                                    setValue(valor);
                                    setPaisn({...paisn, paisnombre:valor})
                                    rex_paises.map((pai) => {
                                        if(pai.painombre.toUpperCase() === valor.toUpperCase()){
                                            setUrlImagenPrevia(pai.paiicono);
                                        }
                                    })
                                }}
                                placeholder="Nombre país"
                            />
                        </Form.Item>
                        
                    </Col>
                    <Col
                        xs={{span:24}} sm={{span:12}} md={{span:12}} lg={{span:12}} xl={{span:12}}
                    >
                        <Form.Item
                            label='Imagen:'
                            className='Input-Usuario'
                        >
                            <Row>
                                <Col span={12}>
                                    <Upload
                                        maxCount={1}
                                        listType='picture-card'
                                        action={config.api}
                                        onPreview={()=> setAbrirModal(true)}
                                        onRemove={() => setPaisn({...paisn, url:null, paisimagen: null})}
                                        beforeUpload ={ (file) => {
                                            let reader = new FileReader();
                                            reader.readAsDataURL(file);
                                            const imagenValida = file.type === 'image/png' || file.type === 'image/jpeg';
                                            if(!imagenValida) {
                                                alertaNotificacion('Solo formato PNG o JPG')
                                                return Upload.LIST_IGNORE;
                                            }else{
                                                reader.onload = function(){
                                                    let base64 = reader.result;
                                                    setPaisn({
                                                        url:base64,
                                                        paisnombre:value,
                                                        paisimagen: base64
                                                    })
                                                }
                                                return false;
                                            }
                                        }}
                                    >
                                    {
                                        paisn.paisimagen != null
                                        ? null
                                        : rex_paises.find((pai) => pai.painombre.toUpperCase() == value.toUpperCase())
                                            ? paisn.paisimagen != null ? null: botonCargarImagen('Cambiar')
                                            : paisn.paisimagen == null && botonCargarImagen('Agregar') 

                                    }
                                    </Upload>
                                </Col>
                                {
                                    rex_paises.find((pai) => pai.painombre.toUpperCase() == value.toUpperCase())
                                    && <Col span={12}>
                                        <div className='Contenedor-Imagen-Actual'>

                                            <span style={{textAlign:'center'}}>Imagen actual:</span>
                                            <div className='Contenedor-Imagen-Icono-Actual Contenedor-Center'>
                                                <img style={{width:'60px', height:'60px', textAlign:'center'}} src={urlImagenPrevia}/>
                                            </div>
                                        </div>
                                    </Col>
                                }
                            </Row>
                            
                            <Modal open={abrirModal} footer={null} onCancel={() => setAbrirModal(false)}>
                                <div className='Contenedor-Center'>
                                    <img
                                        className='Contenedor-Center Contenedor-Modal-Imagen'
                                        alt="example"
                                        src={paisn.paisimagen != null && paisn.paisimagen}
                                    />
                                </div>
                            </Modal>

                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default AgregarPais