import React from 'react'
import { Button, Spin, Row } from "antd";
import { antIconWhite } from "./antd";

const FooterModalCargaArchivos = (props) => {

    const styleFila = {
        display:'flex',
        justifyContent:'flex-end',
        marginTop:'20px'
    }

    const styleBotonGuardar = {
        width: '150px',
        background: '#1890ff',
        color: 'white'
    }

    return (
        <Row style={styleFila}>
            <Button
                style={{marginRight:'10px'}}
                onClick={props.cancelar}
            >Cancelar</Button>
            <Button htmlType="submit" style={styleBotonGuardar}>{props.enviando ? <Spin indicator={antIconWhite}/> : `Guardar`}</Button>
        </Row>
    )
}

export default FooterModalCargaArchivos