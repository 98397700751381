import { Table, Avatar, Button, Tooltip, Row, Col, Popconfirm, Spin, Modal, Pagination, Select, Input } from 'antd';
import React, { useEffect, useState }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { 
    ObtenerCuentasPaisesAdministradorCuentasReducer,
    EliminarCuentasPaisesAdministradorCuentasReducer,
    ModalEditarCuentaAdministradorCuentaReducer,
    MostrarModalAgregarCuentaReducer,
    MostrarModalAgregarPaisReducer,
    EliminarCuentaPaisAdministradorCuentasReducer,
    FiltrarPaisCuentaAdministradorCuentaReducer,
    ObtenerPaisesAdministradorCuentasReducer
} from '../../../Redux/Actions/Administrador/Cuentas/Cuentas';
import { 
    PlusCircleOutlined, 
    EditOutlined, 
    DeleteOutlined,
    ExclamationCircleOutlined,
    MinusCircleOutlined,
    SearchOutlined
} from '@ant-design/icons';
import '../../../Estilos/Rutas/Administrador/Cuentas.css';
import { antIcon } from '../../../Funciones/componentes/antd';
import EditarCuenta from '../../../Componentes/Administrador/Cuentas/EditarCuenta';
import AgregarCuenta from '../../../Componentes/Administrador/Cuentas/AgregarCuenta';
import AgregarPais from '../../../Componentes/Administrador/Cuentas/AgregarPais';
import Moment from 'moment';

const CuentasPaises = () => {
    
    const [cargando, setCargando] = useState(false);
    const [pageTableCuentasPaises, setPageTableCuentasPaises] = useState(1);
    const [pageSizeTableCuentasPaises, setPageSizeTableCuentasPaises] = useState(10);
    const [ selectPaiid, setSelectPaiid ] = useState(0);
    const [ txtBuscar, setTxtBuscar ] = useState('');
    const [ eliminando, setEliminando ] = useState(false);
    const [ cuee, setCuee ] = useState('');
    
    const dispatch = useDispatch();
    const { confirm } = Modal;
    const { Option } = Select;
    
    const { 
        rex_filtro_paises_cuentas,
        rex_modal_editar_cuenta,
        rex_modal_agregar_cuenta,
        rex_modal_agregar_pais,
        rex_paises
    } = useSelector(({administradorCuentas}) => administradorCuentas);
    
    useEffect(() => {
        obtenerCuentasPaises();
        dispatch(ObtenerPaisesAdministradorCuentasReducer())
    }, [])

    useEffect(() => {

    }, [rex_paises])

    const obtenerCuentasPaises = async () => {
        setCargando(true);
        await dispatch(ObtenerCuentasPaisesAdministradorCuentasReducer());
        setCargando(false);
    }

    const buscarPaisCuenta = (txtBuscar, paiid) => {
        dispatch(FiltrarPaisCuentaAdministradorCuentaReducer(txtBuscar, paiid))
    }

    const editarCuentaPais = (record) => {

        let paisescue = [];
        let cuerecord  = rex_filtro_paises_cuentas.filter((cuepa) => cuepa.cueid == record.cueid);
        let paises = cuerecord[0].paises;
        
        for(let i = 0;  i<paises.length; i++ ){
            paisescue.push({label: paises[i].painombre, value: paises[i].paiid});
        }
        dispatch(ModalEditarCuentaAdministradorCuentaReducer(true, record, paisescue))
    }

    const eliminarCuentaPaisPaises = async ({ cueid }) => {

        setEliminando(true);
        setCuee(cueid);
        await dispatch(EliminarCuentasPaisesAdministradorCuentasReducer(cueid));
        setEliminando(false);
    }

    const eliminarCuentaPais = async ( {cueid, cuenombre} ) => {

        confirm({
            title: `¿Eliminar cuenta: ${cuenombre}?`,
            icon: <ExclamationCircleOutlined />,
            okText: 'Eliminar',
            content: <div><b>Se eliminará toda la información de la cuenta incluyendo:</b><br/><br/>
                - Registro con algún usuario<br/>
                - Registro con algún país<br/>
                - Registro en carga de archivos<br/>
                - Registro en distribuidoras<br/>
                - Registro en control archivos<br/><br/>

                <p><b>En carga de archivos:</b></p>
                - Registro con algún canal<br/>
                - Registro con alguna categoria<br/>
                - Registro con algún tipo de archivo<br/>
                - Registro con algún archivo<br/>
            </div>,
        
            async onOk() {
                return await dispatch(EliminarCuentaPaisAdministradorCuentasReducer(cueid));
            },
        
            onCancel() {},
          });

    }
    const columns = [
        {
            title: 'Estado',
            key: 'cueestado',
            width: '50px',
            align: 'center',
            render: (_, record) => (
                <div className='Contenedor-Center'>
                    <Tooltip placement="top" title={record.cueestado ? 'Activado':'Desactivado'}>
                        <div className={`Circulo ${record.cueestado? 'Circulo-Activo': 'Circulo-Desactivo'}`}>
                        </div>
                    </Tooltip>
                </div>
            )
        },
        {
            title: 'Logo',
            key: 'correo',
            width: '40px',
            align: 'center',
            render: (_, record) => (

                <img className='Cuenta-Imagen'
                    src={record.cueimagen 
                    ? record.cueimagen 
                    :'https://cdn-icons-png.flaticon.com/512/126/126486.png'} />

            ),
        },
        {
            title: 'Empresa',
            dataIndex: 'cuenombre',
            key: 'cuenombre',
            width: '150px',
            align: 'center'
        },
        {
            title: 'Paises',
            key: 'cuenombre',
            width: '100px',
            align: 'center',
            render: (_, record) => (
                <div className='Contenedor-Center'>
                    <Avatar.Group
                    maxCount={2}
                    size="large"
                    >
                        {record.paises.map((pai) => (
                            <Tooltip key={pai.paiid} placement="top" title={pai.painombre}>
                                <Avatar src={pai.paiicono}/>
                            </Tooltip>
                        ))}
                    </Avatar.Group>
                </div>
            ),
        },
        {
            title: 'Fecha de creacion',
            key: 'created_at',
            width: '160px',
            align: 'center',
            render: (_, record) => (
                <>{record.created_at?Moment(record.created_at).format('LLL'):'Sin registro' }</>
            ),
        },
        {
            title: 'Fecha de actualización',
            key: 'updated_at',
            width: '160px',
            align: 'center',
            render: (_, record) => (
                <>{record.updated_at?Moment(record.updated_at).format('LLL'):'Sin registro' }</>
            ),
        },
        {
            title: 'Acciones',
            key: 'acciones',
            width: '100px',
            align: 'center',
            render:(_,record) => {
                return <div className='Contenedor-Center'>
                        <Tooltip placement="top" title='Editar'>
                            <Button
                                onClick={() => editarCuentaPais(record)}
                                className='Boton-Accion-Editar'
                            >
                                <EditOutlined
                                    className='Icono-Accion-Editar'
                                />
                            </Button>
                        </Tooltip>
                        {record.paises.length> 0 &&
                            (eliminando && cuee == record.cueid
                                ? <Spin indicator={antIcon}/>
                                : <Tooltip placement="top" title='Eliminar países'>
                                    <Button
                                        className='Boton-Accion'
                                    >
                                        <Popconfirm 
                                            title="Eliminar los países de la cuenta?" 
                                            onConfirm={()=> {
                                                eliminarCuentaPaisPaises(record)
                                            }}
                                        >
                                            <MinusCircleOutlined 
                                                className='Icono-Accion'
                                            />
                                        </Popconfirm>
                                    </Button>
                                  </Tooltip>

                            )
                        }
                        <Button
                            onClick={() => eliminarCuentaPais(record)}
                            className='Boton-Accion-Eliminar'
                        >
                            <Tooltip placement="top" title='Eliminar cuenta'>
                                <DeleteOutlined
                                    className='Icono-Accion-Eliminar'
                                    />
                            </Tooltip>
                        </Button>
                    </div>
            }
        },
    ]
    
    return (
        <div className='Contenedor-Principal-Cuentas'
        >
            <Row>
                <Col span={24}>
                    <h2 className='Text-Center'>Cuentas</h2>
                </Col>
            </Row>
            <Row style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>

                <Col xl={8} lg={12} md={24} sm={24} xs={24}>
                    <Input
                        suffix={<SearchOutlined style={{margin:'0 5px'}} />}
                        size='large'
                        style={{borderRadius:'5px', height:'45px'}}
                        placeholder='Buscar'
                        value={txtBuscar}
                        onChange={(e) => {
                            setTxtBuscar(e.target.value)
                            buscarPaisCuenta(e.target.value, selectPaiid)
                        }}
                    />
                </Col>

                <Col>
                    <Button className='Boton-Agregar' onClick={() => dispatch(MostrarModalAgregarCuentaReducer(true))} type='primary'>
                        Cuenta<PlusCircleOutlined />
                    </Button>
                            
                    <Button className='Boton-Agregar' onClick={() => dispatch(MostrarModalAgregarPaisReducer(true))} type='primary'>
                        <span>Pais</span><PlusCircleOutlined />
                    </Button>
                </Col>
            </Row>
            <Row style={{display:'flex', justifyContent:'space-between', margin:'15px 0'}}>
                <Col>
                    <Select
                        value={selectPaiid}
                        style={{width:'150px'}}
                        onChange={(valor) => {
                            setSelectPaiid(valor)
                            buscarPaisCuenta(txtBuscar, valor)
                        }}
                    >
                        <Option value={0}>Seleccionar Pais</Option>
                        {rex_paises.map((paic) => 
                            <Option key={paic.paiid} value={paic.paiid}>{paic.painombre}</Option>
                        )}
                    </Select>
                </Col>
                <Col>
                    <Pagination
                        defaultCurrent={pageTableCuentasPaises}
                        current={pageTableCuentasPaises}
                        total={rex_filtro_paises_cuentas.length}
                        onChange={(page, pageSize) => {
                            setPageTableCuentasPaises(page == 0? 1 : page)
                            setPageSizeTableCuentasPaises(pageSize)
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table
                        pagination={{
                            defaultCurrent: pageTableCuentasPaises,
                            current: pageTableCuentasPaises,
                            pageSize: pageSizeTableCuentasPaises,
                            position: ['topRight'],
                        }}
                        size= 'small'
                        loading={cargando}
                        columns={columns}
                        dataSource={rex_filtro_paises_cuentas}
                    >
                    </Table>

                </Col>
            </Row>

            { rex_modal_editar_cuenta && <EditarCuenta/> }
            { rex_modal_agregar_cuenta && <AgregarCuenta/> }
            { rex_modal_agregar_pais && <AgregarPais/> }
        </div>


    )
}

export default CuentasPaises