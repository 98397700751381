import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import EstadoRequest from './Comunes/EstadoRequest'
import Login from './Login/Login'
import Comunes from './Comunes/Comunes'
import SeleccionarNegocio from './Componentes/SeleccionarNegocio'
import CargaArchivos from './CargaArchivos/CargaArchivos'
import TablaCargaArchivos from './CargaArchivos/TablaCargaArchivos'
import ModalDtsCargaArchivos from './CargaArchivos/ModalDtsCargaArchivos'
import Complementos from './Complementos/Complementos'
import Status from './Status/Status'
import TablaStatus from './Status/TablaStatus'
import TablaDetalleStatus from './Status/TablaDetalleStatus'
import AdministradorUsuarios from './Administrador/Usuarios/Usuarios'
import AdministradorCuentas from './Administrador/Cuentas/Cuentas'
import AdministradorCargaArchivos from './Administrador/CargaArchivos/CargaArchivos'
import AdministradorTipoUsuarios from './Administrador/TipoUsuarios/TipoUsuarios'
import AdministradorDistribuidoras from './Administrador/DtsDistribuidoras/DtsDistribuidoras'
import AdministradorPermisos from './Administrador/Permisos/Permisos'


const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    estadoRequest : EstadoRequest,
    login : Login,
    comunes : Comunes,
    seleccionarNegocio : SeleccionarNegocio,
    cargaArchivos : CargaArchivos,
    tablaCargaArchivos : TablaCargaArchivos,
    modalDtsCargaArchivos : ModalDtsCargaArchivos,
    complementos : Complementos,
    status : Status,
    tablaStatus : TablaStatus,
    tablaDetalleStatus : TablaDetalleStatus,
    administradorUsuarios : AdministradorUsuarios,
    administradorCuentas  : AdministradorCuentas,
    administradorCargaArchivos  : AdministradorCargaArchivos,
    administradorDistribuidoras  : AdministradorDistribuidoras,
    administradorPermisos  : AdministradorPermisos,
    administradorTipoUsuarios  : AdministradorTipoUsuarios
});

export default createRootReducer
