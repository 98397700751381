import React, { useState } from 'react'
import { Modal, Form, Row, Col, Select, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { 
	AgregarCanalCuentaAdministradorCargaArchivosReducer,
    MostrarModalAgregarCanalCuentaCargaArchivos,
} from '../../../Redux/Actions/Administrador/CargaArchivos/CargaArchivos';
import '../../../Estilos/Rutas/Administrador/CargaArchivos.css'
import FooterModalCargaArchivos from '../../../Funciones/componentes/FooterModalCargaArchivos';

const AgregarCanalCuenta = (props) => {

    const dispatch = useDispatch();
    const { Option } = Select;

    const {
		rex_modal_agregar_cancue_administrador,
        rex_canales_administrador,
    } = useSelector(({administradorCargaArchivos}) => administradorCargaArchivos);

    const {
        rex_cuentas
    } = useSelector(({administradorCuentas}) => administradorCuentas);

    const [ enviando, setEnviando ] = useState(false);
    const [ cancuen, setCancuen ] = useState({cacestado:true});

    const agregarCanalCuenta = async () => {

        setEnviando(true);
        await dispatch(AgregarCanalCuentaAdministradorCargaArchivosReducer(cancuen));
        props.limpiarFiltro();
        setEnviando(false);
    }

    const cancelar = () => {
        dispatch(MostrarModalAgregarCanalCuentaCargaArchivos(false))
    }

    return (
        <Modal
            centered={true}
            open={rex_modal_agregar_cancue_administrador}
            width={700}
            alignItems='center'
            className='ant-modal-content'
            getContainer="#root"
            footer={null}
            onCancel={cancelar}
        >
            <Form
                autoComplete="off"
                layout="vertical"
                onFinish={agregarCanalCuenta}
            >
                <h2 className='Text-Center-Carga-Archivos'>Agregar Canal Cuenta</h2>

                <Row>
                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}} xl={{span:12}}
                    >
                        <Form.Item
                            label='Empresa:'
                            className='Input-Usuario'
                            rules={[
                                { required: true, message: 'La empresa es requerida' },
                            ]}
                            name='cueid'
                        >
                            <Select
                                onChange={(valor) => setCancuen({...cancuen, cueid:valor})}
                                size='large'
                                placeholder='Seleccionar'
                            >
                                {rex_cuentas.map((cue)=> (
                                    <Option
                                        key={cue.cueid}
                                        value={cue.cueid}
                                    >
                                        {cue.cuenombre}
                                    </Option>
                                ))}
                            </Select>

                        </Form.Item>
                    </Col>
                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}} xl={{span:12}}
                    >
                        <Form.Item
                            label='Canal:'
                            className='Input-Usuario'
                            rules={[
                                { required: true, message: 'El canal es requerido' },
                            ]}
                            name='canid'
                        >
                            <Select
                                onChange={(valor) => setCancuen({...cancuen, canid:valor})}
                                size='large'
                                placeholder='Seleccionar'
                            >
                                {rex_canales_administrador.map((can)=> (
                                    <Option
                                        key={can.canid}
                                        value={can.canid}
                                    >
                                        {can.cannombre}
                                    </Option>
                                ))}
                            </Select>

                        </Form.Item>
                    </Col>
                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}} xl={{span:12}}
                    >
                        <Form.Item
                            label='Estado:'
                            className='Input-Usuario'
                        >
                            <Switch
                                defaultChecked
                                className={cancuen.cacestado ? 'Circulo-Activo': 'Circulo-Desactivo'}
                                onChange={(valor) => setCancuen({...cancuen, cacestado:valor})}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <FooterModalCargaArchivos cancelar={cancelar} enviando={enviando} nombre='Cuenta'/>
            </Form>
        </Modal>
    )
}

export default AgregarCanalCuenta