import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Switch, Select, Tag, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import '../../../Estilos/Rutas/Usuarios/Usuarios.css';
import { 
    EditarUsuarioAdministradorUsuarioReducer,
    ModalEditarUsuariosAdministradorReducer
} from '../../../Redux/Actions/Administrador/Usuarios/Usuarios';

const EditaUsuarios = () => {

    const { Option, OptGroup } = Select;

    const { 
        rex_editar_usuario,
        rex_modal_editar_usuario,
    } = useSelector(({administradorUsuarios}) => administradorUsuarios);

    const { 
        rex_tipos_usuarios
    } = useSelector(({administradorTipoUsuarios}) => administradorTipoUsuarios);

    const { 
        rex_paises,
        rex_pacids
    } = useSelector(({administradorCuentas}) => administradorCuentas);

    const dispatch = useDispatch();

    const [enviando, setEnviando] = useState(false);
    const [usue, setUsue] = useState({...rex_editar_usuario});

    useEffect(()=>{

    },[usue]);

    const editarUsuario = async () => {
        setEnviando(true);
        let statusEnviando = await dispatch(EditarUsuarioAdministradorUsuarioReducer(usue));
        setEnviando(statusEnviando);
    }

    const cambiaEstado = (valor) => {
        valor ? setUsue({...usue, estid:1}) : setUsue({...usue, estid:2})
    }

    const agregaPermiso = (valor) => {
        
        const usuest = {...usue};

        const arrayNuevo = rex_pacids.find((cue) => cue.pacid === valor);

        const esta = usue.paisescuentas.find((cue) => cue.pacid === valor);

        if(!esta){
            usuest.paisescuentas.push(arrayNuevo);
            setUsue(usuest);
        }
    }

    const eliminaTag = (pai) => {
        const { pacid } = pai;
        setUsue({
            ...usue, paisescuentas:usue.paisescuentas.filter(cue => cue.pacid !== pacid) 
        });
    }

    return (
        <Modal
            centered={true}
            visible={rex_modal_editar_usuario}
            onCancel = {() => dispatch(ModalEditarUsuariosAdministradorReducer({}, false))}
            onOk = {editarUsuario}
            confirmLoading={enviando}
            okText='Guardar'
            width={700}
            alignItems='center'
            className='ant-modal-content'
            getContainer="#root"
        >
            <Form
                autoComplete="off"
                initialValues={{ switchEst: true }}
                layout="vertical"
            >
                <h2 className='Text-Center-Editar-Usuario'>
                    Editar usuario
                </h2>
                <Row>

                    <Col
                        xs={{span:24}} sm={{span:12}} md={{span:12}} lg={{span:12}} xl={{span:12}}
                    >
                        <Form.Item
                            label='Usuario:'
                            rules={[{ required: true, message: 'El usuario es requerido' }]}
                            className='Input-Usuario'
                        >
                            <Input
                                name='usuusuario'
                                onChange={(e) => 
                                    setUsue({
                                        ...usue,[e.target.name]:e.target.value
                                    })
                                }
                                className='Input-Editar'
                                value={usue.usuusuario}
                                size='large'
                            />

                        </Form.Item>
                    </Col>
                    <Col
                        xs={{span:24}} sm={{span:12}} md={{span:12}} lg={{span:12}} xl={{span:12}}
                    >
                        <Form.Item
                            label='Contraseña:'
                            rules={[{ required: true, message: 'La contraseña es requerida' }]}
                            className='Input-Editar-Password'
                        >
                            <Input.Password
                                name='usucontrasenia'
                                type='password'
                                onChange={(e) => 
                                    setUsue({
                                        ...usue,[e.target.name]:e.target.value
                                    })
                                }
                                size='large'
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>

                    <Col
                        xs={{span:24}} sm={{span:8}} md={{span:8}} lg={{span:8}} xl={{span:8}}
                    >
                        <Form.Item
                            label='Nombres:'
                            rules={[{ required: true, message: 'El nombre es requerido' }]}
                            className='Input-Usuario'
                        >
                            <Input
                                name='pernombre'
                                onChange={(e) => 
                                    setUsue({
                                        ...usue,[e.target.name]:e.target.value
                                    })
                                }
                                className='Input-Editar'
                                value={usue.pernombre}
                                size='large'
                            />

                            </Form.Item>
                    </Col>

                    <Col
                        xs={{span:24}} sm={{span:8}} md={{span:8}} lg={{span:8}} xl={{span:8}}
                    >

                        <Form.Item
                            label='Apellido paterno:'
                            rules={[{ required: true, message: 'El apellido paterno es requerido' }]}
                            className='Input-Usuario'
                        >
                        <Input
                            name='perapellpaterno'
                            onChange={(e) => 
                                setUsue({
                                    ...usue,[e.target.name]:e.target.value
                                })
                            }
                            className='Input-Editar'
                            value={usue.perapellpaterno}
                            size='large'
                        />

                            </Form.Item>
                    </Col>

                    <Col
                        xs={{span:24}} sm={{span:8}} md={{span:8}} lg={{span:8}} xl={{span:8}}
                    >

                        <Form.Item
                            label='Apellido materno:'
                            rules={[{ required: true, message: 'El apellido materno es requerido' }]}
                            className='Input-Usuario'
                        >
                            <Input
                                name='perapellmaterno'
                                onChange={(e) => 
                                    setUsue({
                                        ...usue,[e.target.name]:e.target.value
                                    })
                                }
                                className='Input-Editar'
                                value={usue.perapellmaterno}
                                size='large'
                            />
                        </Form.Item>
                    </Col>
                </Row>
                
                <Row
                    style={{display:'flex'}}
                >
                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}} xl={{span:12}}
                        >
                        <Form.Item
                            label='Cuentas:'
                            className='Input-Usuario'
                        >
                            <Select
                                defaultValue={rex_pacids[0].pacid}
                                style={{

                                }}
                                onChange={agregaPermiso}
                            >
                                {rex_paises && 
                                    rex_paises.map((pai) => (
                                        
                                        <OptGroup label={pai.painombre}>

                                        {   rex_pacids.filter((cue) => cue.painombre === pai.painombre)
                                                .map((cue) => {
                                                    let estapais = usue.paisescuentas.find((cuep) => cuep.pacid === cue.pacid);
                                                    if(estapais){
                                                        return <Option key={cue.pacid} disabled value={cue.pacid}>{cue.cuenombre}</Option>
                                                    }else{
                                                        return <Option key={cue.pacid} value={cue.pacid}>{cue.cuenombre}</Option>
                                                    }
                                                })}

                                        </OptGroup>

                                        
                                    ))
                                }
                            </Select>

                        </Form.Item>

                        <Form.Item
                            className='Input-Usuario'
                            label='Tipo de usuario:'
                        >
                            <Select
                                defaultValue={usue.tpuid}
                                style={{
                                }}
                                onChange={(valor)=> setUsue({...usue, tpuid:valor})}
                            >
                                {
                                    rex_tipos_usuarios && rex_tipos_usuarios.map((perm) => (
                                        <Option key={perm.tpuid} value={perm.tpuid}>{perm.tpunombre}</Option>
                                    )
                                    )
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            className='Input-Usuario'
                            label='Estado:'
                        >
                            <Switch
                                className={usue.estid == 1 ? 'Circulo-Activo': 'Circulo-Desactivo'}
                                defaultChecked = {usue.estid === 1? true:false}
                                onChange={cambiaEstado}
                                size='large'
                            />
                        </Form.Item>

                    </Col>

                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}} xl={{span:12}} style={{display:'flex', flexDirection:'column',justifyContent:'flex-end', textAlign:'right'}}
                    >
                        <Form.Item
                            label='Permiso a cuentas:'
                        >
                            <div style={{display:'flex',flexDirection:'column',height:'150px',overflowY:'scroll'}}>
                                {
                                    usue.paisescuentas&&
                                    usue.paisescuentas.map(pai => (
                                        <Tag
                                            key={pai.pacid}
                                            className='Tag-Cuenta-Usuario-Registro'
                                            closable
                                            onClose={()=> eliminaTag(pai)}
                                            style={{borderRadius:'5px'}}
                                            color='blue'
                                            >
                                            {pai.cuenombre} {' - '} { pai.painombre}
                                        </Tag>
                                    ))
                                }
                            </div>
                        </Form.Item>
                        
                    </Col>

                </Row>
            </Form>
        </Modal>
    )
}

export default EditaUsuarios