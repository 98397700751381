import React, { useState } from 'react';
import { Modal, Row, Col, Form, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { 
    AgregarTipoUsuarioAdministradorUsuarioReducer,
    ModalAgregarTipoUsuarioAdministradorReducer 
} from '../../../Redux/Actions/Administrador/TipoUsuarios/TipoUsuarios';
import '../../../Estilos/Rutas/Usuarios/Usuarios.css';

const AgregarTipoUsuarios = () => {

    const dispatch = useDispatch();
    const [enviando, setEnviando] = useState(false);
    const [tpun, setTpun] = useState({
        tpunombre:'',
        tpuprivilegio:''
    });

    const { 
        rex_modal_agregar_tipo_usuario,
    } = useSelector(({administradorTipoUsuarios}) => administradorTipoUsuarios);

    const agregarTipoUsu = async (tpun) =>{
        setEnviando(true);
        await dispatch(AgregarTipoUsuarioAdministradorUsuarioReducer(tpun));
        setEnviando(false);
    }

    return (
        <Modal
            centered={true}
            visible={rex_modal_agregar_tipo_usuario}
            onCancel = {() => dispatch(ModalAgregarTipoUsuarioAdministradorReducer(false))}
            onOk = {() => agregarTipoUsu(tpun)}
            confirmLoading={enviando}
            okText='Agregar'
            width={700}
            alignItems='center'
            className='ant-modal-content'
        >
            <Form
                autoComplete="off"
                initialValues={{ remember: true }}
            >
                <h2 className='Text-Center-Editar-Usuario'>Agregar tipo de usuario</h2>
                <Row>
                    <Col xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}} xl={{span:12}}>
                        <Form.Item
                            label='Nombre:'
                            name='nombre'
                            className='Input-Usuario'
                            rules={[
                                { required: true, message: 'El nombre es requerido' },
                                { min: 4, message: 'El nombre es muy corto' }
                            ]}
                            >
                            <Input
                            onChange={(e)=> setTpun({
                                ...tpun,[e.target.name]:e.target.value
                            })}
                            name='tpunombre'
                            className='Input-Editar'
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}} xl={{span:12}}>
                        <Form.Item
                            label='Privilegio:'
                            name='privilegio'
                            className='Input-Usuario'
                        >
                            <Input
                                onChange={(e)=> setTpun({
                                    ...tpun,[e.target.name]:e.target.value
                                    })}
                                name='tpuprivilegio'
                                className='Input-Editar'
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

        </Modal>

    )
}

export default AgregarTipoUsuarios