import React, { useEffect, useState } from 'react'
import { Modal, Collapse, Switch, Skeleton } from 'antd'
import { useSelector, useDispatch } from 'react-redux';
import { 
    ModalPermisosEspecialesAdministradorUsuarioReducer,
	ObtenerPermisosEspecialesUsuariosAdministradorUsuariosReducer,
	EditarPermisoEspecialUsuariosAdministradorReducer,
	EditarPermisosEspecialesUsuariosAdministradorReducer,
	GuardarPermisosEspecialesUsuariosAdministradorReducer
} from '../../../Redux/Actions/Administrador/Usuarios/Usuarios';
import '../../../Estilos/Rutas/Usuarios/Usuarios.css';
import {
    CaretRightOutlined 
} from '@ant-design/icons';

const PermisosEspecialesUsuario = ({ usuid }) => {

	const dispatch = useDispatch();

	const { Panel } = Collapse;

    const { 
        rex_modal_permisos_especiales_usuarios,
		rex_permisos_especiales_usuarios
    } = useSelector(({administradorUsuarios}) => administradorUsuarios);

	const [ cargando, setCargando ] = useState(false);
	const [ enviando, setEnviando ] = useState(false);
	const [ listaSkeleton,  ] = useState([1,2,3,4,5,6]);

	const iconoPanel = (indexTpp, checked, permisos) => {

		let tamanioPermisos = permisos.length > 0;

		return <div
					onClick={(event) => {
						event.stopPropagation();
					}}
				>
					<Switch
						checked={checked}
						disabled={!tamanioPermisos}
						className={checked ? 'Circulo-Activo': 'Circulo-Desactivo'}
						onChange={(valor) => {
							dispatch(EditarPermisosEspecialesUsuariosAdministradorReducer(indexTpp, valor))
						}}
					/>
				</div>
	};

	const obtenerPermisosEspecialesUsuario = async () => {
		setCargando(true);
		await dispatch(ObtenerPermisosEspecialesUsuariosAdministradorUsuariosReducer(usuid));
		setCargando(false);
	}

	const guardarPermisosEspeciales = async () => {
		setEnviando(true);
		await dispatch(GuardarPermisosEspecialesUsuariosAdministradorReducer(usuid));
		setEnviando(false);
	}

	useEffect(() => {
		obtenerPermisosEspecialesUsuario();
	}, [])

	return (
		<Modal
			open={rex_modal_permisos_especiales_usuarios}
			closable={false}
			okText='Guardar'
			width={700}
			confirmLoading={enviando}
			onOk={() => guardarPermisosEspeciales()}
			onCancel={() => dispatch(ModalPermisosEspecialesAdministradorUsuarioReducer(false))}
		>
			<div style={{marginBottom:'10px'}}>
				<h2 className='Text-Center' style={{userSelect:'none'}}>Permisos especiales</h2>
			</div>
			{
				cargando
				? <div>
					{listaSkeleton.map((ske, index) => 
						<Skeleton.Input key={index} style={{margin:'5px 0'}} active={true} size={'large'} block={true} />
					)}
				  </div>
				: rex_permisos_especiales_usuarios.map((tippem, indextip) => 
					<Collapse
						key={tippem.tppid}
						className='Contenedor-Permiso-Collapse'
						expandIcon={({ isActive }) => <CaretRightOutlined style={{fontSize:'120%'}} rotate={isActive ? 90 : 0} />}
						collapsible={tippem.permisos.length == 0 && 'disabled'}
					>
						<Panel
							header={tippem.tppnombre}
							key={tippem.tppid}
							extra={iconoPanel(indextip, tippem.checked, tippem.permisos)}
						>
							{
								tippem.permisos.map((pem, indexPem) => 
									<div key={pem.pemid} style={{padding:'10px 0'}}>
										<div style={{display:'flex', justifyContent:'space-between'}}>
											<div>
												<h4 style={{marginBottom:'0'}}>{pem.pempermisos}</h4>
											</div>
											<div>
												<Switch
													checked={pem.checked}
													className={pem.checked ? 'Circulo-Activo': 'Circulo-Desactivo'}
													onChange={(valor) => 
														dispatch(EditarPermisoEspecialUsuariosAdministradorReducer(indextip, indexPem, valor))
													}
												/>
											</div>
										</div>
										<p style={{marginBottom:'0'}}>{pem.pemdescripcion}</p>
									</div>
								)
							}
						</Panel>
					</Collapse>
				
				)
			}
		</Modal>
	)
}

export default PermisosEspecialesUsuario