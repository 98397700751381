import React, {useState} from 'react'
import Seleccionarnegocio from '../../Componentes/SeleccionarNegocio/Seleccionarnegocio'
import { Select } from 'antd'
import {useSelector, useDispatch} from "react-redux";
import {
    ArrowLeftOutlined,
} from '@ant-design/icons';
import {
    ReiniciarVariablesEstadosSeleccionarNegocioReducer
} from '../../Redux/Actions/Componentes/SeleccionarNegocio'
import GifNohayData from '../../Assets/Gif/nohay.gif'

const ControlPanel = () => {

    const dispatch = useDispatch()

    // let paises = ["Perú", "Bolivia", "Chile", "Mexico", "CEAM"]
    
    let cuentas_paises = [
        {
            cuenta : "Kimberly",
            paises : ["Perú", "Bolivia", "Chile"],
            paneles : ["DT Tracking Diario", "DT Data Mart", "Status B2B"]
        },
        {
            cuenta : "Softys",
            paises : ["Perú", "Mexico", "CEAM"],
            paneles : ["DT Tracking Diario", "DT Data Mart", "Status B2B"]
        },
        {
            cuenta : "Grupo Romero",
            paises : ["Perú"],
            paneles : ["DT Tracking Diario"]
        },
        {
            cuenta : "Oceano Foods",
            paises : ["Perú"],
            paneles : ["DT Tracking Diario"]
        },
        {
            cuenta : "Najar",
            paises : ["Perú"],
            paneles : ["DT Tracking Diario"]
        }
    ]

    let paises = [
        {
            pais : "Perú",
            cuenta : "Kimberly"
        },
        {
            pais : "Bolivia",
            cuenta : "Kimberly"
        },
        {
            pais : "Chile",
            cuenta : "Kimberly"
        },

        // 

        {
            pais : "Perú",
            cuenta : "Softys"
        },
        {
            pais : "Chile",
            cuenta : "Softys"
        },
        {
            pais : "Mexico",
            cuenta : "Softys"
        },
        {
            pais : "CEAM",
            cuenta : "Softys"
        },

        // 

        {
            pais : "Perú",
            cuenta : "Grupo Romero"
        },

        // 

        {
            pais : "Perú",
            cuenta : "Oceano Foods"
        },
    ]

    let cuentas = ["Kimberly", "Softys", "Grupo Romero", "Oceano Foods", "Najar"]
    // let cuentas = ["Kimberly", "Softys", "Clorox", "Palmas"]

    let powerbi = [
        {
            cuenta: "Kimberly",
            pais: "Perú",
            powerbi: "https://app.powerbi.com/view?r=eyJrIjoiNmQ5NjMwMTAtYmQzNC00YzU5LWJjMzEtMTI2OWZhZmRiYzVhIiwidCI6IjFkZjQ2ODhjLWI5MTUtNDEwMy05OGMwLTNhMzY4ZmIyOTNlOCJ9",
            panel : "DT Data Mart",
            canal : "tradicional"
        },
        {
            cuenta: "Kimberly",
            pais: "Bolivia",
            powerbi: "https://app.powerbi.com/view?r=eyJrIjoiNzkxMmU2NjAtMTY3My00ZDU2LWIyNDItM2I2MzdlYzUwN2M4IiwidCI6IjFkZjQ2ODhjLWI5MTUtNDEwMy05OGMwLTNhMzY4ZmIyOTNlOCJ9",
            panel : "DT Data Mart",
            canal : "tradicional"
        },
        {
            cuenta: "Kimberly",
            pais: "Chile",
            powerbi: "https://app.powerbi.com/view?r=eyJrIjoiNDdlYzJmZmUtMTQwYS00NWM3LTlkMDItN2Y0MTdhMjIwNzVlIiwidCI6IjFkZjQ2ODhjLWI5MTUtNDEwMy05OGMwLTNhMzY4ZmIyOTNlOCJ9",
            panel : "DT Data Mart",
            canal : "tradicional"
        },

        {
            cuenta: "Kimberly",
            pais: "Perú",
            // powerbi: "https://app.powerbi.com/view?r=eyJrIjoiOTFjNWY1MTQtYWVmNy00NDI5LWExMmYtZjMyODZlYmFjNTI4IiwidCI6IjFkZjQ2ODhjLWI5MTUtNDEwMy05OGMwLTNhMzY4ZmIyOTNlOCJ9",
            powerbi: "https://app.powerbi.com/view?r=eyJrIjoiOWQxNWU0NzItZDczYi00M2MxLTkyYjItY2Y1OGFiMWM4MWUyIiwidCI6IjFkZjQ2ODhjLWI5MTUtNDEwMy05OGMwLTNhMzY4ZmIyOTNlOCJ9",
            panel : "Status B2B",
            canal : "tradicional"
        },

        {
            cuenta: "Kimberly",
            pais: "Bolivia",
            powerbi: "https://app.powerbi.com/view?r=eyJrIjoiMjQ1YzIzOTAtNjQ1My00NzU0LTlhZWMtMjljMWU4OWExZTEwIiwidCI6IjFkZjQ2ODhjLWI5MTUtNDEwMy05OGMwLTNhMzY4ZmIyOTNlOCJ9",
            panel : "Status B2B",
            canal : "tradicional"
        },

        {
            cuenta: "Kimberly",
            pais: "Chile",
            powerbi: "https://app.powerbi.com/view?r=eyJrIjoiOGM3YjM3N2YtNDRhMC00MTQxLWE0NGYtMWY1NWIzZTA4MmJlIiwidCI6IjFkZjQ2ODhjLWI5MTUtNDEwMy05OGMwLTNhMzY4ZmIyOTNlOCJ9",
            panel : "Status B2B",
            canal : "tradicional"
        },

        {
            cuenta: "Kimberly",
            pais: "Perú",
            // powerbi: "https://app.powerbi.com/view?r=eyJrIjoiNjI0YzhlZmYtYWQ1ZS00ODkyLTgzMjQtZTBhMWFlOWFhZmI0IiwidCI6IjFkZjQ2ODhjLWI5MTUtNDEwMy05OGMwLTNhMzY4ZmIyOTNlOCJ9&pageName=ReportSectione7e7ae29f5d49539011f",
            powerbi: "https://app.powerbi.com/view?r=eyJrIjoiZjk0YjY1NjctOGJhMC00ZGJiLWIwNTEtOGFiZmI5ZTBhMGRiIiwidCI6IjFkZjQ2ODhjLWI5MTUtNDEwMy05OGMwLTNhMzY4ZmIyOTNlOCJ9",
            panel : "DT Tracking Diario",
            canal : "tradicional"
        },

        {
            cuenta: "Kimberly",
            pais: "Bolivia",
            // powerbi: "https://app.powerbi.com/view?r=eyJrIjoiNWY0ODdhMmEtZWM3NS00M2Q1LWE5MmEtNTAyMGFkZTgyMmYzIiwidCI6IjFkZjQ2ODhjLWI5MTUtNDEwMy05OGMwLTNhMzY4ZmIyOTNlOCJ9",
            powerbi: "https://app.powerbi.com/view?r=eyJrIjoiZGYyOTFkYTItM2UyMi00NzJjLTg4MDYtZTc0OWE3Yjk1ODg4IiwidCI6IjFkZjQ2ODhjLWI5MTUtNDEwMy05OGMwLTNhMzY4ZmIyOTNlOCJ9",
            panel : "DT Tracking Diario",
            canal : "tradicional"
        },

        {
            cuenta: "Kimberly",
            pais: "Chile",
            // powerbi: "https://app.powerbi.com/view?r=eyJrIjoiNDY4OTJkZmQtMDI1Yy00Y2E4LTlhNDQtMDM1YjVmMDJjYTc2IiwidCI6IjFkZjQ2ODhjLWI5MTUtNDEwMy05OGMwLTNhMzY4ZmIyOTNlOCJ9",
            powerbi: "https://app.powerbi.com/view?r=eyJrIjoiNjA5ODdjYzYtYzIzYS00ODgxLWE4ZjAtOGRhZDJlMDgwNGYwIiwidCI6IjFkZjQ2ODhjLWI5MTUtNDEwMy05OGMwLTNhMzY4ZmIyOTNlOCJ9",
            panel : "DT Tracking Diario",
            canal : "tradicional"
        },

        // 

        {
            cuenta  : "Softys",
            pais    : "Perú",
            powerbi : "https://app.powerbi.com/view?r=eyJrIjoiYmVjZTgyMzQtYzVmMC00NjNiLTljNjAtNTI5NWY0NTk0MDgwIiwidCI6IjFkZjQ2ODhjLWI5MTUtNDEwMy05OGMwLTNhMzY4ZmIyOTNlOCJ9",
            panel   : "DT Tracking Diario",
            canal   : "tradicional"
        },

        {
            cuenta  : "Softys",
            pais    : "Chile",
            powerbi : "https://app.powerbi.com/view?r=eyJrIjoiM2Q1OTE3MDMtMmQ2Ny00OTA1LThiZjMtODNjYjA0NzkyZWVhIiwidCI6IjFkZjQ2ODhjLWI5MTUtNDEwMy05OGMwLTNhMzY4ZmIyOTNlOCJ9",
            panel   : "DT Tracking Diario",
            canal   : "tradicional"
        },

        {
            cuenta  : "Softys",
            pais    : "Mexico",
            powerbi : "https://app.powerbi.com/view?r=eyJrIjoiNmQwZmY4OGUtNzg2ZC00M2FlLWFhZGEtYmQ2NTFjNjUxYjliIiwidCI6IjFkZjQ2ODhjLWI5MTUtNDEwMy05OGMwLTNhMzY4ZmIyOTNlOCJ9",
            panel   : "DT Tracking Diario",
            canal   : "tradicional"
        },

        {
            cuenta  : "Softys",
            pais    : "CEAM",
            powerbi : "https://app.powerbi.com/view?r=eyJrIjoiYmM4NTg5MWMtOGIzMC00MWNhLWE5NmYtYjQ4ZmQzZjVlZDYzIiwidCI6IjFkZjQ2ODhjLWI5MTUtNDEwMy05OGMwLTNhMzY4ZmIyOTNlOCJ9",
            panel   : "DT Tracking Diario",
            canal   : "tradicional"
        },

        {
            cuenta  : "Softys",
            pais    : "Perú",
            powerbi : "https://app.powerbi.com/view?r=eyJrIjoiYWRjZDcwNmEtZTA2Yy00MjY1LWI3OWItZDM4MmM0NTgzYWQxIiwidCI6IjFkZjQ2ODhjLWI5MTUtNDEwMy05OGMwLTNhMzY4ZmIyOTNlOCJ9",
            panel   : "DT Data Mart",
            canal   : "tradicional"
        },
        {
            cuenta  : "Softys",
            pais    : "Chile",
            powerbi : "https://app.powerbi.com/view?r=eyJrIjoiMWRjODEyMGYtMzM5Yi00Njg3LTkxZjQtZTlmMmUxNDlkOTNhIiwidCI6IjFkZjQ2ODhjLWI5MTUtNDEwMy05OGMwLTNhMzY4ZmIyOTNlOCJ9",
            panel   : "DT Data Mart",
            canal   : "tradicional"
        },
        {
            cuenta  : "Softys",
            pais    : "Mexico",
            powerbi : "https://app.powerbi.com/view?r=eyJrIjoiNjFiYzk2NjYtNTkwMS00MWExLWFkNTUtYTc0NDhiOGM0NGE5IiwidCI6IjFkZjQ2ODhjLWI5MTUtNDEwMy05OGMwLTNhMzY4ZmIyOTNlOCJ9",
            panel   : "DT Data Mart",
            canal   : "tradicional"
        },
        {
            cuenta  : "Softys",
            pais    : "CEAM",
            powerbi : "https://app.powerbi.com/view?r=eyJrIjoiNzgwMjE5ZDItZGMzMy00MzI5LTliZTMtMGJlOGViYWIzOTRlIiwidCI6IjFkZjQ2ODhjLWI5MTUtNDEwMy05OGMwLTNhMzY4ZmIyOTNlOCJ9",
            panel   : "DT Data Mart",
            canal   : "tradicional"
        },

        {
            cuenta  : "Softys",
            pais    : "Perú",
            powerbi : null,
            panel   : "Status B2B",
            canal   : "tradicional"
        },

        {
            cuenta  : "Softys",
            pais    : "Chile",
            powerbi : null,
            panel   : "Status B2B",
            canal   : "tradicional"
        },

        {
            cuenta  : "Softys",
            pais    : "Mexico",
            powerbi : null,
            panel   : "Status B2B",
            canal   : "tradicional"
        },

        {
            cuenta  : "Softys",
            pais    : "CEAM",
            powerbi : null,
            panel   : "Status B2B",
            canal   : "tradicional"
        },

        // 

        {
            cuenta  : "Grupo Romero",
            pais    : "Perú",
            powerbi : "https://app.powerbi.com/view?r=eyJrIjoiZjM3ODc0ZmYtODdkNS00ZWM0LTg3NGEtM2EyOTE1NTQ1NjlkIiwidCI6IjFkZjQ2ODhjLWI5MTUtNDEwMy05OGMwLTNhMzY4ZmIyOTNlOCJ9",
            panel   : "DT Tracking Diario",
            canal   : "tradicional"
        },

        {
            cuenta  : "Oceano Foods",
            pais    : "Perú",
            powerbi : "https://app.powerbi.com/view?r=eyJrIjoiOTc2M2Q3ZjctYjE2Mi00YmNjLWFiNGYtNjU5YjY4NmFlOTdkIiwidCI6IjFkZjQ2ODhjLWI5MTUtNDEwMy05OGMwLTNhMzY4ZmIyOTNlOCJ9",
            panel   : "DT Tracking Diario",
            canal   : "tradicional"
        },

        // 
        {
            cuenta  : "Najar",
            pais    : "Perú",
            // powerbi : "https://app.powerbi.com/view?r=eyJrIjoiZGViNDA4NmItOTk4YS00NTQzLTlmNzctNjcyNmZhZDUzN2EwIiwidCI6IjFkZjQ2ODhjLWI5MTUtNDEwMy05OGMwLTNhMzY4ZmIyOTNlOCJ9",
            powerbi : "https://app.powerbi.com/view?r=eyJrIjoiOGIwZmE2ZDMtMWZkYi00ZjhlLWExZDUtMjJjMDI1MjVkYzU1IiwidCI6IjFkZjQ2ODhjLWI5MTUtNDEwMy05OGMwLTNhMzY4ZmIyOTNlOCJ9",
            panel   : "DT Tracking Diario",
            canal   : "tradicional"
        }
    ]
    
    const [controlPanelSeleccionado, setControlPanelSeleccionado] = useState("")
    const [panelSeleccionado, setPanelSeleccionado] = useState("")
    const [paisSeleccionado, setPaisSeleccionado] = useState("Perú")
    const [cuentaSeleccionado, setCuentaSeleccionado] = useState("")

    return (
        <div>

            {
                <Seleccionarnegocio 
                    mostrarCuentasStaticas = {true}
                    cuentasStaticas = {cuentas}
                    setCuentasStaticas = {(e) => {
                        cuentas_paises.map((cuenta_pais) => {
                            if(cuenta_pais.cuenta == e){
                                setPanelSeleccionado(cuenta_pais.paneles[0])
                                setPaisSeleccionado(cuenta_pais.paises[0])
                            }
                        })
                        setCuentaSeleccionado(e)
                    }}


                />
            }

            <div
                style={{paddingTop:'76px'}}
                className="Componente-Principal-Carga-Archivo"
            >
                <div className='Contenedor-Titulo-Carga-Archivo W700-S26-H35-C000000'>
                    <div 
                        style={{
                            marginRight:'15px', fontSize:'20px', cursor:'pointer',
                        }}
                        onClick={() => {
                            setCuentaSeleccionado("")
                            dispatch(ReiniciarVariablesEstadosSeleccionarNegocioReducer())
                        }}
                    >
                        <ArrowLeftOutlined />
                    </div>
                    {cuentaSeleccionado}
                </div>
            </div>




            <div
                id="Contenedor-Filtro-Carga-Archivo"
            >

                {
                    cuentaSeleccionado == ""
                    ?null
                    :<Select 
                        style={{ width: 200 }} 
                        onChange={(e, r) => {
                            setPanelSeleccionado(e)
                        }}
                        value={panelSeleccionado}
                    >
                        {
                            cuentas_paises.map((cuenta_pais, pos) => {
                                return (
                                    cuenta_pais.cuenta == cuentaSeleccionado
                                    ?cuenta_pais.paneles.map((panel) => {
                                        return (
                                            <Select.Option 
                                                value={panel}
                                                key={pos}
                                            >
                                                {panel}
                                            </Select.Option>
                                        )
                                    })
                                    :null
                                )
                            })
                        }
                    </Select>
                }

                <div style={{marginLeft:'30px'}}></div>

                {
                    cuentaSeleccionado == ""
                    ?null
                    :<Select 
                        style={{ width: 120 }} 
                        onChange={(e, r) => {
                            setPaisSeleccionado(e)
                        }}
                        value={paisSeleccionado}
                    >
                        {
                            cuentas_paises.map((cuenta_pais, pos) => {
                                return (
                                    cuenta_pais.cuenta == cuentaSeleccionado
                                    ?cuenta_pais.paises.map((pais) => {
                                        return (
                                            <Select.Option 
                                                value={pais}
                                                key={pos}
                                            >
                                                {pais}
                                            </Select.Option>
                                        )
                                    })
                                    :null
                                )
                            })
                        }
                    </Select>
                }

                {/* <Select 
                    style={{ width: 120 }} 
                    onChange={(e, r) => {
                        setPaisSeleccionado(e)
                    }}
                    value={paisSeleccionado}
                >
                    {
                        paises.map((pais, pos) => {
                            return (
                                <Select.Option 
                                    value={pais}
                                    key={pos}
                                >
                                    {pais}
                                </Select.Option>
                            )
                        })
                    }
                </Select> */}
                <div style={{marginRight:'30px'}}></div>
                
            </div>

            <div style={{marginTop:'-5px'}}>
            
            {
                powerbi.map((data) => {
                    return (
                        cuentaSeleccionado == data.cuenta && data.pais == paisSeleccionado && panelSeleccionado ==  data.panel
                        ?data.powerbi
                            ?<iframe 
                                src={data.powerbi} 
                                title="GODA Dashboards"
                                width="100%" height="1000px" 
                                style={{border:'none'}}
                            >
                
                            </iframe>
                            :<div
                                style={{
                                    marginTop: "20px",
                                    textAlignLast: "center",
                                    marginBottom: "20px"
                                }}
                            >
                                <img src={GifNohayData} /> 
                            </div>
                        :null
                    )
                })
            }

            </div>




        </div>
    )
}

export default ControlPanel