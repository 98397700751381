import React, { useState } from 'react'
import { Modal, Form, Row, Col, Select, Input, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { 
    AgregarCategoriaAdministradorCargaArchivosReducer,
    MostrarModalAgregarCategoriaCargaArchivos
} from '../../../Redux/Actions/Administrador/CargaArchivos/CargaArchivos';
import '../../../Estilos/Rutas/Administrador/CargaArchivos.css'
import FooterModalCargaArchivos from '../../../Funciones/componentes/FooterModalCargaArchivos';

const AgregarCategoria = (props) => {

    const dispatch = useDispatch();

    const { Option } = Select;
    const [ enviando, setEnviando ] = useState(false);
    const [ ccan, setCcan ] = useState({ccaestado: true});

    const {
		rex_modal_agregar_categoria_administrador,
        rex_canales_administrador,
    } = useSelector(({administradorCargaArchivos}) => administradorCargaArchivos);

    const {
        rex_paises,
        rex_cuentas
    } = useSelector(({administradorCuentas}) => administradorCuentas);

    const agregarCategoria = async() => {
        setEnviando(true);
        await dispatch(AgregarCategoriaAdministradorCargaArchivosReducer(ccan));
        props.limpiarFiltro();
        setEnviando(false);
    }

    const cancelar = () => {
        dispatch(MostrarModalAgregarCategoriaCargaArchivos(false))
    }
    
    return (
        <Modal
            centered={true}
            open={rex_modal_agregar_categoria_administrador}
            onCancel = {cancelar}
            width={700}
            alignItems='center'
            className='ant-modal-content'
            getContainer="#root"
            footer={null}
        >
            <Form
                layout='vertical'
                autoComplete='off'
                onFinish={agregarCategoria}
            >
                <h2 className='Text-Center-Carga-Archivos'>Agregar Categoria</h2>

                <Row>
                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}} xl={{span:12}}
                    >
                        <Form.Item
                            className='Input-Usuario'
                            label='Nombre:'
                            name='ccanombre'
                            rules={[{ required: true, message: 'El nombre es requerido' }]}
                        >
                            <Input
                                name='ccanombre'
                                onChange={(e) => setCcan({...ccan, ccanombre:e.target.value})}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}} xl={{span:12}}
                    >
                        <Form.Item
                            label='Canal:'
                            className='Input-Usuario'
                            rules={[
                                { required: true, message: 'El canal es requerido' },
                            ]}
                            name='canid'
                        >
                            <Select
                                onChange={(valor) => setCcan({...ccan, canid:valor})}
                                size='large'
                                placeholder='Seleccionar'
                            >
                                {rex_canales_administrador.map((can)=> (
                                    <Option
                                        key={can.canid}
                                        value={can.canid}
                                    >
                                        {can.cannombre}
                                    </Option>
                                ))}
                            </Select>

                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}} xl={{span:12}}
                    >

                        <Form.Item
                            label='Empresa:'
                            className='Input-Usuario'
                            rules={[
                                { required: true, message: 'El canal es requerido' },
                            ]}
                            name='cueid'
                        >
                            <Select
                                onChange={(valor) => setCcan({...ccan, cueid:valor})}
                                size='large'
                                placeholder='Seleccionar'
                            >
                                {rex_cuentas.map((cue)=> (
                                    <Option
                                        key={cue.cueid}
                                        value={cue.cueid}
                                    >
                                        {cue.cuenombre}
                                    </Option>
                                ))}
                            </Select>

                        </Form.Item>
                    </Col>
                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}} xl={{span:12}}
                    >
                        <Form.Item
                            label='Pais:'
                            className='Input-Usuario'
                            rules={[
                                { required: true, message: 'El canal es requerido' },
                            ]}
                            name='paiid'
                        >
                            <Select
                                onChange={(valor) => setCcan({...ccan, paiid:valor})}
                                size='large'
                                placeholder='Seleccionar'
                            >
                                {rex_paises.map((pai)=> (
                                    <Option
                                        key={pai.paiid}
                                        value={pai.paiid}
                                    >
                                        {pai.painombre}
                                    </Option>
                                ))}
                            </Select>

                        </Form.Item>
                    </Col>
                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}} xl={{span:12}}
                    >
                        <Form.Item
                            label='Estado:'
                            className='Input-Usuario'
                        >
                            <Switch
                                defaultChecked
                                className={ccan.ccaestado ? 'Circulo-Activo': 'Circulo-Desactivo'}
                                onChange={(valor) => setCcan({...ccan, ccaestado:valor})}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <FooterModalCargaArchivos cancelar={cancelar} enviando={enviando} nombre='Categoria'/>
            </Form>
        </Modal>
    )
}

export default AgregarCategoria