import config from '../../../config'
import { estadoRequestReducer } from "../Comunes/EstadoRequest"
import {
    OBTENER_DATA_TABLA_DETALLE_STATUS,
    SELECCIONAR_FILA_DATA_TABLA_DETALLE_STATUS,
    MOSTRAR_MODAL_CONFIRMACION_DETALLE_STATUS,
    EDITAR_O_ELIMINAR_STATUS_DETALLE_STATUS,
    CARGANDO_MODAL_CONFIRMACION_STATUS_DETALLE_STATUS,
    ACTUALIZAR_COLUMMNAS_TABLA_DETALLE_STATUS
} from '../../../Constantes/Status/Status'
import { notification } from 'antd'
import {
    ObtenerStatusReducer
} from './Status'

export const ObtenerDetalleStatusReducer = (reiniciar = true) => async (dispatch, getState) => {

    const rex_fila_data_seleccionado_tabla_status = getState().tablaStatus.rex_fila_data_seleccionado_tabla_status

    if(reiniciar == true){
        dispatch({
            type: OBTENER_DATA_TABLA_DETALLE_STATUS,
            payload: []
        })
    }

    await fetch(config.api+'status/mostrar-detalles',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                "req_stcid": rex_fila_data_seleccionado_tabla_status.stcid,
                "req_coaid": rex_fila_data_seleccionado_tabla_status.coaid
            }),
        }
    )
    .then( async res => {
            await dispatch(estadoRequestReducer(res.status))
            return res.json()
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request
        if(estadoRequest == true){
            if(data.respuesta == true){
                
                dispatch({
                    type: OBTENER_DATA_TABLA_DETALLE_STATUS,
                    payload: data.datos
                })

            }
        }

    }).catch((error)=> {
        console.log(error)
        // dispatch(RegistrarErrorReducer(error, config.api+'login', datos))
    });

}

export const EditarColumnaTipoProcesoDetalleStatusReducer = (fila) => async (dispatch, getState) => {

    let rex_columnas_tabla_detalle_status = getState().tablaDetalleStatus.rex_columnas_tabla_detalle_status;

    let nuevasColumnas;

    if(fila.tcanombre == 'SFTP' && fila.pacid['pacid'] == 2){

        let columnasAnteriores = rex_columnas_tabla_detalle_status.filter((col) => col.title != 'Tipo de proceso')


        let columnaSFTP = {
            title: 'Tipo de proceso',
            dataIndex: 'cartipproc',
            key: 'cartipproc',
            align:'center',
            width:'10',
            render: (cartipproc) => (
                <div title={cartipproc} style={{display:'flex', justifyContent:'center'}} className="Celda-Tabla-Carga-Archivo W400-S11-H16-C1E1E1E">
                    {cartipproc}
                </div>
            ),
        }

        columnasAnteriores.splice(5, 0, columnaSFTP);

        nuevasColumnas = columnasAnteriores;
        
    }else{

        nuevasColumnas = rex_columnas_tabla_detalle_status.filter((col) => col.title != 'Tipo de proceso');
    }
    
    dispatch({
        type: ACTUALIZAR_COLUMMNAS_TABLA_DETALLE_STATUS,
        payload: nuevasColumnas
    })
}

export const SeleccionarFilaDetalleStatusReducer = (fila, editaroeliminar) => async (dispatch, getState) => {

    const rex_mostrar_modal_confirmacion_staus_detalle_status = getState().tablaDetalleStatus.rex_mostrar_modal_confirmacion_staus_detalle_status

    dispatch({
        type: SELECCIONAR_FILA_DATA_TABLA_DETALLE_STATUS,
        payload: fila
    })

    dispatch({
        type: MOSTRAR_MODAL_CONFIRMACION_DETALLE_STATUS,
        payload: !rex_mostrar_modal_confirmacion_staus_detalle_status
    })

    dispatch({
        type: EDITAR_O_ELIMINAR_STATUS_DETALLE_STATUS,
        payload: editaroeliminar // editar o eliminar
    })
}

export const EditarStatusArchivoSubidoReducer = () => async (dispatch, getState) => {

    const rex_fila_data_seleccionado_tabla_detalle_status = getState().tablaDetalleStatus.rex_fila_data_seleccionado_tabla_detalle_status

    dispatch({
        type: CARGANDO_MODAL_CONFIRMACION_STATUS_DETALLE_STATUS,
        payload: true
    })

    await fetch(config.api+'status/editar',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                "req_caridcsv": rex_fila_data_seleccionado_tabla_detalle_status.carcsvid,
                "req_caridxls": rex_fila_data_seleccionado_tabla_detalle_status.carxlsid
            }),
        }
    )
    .then( async res => {
            await dispatch(estadoRequestReducer(res.status))
            return res.json()
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request
        
        if(estadoRequest == true){
            if(data.respuesta == true){
                
                notification.success({
                    message: `Notificación`,
                    description: data.mensaje,
                    placement:'topRight',
                });

                dispatch(SeleccionarFilaDetalleStatusReducer(null, null))
                dispatch(ObtenerDetalleStatusReducer(false))
                dispatch(ObtenerStatusReducer())
            }
        }

    }).catch((error)=> {
        console.log(error)
        notification.error({
            message: `Notificación`,
            description: error.mensaje,
            placement:'topRight',
        });
        // dispatch(RegistrarErrorReducer(error, config.api+'login', datos))
    });

    dispatch({
        type: CARGANDO_MODAL_CONFIRMACION_STATUS_DETALLE_STATUS,
        payload: false
    })

}

export const EliminarDetalleStatusSubidoReducer = () => async (dispatch, getState) => {

    const rex_fila_data_seleccionado_tabla_detalle_status = getState().tablaDetalleStatus.rex_fila_data_seleccionado_tabla_detalle_status

    dispatch({
        type: CARGANDO_MODAL_CONFIRMACION_STATUS_DETALLE_STATUS,
        payload: true
    })

    console.log(rex_fila_data_seleccionado_tabla_detalle_status);

    await fetch(config.api+'status/eliminar',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                "req_caridcsv"  : rex_fila_data_seleccionado_tabla_detalle_status.carcsvid,
                "req_caridxls"  : rex_fila_data_seleccionado_tabla_detalle_status.carxlsid,
                "req_pacid"     : rex_fila_data_seleccionado_tabla_detalle_status.pacid,
                "req_tcanombre" : rex_fila_data_seleccionado_tabla_detalle_status.tcanombre,
                "req_canid"     : rex_fila_data_seleccionado_tabla_detalle_status.canid,
            }),
        }
    )
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        return res.json()
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request
        
        if(estadoRequest == true){
            if(data.respuesta == true){
                
                notification.success({
                    message: `Notificación`,
                    description: data.mensaje,
                    placement:'topRight',
                });

                dispatch(SeleccionarFilaDetalleStatusReducer(null, null))
                dispatch(ObtenerDetalleStatusReducer(false))
                dispatch(ObtenerStatusReducer())
            }
        }

    }).catch((error)=> {
        console.log(error)
        notification.error({
            message: `Notificación`,
            description: error.mensaje,
            placement:'topRight',
        });
        // dispatch(RegistrarErrorReducer(error, config.api+'login', datos))
    });

    dispatch({
        type: CARGANDO_MODAL_CONFIRMACION_STATUS_DETALLE_STATUS,
        payload: false
    })
}