import { Modal, Select, Form , Row, Col, Button, Upload, Switch  } from 'antd';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { 
    EditarCuentaAdministradorCuentaReducer,
    MostrarModalEditarCuentaReducer
} from '../../../Redux/Actions/Administrador/Cuentas/Cuentas';
import '../../../Estilos/Rutas/Administrador/Cuentas.css'
import config from '../../../config'

const EditarCuenta = () => {

    const { Option } = Select;
    const dispatch = useDispatch();
    
    const {
        rex_paises,
        rex_modal_editar_cuenta,
        rex_cuenta_paises_editar,
        rex_paises_cue_inicial
    } = useSelector(({administradorCuentas}) => administradorCuentas);
    
    const [enviando , setEnviando ] = useState(false);
    const [ cuentae, setCuentae ] = useState({
        cueid: rex_cuenta_paises_editar.cueid,
        paises:rex_paises_cue_inicial.map((paiid) => paiid.value),
        nombre: rex_cuenta_paises_editar.cuenombre,
        cueestado: rex_cuenta_paises_editar.cueestado
    });

    const editarCuenta =  async (cuentae) => {
        setEnviando(true);
        await dispatch(EditarCuentaAdministradorCuentaReducer(cuentae));
        setEnviando(false);
    }

    return (
        <Modal
            centered={true}
            open={rex_modal_editar_cuenta}
            onCancel = {() => dispatch(MostrarModalEditarCuentaReducer(false))}
            onOk = {() => editarCuenta(cuentae)}
            confirmLoading={enviando}
            okText='Guardar'
            width={700}
            alignItems='center'
            className='ant-modal-content'
            getContainer="#root"
        >
            <h2 className='Text-Center-Dts-Administrador'>Editar Cuenta</h2>
            <Form
                layout='vertical'
                autoComplete='off'
            >
                <Row>
                    <Col xs={{span:24}} sm={{span:12}} md={{span:12}} lg={{span:12}} xl={{span:12}}>
                        
                        <Form.Item
                            label='Nombre:'
                            className='Input-Usuario'
                        >
                            <span style={{fontWeight:'600'}}>{rex_cuenta_paises_editar.cuenombre}</span>
                            
                        </Form.Item>
                    </Col>
                    <Col xs={{span:24}} sm={{span:12}} md={{span:12}} lg={{span:12}} xl={{span:12}}>
                        <Form.Item
                            label='Estado:'
                            className='Input-Usuario'
                        >
                            <Switch
                                defaultChecked = {cuentae.cueestado === 1? true:false}
                                className={cuentae.cueestado ? 'Circulo-Activo': 'Circulo-Desactivo'}
                                onChange={(valor)=> setCuentae({
                                    ...cuentae, cueestado:valor
                                })}
                            />
                        </Form.Item>
                    </Col>

                </Row>
                <Row>

                    <Col xs={{span:24}} sm={{span:12}} md={{span:12}} lg={{span:12}} xl={{span:12}}>
                        <Form.Item
                            label='Imagen:'
                            className='Input-Usuario'
                        >
                            <Upload.Dragger
                                listType='picture'
                                action={config.api}
                                beforeUpload={(file)=>{
                                    let reader = new FileReader();
                                    reader.readAsDataURL(file);
                                    reader.onload = function(){
                                        let base64 = reader.result;
                                        setCuentae({
                                            ...cuentae,imagen:base64
                                        });
                                    }
                                    return false;
                                }
                                }
                            >
                                <Button>Cargar Imagen</Button>
                            </Upload.Dragger>

                        </Form.Item>
                    </Col>
                    <Col xs={{span:24}} sm={{span:12}} md={{span:12}} lg={{span:12}} xl={{span:12}}>
                        <Form.Item
                            className='Input-Usuario'
                            label='Paises:'
                        >
                            <Select
                                mode="multiple"
                                allowClear
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Agregar país"
                                defaultValue={rex_paises_cue_inicial.map((paiid) => paiid)}
                                onChange={(valor) => setCuentae({...cuentae,paises:valor})}
                                size='large'
                            >
                                {rex_paises.map((pai)=> (
                                    <Option
                                        key={pai.paiid}
                                        value={pai.paiid}
                                    >{pai.painombre}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

            </Form>

        </Modal>
    )
}

export default EditarCuenta