import React, { useState } from 'react'
import { Modal, Form, Row, Col, Switch, Select, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { 
	EditarCategoriaAdministradorCargaArchivosReducer,
	MostrarModalEditarCategoriaCargaArchivos
} from '../../../Redux/Actions/Administrador/CargaArchivos/CargaArchivos';
import '../../../Estilos/Rutas/Administrador/CargaArchivos.css'
import FooterModalCargaArchivos from '../../../Funciones/componentes/FooterModalCargaArchivos';

const EditarCategoria = (props) => {

	const dispatch = useDispatch();
	const { Option } = Select;

	const {
		rex_modal_editar_categoria_administrador,
		rex_ccacat_editar_administrador,
		rex_canales_administrador,
    } = useSelector(({administradorCargaArchivos}) => administradorCargaArchivos);

	const {
		rex_paises,
		rex_cuentas
    } = useSelector(({administradorCuentas}) => administradorCuentas);

	const [ enviando, setEnviando ] = useState(false);
	const [ ccacate, setCcacate ] = useState({...rex_ccacat_editar_administrador})

	const editarCategoria = async () => {
		setEnviando(true);
		await dispatch(EditarCategoriaAdministradorCargaArchivosReducer(ccacate));
		props.limpiarFiltro();
		setEnviando(false);
	}

	const cancelar = () => {
		dispatch(MostrarModalEditarCategoriaCargaArchivos(false, []))
	}

	return (
		
		<Modal
			centered={true}
			open={rex_modal_editar_categoria_administrador}
			onCancel = {cancelar}
			width={700}
			alignItems='center'
			className='ant-modal-content'
			getContainer="#root"
			footer={null}
		>
			<Form
				autoComplete='off'
				layout='vertical'
				onFinish={editarCategoria}
			>
				<h2 className='Text-Center-Carga-Archivos'>Editar Categoria</h2>
				<Row>
					<Col
						xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}} xl={{span:12}}
					>
                        <Form.Item
                            className='Input-Usuario'
                            label='Nombre:'
                        >
                            <Input
                                name='ccanombre'
                                onChange={(e) => setCcacate({...ccacate, ccanombre: e.target.value})}
                                value={ccacate.ccanombre}
                            />
                        </Form.Item>
					</Col>
					<Col
						xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}} xl={{span:12}}
					>
						<Form.Item
							label='Canal:'
							className='Input-Usuario'
						>
							<Select
								onChange={(valor) => setCcacate({...ccacate, canid: valor})}
								defaultValue={ccacate.canid}
								size='large'
								placeholder='Seleccionar'
							>
								{rex_canales_administrador.map((can)=> (
									<Option
										key={can.canid}
										value={can.canid}
									>
										{can.cannombre}
									</Option>
								))}
							</Select>

						</Form.Item>
					</Col>
				</Row>

				<Row>
					<Col
						xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}} xl={{span:12}}
					>
						<Form.Item
							label='Empresa:'
							className='Input-Usuario'
						>
							<Select
								onChange={(valor) => setCcacate({...ccacate, cueid: valor})}
								defaultValue={ccacate.cueid}
								size='large'
								placeholder='Seleccionar'
							>
								{rex_cuentas.map((cue)=> (
									<Option
										key={cue.cueid}
										value={cue.cueid}
									>
										{cue.cuenombre}
									</Option>
								))}
							</Select>

						</Form.Item>
					</Col>
					<Col
						xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}} xl={{span:12}}
					>
						<Form.Item
							label='Pais:'
							className='Input-Usuario'
						>
							<Select
								onChange={(valor) => setCcacate({...ccacate, paiid: valor})}
								defaultValue={ccacate.paiid}
								size='large'
								placeholder='Seleccionar'
							>
								{rex_paises.map((pai)=> (
									<Option
										key={pai.paiid}
										value={pai.paiid}
									>
										{pai.painombre}
									</Option>
								))}
							</Select>

						</Form.Item>
					</Col>
				</Row>
				<Row>
                    <Col>
                        <Form.Item
                            className='Input-Usuario'
                            label='Estado:'
                        >
                            <Switch
                                defaultChecked = {ccacate.ccaestado}
								className={ccacate.ccaestado ? 'Circulo-Activo': 'Circulo-Desactivo'}
                                onChange={(valor) => setCcacate({...ccacate, ccaestado: valor})}
                                size='large'
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <FooterModalCargaArchivos cancelar={cancelar} enviando={enviando}/>
			</Form>

		</Modal>

	)
}

export default EditarCategoria