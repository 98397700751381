import React from 'react';
import {Provider} from 'react-redux'
import generateStore, {history} from './Redux/Store/index';
import { ConnectedRouter } from 'connected-react-router'
import {BrowserRouter, Route, Routes} from "react-router-dom";
import App from './App/App'
import './Estilos/Letras/LetraLuminoso.css'
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'

// CONFIGURAR EL IDIOMA PARA ANTD
import { ConfigProvider } from "antd";
import moment from "moment";
import 'moment/locale/es';
import es_ES from "antd/lib/locale/es_ES";

moment.locale("es");

function NextApp() {

    const store = generateStore()

    return (
        <ConfigProvider locale={es_ES}>
            <Provider store={store}>

                {/* <ConnectedRouter history={history}> */}
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                {/* </ConnectedRouter> */}
            </Provider>
        </ConfigProvider>
    )
}

export default NextApp;
