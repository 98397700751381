import React from 'react'
import '../../Estilos/Rutas/Login/Login.css'
import { Input, Button, Form } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import {useSelector, useDispatch} from "react-redux";
import {
    LoginReducer
} from '../../Redux/Actions/Login/Login'

const Login = () => {

    const dispatch = useDispatch()

    const { 
        rex_cargando_btn_login
    } = useSelector(({login}) => login);

    const enviarDatosLogin = (valores) => {
        dispatch(LoginReducer(valores))
    };

    return (
        <div
            id="Contenedor-Login"
        >
            
            <div
                id="Contenedor-Formulario-Login"
            >
                <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    onFinish={enviarDatosLogin}
                >

                    <div
                        className='W700-S20-H27-CPlomoOscuro Titulo-Login'
                    >
                        Iniciar sesión a Admi Grow
                    </div>

                    <div>
                        <Form.Item
                            name="req_usuario"
                            rules={[{ 
                                required: true, 
                                message: 'Es necesario un usuario'
                            }]}
                        >
                            <Input 
                                placeholder="Dirección de correo electrónico" 
                                style={{height:'50px'}}
                            />
                        </Form.Item>
                    </div>

                    <div style={{marginTop:'15px', marginBottom:'30px'}}>
                        <Form.Item
                            name="req_contrasenia"
                            rules={[{ 
                                required: true, 
                                message: 'Es necesario una contraseña'
                            }]}
                        >
                            <Input.Password
                                placeholder="Contraseña" 
                                rules={[{ 
                                    required: true, 
                                    message: 'Es necesario una contraseña'
                                }]}
                                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                style={{height:'48px'}}
                            />
                        </Form.Item>
                    </div>

                    {/* <div className='W400-S16-H21-CPlomo' style={{marginBottom:'20px', textAlignLast: "center"}}>
                        ¿Se te olvidó tu contraseña?
                    </div> */}
                    <div
                        style={{
                            textAlign: "center"
                        }}
                    >
                        <Button 
                            type="primary" 
                            shape="round" 
                            // size={size}
                            className='Btn-Login W700-S16-H21-CBlanco'
                            htmlType="submit"
                            loading={rex_cargando_btn_login}
                        >
                            Iniciar Sesión
                        </Button>
                    </div>
                </Form>
            </div>


        </div>
    )
}

export default Login