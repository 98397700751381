import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Table, Tooltip, Button, Pagination, Row , Col, Select, Input, Modal } from 'antd'
import '../../../Estilos/Rutas/Administrador/CargaArchivos.css'
import { 
	ObtenerCanalesCuentasAdministradorCargaArchivosReducer,
	FiltrarCanalCuentaAdministradorCargaArchivosReducer,
	MostrarModalAgregarCanalCuentaCargaArchivos,
	MostrarModalEditarCanalCuentaCargaArchivos,
	EliminarCanalCuentaAdministradorCargaArchivosReducer
} from '../../../Redux/Actions/Administrador/CargaArchivos/CargaArchivos'
import AgregarCanalCuenta from './AgregarCanalCuenta'
import EditarCanalesCuentas from './EditarCanalesCuentas'
import { 
	EditOutlined,
	SearchOutlined,
	DeleteOutlined,
	ExclamationCircleOutlined,
	ReloadOutlined,
	PlusCircleOutlined
} from '@ant-design/icons'
import Moment from 'moment'

const CanalesCuentas = () => {

	const dispatch = useDispatch();
	const { Option } = Select;
	const { confirm } = Modal;
	
    const [pageTableCanalesCuentas, setPageTableCanalesCuentas] = useState(1)
    const [pageSizeTableCanalesCuentas, setPageSizeTableCanalesCuentas] = useState(10)
	const [ selectCan, setSelectCan ] = useState(0)
	const [ selectCue, setSelectCue ] = useState(0)
	const [ cargando, setCargando ] = useState(false)
	const [ txtBuscar, setTxtBuscar] = useState('')

	const {
		rex_modal_editar_cancue_administrador,
		rex_modal_agregar_cancue_administrador,
		rex_filtro_canales_cuentas,
		rex_canales_administrador
    } = useSelector(({administradorCargaArchivos}) => administradorCargaArchivos);

	const {
        rex_cuentas
    } = useSelector(({administradorCuentas}) => administradorCuentas);

	useEffect(() => {
		obtenerCanalesCuentasAdministrador();
	}, []);

	const buscarCanalCuenta = (txtBuscar, canid, cueid) => {
		dispatch(FiltrarCanalCuentaAdministradorCargaArchivosReducer(txtBuscar, canid, cueid));
	}

	const obtenerCanalesCuentasAdministrador = async () => {
		setCargando(true);
		await dispatch(ObtenerCanalesCuentasAdministradorCargaArchivosReducer());
		setCargando(false);
	}

	const limpiarFiltro = () => {
		setSelectCan(0);
		setSelectCue(0);
		setTxtBuscar('');
	}

	const confirmarEliminarCanalCuenta = ( {cacid, cuenombre, cannombre} ) => {
		confirm({
            title: `¿Eliminar cuenta ${cuenombre} del canal ${cannombre}?`,
            icon: <ExclamationCircleOutlined />,
            okText: 'Eliminar',
            content: <div>Se eliminará toda la información incluyendo:<br/><br/>
			- Registro con alguna categoria<br/>
			- Registro con algún tipo de archivo<br/>
			- Registro con algún archivo<br/>
		</div>,
        
            async onOk() {
                
                let respuesta  = await dispatch(EliminarCanalCuentaAdministradorCargaArchivosReducer(cacid));
				respuesta && limpiarFiltro();
            },
        
            onCancel() {},
        });
    }

	const columns = [
		{
			title: 'Item',
			key: 'cacid',
			align: 'center',
			width:'80px',
			render: (text, record, index) => (pageTableCanalesCuentas - 1) * pageSizeTableCanalesCuentas + index + 1,
		},
		{
			title: 'Estado',
			key: 'cacestado',
			align: 'center',
			width:'80px',
			render: (_, record) => (

				<div style={{display:'flex', justifyContent:'center'}}>
					<Tooltip placement="top" title={record.cacestado ? 'Activado':'Desactivado'}>
						<div className={`Circulo ${record.cacestado? 'Circulo-Activo': 'Circulo-Desactivo'}`}>
						</div>
					</Tooltip>
				</div>
			)
		},
		{
			title: 'Empresa',
			dataIndex: 'cuenombre',
			key: 'cuenombre',
			align: 'center',
			width:'200px'
		},
		{
			title: 'Canal',
			dataIndex: 'cannombre',
			key: 'cannombre',
			align: 'center',
			width:'200px'
		},
		{
			title: 'Fecha de creación',
			key: 'created_at',
			align: 'center',
			width:'250px',
			render: (_, record) => (
				<>{record.created_at?Moment(record.created_at).format('LLL'):'Sin registro'}</>
			),
		},
		{
			title: 'Fecha de actualización',
			key: 'updated_at',
			align: 'center',
			width:'250px',
			render: (_, record) => (
				<>{record.updated_at?Moment(record.updated_at).format('LLL'):'Sin registro'}</>
			),
		},
		{
			title: 'Acción',
			key: 'editar',
			align: 'center',
			width: '100px',
			fixed:'right',
			render: (_,record ) => (
				<div className='Contenedor-Acciones-Iconos'>
					<Tooltip placement="top" title='Editar'>
						<Button
							onClick={() => dispatch(MostrarModalEditarCanalCuentaCargaArchivos(true, record))}
							className='Boton-Accion-Editar'
						>
							<EditOutlined
								className='Icono-Accion-Editar'
							/>
						</Button>
					</Tooltip>
					<Tooltip placement='top' title='Eliminar'>
						<Button
							onClick={()=> confirmarEliminarCanalCuenta(record)}
							className='Boton-Accion-Eliminar'
						>
							<DeleteOutlined
								className='Icono-Accion-Eliminar'
							/>
						</Button>
					</Tooltip>
				</div>
			)	
		},
	];

	return (
		<div className='Contenedor-Carga-Archivos-Tabla'>

			<Row className='Fila-Buscador-Boton-Carga-Archivos'>
				<Col
					xl={8} lg={8} md={24} sm={24} xs={24}
				>
					<Row>
						<Col span={20}>
							<Input
								suffix={<SearchOutlined className='Icono-Input-Buscador'/>}
								size='large'
								className='Input-Buscador-Carga-Archivos'
								placeholder='Buscar'
								value={txtBuscar}
								onChange={(e) => {
									setTxtBuscar(e.target.value)
									buscarCanalCuenta(e.target.value, selectCan, selectCue)
								}}
							/>
						</Col>
						<Col span={4} style={{display:'flex',alignItems:'center'}}>
							<Tooltip title='Actualizar'>
								<div
									className='Boton-Actualizar'
									onClick={async ()=> {	
											setCargando(true);
											await dispatch(ObtenerCanalesCuentasAdministradorCargaArchivosReducer());
											setCargando(false);
									}}
								>
									<ReloadOutlined
										className='Icono-Actualizar'
									/>
								</div>
							</Tooltip>
						</Col>
					</Row>
				</Col>
				<Row>	
					<Col>
						<Button
							onClick={() => dispatch(MostrarModalAgregarCanalCuentaCargaArchivos(true))}
							className='Boton-Crear-Carga-Archivos'
						>Crear<PlusCircleOutlined/></Button>
					</Col>
				</Row>
			</Row>
			<Row className='Contenedor-Filtro-Carga-Archivos'>
				<Row>
					<Col>
						<Select
							value={selectCue}
							className='Select-Filtro-Carga-Archivos'
							onChange={(valor) => {
								setSelectCue(valor)
								buscarCanalCuenta(txtBuscar, selectCan, valor)
							}}
						>
							<Option value={0}>Seleccionar Empresa</Option>
							{
								rex_cuentas.map((cue) => (
									<Option key={cue.cueid} value={cue.cueid}>{cue.cuenombre}</Option>
								))
							}
						</Select>
					</Col>
					<Col>
						<Select
							value={selectCan}
							className='Select-Filtro-Carga-Archivos'
							onChange={(valor)=> {
								setSelectCan(valor)
								buscarCanalCuenta(txtBuscar, valor, selectCue)
							}}
						>
							<Option value={0}>Seleccionar Canal</Option>
							{
								rex_canales_administrador.map((can)=> (
									<Option key={can.canid} value={can.canid}>{can.cannombre}</Option>
								))
							}
						</Select>
					</Col>
				</Row>
				<Row>
					<Col
						style={{display:'flex', justifyContent:'flex-end', margin:'5px 0'}}
					>
						<Pagination
							defaultCurrent={pageTableCanalesCuentas}
							current={pageTableCanalesCuentas}
							total={rex_filtro_canales_cuentas.length}
							onChange={(page, pageSize) => {
								setPageTableCanalesCuentas(page == 0? 1 : page)
								setPageSizeTableCanalesCuentas(pageSize)

							}}
						/>
					</Col>
				</Row>
			</Row>
			<Table
				dataSource={rex_filtro_canales_cuentas} 
				columns={columns}
				loading={cargando}
				pagination={{
					defaultCurrent: pageTableCanalesCuentas,
					current: pageTableCanalesCuentas,
					pageSize: pageSizeTableCanalesCuentas,
					position: ['topRight'],
				}}
				scroll={{
					x:1000
				}}
				size='small'
			/>

			{rex_modal_editar_cancue_administrador
				&& <EditarCanalesCuentas
					limpiarFiltro={limpiarFiltro}
				/>}
			{rex_modal_agregar_cancue_administrador
				&& <AgregarCanalCuenta
					limpiarFiltro={limpiarFiltro}
					selectCan={selectCan}
					selectCue={selectCue}
					txtBuscar={txtBuscar}
				/>}
		</div>
	)
}

export default CanalesCuentas