
import config from "../../../../config";
import { estadoRequestReducer } from "../../Comunes/EstadoRequest";
import { errorNotificacion } from "../../../../Funciones/Notificaciones/Notificaciones";
import { 
    AGREGAR_CUENTA_ADMINISTRADOR,
    AGREGAR_PAIS_ADMINISTRADOR,
    EDITAR_CUENTA_PAIS_ADMINISTRADOR,
    OBTENER_CUENTAS_PAISES,
    FILTRAR_CUENTAS_PAISES,
    OBTENER_CUENTAS_CUENTAS,
    OBTENER_PAISES_CUENTAS,
    OBTENER_PACIDS_PAISES_CUENTAS
} from "../../../../Constantes/Administrador/Cuentas/Cuentas";
import {
    exitoNotificacion,
    alertaNotificacion
} from "../../../../Funciones/Notificaciones/Notificaciones";

export const ObtenerCuentasPaisesAdministradorCuentasReducer = () => async (dispatch, getState) => {

    await fetch(config.api+'obtener-cuentaspaises',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
        }
    )
    .then( async res => {
            await dispatch(estadoRequestReducer(res.status))
            return res.json()
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){
            dispatch({
                type: OBTENER_CUENTAS_PAISES,
                payload: {
                    cuentaspaises     : data.cuentaspaises,
                }
            })
            dispatch({
                type: FILTRAR_CUENTAS_PAISES,
                payload: data.cuentaspaises
            })
        }else{
            alertaNotificacion(data.mensaje)
        }

    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}

export const ObtenerCuentasAdministradorCuentasReducer = () => async (dispatch, getState) => {

    await fetch(config.api+'obtener-cuentas',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
        }
    )
    .then( async res => {
            await dispatch(estadoRequestReducer(res.status))
            return res.json()
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){

            dispatch({
                type: OBTENER_CUENTAS_CUENTAS,
                payload: data.cuentas
            })
        }

    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}

export const ObtenerPaisesAdministradorCuentasReducer = () => async (dispatch, getState) => {

    await fetch(config.api+'obtener-paises',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
        }
    )
    .then( async res => {
            await dispatch(estadoRequestReducer(res.status))
            return res.json()
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){
            dispatch({
                type: OBTENER_PAISES_CUENTAS,
                payload: data.paises
            })
        }

    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}

export const ObtenerCuentasPaisesUsuariosAdministradorCuentasReducer = () => async (dispatch, getState) => {

    await fetch(config.api+'obtener-pacid',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
        }
    )
    .then( async res => {
            await dispatch(estadoRequestReducer(res.status))
            return res.json()
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){
            dispatch({
                type: OBTENER_PACIDS_PAISES_CUENTAS,
                payload: data.pacid
            })
        }

    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}

export const EliminarCuentaPaisAdministradorCuentasReducer = ( cueid ) => async ( dispatch, getState ) => {

    await fetch(config.api+'eliminar-cuenta-pais',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
            body: JSON.stringify(
                {
                    "cueid"        : cueid,
                }
            ),   
        }
    )
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        return res.json()
    })
    .then(data => {

        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){

            dispatch(ObtenerCuentasPaisesAdministradorCuentasReducer());
            exitoNotificacion(data.mensaje);

        }else{
            alertaNotificacion(data.mensaje);
        }

    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}

export const EliminarCuentasPaisesAdministradorCuentasReducer = (cueid) => async (dispatch, getState) => {

    await fetch(config.api+'eliminar-cuenta-pais-paises',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
            body: JSON.stringify(
                {
                    "cueid"        : cueid,
                }
            ),
        }
    )
    .then( async res => {
            await dispatch(estadoRequestReducer(res.status))
            return res.json()
    })
    .then(data => {

        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){

            dispatch(ObtenerCuentasPaisesAdministradorCuentasReducer());
            exitoNotificacion(data.mensaje);
        }else{
            alertaNotificacion(data.mensaje);
        }

    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}

export const AgregarCuentasAdministradorCuentasReducer = (cuentan) => async (dispatch, getState) => {

    await fetch(config.api + 'agregar-cuenta',
    {
        mode: 'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'api_token'	   : localStorage.getItem('usutoken')
        },
        body: JSON.stringify(
            {
                "cuenombre"         : cuentan.nombrecuenta,
                "paises"            : cuentan.paises,
                'cueimagen'         : cuentan.cueimagen
            }
        ),
    }
    ).then( async res => {
            await dispatch(estadoRequestReducer(res.status))
            return res.json()
    })
    .then(data => {

        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){

            dispatch({
                type: AGREGAR_CUENTA_ADMINISTRADOR,
                payload: false
            })

            dispatch(ObtenerCuentasPaisesAdministradorCuentasReducer());

            exitoNotificacion(data.mensaje);

        }else{

            if(data.errores){
                data.errores.map(err=> alertaNotificacion(err));
            }else{
                alertaNotificacion(data.mensaje);
            }
            
        }

    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}

export const AgregarPaisAdministradorCuentaReducer = (paisn) => async (dispatch, getState) => {

    await fetch(config.api + 'agregar-pais',
    {
        mode: 'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'api_token'	   : localStorage.getItem('usutoken')
        },
        body: JSON.stringify(
            {
                "painombre"         : paisn.paisnombre,
                'paiimagen'         : paisn.paisimagen
            }
        ),
    }
    ).then( async res => {
            await dispatch(estadoRequestReducer(res.status))
            return res.json()
    })
    .then(data => {

        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){
            dispatch(MostrarModalAgregarPaisReducer(false))
            exitoNotificacion(data.mensaje)
            dispatch(ObtenerPaisesAdministradorCuentasReducer());
            dispatch(ObtenerCuentasPaisesAdministradorCuentasReducer());

        }else{

            if(data.errores){
                data.errores.map(err=> alertaNotificacion(err));
            }else{
                alertaNotificacion(data.mensaje);
            }
        }

    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}

export const EditarCuentaAdministradorCuentaReducer = (cuentae) => async (dispatch, getState) => {

    await fetch(config.api + 'editar-cuenta',
        {
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify(
                {
                    "req_cueid"         : cuentae.cueid,
                    "req_cuepaises"     : cuentae.paises,
                    "req_cuenombre"     : cuentae.nombre,
                    "req_cueimagen"     : cuentae.imagen,
                    "req_cueestado"     : cuentae.cueestado
                }
            ),
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api_token'	   : localStorage.getItem('usutoken')
            }
        }
    ).then( async res => {
            await dispatch(estadoRequestReducer(res.status))
            return res.json()
    })
    .then(data => {

        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){

            dispatch(ModalEditarCuentaAdministradorCuentaReducer(false, [], []))
            exitoNotificacion(data.mensaje);
            dispatch(ObtenerCuentasPaisesAdministradorCuentasReducer());

        }else{

            if(data.errores){
                data.errores.map(err=> alertaNotificacion(err));
            }else{
                alertaNotificacion(data.mensaje);
            }
        }

    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}

export const MostrarModalAgregarCuentaReducer = (estado) => async (dispatch, getState) => {

    dispatch({
        type: AGREGAR_CUENTA_ADMINISTRADOR,
        payload: estado
    })
}

export const MostrarModalEditarCuentaReducer = (estado) => async (dispatch, getState) => {

    dispatch({
        type: EDITAR_CUENTA_PAIS_ADMINISTRADOR,
        payload: {
            modalcuee: estado
        }
    })
}

export const MostrarModalAgregarPaisReducer = (estado) => async (dispatch, getState) => {

    dispatch({
        type: AGREGAR_PAIS_ADMINISTRADOR,
        payload: estado
    })
}

export const ModalEditarCuentaAdministradorCuentaReducer = ( estado, cuepais, paises ) => async (dispatch, getState) => {
    dispatch({
        type: EDITAR_CUENTA_PAIS_ADMINISTRADOR,
        payload: {
            modalcuee   : estado,
            cuepais     : cuepais,
            paisescue   : paises
        }
    })
}

export const FiltrarPaisCuentaAdministradorCuentaReducer = (txtBuscar, paiid) => async (dispatch, getState) => {

    let listaPaisesCuentas  = getState().administradorCuentas.rex_cuentas_paises_cuentas;

    if(txtBuscar || paiid){

        let paisescuentasFiltrados = listaPaisesCuentas.filter((pac) => 
            (
                pac.cuenombre.toLocaleLowerCase().includes(txtBuscar.toLocaleLowerCase())
                || pac.paises.find(pai => pai.painombre.toLocaleLowerCase().includes(txtBuscar.toLocaleLowerCase()))
            )
            && (paiid != 0 
                ? pac.paises.find(pai => pai.paiid == paiid)
                : pac.paises.find(pai => typeof pai.paiid == 'number'))    
        )

        dispatch({
            type    : FILTRAR_CUENTAS_PAISES,
            payload : paisescuentasFiltrados
        })

    }else{
        dispatch({
            type    : FILTRAR_CUENTAS_PAISES,
            payload : listaPaisesCuentas
        })
    }
}