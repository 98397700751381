import React, {useState} from 'react'
import {
    OBTENER_DATA_TABLA_CARGA_ARCHIVOS,
    ACTUALIZAR_COLUMNAS_TABLA_CARGA_ARCHIVOS,
    SELECCIONAR_FILA_DATA_TABLA_CARGA_ARCHIVOS,
    MOSTRAR_MODAL_CONFIRMACION_TABLA_CARGA_ARCHIVOS,
    MOSTRAR_MODAL_SELECCIONAR_DT_TABLA_CARGA_ARCHIVOS,
    MOSTRAR_MODAL_GUARDANDO_ARCHIVOS_PYTHON_TABLA_CARGA_ARCHIVOS,
    PETICION_GUARDANDO_PYTHON_CARGA_ARCHIVOS,
    PETICION_OBTENIDA_PYTHON_CARGA_ARCHIVOS,
    MOSTRAR_MODAL_TIEMPO_PETICION_TABLA_CARGA_ARCHIVOS,
    MOSTRAR_MODAL_NOTIFICACION_TABLA_CARGA_ARCHIVOS,
    MOSTRAR_MODAL_RANGO_TIEMPO_INVALIDO_CARGA_ARCHIVOS,
    HORARIOS_MODAL_RANGO_TIEMPO_INVALIDO_CARGA_ARCHIVOS
} from '../../../Constantes/CargaArchivos/CargaArchivos'
import { Button, Upload, DatePicker, Tooltip, Switch, Row, Col } from 'antd';
import { 
    PaperClipOutlined, 
    PlusCircleOutlined, 
    CloseOutlined, 
    QuestionCircleOutlined 
} from '@ant-design/icons';
import dayjs from 'dayjs';

import { useDispatch, useSelector} from "react-redux";
import {
    SeleccionarArchivoTablaCargaArchivo,
    EnviarArchivoCargadoTablaCargaArchivoReducer,
    SeleccionarRangoFechaCargaArchivoReducer,
    CargandoBtnEnviarArchivoReducer,
    AgregarFilaTablaCargaArchivoReducer,
    SeleccionarDtFilaTablaCargaArchivoReducer,
    EliminarFilaTablaCargaArchivoReducer,
    EliminarArchivoSeleccionadoTablaCargaArchivoReducer,
    SeleccionarFechaSFTPCargaArchivoReducer,
    SeleccionaTipoProcesamientoSFTPCargaArchivoReducer,
    NotificacionRangoTiempoInvalidoReducer
} from '../../Actions/CargaArchivos/TablaCargaArchivos'
import Moment from 'moment';
import { alertaNotificacion, alertaNotificacionLeft } from '../../../Funciones/Notificaciones/Notificaciones';

export const BtnCargaArchivo = (props) => {

    const record = props.record
    const archivo = props.archivo
    const dispatch = useDispatch()


    const {
        rex_data_modal_dts_carga_archivos,
    } = useSelector(({modalDtsCargaArchivos}) => modalDtsCargaArchivos);


    return (
        <div
            style={
                archivo == "principal"
                ?record.archivoprincipal
                    ?{
                        marginTop:'-31px', display:'flex'
                    }
                    :{display:'flex'}
                :record.archivosecundario
                    ?{
                        marginTop:'-31px', display:'flex'
                    }
                    :{display:'flex'}
            }
        >
            <div
                style={
                    record.cargando
                    ?{cursor:'not-allowed'}
                    :{}
                }
            >
                <Upload
                    onRemove={() => {
                        dispatch(EliminarArchivoSeleccionadoTablaCargaArchivoReducer(record.key, archivo))
                    }}
                    beforeUpload={async (file) => {
                        
                        let extFile = file.name.split('.').pop();
                        let respuesta = await dispatch(NotificacionRangoTiempoInvalidoReducer(record))
                        
                        let archivoValido
                        let mensajeInvalido
                        let dts = rex_data_modal_dts_carga_archivos.find(dts => dts.seleccionado == true)
                                                
                        if(archivo == "principal"){
                            
                            let nombreValido = true
                            archivoValido = (file.type == 'text/csv' || file.type == 'application/x-zip-compressed' || file.type === 'application/x-rar-compressed' || extFile == 'rar');

                            mensajeInvalido = !archivoValido ? 'Solo archivos .CSV o .ZIP':null

                            if(dts){
                                if(dts.reglas !== null){
                                    if(dts.reglas.rstnombrecsv){
                                        if(dts.reglas.rstnombrecsv !== file.name){
                                            nombreValido = false
                                            mensajeInvalido = 'El nombre del archivo debe llamarse: ' + dts.reglas.rstnombrecsv
                                        }
                                    }
                                    if(dts.reglas.rstcontienecsv){
                                        if(!file.name.includes(dts.reglas.rstcontienecsv)){
                                            nombreValido = false
                                            mensajeInvalido = 'El nombre del archivo debe contener: "' + dts.reglas.rstcontienecsv + '"'
                                        }
                                    }
                                }
                            }
                            archivoValido = archivoValido && nombreValido

                        }else{
                            let nombreValido = true
                            archivoValido = (file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type == 'application/x-zip-compressed' || extFile == 'rar');
                            mensajeInvalido = !archivoValido ? 'Solo archivos .XLSX o .ZIP':null

                            if(dts){
                                if(dts.reglas != null){
                                    if(dts.reglas.rstnombrexlsx){                                        
                                        if(dts.reglas.rstnombrexlsx !== file.name){
                                            nombreValido = false
                                            mensajeInvalido = 'El nombre del archivo debe llamarse: ' + dts.reglas.rstnombrexlsx 
                                        }
                                    }
                                    
                                    if(dts.reglas.rstcontienexlsx){
                                        if(!file.name.includes(dts.reglas.rstcontienexlsx)){
                                            nombreValido = false
                                            mensajeInvalido = 'El nombre del archivo debe contener: "' + dts.reglas.rstcontienexlsx + '"'
                                        }
                                    }                                    
                                }
                                archivoValido = archivoValido && nombreValido
                            }
                        }
                        
                        if(!respuesta){
                            return Upload.LIST_IGNORE;
                        }

                        if(!archivoValido){
                            alertaNotificacionLeft(mensajeInvalido)
                            return Upload.LIST_IGNORE;
                        }else{
                            dispatch(SeleccionarArchivoTablaCargaArchivo(file, record.key, archivo))
                            return false;
                        }

                    }}
                    fileList={
                        archivo == "principal" 
                        ?record.archivoprincipal 
                            ?[record.archivoprincipal] 
                            :[]  
                        :record.archivosecundario
                            ?[record.archivosecundario] 
                            :[]  
                    }
                    disabled={record.cargando}
                >
                    <div
                        style={
                            record.cargando
                            ?{display:'flex', cursor:'not-allowed'}
                            :{display:'flex', cursor:'pointer'}
                        }
                    >
                        {
                            archivo == "principal" 
                            ?record.archivoprincipal
                                ?null
                                :<>
                                    <div 
                                        className='W600-S12-H16-C787878' 
                                        style={{
                                            paddingRight:'5px',
                                            paddingTop:'2px'
                                        }}
                                    >
                                        Subir archivo
                                    </div>
                                    <PaperClipOutlined style={{fontSize:'22px'}} />
                                </>
                            :record.archivosecundario
                                ?null
                                :<>
                                    <div 
                                        className='W600-S12-H16-C787878' 
                                        style={{
                                            paddingRight:'5px',
                                            paddingTop:'2px'
                                        }}
                                    >
                                        Subir archivo
                                    </div>
                                    <PaperClipOutlined style={{fontSize:'22px'}} />
                                </>
                        } 
                    </div>
                </Upload>
            </div>

            {
                archivo == "principal" && record.agregarnuevasfilas == true
                ?<Tooltip
                    title="Los archivos de reproceso son solo en formato CSV si son más de un archivo se tienen que subir en un ZIP"
                    placement="bottom"
                >
                    <div
                        style={{
                            fontSize: "18px",
                            marginLeft: "10px",
                            marginTop: "1px",
                            cursor:'pointer'
                        }}
                    >
                        <QuestionCircleOutlined />
                    </div>
                </Tooltip>
                :null
            }
        </div>
    )
}

export const BtnRangoFechas = (props) => {
    const record = props.record
    const dispatch = useDispatch()

    const [switchMesAnio, setSwitchMesAnio] = useState(false);
    const monthFormat = 'YYYY-MM';

    return (

        record.tcanombre == 'SFTP' && record.pacid == 2
        ?   record.itempadre
            ? null
            : <Row style={{display:'flex', alignItems:'center'}}>
                <Col span={14}>
                    <Tooltip
                        title='Periodo a procesar para Ketal, si no se selecciona se usará el periodo en curso'
                        placement='left'
                    >
                        <DatePicker
                            value={record.fechaSFTP == '' ? null : Moment(record.fechaSFTP, monthFormat)}
                            format={monthFormat}
                            defaultValue={null}
                            onChange={async (date, dateString) => {

                                let respuesta = await dispatch(NotificacionRangoTiempoInvalidoReducer(record))
                                if(respuesta){
                                    dispatch(SeleccionarFechaSFTPCargaArchivoReducer(dateString, record.key))
                                }
                            }}
                            picker="month"
                        />
                    </Tooltip>
                </Col>
                <Col span={10} style={{padding:'2px', display:'flex', justifyContent:'center'}}>
                    <Tooltip
                        title='Tipo de procesamiento para Ketal'
                    >
                        <Switch
                            style={{backgroundColor: switchMesAnio ? '#2E86C1':'#2ECC71'}}
                            onChange={async (valor) => {

                                let respuesta = await dispatch(NotificacionRangoTiempoInvalidoReducer(record))

                                if(respuesta){
                                    dispatch(SeleccionaTipoProcesamientoSFTPCargaArchivoReducer(valor, record.key))
                                }
                                setSwitchMesAnio(valor)
                                
                            }}
                            checkedChildren={'Diario'}
                            unCheckedChildren={'Cierre'}
                        />
                    </Tooltip>
                </Col>
            </Row>
        : <DatePicker.RangePicker
            picker="month" 
            disabledDate={(current) => {

                let arrayDisableKCPE = [28, 37, 10]

                const range = record.pacid == 1 
                    ? arrayDisableKCPE.includes(record.stcid)
                        ? current && current < Moment('2021-01-01', 'YYYY-MM-DD')
                        : current && current < Moment('2021-01-01', 'YYYY-MM-DD')
                    : null
                return range
            }}
            onChange={(_, fechas) => {
                dispatch(SeleccionarRangoFechaCargaArchivoReducer(fechas, record.key))
            }}
            value={
                record.fechasmoment
            }
            disabled={record.cargando}
        />
    )
}

export const BtnEnviarArchivo = (props) => {

    const record = props.record
    const dispatch = useDispatch()

    return (
        <>
            {
                record.subitem == true
                ?<div
                    style={{
                        width: "100%",
                        textAlignLast: "center"
                    }}
                >
                    <Button
                        type="danger"
                        icon={<CloseOutlined />}
                        // icon={<CheckOutlined />}
                        loading={false}
                        onClick={() => {
                            dispatch(EliminarFilaTablaCargaArchivoReducer(record.key))
                        }}
                        // className='Btn-Enviando-Archivo-Tabla-Carga-Archivo'
                        shape="circle"
                    />
                </div>
                :<Button
                    type={
                        record.tcanombre == 'SFTP' ||
                            ((record.pacid == 2 && record.canid == 2 && record.stcnombre == 'Combos BO') 
                            || (record.pacid == 2 && record.canid == 2 && record.stcnombre =='Normal All BO'))
                        ?   record.archivosecundario
                            ?"primary"
                            :'secondary'
                        :   record.archivoprincipal
                            ?"primary"
                            :'secondary'
                    }
                    onClick={async ()=>{

                        let respuesta = await dispatch(NotificacionRangoTiempoInvalidoReducer(record))

                        if(respuesta){
                            dispatch(CargandoBtnEnviarArchivoReducer(record, true))
                        }
                    }}
                    loading={record.cargando}
                    disabled={

                        record.tcanombre == 'SFTP' ||
                            ((record.pacid == 2 && record.canid == 2 && record.stcnombre == 'Combos BO') 
                            || (record.pacid == 2 && record.canid == 2 && record.stcnombre =='Normal All BO'))
                        ?   record.archivosecundario
                            ?false
                            :true
                        :   record.archivoprincipal
                            ?false
                            :true
                        
                        
                    }
                >
                    Enviar    
                </Button>
            }
        </>
    )
}

export const BtnAgregarFila = (props) => {

    const record = props.record
    const dispatch = useDispatch()

    return (
        <>
            <div
                style={{
                    marginLeft: "10px",
                    alignSelf: "center",
                    width: "20px"
                }}
            >
                {
                    record.agregarnuevasfilas == 1
                    ?<PlusCircleOutlined 
                        style={{
                            marginTop: "4px",
                            fontSize: "18px",
                            cursor:'pointer'
                        }}
                        onClick={async () => {

                            
                            let respuesta = await dispatch(NotificacionRangoTiempoInvalidoReducer(record))
                            if(respuesta){
                                dispatch(AgregarFilaTablaCargaArchivoReducer(record.key))
                            }
                        }}
                    />
                    :null
                }
            </div>
        </>
    )
}

export const SeleccionarDt = (props) => {
    const record = props.record
    const dispatch = useDispatch()

    return (
        <div 
            title={
                record.subitem == true || record.agregarnuevasfilas
                ?record.dtseleccionada
                    ?record.dtseleccionada.dtsclientesubhml
                    : record.canal == 'Moderno' ? 'Holding' : record.stcnombre

                :record.stcnombre
            }
            className={"Celda-Tabla-Carga-Archivo"}
            style={
                record.subitem == true || record.agregarnuevasfilas
                ?{cursor:'pointer'}
                :{}
            }
            onClick={async () => {

                let respuesta = await dispatch(NotificacionRangoTiempoInvalidoReducer(record))

                if(respuesta){
                    if(record.subitem == true || record.agregarnuevasfilas){
                        dispatch(SeleccionarDtFilaTablaCargaArchivoReducer(true, record))
                    }
                }
            }}
        >
            {
                record.subitem == true || record.agregarnuevasfilas
                ?<div>
                    {
                        record.dtseleccionada
                        ?<span className='W600-S12-H16-C1876F2'>{record.dtseleccionada.dtsclientesubhml}</span>
                        :   record.canal == 'Moderno' ? 
                        
                            <span className='W600-S12-H16-CC4C4C4-L0015'>Holding</span>
                            : <span className='W600-S12-H16-CC4C4C4-L0015'>{record.stcnombre}</span>
                    }
                </div>
                :record.stcnombre
            }
        </div>
    )
}

const INIT_STATE = {
    rex_mostrar_modal_confirmacion_tabla_carga_archivos : false,
    rex_mostrar_modal_seleccionar_dt_tabla_carga_archivos : false,


    rex_modal_rango_tiempo_invalido_carga_archivos: false,
    rex_mostrar_modal_guardando_archivos_python_carga_archivos: false,
    rex_peticion_guardando_python_carga_archivos: 1,
    rex_peticion_obtenida_python_carga_archivos: false,
    rex_mostrar_modal_tiempo_peticion_python_carga_archivos: false,

    
    rex_mostrar_modal_notificacion_tabla_carga_archivos: false,
    rex_horarios_modal_rango_tiempo_invalido_carga_archivos: [],


    rex_fila_data_seleccionado_tabla_carga_archivos : null,
    rex_data_tabla_carga_archivos : [],
    rex_columnas_tabla_carga_archivos: [
        {
            title: 'Item',
            dataIndex: 'item',
            key: 'item',
            width: 65,
            align:'center'
        },
        {
            title: 'Canal',
            dataIndex: 'canal',
            key: 'canal',
            width: 100,
            align:'center',
            render: (_, record) => (
                <div title={record.canal} className="Celda-Tabla-Carga-Archivo">
                    {record.canal}
                </div>
            )
        },
        {
            title: 'Categoría',
            dataIndex: 'ccanombre',
            key: 'ccanombre',
            align:'center',
            render: (_, record) => (
                <div title={record.ccanombre} className="Celda-Tabla-Carga-Archivo">
                    {record.ccanombre}
                </div>
            ),
        },
        {
            title: 'Tipo de Archivo',
            dataIndex: 'tcanombre',
            key: 'tcanombre',
            align:'center',
            render: (_, record) => (
                <div title={record.tcanombre} className="Celda-Tabla-Carga-Archivo">
                    {record.tcanombre}
                </div>
            ),
        },
        {
            title: 'Archivo',
            dataIndex: 'stcnombre',
            key: 'stcnombre',
            align:'center',
            render: (_, record) => (
                <SeleccionarDt
                    record = {record}
                />
            ),
        },
        {
            title: 'Subir CSV',
            key: 'subir',
            align:'center',
            render: (_, record) => (
                <>
                    <BtnCargaArchivo archivo={"principal"} record = {record} />
                </>
            ),
        },
        {
            title: 'Subir XLSX',
            key: 'subircsv',
            align:'center',
            render: (_, record) => (
                <>
                    <BtnCargaArchivo archivo={"secundario"} record = {record} />
                </>
            ),
        },
        {
            title: 'Tiempo',
            key: 'tiempo',
            align:'center',
            render: (_, record) => (
                <>
                    {
                        // record.agregarnuevasfilas == true || record.subitem == true
                        record.tienerangofechas == true
                        ?<BtnRangoFechas 
                                record = {record}
                        />
                        :null
                    } 
                </>
            ),
            width: 240,
        },
        {
            title: 'Enviar',
            key: 'enviar',
            align:'center',
            render: (_, record) => (
                <div
                    style={{
                        display:'flex'
                    }}
                >
                    <BtnEnviarArchivo 
                        record = {record}
                    />
                    <BtnAgregarFila 
                        record = {record}
                    />
                    
                </div>
            )
        }
    ]
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case HORARIOS_MODAL_RANGO_TIEMPO_INVALIDO_CARGA_ARCHIVOS : {
            return {
                ...state,
                rex_horarios_modal_rango_tiempo_invalido_carga_archivos : action.payload,
            }
        }
        case OBTENER_DATA_TABLA_CARGA_ARCHIVOS: {
            return {
                ...state,
                rex_data_tabla_carga_archivos : action.payload,
            }
        }

        case MOSTRAR_MODAL_RANGO_TIEMPO_INVALIDO_CARGA_ARCHIVOS: {
            return {
                ...state,
                rex_modal_rango_tiempo_invalido_carga_archivos : action.payload,
            }
        }

        case ACTUALIZAR_COLUMNAS_TABLA_CARGA_ARCHIVOS: {
            return {
                ...state,
                rex_data_tabla_carga_archivos : action.payload.data,
                rex_columnas_tabla_carga_archivos : action.payload.columnas
            }
        }
        case SELECCIONAR_FILA_DATA_TABLA_CARGA_ARCHIVOS: {
            return {
                ...state,
                rex_fila_data_seleccionado_tabla_carga_archivos : action.payload
            }
        }
        case MOSTRAR_MODAL_CONFIRMACION_TABLA_CARGA_ARCHIVOS: {
            return {
                ...state,
                rex_mostrar_modal_confirmacion_tabla_carga_archivos : action.payload
            }
        }
        case MOSTRAR_MODAL_SELECCIONAR_DT_TABLA_CARGA_ARCHIVOS: {
            return {
                ...state,
                rex_mostrar_modal_seleccionar_dt_tabla_carga_archivos : action.payload
            }
        }
        case MOSTRAR_MODAL_GUARDANDO_ARCHIVOS_PYTHON_TABLA_CARGA_ARCHIVOS: {
            return {
                ...state,
                rex_mostrar_modal_guardando_archivos_python_carga_archivos : action.payload
            }
        }
        case PETICION_GUARDANDO_PYTHON_CARGA_ARCHIVOS:{
            return {
                ...state,
                rex_peticion_guardando_python_carga_archivos : action.payload
            }
        }
        case PETICION_OBTENIDA_PYTHON_CARGA_ARCHIVOS:{
            return {
                ...state,
                rex_peticion_obtenida_python_carga_archivos : action.payload
            }
        }
        case MOSTRAR_MODAL_TIEMPO_PETICION_TABLA_CARGA_ARCHIVOS:{
            return {
                ...state,
                rex_mostrar_modal_tiempo_peticion_python_carga_archivos : action.payload
            }
        }
        case MOSTRAR_MODAL_NOTIFICACION_TABLA_CARGA_ARCHIVOS:{
            return {
                ...state,
                rex_mostrar_modal_notificacion_tabla_carga_archivos : action.payload
            }
        }
        default:
            return state;
    }
}
