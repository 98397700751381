import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Table, Button, Row, Col, Input, Pagination, Select, Tooltip, Modal } from 'antd'
import '../../../Estilos/Rutas/Administrador/CargaArchivos.css'
import {
	FiltrarTipoCargaAdministradorCargaArchivoReducer,
	ObtenerTiposCargaArchivosAdministradorCargaArchivosReducer,
	MostrarModalAgregarTipoCargaCargaArchivos,
	MostrarModalEditarTipoCargaCargaArchivos,
	EliminarTipoCargaAdministradorCargaArchivosReducer
} from '../../../Redux/Actions/Administrador/CargaArchivos/CargaArchivos'
import AgregarTipoCarga from './AgregarTipoCarga'
import EditarTipoCarga from './EditarTipoCarga'
import { 
	EditOutlined,
	SearchOutlined,
	DeleteOutlined,
	ExclamationCircleOutlined,
	ReloadOutlined,
	PlusCircleOutlined
} from '@ant-design/icons'
import Moment from 'moment'

const TipoCargaArchivos = () => {

	const dispatch = useDispatch();
	const { Option } = Select;
	const { confirm } = Modal;
	
    const [pageTableTipoArchivo, setPageTableTipoArchivo] = useState(1)
    const [pageSizeTableTipoArchivo, setPageSizeTableTipoArchivo] = useState(10)
	const [ txtBuscar, setTxtBuscar ] = useState('')
	const [ selectPai, setSelectPai ] = useState(0)
	const [ selectCan, setSelectCan ] = useState(0)
	const [ selectCue, setSelectCue ] = useState(0)
	const [ selectCca, setSelectCca ] = useState(0)
	const [cargando, setCargando] = useState(false);

	const { 
		rex_modal_editar_tcatipo_administrador,
		rex_modal_agregar_tcatipo_administrador,
		rex_filtro_tipos_carga,
		rex_canales_administrador,
		rex_categorias_unicos
    } = useSelector(({administradorCargaArchivos}) => administradorCargaArchivos);
	
	const {
		rex_paises,
		rex_cuentas
    } = useSelector(({administradorCuentas}) => administradorCuentas);

	useEffect(() => {
		obtenerTiposCargaArchivosAdministrador();
	}, []);

	const obtenerTiposCargaArchivosAdministrador = async () => {
		setCargando(true);
		await dispatch(ObtenerTiposCargaArchivosAdministradorCargaArchivosReducer());
		setCargando(false);
	}

	const buscarTipoCarga = (txt, paiid, cueid, canid, ccanombre) => {
		dispatch(FiltrarTipoCargaAdministradorCargaArchivoReducer(txt, paiid, cueid, canid, ccanombre))
	}

	const limpiarFiltro = () => {
		setTxtBuscar('');
		setSelectPai(0);
		setSelectCan(0);
		setSelectCue(0);
		setSelectCca(0)
	}

	const confirmarEliminarTipoCarga = ( {tcaid , tcanombre} ) => {
        confirm({
            title: `¿Eliminar tipo de carga ${tcanombre}?`,
            icon: <ExclamationCircleOutlined />,
            okText: 'Eliminar',
            content: <div>Se eliminará toda la información del tipo de carga incluyendo:<br/><br/>
			- Registro con algún canal<br/>
			- Registro con alguna cuenta<br/>
			- Registro con alguna categoria<br/>
			- Registro con algún archivo<br/>
		</div>,
        
            async onOk() {
                
                let respuesta = await dispatch(EliminarTipoCargaAdministradorCargaArchivosReducer(tcaid));
				respuesta && limpiarFiltro();
            },
        
            onCancel() {},
        });
    }

	const columns = [
		{
			title: 'Item',
			key: 'cacid',
			align: 'center',
			width:'80px',
			render: (_, record, index) => (pageTableTipoArchivo - 1) * pageSizeTableTipoArchivo + index + 1,
		},
		{
			title: 'Estado',
			key: 'tcaestado',
			align: 'center',
			width:'80px',
			render: (_, record) => (

				<div style={{display:'flex', justifyContent:'center'}}>
					<Tooltip placement="top" title={record.tcaestado ? 'Activado' : 'Desactivado'}>
						<div className={`Circulo ${record.tcaestado? 'Circulo-Activo': 'Circulo-Desactivo'}`}>
						</div>
					</Tooltip>
				</div>
			)
		},
		{
			title: 'Empresa',
			dataIndex: 'cuenombre',
			key: 'cuenombre',
			align: 'center',
			width:'200px'
		},
		{
			title: 'Pais',
			key: 'paiicono',
			align: 'center',
			width:'100px',
			render:(_,record) => (
				
				<Tooltip title={record.painombre} placement="right">
					<img
						style={{width:'30px',height:'30px'}}
						src={record.paiicono}
					/>
				</Tooltip>
			)
		},
		{
			title: 'Canal',
			dataIndex: 'cannombre',
			key: 'cannombre',
			align: 'center',
			width:'200px'
		},
		{
			title: 'Categoria',
			dataIndex: 'ccanombre',
			key: 'ccanombre',
			align: 'center',
			width:'200px'
		},
		{
			title: 'Tipo carga',
			dataIndex: 'tcanombre',
			key: 'tcanombre',
			align: 'center',
			width:'200px'
		},
		{
			title: 'Fecha de creación',
			key: 'created_at',
			align: 'center',
			width:'250px',
			render: (_, record) => (
				<>{record.created_at?Moment(record.created_at).format('LLL'):'Sin registro'}</>
			),
		},
		{
			title: 'Fecha de actualización',
			key: 'updated_at',
			align: 'center',
			width:'250px',
			render: (_, record) => (
				<>{record.updated_at?Moment(record.updated_at).format('LLL'):'Sin registro'}</>
			),
		},
		{
			title: 'Acción',
			key: 'editar',
			align: 'center',
			width: '100px',
			fixed:'right',
			render: (_,record ) => (
				<div className='Contenedor-Acciones-Iconos'>
					<Tooltip placement="top" title='Editar'>
						<Button
							onClick={()=> dispatch(MostrarModalEditarTipoCargaCargaArchivos(true, record))}
							className='Boton-Accion-Editar'
						>
							<EditOutlined
								className='Icono-Accion-Editar'
							/>
						</Button>
					</Tooltip>
					<Tooltip placement='top' title='Eliminar'>
						<Button
							className='Boton-Accion-Eliminar'
							onClick={()=> confirmarEliminarTipoCarga(record)}
						>
							<DeleteOutlined
								className='Icono-Accion-Eliminar'
							/>
						</Button>
					</Tooltip>
				</div>
			)
		},
	];

	return (
		<div className='Contenedor-Carga-Archivos-Tabla'>

			<Row className='Fila-Buscador-Boton-Carga-Archivos'>
				<Col
					xl={8} lg={12} md={24} sm={24} xs={24}
				>
					<Row>
						<Col span={20}>
							<Input
								suffix={<SearchOutlined className='Icono-Input-Buscador' />}
								size='large'
								className='Input-Buscador-Carga-Archivos'
								placeholder='Buscar'
								value={txtBuscar}
								onChange={(e) => {
									setTxtBuscar(e.target.value)
									buscarTipoCarga(e.target.value, selectPai, selectCue, selectCan)
								}}
							/>
						</Col>
						<Col style={{display:'flex',alignItems:'center'}} span={4}>
							<Tooltip title='Actualizar'>
								<div
									className='Boton-Actualizar'
									onClick={async ()=> {
										setCargando(true);
										await dispatch(ObtenerTiposCargaArchivosAdministradorCargaArchivosReducer())
										setCargando(false);
									}}
								>
									<ReloadOutlined
										className='Icono-Actualizar'
									/>
								</div>
							</Tooltip>
						</Col>
					</Row>
				</Col>
				<Row>
					<Col>
						<Button
							onClick={() => dispatch(MostrarModalAgregarTipoCargaCargaArchivos(true))}
							className='Boton-Crear-Carga-Archivos'
						>Crear<PlusCircleOutlined/></Button>
					</Col>
				</Row>
			</Row>
			<Row className='Contenedor-Filtro-Carga-Archivos'>
				<Row>
					<Col>
						<Select
							value={selectCue}
							className='Select-Filtro-Carga-Archivos'
							onChange={ (valor) => {
								setSelectCue(valor)
								buscarTipoCarga(txtBuscar, selectPai, valor, selectCan, selectCca)
							}}
						>
							<Option value={0}>Seleccionar Empresa</Option>
							{
								rex_cuentas.map((cue)=> (
									<Option key={cue.cueid} value={cue.cueid}>{cue.cuenombre}</Option>
								))
							}
						</Select>
					</Col>
					<Col>
						<Select
							value={selectPai}
							className='Select-Filtro-Carga-Archivos'
							onChange={ (valor) => {
								setSelectPai(valor)
								buscarTipoCarga(txtBuscar, valor, selectCue, selectCan, selectCca)
							}}
						>
							<Option value={0}>Seleccionar Pais</Option>
							{
								rex_paises.map((pai) => (
									<Option key={pai.paiid} value={pai.paiid}>{pai.painombre}</Option>
								))
							}
						</Select>
					</Col>
					<Col>
						<Select
							value={selectCan}
							className='Select-Filtro-Carga-Archivos'
							onChange={ (valor) => {
								setSelectCan(valor)
								buscarTipoCarga(txtBuscar, selectPai, selectCue, valor, selectCca)
							}}
						>
							<Option value={0}>Seleccionar Canal</Option>
							{
								rex_canales_administrador.map((can)=> (
									<Option key={can.canid} value={can.canid}>{can.cannombre}</Option>
								))
							}
						</Select>
					</Col>
					<Col>
						<Select
							value={selectCca}
							className='Select-Filtro-Carga-Archivos'
							onChange={ (valor) => {
								setSelectCca(valor)
								buscarTipoCarga(txtBuscar, selectPai, selectCue, selectCan, valor)
							}}
						>
							<Option value={0}>Seleccionar Categoria</Option>
							{
								rex_categorias_unicos.map((cca)=> (
									<Option key={cca.ccanombre} value={cca.ccanombre}>{cca.ccanombre}</Option>
								))
							}
						</Select>
					</Col>
				</Row>
				<Row>
					<Col
						style={{display:'flex', justifyContent:'flex-end', margin:'5px 0'}}
					>
						<Pagination
							defaultCurrent={pageTableTipoArchivo}
							current={pageTableTipoArchivo}
							total={rex_filtro_tipos_carga.length}
							onChange={(page, pageSize) => {
								setPageTableTipoArchivo(page == 0? 1 : page)
								setPageSizeTableTipoArchivo(pageSize)
							}}
						/>
					</Col>
				</Row>
			</Row>

			<Table
				dataSource={rex_filtro_tipos_carga} 
				columns={columns}
				loading={cargando}
				pagination={{
					defaultCurrent: pageTableTipoArchivo,
					current: pageTableTipoArchivo,
					pageSize: pageSizeTableTipoArchivo,
					position: ['topRight'],
				}}
				scroll={{
					x:1600
				}}
				size='small'
			/>

			{ rex_modal_editar_tcatipo_administrador
				&& <EditarTipoCarga
					limpiarFiltro = {limpiarFiltro}
				/>}
			{ rex_modal_agregar_tcatipo_administrador
				&& <AgregarTipoCarga
					limpiarFiltro={limpiarFiltro}
				/>}
		</div>
	)

}

export default TipoCargaArchivos