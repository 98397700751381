import { Modal, Form, Row, Col, Input, Upload, Button, Select } from 'antd';
import React,{ useState} from 'react';
import { 
    AgregarCuentasAdministradorCuentasReducer,
    MostrarModalAgregarCuentaReducer
} from '../../../Redux/Actions/Administrador/Cuentas/Cuentas';
import { useSelector, useDispatch } from 'react-redux';
import { alertaNotificacion } from '../../../Funciones/Notificaciones/Notificaciones';
import '../../../Estilos/Rutas/Administrador/Cuentas.css'
import config from '../../../config'

const AgregarCuenta = () => {

    const { Option } = Select;
    const dispatch = useDispatch();

    const {
        rex_modal_agregar_cuenta,
        rex_paises,
    } = useSelector(({administradorCuentas}) => administradorCuentas);

    const [ cuentan, setCuentan ] = useState({});
    const [ enviando, setEnviando ] = useState(false);

    const agregarCuenta = async (cuentan) => {
        setEnviando(true);
        await dispatch(AgregarCuentasAdministradorCuentasReducer(cuentan));
        setEnviando(false);
    }

    return (
        <Modal
            centered={true}
            open={rex_modal_agregar_cuenta}
            onCancel = {() => dispatch(MostrarModalAgregarCuentaReducer(false))}
            onOk = {() => agregarCuenta(cuentan)}
            confirmLoading={enviando}
            okText='Guardar'
            width={700}
            alignItems='center'
            className='ant-modal-content'
            getContainer="#root"
        >
            <h2 className='Text-Center-Agregar-Cuenta'>Agregar Cuenta</h2>
            <Form
                layout='vertical'
                autoComplete='off'
            >
                <Row>

                    <Col
                        xs={{span:24}} sm={{span:12}} md={{span:12}} lg={{span:12}} xl={{span:12}}
                    >
                        <Form.Item
                            label='Nombre:'
                            rules={[
                                { required: true, message: 'El nombre es requerido' },
                                { min: 4, message: 'El nombre es muy corto' }
                            ]}
                            className='Input-Usuario'
                            name='nombrecuenta'
                        >
                            <Input
                                name='nombrecuenta'
                                onChange={(e) => setCuentan({...cuentan, nombrecuenta: e.target.value})}
                                className='Input-Editar'
                                size='large'
                            />

                        </Form.Item>
                        
                    </Col>

                    <Col
                        xs={{span:24}} sm={{span:12}} md={{span:12}} lg={{span:12}} xl={{span:12}}
                    >

                        <Form.Item
                            className='Input-Usuario'
                            label='Paises:'
                        >
                            <Select
                                mode="multiple"
                                allowClear
                                className='Input-Editar'
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Agregar paises"
                                onChange={(valor) => setCuentan({...cuentan, paises:valor})}
                                size='large'
                            >
                                {rex_paises.map((pai)=> (
                                <Option
                                    key={pai.paiid}
                                    value={pai.paiid}
                                >{pai.painombre}</Option>
                            ))}
                            </Select>
                        </Form.Item>

                    </Col>
                </Row>

                <Row style={{display:'flex',justifyContent:'center'}}>
                    <Col xs={{span:24}} sm={{span:8}} md={{span:8}} lg={{span:8}} xl={{span:8}}>
                        <Form.Item
                            label='Imagen:'
                            className='Input-Usuario'
                        >
                            <Upload.Dragger
                                maxCount={1}
                                listType='picture'
                                action={config.api}
                                beforeUpload={ (file) =>{

                                    let reader = new FileReader();
                                    reader.readAsDataURL(file);

                                    const imagenValida = file.type === 'image/png' || file.type === 'image/jpeg';

                                    if(!imagenValida) {
    
                                        alertaNotificacion('Solo formato PNG o JPG')

                                        return Upload.LIST_IGNORE;
                                    }else{

                                        reader.onload = function(){
                                            let base64 = reader.result;
                                            setCuentan({
                                                ...cuentan,cueimagen:base64
                                            });
                                        }
                                        return false;
                                    }
                                }}
                            >
                                <Button>Cargar Imagen</Button>
                            </Upload.Dragger>

                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        </Modal>
    )
}

export default AgregarCuenta