import React, {useState} from 'react'
import Seleccionarnegocio from '../../Componentes/SeleccionarNegocio/Seleccionarnegocio'
import { Select } from 'antd'
import {useSelector, useDispatch} from "react-redux";
import {
    ArrowLeftOutlined,
} from '@ant-design/icons';
import {
    ReiniciarVariablesEstadosSeleccionarNegocioReducer
} from '../../Redux/Actions/Componentes/SeleccionarNegocio'

const StatusDtKc = () => {

    const dispatch = useDispatch()

    let paises = ["Perú", "Bolivia", "Chile"]
    let cuentas = ["Kimberly"]

    let powerbi = [
        {
            pais: "Perú",
            cuenta: "Kimberly",
            powerbi: "https://app.powerbi.com/view?r=eyJrIjoiNjI0YzhlZmYtYWQ1ZS00ODkyLTgzMjQtZTBhMWFlOWFhZmI0IiwidCI6IjFkZjQ2ODhjLWI5MTUtNDEwMy05OGMwLTNhMzY4ZmIyOTNlOCJ9&pageName=ReportSectione7e7ae29f5d49539011f",
            canal : ""
        },
        {
            pais: "Bolivia",
            cuenta: "Kimberly",
            powerbi: "https://app.powerbi.com/view?r=eyJrIjoiNWY0ODdhMmEtZWM3NS00M2Q1LWE5MmEtNTAyMGFkZTgyMmYzIiwidCI6IjFkZjQ2ODhjLWI5MTUtNDEwMy05OGMwLTNhMzY4ZmIyOTNlOCJ9",
            canal : ""
        },

        {
            pais: "Chile",
            cuenta: "Kimberly",
            powerbi: "https://app.powerbi.com/view?r=eyJrIjoiNDY4OTJkZmQtMDI1Yy00Y2E4LTlhNDQtMDM1YjVmMDJjYTc2IiwidCI6IjFkZjQ2ODhjLWI5MTUtNDEwMy05OGMwLTNhMzY4ZmIyOTNlOCJ9",
            canal : ""
        }
    ]
    
    const [paisSeleccionado, setPaisSeleccionado] = useState("Perú")
    const [cuentaSeleccionado, setCuentaSeleccionado] = useState("Kimberly")

    return (
        <div>

            {
                <Seleccionarnegocio 
                    mostrarCuentasStaticas = {true}
                    cuentasStaticas = {cuentas}
                    setCuentasStaticas = {(e) => setCuentaSeleccionado(e)}


                />
            }

            <div
                style={{paddingTop:'76px'}}
                className="Componente-Principal-Carga-Archivo"
            >
                <div className='Contenedor-Titulo-Carga-Archivo W700-S26-H35-C000000'>
                    <div 
                        style={{
                            marginRight:'15px', fontSize:'20px', cursor:'pointer',
                        }}
                        onClick={() => {
                            dispatch(ReiniciarVariablesEstadosSeleccionarNegocioReducer())
                        }}
                    >
                        <ArrowLeftOutlined />
                    </div>
                    Status DT KC
                </div>
            </div>




            <div
                id="Contenedor-Filtro-Carga-Archivo"
            >

                <Select 
                    style={{ width: 120 }} 
                    onChange={(e, r) => {
                        setPaisSeleccionado(e)
                    }}
                    value={paisSeleccionado}
                >
                    {
                        paises.map((pais, pos) => {
                            return (
                                <Select.Option 
                                    value={pais}
                                    key={pos}
                                >
                                    {pais}
                                </Select.Option>
                            )
                        })
                    }
                </Select>
                <div style={{marginRight:'30px'}}></div>
                
            </div>

            <div style={{marginTop:'20px'}}>
            
            {
                powerbi.map((data) => {
                    return (
                        cuentaSeleccionado == data.cuenta && data.pais == paisSeleccionado
                        ?<iframe 
                            src={data.powerbi} 
                            title="GODA Dashboards"
                            width="100%" height="1000px" 
                            style={{border:'none'}}
                        >
            
                        </iframe>
                        :null
                    )
                })
            }

            </div>




        </div>
    )
}

export default StatusDtKc