import { LoadingOutlined } from '@ant-design/icons';

export const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
);
export const antIconWhite = (
    <LoadingOutlined
      style={{
        fontSize: 20,
        color: 'white'
      }}
      spin
    />
);
