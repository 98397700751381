import { Modal, Row, Col, Button } from 'antd'
import React from 'react'
import {useSelector, useDispatch} from "react-redux";
import '../../Estilos/Rutas/Administrador/CargaArchivos.css'
import {
    MOSTRAR_MODAL_TIEMPO_PETICION_TABLA_CARGA_ARCHIVOS
} from '../../Constantes/CargaArchivos/CargaArchivos'

const ModalTiempoPeticionArchivosPython = () => {

    const dispatch = useDispatch()

    let { 
        rex_mostrar_modal_tiempo_peticion_python_carga_archivos
    } = useSelector(({tablaCargaArchivos}) => tablaCargaArchivos);

    const ocultarModal = () => {
        dispatch({
            type: MOSTRAR_MODAL_TIEMPO_PETICION_TABLA_CARGA_ARCHIVOS,
            payload: false
        })
    }

    return (
        <Modal
            title="Carga de archivos"
            cancelText={null}
            footer={null}
            centered={true}
            open={rex_mostrar_modal_tiempo_peticion_python_carga_archivos}
            className='Modal-Cargando-Archivos-Python'
        >
            <p>La petición ha tomado mucho tiempo</p>
            <Row className='Contenedor-Fila-Canales'>
                <Col>
                    <Button
                        className='Boton-Cargando-Archivos-Python'
                        type="primary"
                        onClick={() => ocultarModal()}
                    >
                        Aceptar
                    </Button>
                </Col>
            </Row>
        </Modal>
    )
}

export default ModalTiempoPeticionArchivosPython