import React, {useEffect, useState} from 'react'
import Seleccionarnegocio from '../../Componentes/SeleccionarNegocio/Seleccionarnegocio';
import {useSelector, useDispatch} from "react-redux";
import '../../Estilos/Rutas/CargaArchivos/CargaArchivos.css'
import { Table, Select } from 'antd';
import {
    SeleccionarPaisNegocioComplementosReducer
} from '../../Redux/Actions/Complementos/Complementos'
import {
    ObtenerCargaArchivosReducer
} from '../../Redux/Actions/CargaArchivos/CargaArchivos'
import {
    CargandoBtnEnviarArchivoReducer,
    EnviarArchivoCargadoTablaCargaArchivoReducer,
    SeleccionarDtFilaTablaCargaArchivoReducer,
    EnviarVariosArchivosTablaCargaArchivoReducer,
} from '../../Redux/Actions/CargaArchivos/TablaCargaArchivos'
import {
    SeleccionarDtCargaArchivoReducer
} from '../../Redux/Actions/CargaArchivos/ModalDtsCargaArchivos'

import ModalConfirmacionEnvio from '../../Componentes/CargaArchivos/ModalConfirmacionEnvio';
import ModalDts from '../../Componentes/CargaArchivos/ModalDts';
import ModalGuardandoArchivosPython from '../../Componentes/CargaArchivos/ModalGuardandoArchivosPython';
import ModalTiempoPeticionArchivosPython from '../../Componentes/CargaArchivos/ModalTiempoPeticionArchivosPython';
import ModalNotificacion from '../../Componentes/CargaArchivos/ModalNotificacion';
import {
    ReiniciarVariablesEstadosSeleccionarNegocioReducer,
    OcultarModuloSeleccionarNegocioReducer
} from '../../Redux/Actions';
import { 
	ArrowLeftOutlined,
} from '@ant-design/icons'
import ModalRangoTiempoInvalido from '../../Componentes/CargaArchivos/ModalRangoTiempoInvalido';

const CargaArchivos = () => {
    
    const dispatch = useDispatch()

    const { 
        rex_data_paises_complementos,
        rex_seleccionar_pais_complementos
    } = useSelector(({complementos}) => complementos);

    let { 
        rex_columnas_tabla_carga_archivos,
        rex_data_tabla_carga_archivos,
        rex_fila_data_seleccionado_tabla_carga_archivos,
        rex_mostrar_modal_confirmacion_tabla_carga_archivos,
        rex_mostrar_modal_seleccionar_dt_tabla_carga_archivos,
        rex_mostrar_modal_guardando_archivos_python_carga_archivos,
        rex_mostrar_modal_tiempo_peticion_python_carga_archivos,
        rex_mostrar_modal_notificacion_tabla_carga_archivos
    } = useSelector(({tablaCargaArchivos}) => tablaCargaArchivos);

    const { 
        rex_ocultar_modulo_seleccionar_negocio
    } = useSelector(({seleccionarNegocio}) => seleccionarNegocio);


    const { 
        rex_data_modal_dts_carga_archivos
    } = useSelector(({modalDtsCargaArchivos}) => modalDtsCargaArchivos);

    const [cargandoEnviandoArchivo, setCargandoEnviandoArchivo] = useState(false)
      

    useEffect(() => {
        if(rex_data_paises_complementos.length > 0){
            dispatch(
                SeleccionarPaisNegocioComplementosReducer(0, rex_data_paises_complementos[0])
            )
        }

    }, [rex_data_paises_complementos])

    useEffect(() => {

        if(rex_seleccionar_pais_complementos.paiid){
            dispatch(ObtenerCargaArchivosReducer())
        }

    }, [rex_seleccionar_pais_complementos])

    useEffect(() => {
        dispatch(ReiniciarVariablesEstadosSeleccionarNegocioReducer())
    },[])

    return (
        <div
        >
            {
                <Seleccionarnegocio 
            
                />
            }

            <div 
                style={{paddingTop:'76px'}}
                className="Componente-Principal-Carga-Archivo"
            >
                <div className='Contenedor-Titulo-Carga-Archivo W700-S26-H35-C000000'>
                    <div 
                        style={{
                            marginRight:'15px', fontSize:'20px', cursor:'pointer',
                        }}
                        onClick={() => {
                            dispatch(OcultarModuloSeleccionarNegocioReducer(false))
                            dispatch(ReiniciarVariablesEstadosSeleccionarNegocioReducer())
                        }}
                    >
                        <ArrowLeftOutlined />
                    </div>
                    Carga de Archivos
                </div>
                <div
                    id="Contenedor-Filtro-Carga-Archivo"
                >
                    <Select 
                        style={{ width: 120 }} 
                        onChange={(e, r) => {
                            dispatch(SeleccionarPaisNegocioComplementosReducer(e))
                        }}
                        value={rex_seleccionar_pais_complementos.paiid}
                    >
                        {
                            rex_data_paises_complementos.map((pais) => {
                                return (
                                    <Select.Option 
                                        value={pais.paiid}
                                        key={pais.paiid}
                                    >
                                        {pais.painombre}
                                    </Select.Option>
                                )
                            })
                        }
                    </Select>
                </div>

                <div
                    style={{paddingLeft: "40px", paddingRight:"40px", paddingTop:"20px"}}
                >
                    <div
                        className='Contenedor-Tabla-Carga-Archivos'
                    >
                        <Table
                            columns={[...rex_columnas_tabla_carga_archivos]}
                            dataSource={[...rex_data_tabla_carga_archivos]}
                            rowClassName={() => 'editable-row'}
                            // loading={true}
                            pagination={{ position: ['none', 'none'], pageSize: 200 }}
                            scroll={{ y: 505 }}
                        />

                        <ModalRangoTiempoInvalido/>
                    </div>
                </div>
            </div>

            {
                rex_mostrar_modal_confirmacion_tabla_carga_archivos == false 
                ? null
                : <ModalConfirmacionEnvio 
                    rex_mostrar_modal_confirmacion_tabla_carga_archivos = {rex_mostrar_modal_confirmacion_tabla_carga_archivos}
                    rex_fila_data_seleccionado_tabla_carga_archivos = {rex_fila_data_seleccionado_tabla_carga_archivos}
                    cargandoEnviandoArchivo = {cargandoEnviandoArchivo}
                    setCargandoEnviandoArchivo = {(estado) => setCargandoEnviandoArchivo(estado)}
                    CargandoBtnEnviarArchivoReducer = {(fila, estado) => dispatch(CargandoBtnEnviarArchivoReducer(fila, estado))}
                    EnviarArchivoCargadoTablaCargaArchivoReducer = {(fila, comentario) => dispatch(EnviarArchivoCargadoTablaCargaArchivoReducer(fila, comentario))}
                    EnviarVariosArchivosTablaCargaArchivoReducer = {(fila, comentario) => dispatch(EnviarVariosArchivosTablaCargaArchivoReducer(fila, comentario))}
                />
            }

            {
                rex_mostrar_modal_seleccionar_dt_tabla_carga_archivos == true
                ?<ModalDts
                    rex_fila_data_seleccionado_tabla_carga_archivos = {rex_fila_data_seleccionado_tabla_carga_archivos}
                    rex_mostrar_modal_seleccionar_dt_tabla_carga_archivos = {rex_mostrar_modal_seleccionar_dt_tabla_carga_archivos}
                    SeleccionarDtFilaTablaCargaArchivoReducer = {(estado, dtseleccionado) => dispatch(SeleccionarDtFilaTablaCargaArchivoReducer(estado, null, dtseleccionado)) }
                    rex_data_modal_dts_carga_archivos = {rex_data_modal_dts_carga_archivos}
                    SeleccionarDtCargaArchivoReducer = {(pos) => {
                        dispatch(SeleccionarDtCargaArchivoReducer(pos))
                    }}
                />
                :null
            }

            {
                rex_mostrar_modal_guardando_archivos_python_carga_archivos && <ModalGuardandoArchivosPython/>
            }
            {
                rex_mostrar_modal_tiempo_peticion_python_carga_archivos && <ModalTiempoPeticionArchivosPython/>
            }
            {
                rex_mostrar_modal_notificacion_tabla_carga_archivos && <ModalNotificacion/>
            }

        </div>
    )
}

export default CargaArchivos