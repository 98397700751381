import config from '../../../config'
import { estadoRequestReducer } from "../Comunes/EstadoRequest"
import {
    OBTENER_DATA_TABLA_STATUS,
    CAMBIAR_FECHA_STATUS
} from '../../../Constantes/Status/Status'

export const ObtenerStatusReducer = () => async (dispatch, getState) => {
    
    const {
        rex_anio_seleccionado_status,
        rex_mes_numero_seleccionado_status
    } = getState().status
    const rex_seleccionar_pais_complementos = getState().complementos.rex_seleccionar_pais_complementos
    const rex_seleccionar_canal_complementos = getState().complementos.rex_seleccionar_canal_complementos

    await fetch(config.api+'status/obtener',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                "req_anio"  : rex_anio_seleccionado_status,
                "req_mes"   : rex_mes_numero_seleccionado_status,
                "req_pacid" : rex_seleccionar_pais_complementos,
                "req_canid" : rex_seleccionar_canal_complementos
            }),
        }
    )
    .then( async res => {
            await dispatch(estadoRequestReducer(res.status))
            return res.json()
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request
        
        if(estadoRequest == true){
            if(data.respuesta == true){
                
                dispatch({
                    type: OBTENER_DATA_TABLA_STATUS,
                    payload: data.datos
                })

            }
        }else{
            dispatch({
                type: OBTENER_DATA_TABLA_STATUS,
                payload: []
            })
        }

    }).catch((error)=> {
        console.log(error)
        // dispatch(RegistrarErrorReducer(error, config.api+'login', datos))
    });

}

export const CambiarFechaStatusReducer = (anio, mes) => async (dispatch, getState) => {

    dispatch({
        type: CAMBIAR_FECHA_STATUS,
        payload: {
            "anio": anio,
            "mes" : mes
        }
    })

    dispatch(ObtenerStatusReducer())
}