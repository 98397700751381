import React, {useState} from 'react'
import { Button, Modal } from 'antd';
import { useDispatch } from 'react-redux';
import {
    ModalGuardandoArchivosPythonTablaCargaArchivoReducer,
    ModalNotificacionPython
} from '../../Redux/Actions/CargaArchivos/TablaCargaArchivos';

const ModalConfirmacionEnvio = (props) => {

    const rex_mostrar_modal_confirmacion_tabla_carga_archivos = props.rex_mostrar_modal_confirmacion_tabla_carga_archivos
    const rex_fila_data_seleccionado_tabla_carga_archivos = props.rex_fila_data_seleccionado_tabla_carga_archivos
    const cargandoEnviandoArchivo = props.cargandoEnviandoArchivo
    const setCargandoEnviandoArchivo = props.setCargandoEnviandoArchivo
    const CargandoBtnEnviarArchivoReducer = props.CargandoBtnEnviarArchivoReducer
    const EnviarArchivoCargadoTablaCargaArchivoReducer = props.EnviarArchivoCargadoTablaCargaArchivoReducer
    const EnviarVariosArchivosTablaCargaArchivoReducer = props.EnviarVariosArchivosTablaCargaArchivoReducer

    const [txtcomentario, setTxtcomentario] = useState("")

    const dispatch = useDispatch()

    return (
        <>
            <Modal
                visible={rex_mostrar_modal_confirmacion_tabla_carga_archivos}
                footer={null}
                title={null}
                closeIcon={<div></div>}
                centered={true}
                className="Modal-Confirmacion-Carga-Archivo"
                width={"310px"}
            >
                <div
                    style={{
                        textAlign: "-webkit-center",
                        borderRadius: "8px"
                    }}
                >
                    <div className='W600-S12-H16-C1E1E1E' style={{marginBottom:'5px'}}>
                        ¿Está seguro que desea Enviar y Confirmar?
                    </div>

                    <div className='W400-S12-H16-C1E1E1E'>
                        Recuerde que una vez confirmado esto se enviará al servidor 
                    </div><br/>

                    <div className='W400-S12-H16-C1E1E1E'>
                        Agrega un comentario adicional (opcional)
                    </div>
                    <div>

                        <textarea 
                            style={{ width: "100%"}}
                            onChange={(e) => {
                                setTxtcomentario(e.target.value)
                            }}
                        >

                        </textarea>
                    </div>

                    <div
                        style={{
                            display:'flex',
                            justifyContent: "center",
                            marginTop:'20px'
                        }}
                    >
                        <Button
                            type='primary'
                            style={{
                                marginRight:'22px'
                            }}
                            className="W600-S12-H16-CFFFFFF-l0015"
                            onClick={async () => {
                                setCargandoEnviandoArchivo(true)
                                // await dispatch(EnviarArchivoCargadoTablaCargaArchivoReducer(rex_fila_data_seleccionado_tabla_carga_archivos))

                                if(rex_fila_data_seleccionado_tabla_carga_archivos.agregarnuevasfilas == true){
                                    await EnviarVariosArchivosTablaCargaArchivoReducer(rex_fila_data_seleccionado_tabla_carga_archivos, txtcomentario)
                                }else{
                                    let respuesta;
                                    dispatch(ModalGuardandoArchivosPythonTablaCargaArchivoReducer(true))
                                    respuesta = await EnviarArchivoCargadoTablaCargaArchivoReducer(rex_fila_data_seleccionado_tabla_carga_archivos, txtcomentario)
                                    dispatch(ModalGuardandoArchivosPythonTablaCargaArchivoReducer(false))
                                    respuesta && dispatch(ModalNotificacionPython(true))
                                }
                                setCargandoEnviandoArchivo(false)
                                CargandoBtnEnviarArchivoReducer(rex_fila_data_seleccionado_tabla_carga_archivos, false)
                            }}
                            loading={cargandoEnviandoArchivo}
                        >
                            Confirmar
                        </Button>
                        <Button
                            type='secondary'
                            className='Btn-Desactivado-Carga-Archivo W600-S12-H16-CFFFFFF-l0015'
                            onClick={() => {
                                // dispatch(CargandoBtnEnviarArchivoReducer(rex_fila_data_seleccionado_tabla_carga_archivos, false))
                                CargandoBtnEnviarArchivoReducer(rex_fila_data_seleccionado_tabla_carga_archivos, false)
                            }}
                        >
                            Cancelar
                        </Button>
                    </div>
                </div>

            </Modal>
        </>
    )
}

export default ModalConfirmacionEnvio