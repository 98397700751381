
import config from "../../../../config";
import { estadoRequestReducer } from "../../Comunes/EstadoRequest";
import {
    MODAL_AGREGAR_DISTRIBUIDORAS_DTS_ADMINISTRADOR,
    MODAL_EDITAR_DISTRIBUIDORAS_DTS_ADMINISTRADOR,
    OBTENER_DISTRIBUIDORAS_DTS_ADMINISTRADOR,
} from "../../../../Constantes/DtsDistribuidoras/DtsDistribuidoras";
import { 
    exitoNotificacion,
    errorNotificacion,
    alertaNotificacion
} from "../../../../Funciones/Notificaciones/Notificaciones";
import { DescargaDataExcel } from "../../../../Funciones/componentes/DescargaExcel/DescargaData";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

export const ObtenerDtsDistribuidorasAdministradorDistribuidorasReducer = (txtBuscar, selectPai, selectCue, selectCan, page, perpage) => async (dispatch, getState) => {

    await fetch(config.api+'complementos/obtener-distribuidora?pag='+ page,
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
            body: JSON.stringify(
                {
                    'perpage'   : perpage,
                    'txtBuscar' : txtBuscar,
                    'selectPai' : selectPai,
                    'selectCue' : selectCue,
                    'selectCan' : selectCan
                }
            )
        }
    )
    .then( async res => {
            await dispatch(estadoRequestReducer(res.status))
            return res.json()
    })
    .then(data => {

        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){

            dispatch({
                type    : OBTENER_DISTRIBUIDORAS_DTS_ADMINISTRADOR,
                payload : data.data
            })

        }else{
            errorNotificacion('Ha ocurrido un error');
        }

    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}

export const EditarDtsDistribuidorasAdministradorDistribuidorasReducer = ( dtse ) => async (dispatch, getState) => {

    await fetch(config.api+'complementos/editar-distribuidora',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
            body: JSON.stringify(dtse)
        }
    )
    .then( async res => {
            await dispatch(estadoRequestReducer(res.status))
            return res.json()
    })
    .then(data => {

        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){

            exitoNotificacion(data.mensaje)
            dispatch(ObtenerDtsDistribuidorasAdministradorDistribuidorasReducer())
            dispatch(ModalEditarDtsDistribuidoraAdministradorDistribuidorasReducer(false, {}))
            
        }else{
            if(data.errores){
                data.errores.map((err) => alertaNotificacion(err));
            }else{
                alertaNotificacion(data.mensaje);
            }
        }

    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}

export const AgregarDtsDistribuidorasAdministradorDistribuidorasReducer = ( dtsn ) => async ( dispatch, getState) => {

    await fetch(config.api+'complementos/crear-distribuidora',
        {   
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                "req_distribuidora" :dtsn.dtsclientesubhml,
                "req_canid"         :dtsn.canid,
                "req_cueid"         :dtsn.cueid,
                "req_paiid"         :dtsn.paiid,
                "req_dtsextractor"  :dtsn.dtsextractor,
            })
        }
    )
    .then( async res => {
            await dispatch(estadoRequestReducer(res.status))
            return res.json()
    })
    .then(data => {

        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){

            exitoNotificacion(data.mensaje)
            dispatch(ObtenerDtsDistribuidorasAdministradorDistribuidorasReducer())
            dispatch(ModalAgregarDtsDistribuidoraAdministradorDistribuidorasReducer(false))
            
        }else{
            if(data.errores){
                data.errores.map(err=> alertaNotificacion(err));
            }else{
                alertaNotificacion(data.mensaje);
            }
        }

    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });
}

export const EliminarDtsDistribuidorasAdministradorDistribuidorasReducer = ( dtsid ) => async (dispatch, getState) => {
    
    await fetch(config.api+'complementos/eliminar-distribuidora',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({dtsid})
        }
    )
    .then( async res => {
            await dispatch(estadoRequestReducer(res.status))
            return res.json()
    })
    .then(data => {

        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){

            exitoNotificacion(data.mensaje)
            dispatch(ObtenerDtsDistribuidorasAdministradorDistribuidorasReducer())
            
        }else{
            errorNotificacion(data.mensaje);
        }

    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });

}

export const DescargarDataDtsAdministradorDistribuidorasReducer = (txtBuscar, selectPai, selectCue, selectCan) => async (dispatch, getState) => {

    const { fileType, estilosHeader, clave } = DescargaDataExcel();

    await fetch(config.api+'complementos/descargar-data-distribuidora',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                req_txtBuscar   : txtBuscar,
                req_paiid       : selectPai,
                req_cueid       : selectCue,
                req_canid       : selectCan 
            })
        }
    )
    .then( async res => {
            await dispatch(estadoRequestReducer(res.status))
            return res.json()
    })
    .then(data => {

        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){

            const ws = XLSX.utils.json_to_sheet(data.dts);

            ws['!cols'] = [];

            ws['!cols'] = [
                {'width' : 20},
                {'width' : 30},
                {'width' : 30},
                {'width' : 25},
                {'width' : 20},
                {'width' : 20},
            ];

            ws["A1"].s = estilosHeader;
            ws["B1"].s = estilosHeader;
            ws["C1"].s = estilosHeader;
            ws["D1"].s = estilosHeader;
            ws["E1"].s = estilosHeader;
            ws["F1"].s = estilosHeader;

            data.dts.map((data, index) => {
                ws["A" + (index+2) ].s = { alignment : { horizontal: 'center'} };
                ws["B" + (index+2) ].s = { alignment : { horizontal: 'center'} };
                ws["C" + (index+2) ].s = { alignment : { horizontal: 'center'} };
                ws["D" + (index+2) ].s = { alignment : { horizontal: 'center'} };
                ws["E" + (index+2) ].s = { alignment : { horizontal: 'center'} };
                ws["F" + (index+2) ].s = { alignment : { horizontal: 'center'} };
            });
    
            const wb = { Sheets: { 'Distribuidoras':ws }, SheetNames: ['Distribuidoras'] };
            const excelBuffer = XLSX.write(wb, { bookType:'xlsx', type:'array'});
            const dataExcel = new Blob([excelBuffer], { type: fileType});
            FileSaver.saveAs(dataExcel, 'Distribuidoras-' + clave + '.xlsx')
            
        }else{
            if(data.errores){
                data.errores.map((err) => alertaNotificacion(err));
            }else{
                alertaNotificacion(data.mensaje);
            }
        }

    }).catch((error)=> {
        alertaNotificacion('Ha ocurrido un error en el servidor');
    });

}

export const ModalEditarDtsDistribuidoraAdministradorDistribuidorasReducer = ( state, record ) => async (dispatch, getState) => {
    dispatch({
        type    : MODAL_EDITAR_DISTRIBUIDORAS_DTS_ADMINISTRADOR,
        payload : {
            modal   :  state,
            dts     : record
        }
    })
}

export const ModalAgregarDtsDistribuidoraAdministradorDistribuidorasReducer = ( state ) => async ( dispatch, getState) => {

    dispatch({
        type    : MODAL_AGREGAR_DISTRIBUIDORAS_DTS_ADMINISTRADOR,
        payload : state
    })
}