import {
    OBTENER_DATA_MODAL_DTS_CARGA_ARCHIVOS
} from "../../../Constantes/CargaArchivos/CargaArchivos";

const INIT_STATE = {
    rex_data_modal_dts_carga_archivos : []
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case OBTENER_DATA_MODAL_DTS_CARGA_ARCHIVOS: {
            return {
                ...state,
                rex_data_modal_dts_carga_archivos : action.payload,
            }
        }
        default:
            return state;
    }
}
