export const OBTENER_CANALES_ADMINISTRADOR_CARGAARCHIVOS = 'OBTENER_CANALES_ADMINISTRADOR_CARGAARCHIVOS';
export const OBTENER_CANALES_CUENTAS_ADMINISTRADOR_CARGAARCHIVOS = 'OBTENER_CANALES_CUENTAS_ADMINISTRADOR_CARGAARCHIVOS';
export const OBTENER_CATEGORIAS_CARGA_ADMINISTRADOR_CARGAARCHIVOS = 'OBTENER_CATEGORIAS_CARGA_ADMINISTRADOR_CARGAARCHIVOS';
export const OBTENER_TIPOS_CARGA_ADMINISTRADOR_CARGAARCHIVOS = 'OBTENER_TIPOS_CARGA_ADMINISTRADOR_CARGAARCHIVOS';
export const AGREGAR_CANAL_ADMINISTRADOR_CARGAARCHIVOS = 'AGREGAR_CANAL_ADMINISTRADOR_CARGAARCHIVOS';
export const EDITAR_CANAL_CUENTA_ADMINISTRADOR_CARGAARCHIVOS = 'EDITAR_CANAL_CUENTA_ADMINISTRADOR_CARGAARCHIVOS';
export const EDITAR_CATEGORIA_ADMINISTRADOR_CARGAARCHIVOS = 'EDITAR_CATEGORIA_ADMINISTRADOR_CARGAARCHIVOS';
export const AGREGAR_CANAL_CUENTA_ADMINISTRADOR_CARGAARCHIVOS = 'AGREGAR_CANAL_CUENTA_ADMINISTRADOR_CARGAARCHIVOS';
export const AGREGAR_CATEGORIA_ADMINISTRADOR_CARGAARCHIVOS = 'AGREGAR_CATEGORIA_ADMINISTRADOR_CARGAARCHIVOS';
export const OBTENER_SUB_TIPOS_CARGA_ADMINISTRADOR_CARGAARCHIVOS = 'OBTENER_SUB_TIPOS_CARGA_ADMINISTRADOR_CARGAARCHIVOS';
export const EDITAR_TCATIPO_ADMINISTRADOR_CARGAARCHIVOS = 'EDITAR_TCATIPO_ADMINISTRADOR_CARGAARCHIVOS';
export const AGREGAR_TIPO_ADMINISTRADOR_CARGAARCHIVOS = 'AGREGAR_TIPO_ADMINISTRADOR_CARGAARCHIVOS';
export const AGREGAR_SUBTIPO_ADMINISTRADOR_CARGAARCHIVOS = 'AGREGAR_SUBTIPO_ADMINISTRADOR_CARGAARCHIVOS';
export const EDITAR_SUBTIPO_ADMINISTRADOR_CARGAARCHIVOS = 'EDITAR_SUBTIPO_ADMINISTRADOR_CARGAARCHIVOS';
export const FILTRAR_CANAL_CUENTA_ADMINISTRADOR_CARGAARCHIVOS = 'FILTRAR_CANAL_CUENTA_ADMINISTRADOR_CARGAARCHIVOS';
export const FILTRAR_CATEGORIA_ADMINISTRADOR_CARGAARCHIVOS = 'FILTRAR_CATEGORIA_ADMINISTRADOR_CARGAARCHIVOS';
export const FILTRAR_TIPO_CARGA_ADMINISTRADOR_CARGAARCHIVOS = 'FILTRAR_TIPO_CARGA_ADMINISTRADOR_CARGAARCHIVOS';
export const FILTRAR_CANAL_ADMINISTRADOR_CARGAARCHIVOS = 'FILTRAR_CANAL_ADMINISTRADOR_CARGAARCHIVOS';
export const FILTRAR_SUBTIPO_CARGA_ADMINISTRADOR_CARGAARCHIVOS = 'FILTRAR_SUBTIPO_CARGA_ADMINISTRADOR_CARGAARCHIVOS';
export const EDITAR_CORREO_SUBTIPO_CARGA_ADMINISTRADOR_CARGAARCHIVOS = 'EDITAR_CORREO_SUBTIPO_CARGA_ADMINISTRADOR_CARGAARCHIVOS';
export const MODIFICAR_UNICAMENTE_CORREOS_CARGA_ADMINISTRADOR_CARGAARCHIVOS = 'MODIFICAR_UNICAMENTE_CORREOS_CARGA_ADMINISTRADOR_CARGAARCHIVOS';
export const EDITAR_CORREOS_ENVIAR_SUBTIPO_CARGA_ADMINISTRADOR_CARGAARCHIVOS = 'EDITAR_CORREOS_ENVIAR_SUBTIPO_CARGA_ADMINISTRADOR_CARGAARCHIVOS';
export const OBTENER_UNICAMENTE_CATEGORIAS_SELECT_ADMINISTRADOR_CARGAARCHIVOS = 'OBTENER_UNICAMENTE_CATEGORIAS_SELECT_ADMINISTRADOR_CARGAARCHIVOS';
export const OBTENER_UNICAMENTE_TIPOS_CARGA_SELECT_ADMINISTRADOR_CARGAARCHIVOS = 'OBTENER_UNICAMENTE_TIPOS_CARGA_SELECT_ADMINISTRADOR_CARGAARCHIVOS';
export const EDITAR_SUBTIPO_REGLAS_ADMINISTRADOR = 'EDITAR_SUBTIPO_REGLAS_ADMINISTRADOR';