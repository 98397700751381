import React from 'react'
import {  Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { MostrarModalRangoTiempoInvalidoReducer } from '../../Redux/Actions/CargaArchivos/TablaCargaArchivos';
import procesandoDocumento from '../../Assets/Imagenes/Componentes/Notificaciones/procesandoDocumento.gif'
import Moment from 'moment';
import '../../Estilos/Componentes/CargaArchivos/ModalNotificacion.css'

const ModalRangoTiempoInvalido = () => {
    
    const dispatch = useDispatch()

    let { 
        rex_modal_rango_tiempo_invalido_carga_archivos,
        rex_horarios_modal_rango_tiempo_invalido_carga_archivos
    } = useSelector(({tablaCargaArchivos}) => tablaCargaArchivos);

    const cerrarModalNotificacion = () => {
        dispatch(MostrarModalRangoTiempoInvalidoReducer(false, []))
    }
    
    return (
        
        <Modal
        title={null}
        cancelText={null}
        closeIcon={<div></div>}
        footer={null}
        centered={true}
        onCancel={cerrarModalNotificacion}
        open={rex_modal_rango_tiempo_invalido_carga_archivos}
        width={450}
        maskClosable={true}
        className='Modal-Notificacion-Carga-Archivo-Procesando'
        
    >
        <div
        onClick={cerrarModalNotificacion}
        >
            <div 
                className='Contenedor-Modal-Notificacion-Carga-Archivo'
            >
                <h3
                    className='Texto-Modal-Notificacion-Carga-Archivo-Procesando'
                >
                    Lo siento, no se pueden subir archivos en este momento
                </h3>
                <h3 className='Texto-Modal-Notificacion-Carga-Archivo-Procesando'>
                    porque los cubos se están actualizando.
                </h3>
                <div className='Contenedor-Imagen-Procesando'>
                    <img  src={procesandoDocumento}/>
                </div>
                <div className='Contenedor-Texto-Notificacion-Rango-Tiempo'>
                    <h3 className='Texto-Modal-Notificacion-Carga-Archivo-Mensaje'>
                        <br />Te recordamos que hoy los horarios de actualización son:
                    </h3>
                    <h3 className='Texto-Modal-Notificacion-Carga-Archivo-Horarios'>
                        {
                            rex_horarios_modal_rango_tiempo_invalido_carga_archivos.map(hor => {
                                return <>-Desde {Moment("01-01-2000 "+ hor.hstrangoinicio).format("hh:mma")} hasta {Moment("01-01-2000 "+ hor.hstrangofinal).format("hh:mma")}<br/> </>
                            })
                        }
                    </h3>
                </div>
            </div>
        </div>

    </Modal>
    )
}

export default ModalRangoTiempoInvalido