import React, { useState } from 'react'
import { Modal, Form, Row, Col, Input, Select, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { 
	AgregarSubTipoAdministradorCargaArchivosReducer,
    MostrarModalAgregarSubTipoCargaCargaArchivos
} from '../../../Redux/Actions/Administrador/CargaArchivos/CargaArchivos';
import '../../../Estilos/Rutas/Administrador/CargaArchivos.css'
import FooterModalCargaArchivos from '../../../Funciones/componentes/FooterModalCargaArchivos';
import Moment from 'moment'

const AgregarSubTipo = (props) => {

    const dispatch = useDispatch();
    const { Option } = Select;

    const {
		rex_modal_agregar_subtipo_administrador,
        rex_canales_administrador,
        rex_tipo_cargas_unicos,
        rex_categorias_unicos
    } = useSelector(({administradorCargaArchivos}) => administradorCargaArchivos);

    const {
        rex_paises,
        rex_cuentas
    } = useSelector(({administradorCuentas}) => administradorCuentas);

    const [ enviando, setEnviando ] = useState(false);
    const [ stcn, setStcn ] = useState({
        stcestado           : true,
        stcrangofecha       : true,
        stcfiladistribuidora: true,
        req_anio            : Moment().year(),
        req_mes             : 1 + Moment().month() 
    });

    const agregarSubTipo = async () => {

        setEnviando(true);
        await dispatch(AgregarSubTipoAdministradorCargaArchivosReducer(stcn));
        props.limpiarFiltro();
        setEnviando(false);
    }

    const cancelar = () => {
        dispatch(MostrarModalAgregarSubTipoCargaCargaArchivos(false))
    }

    return (
        <Modal
            centered={true}
            open={rex_modal_agregar_subtipo_administrador}
            onCancel = {cancelar}
            width={750}
            alignItems='center'
            className='ant-modal-content'
            footer={null}
            getContainer="#root"
        >
            <Form
                layout='vertical'
                autoComplete='off'
                onFinish={agregarSubTipo}
            >
                <h2 className='Text-Center-Carga-Archivos'>Agregar SubTipo</h2>

                <Row>
                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}}
                    >
                        <Form.Item
                            className='Input-Usuario'
                            label='Nombre:'
                            name='stcnombre'
                            rules={[
                                { required  : true, message: 'El nombre es requerido' },
                            ]}
                        >
                            <Input
                                name='stcnombre'
                                onChange={(e) => setStcn({...stcn, stcnombre:e.target.value})}
                                className='Input-Editar'
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}}
                    >
                        <Form.Item
                            className='Input-Usuario'
                            label='Tipo carga:'
                            name='tcanombre'
                            rules={[
                                { required  : true, message: 'El tipo de carga es requerido' },
                            ]}
                        >
							<Select
								onChange={(valor) => setStcn({...stcn, tcanombre:valor})}
								size='large'
								placeholder='Seleccionar'
								className='Input-Editar'
							>
								{
									rex_tipo_cargas_unicos.map((tca) => 
										<Option
										key={tca.tcanombre}
										value={tca.tcanombre}
									>
											{tca.tcanombre}
										</Option>
									)
								}
							</Select>
                        </Form.Item>
                    </Col>

                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}}
                    >
                        <Form.Item
                            className='Input-Usuario'
                            label='Categoria:'
                            name='ccanombre'
                            rules={[
                                { required  : true, message: 'La categoria es requerida' },
                            ]}
                        >
                            <Select
								onChange={(valor) => setStcn({...stcn, ccanombre:valor})}
								size='large'
								placeholder='Seleccionar'
								className='Input-Editar'
							>
								{
									rex_categorias_unicos.map((cca) => 
										<Option
										key={cca.ccanombre}
										value={cca.ccanombre}
									>
											{cca.ccanombre}
										</Option>
									)
								}
							</Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>

                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}}
                    >
                        <Form.Item
                            className='Input-Usuario'
                            label='Canal:'
                            rules={[
                                { required  : true, message: 'El canal es requerido' },
                            ]}
                            name='canid'
                        >
                            <Select
								onChange={(valor) => setStcn({...stcn, canid: valor})}
								size='large'
								placeholder='Seleccionar'
							>
								{rex_canales_administrador.map((can)=> (
									<Option
										key={can.canid}
										value={can.canid}
									>
										{can.cannombre}
									</Option>
								))}
							</Select>

                        </Form.Item>
                    </Col>
                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}}
                    >
                        <Form.Item
                            className='Input-Usuario'
                            label='Empresa:'
                            rules={[
                                { required  : true, message: 'La empresa es requerida' },
                            ]}
                            name='cueid'
                        >
                            <Select
								onChange={(valor) => setStcn({...stcn, cueid:valor})}
								size='large'
								placeholder='Seleccionar'
							>
								{rex_cuentas.map((cue)=> (
									<Option
										key={cue.cueid}
										value={cue.cueid}
									>
										{cue.cuenombre}
									</Option>
								))}
							</Select>

                        </Form.Item>
                    </Col>
                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}}
                    >
                        <Form.Item
                            className='Input-Usuario'
                            label='Pais:'
                            rules={[
                                { required  : true, message: 'El pais es requerido' },
                            ]}
                            name='paiid'
                        >
                            <Select
								onChange={(valor) => setStcn({...stcn, paiid:valor})}
								size='large'
								placeholder='Seleccionar'
							>
								{rex_paises.map((pai)=> (
									<Option
										key={pai.paiid}
										value={pai.paiid}
									>
										{pai.painombre}
									</Option>
								))}
							</Select>

                        </Form.Item>
                    </Col>
                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}}
                    >
                        <Form.Item
                            className='Input-Usuario'
                            label='Estado:'
                        >
                            <Switch
                                defaultChecked
                                className={stcn.stcestado ? 'Circulo-Activo': 'Circulo-Desactivo'}
                                onChange={(valor) => setStcn({...stcn, stcestado: valor})}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}}
                    >
                        <Form.Item
                            className='Input-Usuario'
                            label='Seleccionar distribuidora:'
                        >
                            <Switch
                                checkedChildren="Si"
                                unCheckedChildren="No"
                                defaultChecked
                                className={stcn.stcfiladistribuidora ? 'Circulo-Activo': 'Circulo-Desactivo'}
                                onChange={(valor) => setStcn({...stcn, stcfiladistribuidora: valor})}
                                size='large'
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}}
                    >
                        <Form.Item
                            className='Input-Usuario'
                            label='Rango de fechas:'
                        >
                            <Switch
                                checkedChildren="Si"
                                unCheckedChildren="No"
                                defaultChecked
                                className={stcn.stcrangofecha ? 'Circulo-Activo': 'Circulo-Desactivo'}
                                onChange={(valor) => setStcn({...stcn, stcrangofecha: valor})}
                                size='large'
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <FooterModalCargaArchivos cancelar={cancelar} enviando={enviando} nombre='Subtipo'/>
            </Form>
        </Modal>
    )
}

export default AgregarSubTipo