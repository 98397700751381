export const OBTENER_PERMISOS_TIPO_USUARIO_ADMINISTRADOR = 'OBTENER_PERMISOS_TIPO_USUARIO_ADMINISTRADOR';

export const OBTENER_PERMISOS_ADMINISTRADOR = 'OBTENER_PERMISOS_ADMINISTRADOR';

export const EDITAR_PERPERMISOS_ADMINISTRADOR = 'EDITAR_PERPERMISOS_ADMINISTRADOR';

export const AGREGAR_PERPERMISOS_ADMINISTRADOR = 'AGREGAR_PERPERMISOS_ADMINISTRADOR';

export const FILTRAR_PERMISOS_ADMINISTRADOR = 'FILTRAR_PERMISOS_ADMINISTRADOR';

export const OCULTAR_MODAL_TIPOS_PERMISOS_ADMINISTRADOR = 'OCULTAR_MODAL_TIPOS_PERMISOS_ADMINISTRADOR';

export const OBTENER_TIPOS_PERMISOS_ADMINISTRADOR = 'OBTENER_TIPOS_PERMISOS_ADMINISTRADOR';