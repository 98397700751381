export default {
    api: 'https://backend-goda.growanalytics.app/api/',
    // api: 'http://127.0.0.1:8000/api/',
    apiSmartview: 'https://express-smartview.grow-corporate.com/',
    // apiSmartview: 'http://127.0.0.1:8004/',
    scriptApi: 'https://pygoda.growanalytics.app/api/',
    // scriptApi: 'http://127.0.0.1:8000/api/',
    apiCorporate : "https://gia-back.grow-corporate.com/",
    // apiCorporate : "http://127.0.0.1:8001/",
    // apiNodeSpider: 'http://localhost:3001/',
    apiNodeSpider: 'https://express-backend-spider.grow-corporate.com/',
    basename: '/', 
    produccion : true,
    nombreSistema : 'GODA',
    nombreSistemaHo : 'GODA',
    nombreCliente : 'Grow Analytics'
};