import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Table, Tooltip, Modal, Select, Row, Col, Button, Pagination, Input, DatePicker, Popover } from 'antd';
import '../../../Estilos/Rutas/Usuarios/Usuarios.css';
import { 
    ObtenerUsuariosAdministradorUsuariosReducer,
    EliminaUsuarioAdministradorUsuariosReducer,
    ModalEditarUsuariosAdministradorReducer,
    ModalPermisosEspecialesAdministradorUsuarioReducer,
    DescargarDataUsuariosAdministradorReducer,
    DescargarActividadUsuariosAdministradorReducer,
    FiltrarUsuariosAdministradorReducer,
} from '../../../Redux/Actions/Administrador/Usuarios/Usuarios';
import { 
    ObtenerPaisesAdministradorCuentasReducer,
    ObtenerCuentasPaisesUsuariosAdministradorCuentasReducer
} from '../../../Redux/Actions/Administrador/Cuentas/Cuentas';
import { 
    ObtenerTpusAdministradorUsuariosReducer
} from '../../../Redux/Actions/Administrador/TipoUsuarios/TipoUsuarios';
import EditaUsuarios from '../../../Componentes/Administrador/Usuarios/EditaUsuarios';
import { 
    DeleteOutlined, 
    EditOutlined, 
    ExclamationCircleOutlined, 
    PlusCircleOutlined,
    SearchOutlined,
    DownloadOutlined,
    StarOutlined,
    StarFilled,
    LoadingOutlined
} from '@ant-design/icons';
import Moment from 'moment';
import PermisosEspecialesUsuario from '../../../Componentes/Administrador/Usuarios/PermisosEspecialesUsuario';
import { alertaNotificacion } from '../../../Funciones/Notificaciones/Notificaciones';

const Usuarios = () => {

    const { confirm } = Modal;
    const { Option } = Select;
    const { RangePicker } = DatePicker;

    const dispatch = useDispatch();
    let navigate = useNavigate();

    const [ usuidPem, setUsuIdPem ] = useState('');
    const [ hoverPaisesCuentas, setHoverPaisesCuentas ] = useState(false);
    const [ clickPaisesCuentas, setClickPaisesCuentas ] = useState(false);
    const [ selectPai, setSelectPai ] = useState(0);
    const [ selectTpu, setSelectTpu ] = useState(0);
    const [ txtBuscar, setTxtBuscar ] = useState('');
    const [ fechaActividad, setFechaActividad ] = useState(null);
    const [ descargarData, setDescargarData ] = useState(false);
    const [ descargarActividad, setDescargarActividad ] = useState(false);
    const [pageTableUsuarios, setPageTableUsuarios ] = useState(1);
    const [pageSizeTableUsuarios, setPageSizeTableUsuarios ] = useState(10);
    
    const { 
        rex_filtro_usuarios,
        rex_modal_editar_usuario,
        rex_cargando_tabla_usuarios,
        rex_modal_permisos_especiales_usuarios
    } = useSelector(({administradorUsuarios}) => administradorUsuarios);
    
    const { 
        rex_tipos_usuarios,
    } = useSelector(({administradorTipoUsuarios}) => administradorTipoUsuarios);

    const {
        rex_paises
    } = useSelector(({administradorCuentas}) => administradorCuentas);
        
    useEffect(()=> {
        dispatch(ObtenerUsuariosAdministradorUsuariosReducer())
        dispatch(ObtenerTpusAdministradorUsuariosReducer());
        dispatch(ObtenerPaisesAdministradorCuentasReducer());
        dispatch(ObtenerCuentasPaisesUsuariosAdministradorCuentasReducer());
    },[]);

        
    const descargarDataUsuarios = async () => {
                
        setDescargarData(true);
        await dispatch(DescargarDataUsuariosAdministradorReducer(selectTpu, selectPai, txtBuscar))
        setDescargarData(false);
    }

    const descargarActividadUsuarios = async () => {

        if(fechaActividad == null){
            alertaNotificacion('Seleccione rango de fecha por favor');
        }else{
            setFechaActividad([...fechaActividad], fechaActividad[1] = fechaActividad[1] + '-31')            
            setDescargarActividad(true);
            await dispatch(DescargarActividadUsuariosAdministradorReducer(fechaActividad));
            setDescargarActividad(false);
        }
    }

    const cambiaHoverPaisesCuentas = (open) => {
        setHoverPaisesCuentas(open)
        setClickPaisesCuentas(false)
    }

    const cambiaClickPaisesCuentas = (open) => {
        setHoverPaisesCuentas(false)
        setClickPaisesCuentas(open)
    }

    const contenidoClickPaisesCuentas = ( data ) => (
        data.map((pac) => (
            <div key={pac.usuid} style={{margin:'5px 0'}}>
                <img style={{height:'auto', width:'25px'}} src={pac.paiicono}/>{'  '}{pac.cuenombre}
            </div>
        ))
    );

    const contenidoHoverPaisesCuentas = ( data ) => (
        <div
            style={{display:'flex', flexDirection:'row', width:'100%'}}
            className='Contenedor-Hover-Paises-Cuentas-Usuarios'
        >
            {
                data.map((pac) => (
                    <div
                        style={{margin:'0 10px', position:'relative', width:'100%'}}
                        key={pac.paiid}
                    >
                            <Tooltip placement="bottom" title={pac.painombre}>
                                <img
                                    src={pac.paiicono}
                                    className='Imagen-Pais-Usuario'
                                    />
                            </Tooltip>
                            <Tooltip placement="bottom" title={pac.cuenombre}>
                                <img
                                    src={pac.cueimagen}
                                    className='Imagen-Cuenta-Usuario'
                                />
                            </Tooltip>
                        </div>
                ))

            }
        </div>
    )
 
    const columns = [
        {
            title: 'Item',
            dataIndex: 'item',
            key: 'item',
            width: '50px',
            align: 'center',
            render: (text, record, index) => (pageTableUsuarios - 1) * pageSizeTableUsuarios + index + 1,
            
        },
        {
            title: 'Tipo',
            dataIndex: 'tpunombre',
            key: 'tpunombre',
            width: '150px',
            align: 'center',
        },
        {
            title: 'Estado',
            key: 'estid',
            width: '60px',
            align: 'center',
            render: (_, record) => (
                <div className='Contenedor-Centrado'>
                    <Tooltip placement="top" title={record.estid == 1 ? 'Activado':'Desactivado'}>
                        <div className={`Circulo ${record.estid == 1? 'Circulo-Activo': 'Circulo-Desactivo'}`}>
                        </div>
                    </Tooltip>
                </div>
            ),
        },
        {
            title: 'Permisos especiales',
            key: 'usupermisosespeciales',
            width: '100px',
            align: 'center',
            render: (_, record) => (
                <div
                    className='Contenedor-Centrado'
                    onClick={() => {
                        dispatch(ModalPermisosEspecialesAdministradorUsuarioReducer(true))
                        setUsuIdPem(record.usuid)
                    }}
                >
                    {record.tienePermiso
                        ? <StarFilled style={{fontSize:'24px', color:'yellow'}}/>
                        : <StarOutlined style={{fontSize:'24px'}}/>
                    }
                </div>
            )
        },
        {
            title: 'Pais',
            key: 'paisescuentas',
            align: 'center',
            width: 'center',
            render: (_,record) => (
                <Popover
                    content={contenidoHoverPaisesCuentas(record.paisescuentas)}
                    title={null}
                    placement="right"
                    trigger='hover'
                    open={hoverPaisesCuentas}
                    onOpenChange={() => cambiaHoverPaisesCuentas()}
                >
                    <Popover
                        content={contenidoClickPaisesCuentas(record.paisescuentas)}
                        title='Paises y cuentas'
                        trigger='click'
                        open={clickPaisesCuentas}
                        onOpenChange={() => cambiaClickPaisesCuentas()}
                    >
                        <img className='Imagen-Pais' src={record.paisescuentas[0].paiicono}/>
                    </Popover>

                </Popover>
            )
        },
        {
            title: 'Avatar',
            key: 'usuimagen',
            width: '100px',
            render: (_, record) => (
                <img
                    className='Imagen-Avatar-Usuario'
                    src={record.usuimagen
                        ?record.usuimagen
                        :'https://cdn-icons-png.flaticon.com/512/126/126486.png'
                    }
                />
            ),
            align: 'center'
        },
        {
            title: 'Usuario',
            key: 'correo',
            width: '100px',
            align: 'center',
            render:(_, record) => (
                <div 
                    title={record.usuusuario}
                    className='Celda-Title-Usuario-Administrador'
                >
                    {record.usuusuario}
                </div>
            )
        },
        {
            title: 'Nombre completo',
            key: 'pernombrecompleto',
            width: '150px',
            align: 'center',
            render:(_,record) => (
                <div className='Celda-Usuario-Title' title={record.pernombrecompleto}>
                    {record.pernombrecompleto}
                </div>
            )
        },
        {
            title: 'Fecha de creacion',
            key: 'created_at',
            width: '250px',
            align: 'center',
            render: (_, record) => (
                <>{record.created_at?Moment(record.created_at).format('LLL'):'Sin registro'}</>
            ),
        },
        {
            title: 'Fecha de actualización',
            key: 'updated_at',
            width: '250px',
            align: 'center',
            render: (_, record) => (
                <>
                    <>{record.updated_at?Moment(record.updated_at).format('LLL'):'Sin registro'}</>
                    <div className='Contenedor-Boton'>
                        <Tooltip placement="top">
                            <DeleteOutlined className='Boton-Eliminar' onClick={()=> confirmarEliminar(record)}/>
                        </Tooltip>
                        <Tooltip placement="top">
                            <EditOutlined className='Boton-Editar' onClick={()=> 
                                    dispatch(ModalEditarUsuariosAdministradorReducer(record, true))
                                }
                            />
                        </Tooltip>
                    </div>
                </>
            )
        },
    ];

    
    const confirmarEliminar = (record) => {

        confirm({
            title: `¿Eliminar a ${record.usuusuario}?`,
            icon: <ExclamationCircleOutlined />,
            okText: 'Eliminar',
            content: 'Al pulsar eliminar se eliminará completamente al usuario.',
        
            onOk() {
                dispatch(EliminaUsuarioAdministradorUsuariosReducer(record));
            },
        
            onCancel() {},
          });
    };

    return (
        
        <div className='Contenedor-Usuarios-Administrador'>
            <Row>
                <Col span={24}>
                    <h2 className='Text-Center'>Usuarios</h2>
                </Col>
            </Row>
            <Row className='Fila-Filtro-Agregar'>
                <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                    <Input
						suffix={<SearchOutlined style={{margin:'0 5px'}} />}
						size='large'
						style={{borderRadius:'5px', height:'45px'}}
						placeholder='Buscar'
						value={txtBuscar}
						onChange={(e) => {
							setTxtBuscar(e.target.value)
                            dispatch(FiltrarUsuariosAdministradorReducer(selectTpu, selectPai, e.target.value))
						}}
					/>
                </Col>
                <Col>
                    <Row>
                        <Button style={{marginLeft:'10px'}}
                            onClick={()=> navigate("/registro-usuarios", { replace: true })}
                            type="primary"
                        >
                            Crear<PlusCircleOutlined />
                        </Button>
                    </Row>
                </Col>
            </Row>
            <Row style={{display:'flex', justifyContent:'space-between', margin:'15px 0'}}>
                <Col className='Contenedor-Centrado'>
                    <Select
                        value={selectPai}
                        style={{width:'150px', margin:'3px 3px'}}
                        onChange={(valor) => {
                                setSelectPai(valor)
                                dispatch(FiltrarUsuariosAdministradorReducer(selectTpu, valor, txtBuscar))
                            }
                        }
                        className='Select-Filtro'
                            >
                            <Option value={0}>Seleccionar pais</Option>

                            {rex_paises && rex_paises.map((pai)=> (
                                <Option key={pai.paiid} value={pai.paiid}>{pai.painombre}</Option>
                            ))}
                    </Select>


                    <Select
                        value={selectTpu}
                        className='Select-Filtro'
                        style={{width:'150px', margin:'3px 3px'}}
                        onChange={(valor) => {
                            setSelectTpu(valor)
                            dispatch(FiltrarUsuariosAdministradorReducer(valor, selectPai, txtBuscar))
                        }}
                    >
                        <Option value={0}>Seleccionar Tipo de usuario</Option>
                        {
                            rex_tipos_usuarios &&
                            rex_tipos_usuarios.map((perm) => (
                                <Option key={perm.tpuid} value={perm.tpuid}>{perm.tpunombre}</Option>
                                )
                            )
                        }
                    </Select>
                </Col>
                <Col>
                    <Row
                        style={{display:'flex', gap:'5px'}}
                    >
                        <Col>
                            <RangePicker
                                picker="month"
                                onChange={(valor, fecha) => setFechaActividad(fecha)}
                                disabledDate={(current) => current.isAfter(Moment())}
                            />
                            <Button style={{marginLeft:'10px'}}
                                onClick={()=> descargarActividadUsuarios()}
                                type="primary"
                            >
                                Actividad
                                {
                                    descargarActividad
                                    ? <LoadingOutlined/>
                                    : <DownloadOutlined />
                                }
                            </Button>
                        </Col>
                        <Col>
                            <Button style={{marginLeft:'10px'}}
                                onClick={() => descargarDataUsuarios()}
                                type="primary"
                            >
                                Usuarios
                                {
                                    descargarData
                                    ? <LoadingOutlined/>
                                    :  <DownloadOutlined/>
                                }
                            </Button>
                        </Col>
                        <Col>
                        <Pagination
                            defaultCurrent={pageTableUsuarios}
                            current={pageTableUsuarios}
                            total={rex_filtro_usuarios.length}
                            onChange={(page, pageSize) => {
                                setPageTableUsuarios(page == 0? 1 : page)
                                setPageSizeTableUsuarios(pageSize)
                            }}
                        />
                        </Col>
                    </Row>
                </Col>
            </Row>

                
            <Table
                pagination={{
                    defaultCurrent: pageTableUsuarios,
                    current: pageTableUsuarios,
                    pageSize: pageSizeTableUsuarios,
                    position: ['topRight'],
                }}
                loading={rex_cargando_tabla_usuarios}
                size={'small'}
                rowClassName={'editable-row'}
                dataSource={rex_filtro_usuarios}
                columns={columns}
            />

            { rex_modal_editar_usuario && <EditaUsuarios/> }
            { rex_modal_permisos_especiales_usuarios && <PermisosEspecialesUsuario usuid={usuidPem}/> }

        </div>
    )
}

export default Usuarios