import React from 'react'
import '../Estilos/App/App.css'
import LogoPaginaColor from '../Assets/Imagenes/Logos/logocolor.png'
import {useSelector, useDispatch} from "react-redux";
import {Route, Routes, Navigate } from "react-router-dom";
import Rutas from '../Rutas/index'
import Login from '../Rutas/Login/Login'

const App = () => {

    const {
        rex_ComunesCargandoPagina,
        rex_ComunesCargandoPaginaInicio
    } = useSelector(({comunes}) => comunes)

    const {
        rex_token_login
    } = useSelector(({login}) => login)

    return (
        <div>

            <div style={{position:'absolute', width:'100%', height:'100vh'}}>
                <div style={{position:'relative', width:'100%', height:'100%'}}>
                    
                    {
                        rex_token_login != null
                        // false == true
                        ?<Rutas />
                        :<Routes>
                            <Route exact path='/login' element={<Login/>}/>
                            <Route path="*" element={<Navigate replace to="/login" />} />
                        </Routes>
                    }

                </div>
            </div>

            {
                // rex_ComunesCargandoPagina === true || rex_ComunesCargandoPaginaInicio == true
                rex_ComunesCargandoPagina === false || rex_ComunesCargandoPaginaInicio == false
                ?<div
                    id="Img-Logo-App"
                >
                    <img 
                        src={LogoPaginaColor} 
                        width={'150px'} 
                    />
                    
                </div>
                :null
            }
            
        </div>
    )
}

export default App