import { Modal} from 'antd'
import React from 'react'
import {useSelector} from "react-redux";
import avionLoading from '../../Assets/Imagenes/Componentes/Notificaciones/avionLoading.gif'
import '../../Estilos/Componentes/CargaArchivos/ModalNotificacion.css'


const ModalGuardandoArchivosPython = () => {

    let { 
        rex_mostrar_modal_guardando_archivos_python_carga_archivos
    } = useSelector(({tablaCargaArchivos}) => tablaCargaArchivos);

    return (
        <Modal
            title={null}
            cancelText={null}
            footer={null}
            centered={true}
            open={rex_mostrar_modal_guardando_archivos_python_carga_archivos}
            className='Modal-Cargando-Archivos-Python-Guardando'
            width={350}
            closable={false}
        >
            <div>
                <div style={{display:'flex', justifyContent:'center', margin:'5px 0'}}>
                    <h3 className='Titulo-Modal-Subir-Archivo'>Subir archivos</h3>
                </div>
                <div style={{display:'flex', justifyContent:'center'}}>
                    <p className='Texto-Modal-Guardando-Archivos-Python'>Aguarde unos minutos, la información se está guardando</p>
                </div>

                <div className='Contenedor-Imagen-Loading'>
                    <img className='Imagen-Avion-Loading' src={avionLoading}/>
                </div>

            </div>
        </Modal>
    )
}

export default ModalGuardandoArchivosPython