import React, { useState } from 'react'
import { Modal, Form, Row, Col, Select, Tag, Input, Button, Spin } from 'antd';
import '../../../Estilos/Rutas/Administrador/CargaArchivos.css'
import { useDispatch, useSelector } from 'react-redux';
import {
	MostrarModalEditarCorreoSubTipoAdministradorCargaArchivosReducer,
    AgregarCorreoInputAdministradorCargaArchivosReducer,
    EditarCorreosStcAdministradorCargaArchivosReducer,
    agregarCorreoSelectAdministradorCargaArchivosReducer,
} from '../../../Redux/Actions/Administrador/CargaArchivos/CargaArchivos'
import { alertaNotificacion } from '../../../Funciones/Notificaciones/Notificaciones';
import { antIconWhite } from '../../../Funciones/componentes/antd';
import AccionesAgregarCorreoCargaArchivos from '../../../Funciones/componentes/CargaArchivos/AccionesAgregarCorreoCargaArchivos';

const EditarCorreoSubTipo = (props) => {

    const dispatch = useDispatch();
    const { Option } = Select;

    const { 
        rex_subtipe_editar_administrador,
        rex_modal_editar_correo_subtipo,
        rex_correos_carga_administrador,
        rex_correos_carga_enviar_administrador
    } = useSelector(({administradorCargaArchivos}) => administradorCargaArchivos);

    const [ enviando, setEnviando ] = useState(false);
    const [ agregarCorreoPrincipal, setAgregarCorreoPrincipal ] = useState(false);
    const [ agregarCorreoSecundario, setAgregarCorreoSecundario ] = useState(false);
    const [ nuevoCorreoPrincipal, setNuevoCorreoPrincipal ] = useState('')
    const [ nuevoCorreoSecundario, setNuevoCorreoSecundario ] = useState('')

    const cancelar = () => {
        dispatch(MostrarModalEditarCorreoSubTipoAdministradorCargaArchivosReducer(false, []))
    }

    const editarCorreoSubTipo = async (stcid) => {
        setEnviando(true);
        await dispatch(EditarCorreosStcAdministradorCargaArchivosReducer(stcid));
        props.limpiarFiltro();
        setEnviando(false);
    }

    const validarCorreo = async (correo, tipo) => {

        let regexCorreo = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
        let respuesta;

        if(correo.length == 0 || !regexCorreo.test(correo)){
            alertaNotificacion('Correo inválido');
        }else{

            tipo ? setAgregarCorreoPrincipal(false) : setAgregarCorreoSecundario(false)

            respuesta = await dispatch(AgregarCorreoInputAdministradorCargaArchivosReducer(correo, tipo))

            if(respuesta == false){
                alertaNotificacion('Correo ya seleccionado');
            }
        }
    }

    return (
        <Modal
            open={rex_modal_editar_correo_subtipo}
            onCancel = {cancelar}
            width={750}
            centered={true}
            footer={null}
            className='ant-modal-content'
            getContainer="#root"
        >
            <Form
                autoComplete='off'
                layout='vertical'
            >
                <h2 className='Text-Center-Carga-Archivos'>Editar correos</h2>
                <Row className='Input-Usuario'>
                    <Col sm={{span:24}} md={{span:8}}>
                        <p><b>Categoria: </b>{rex_subtipe_editar_administrador.ccanombre}</p>
                    </Col>
                    <Col sm={{span:24}} md={{span:8}}>
                        <p><b>Tipo: </b>{rex_subtipe_editar_administrador.tcanombre}</p>
                    </Col>
                    <Col sm={{span:24}} md={{span:8}}>
                        <p><b>Nombre: </b>{rex_subtipe_editar_administrador.stcnombre}</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}} xl={{span:12}}>
                        <Form.Item
                            className='Input-Usuario'
                            label='Destinatarios:'
                        >
                            <Row>
                                <Col span={`${agregarCorreoPrincipal ? '16':'20'}`}>
                                    {
                                        agregarCorreoPrincipal
                                        ? <Input
                                            placeholder='Nuevo correo' style={{height:'33px'}}
                                            name='nuevoCorreoPrincipal'
                                            value={nuevoCorreoPrincipal}
                                            onChange={(e) => setNuevoCorreoPrincipal(e.target.value)}
                                          />
                                        : <Select
                                            value='Seleccionar'
                                            onChange={(valor) => dispatch(agregarCorreoSelectAdministradorCargaArchivosReducer(valor, null, null, 1))}
                                            >
                                            {
                                                rex_correos_carga_administrador.map((cor) =>
                                                {
                                                    let existeCorreo = rex_correos_carga_enviar_administrador.findIndex((core) => core.corid === cor.corid  && (core.scotipodestinatario == 1 || core.scotipodestinatario == 0));

                                                    return <Option key={cor.corid} disabled={existeCorreo == -1 ? false: true}>{cor.corcorreo}</Option>
                                                }
                                                )

                                            }
                                        </Select>
                                    }
                                </Col>
                                <Col span={`${agregarCorreoPrincipal ? '8':'4'}`} style={{display:'flex', justifyContent:'center'}}>
                                    {
                                        agregarCorreoPrincipal
                                        ? <AccionesAgregarCorreoCargaArchivos
                                        validarCorreo= {validarCorreo} correo={nuevoCorreoPrincipal} tipo={1} setAgregarCorreo={setAgregarCorreoPrincipal}/>
                                        :<Button onClick={()=> {
                                            setAgregarCorreoPrincipal(true)
                                            setNuevoCorreoPrincipal('')
                                        }} type='primary'>+</Button>
                                    }
                                </Col>
                            </Row>
                            <div className='Contenedor-Tag-Carga-Archivos'>
                                {
                                    rex_correos_carga_enviar_administrador.map( (cortag) => {
                                        if(cortag.scotipodestinatario != 0){
                                            return <Tag
                                                key={cortag.corid}
                                                closable
                                                onClose={()=>

                                                    dispatch(agregarCorreoSelectAdministradorCargaArchivosReducer(cortag.corid,null, 1, 1))
                                                }
                                                className='Tag-Correo-Carga-Archivos'
                                                color='blue'
                                            >
                                                <span>{cortag.corcorreo}</span>
                                            </Tag>
                                        }
                                    })
                                }
                            </div>
                        </Form.Item>
                    </Col>
                    <Col xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}} xl={{span:12}}>
                        <Form.Item
                            className='Input-Usuario'
                            label='Enviar copias a:'
                        >
                            <Row>
                                <Col span={`${agregarCorreoSecundario ? '16':'20'}`}>
                                    {
                                        agregarCorreoSecundario
                                        ? <Input
                                            placeholder='Nuevo correo' style={{height:'33px'}}
                                            name='nuevoCorreoSecundario'
                                            value={nuevoCorreoSecundario}
                                            onChange={(e) => setNuevoCorreoSecundario(e.target.value)}
                                          />
                                        : <Select
                                            value='Seleccionar'
                                            onChange={(valor) => dispatch(agregarCorreoSelectAdministradorCargaArchivosReducer(valor, null, null, 0))}
                                        >
                                            {
                                                rex_correos_carga_administrador.map((cor) => {

                                                    let existeCorreo = rex_correos_carga_enviar_administrador.findIndex((core) => core.corid === cor.corid && (core.scotipodestinatario == 0 || core.scotipodestinatario == 1));
                                                    return <Option key={cor.corid} disabled={existeCorreo == -1 ? false: true}>{cor.corcorreo}</Option>
                                                }
                                                )
                                            }
                                         </Select>
                                    }
                                </Col>
                                <Col span={`${agregarCorreoSecundario ? '8':'4'}`} style={{display:'flex', justifyContent:'center'}}>
                                    {
                                        agregarCorreoSecundario
                                        ? <AccionesAgregarCorreoCargaArchivos
                                        validarCorreo= {validarCorreo} correo={nuevoCorreoSecundario} tipo={0} setAgregarCorreo={setAgregarCorreoSecundario}/>
                                        :<Button onClick={()=> {
                                            setNuevoCorreoSecundario('')
                                            setAgregarCorreoSecundario(true)
                                        }} type='primary'>+</Button>
                                    }
                                </Col>
                            </Row>
                            <div className='Contenedor-Tag-Carga-Archivos'>
                                {
                                    rex_correos_carga_enviar_administrador.map( (cortag) => {
                                        if(cortag.scotipodestinatario != 1){
                                            return <Tag
                                                key={cortag.corid}
                                                closable
                                                onClose={()=> 
                                                    dispatch(agregarCorreoSelectAdministradorCargaArchivosReducer(cortag.corid,null, 0, 0))
                                                }
                                                className='Tag-Correo-Carga-Archivos'
                                                color='blue'
                                            >
                                                <span>{cortag.corcorreo}</span>
                                            </Tag>
                                        }
                                    })
                                }
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <Row style={{display:'flex', justifyContent:'flex-end', marginTop:'20px'}}>
                <Button
                    style={{marginRight:'10px'}}
                    onClick={cancelar}
                >Cancelar</Button>
                <Button
                    style={{width:'180px'}} type="primary"
                    onClick={() => editarCorreoSubTipo(rex_subtipe_editar_administrador.stcid)}
                >
                    {enviando ? <Spin indicator={antIconWhite}/> : 'Guardar correos'}
                </Button>
            </Row>

        </Modal>
    )
}

export default EditarCorreoSubTipo