import { Select, DatePicker, Table } from 'antd'
import React, {useEffect, useState} from 'react'
import Seleccionarnegocio from '../../Componentes/SeleccionarNegocio/Seleccionarnegocio'
import {useSelector, useDispatch} from "react-redux";
import {
    SeleccionarPaisNegocioComplementosReducer,
    SeleccionarCanalNegocioComplementosReducer
} from '../../Redux/Actions/Complementos/Complementos'
import {
    ReiniciarVariablesEstadosSeleccionarNegocioReducer,
} from '../../Redux/Actions/Componentes/SeleccionarNegocio'
import{
    ObtenerStatusReducer,
    CambiarFechaStatusReducer
} from '../../Redux/Actions/Status/Status'
import { 
	ArrowLeftOutlined,
} from '@ant-design/icons'
import '../../Estilos/Rutas/Status/Status.css'
import moment from 'moment';
import ModalDetalle from '../../Componentes/Status/ModalDetalle';

const Status = () => {

    const dispatch = useDispatch()

    const { 
        rex_data_paises_complementos,
        rex_seleccionar_pais_complementos,
        rex_seleccionar_canal_complementos
    } = useSelector(({complementos}) => complementos);

    const { 
        rex_data_tabla_status,
        rex_columnas_tabla_status,
        rex_mostrar_modal_detalle_status,
        rex_fila_data_seleccionado_tabla_status
    } = useSelector(({tablaStatus}) => tablaStatus);

    useEffect(() => {
        if(rex_data_paises_complementos.length > 0){
            dispatch(SeleccionarPaisNegocioComplementosReducer(0, rex_data_paises_complementos[0]))
        }
    }, [rex_data_paises_complementos])

    const { 
        rex_data_negocios_componentes,
        rex_cargando_componente_seleccionar_negocio,
        rex_negocio_seleccionado,
        rex_ocultar_modulo_seleccionar_negocio
    } = useSelector(({seleccionarNegocio}) => seleccionarNegocio);

    useEffect(() => {

        if(rex_seleccionar_pais_complementos.paiid){
            dispatch(ObtenerStatusReducer())
        }

    }, [rex_seleccionar_pais_complementos, rex_seleccionar_canal_complementos ])

    	
    const fechaactual = new Date();

    const [mostrarModal, setMostrarModal] = useState(false)

    return (
        <>

            {
                <Seleccionarnegocio 
            
                />
            }

            <div
                style={{paddingTop:'76px'}}
                className="Componente-Principal-Carga-Archivo"
            >
                <div className='Contenedor-Titulo-Carga-Archivo W700-S26-H35-C000000'>
                    <div 
                        style={{
                            marginRight:'15px', fontSize:'20px', cursor:'pointer',
                        }}
                        onClick={() => {
                            dispatch(ReiniciarVariablesEstadosSeleccionarNegocioReducer())
                        }}
                    >
                        <ArrowLeftOutlined />
                    </div>
                    Status
                </div>
                <div
                    id="Contenedor-Filtro-Carga-Archivo"
                >
                    <div
                        style={{marginRight:'20px'}}
                    >
                        <DatePicker 
                            onChange={(e, fecha) => {
                                let fechaparceada = fecha.split('-')
                                dispatch(CambiarFechaStatusReducer(fechaparceada[0], fechaparceada[1]))
                            }} 
                            picker="month" 
                            defaultValue={moment(fechaactual.getFullYear()+'-'+(fechaactual.getMonth()+1), 'YYYY-MM-DD')}
                        />
                    </div>

                    <div style={{marginRight:'20px'}}>

                        <Select 
                            style={{ width: 120 }} 
                            onChange={(e, r) => {
                                dispatch(SeleccionarPaisNegocioComplementosReducer(e))
                            }}
                            value={rex_seleccionar_pais_complementos.paiid}
                        >
                            {
                                rex_data_paises_complementos.map((pais) => {
                                    return (
                                        <Select.Option 
                                            value={pais.paiid}
                                            key={pais.paiid}
                                        >
                                            {pais.painombre}
                                        </Select.Option>
                                    )
                                })
                            }
                        </Select>
                    </div>

                    <Select
                        style={{ width: 120 }} 
                        onChange={(e, r) => {
                            dispatch(SeleccionarCanalNegocioComplementosReducer(e))
                        }}
                        value={rex_seleccionar_canal_complementos}
                    >
                        <Select.Option value={0}>Todos</Select.Option>
                        <Select.Option value={1}>Tradicional</Select.Option>
                        <Select.Option value={2}>Moderno</Select.Option>
                    </Select>

                    
                </div>

                <div
                    style={{paddingLeft: "40px", paddingRight:"40px", paddingTop:"20px"}}
                >
                    <div
                        className='Contenedor-Tabla-Carga-Archivos'
                    >
                        <Table
                            columns={[...rex_columnas_tabla_status]}
                            dataSource={[...rex_data_tabla_status]}
                            rowClassName={() => 'editable-row'}
                            pagination={{ position: ['none', 'none'], pageSize: 200 }}
                            scroll={{ y: 505 }}
                        />
                    </div>
                </div>

                

            </div>

            <ModalDetalle
                mostrarModal = {rex_mostrar_modal_detalle_status}
                fila_seleccionada = {rex_fila_data_seleccionado_tabla_status}
            />

        </>
    )
}

export default Status