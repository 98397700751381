import {
    SELECCIONAR_PAIS_NEGOCIO_COMPLEMENTOS,
    OBTENER_DATA_PAISES_NEGOCIO_COMPLEMENTOS,
    SELECCIONAR_CANAL_NEGOCIO_COMPLEMENTOS,
} from "../../../Constantes/Complementos/Complementos";

const INIT_STATE = {
    rex_data_paises_complementos: [],
    rex_seleccionar_pais_complementos : {},
    rex_seleccionar_canal_complementos : 0,
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case OBTENER_DATA_PAISES_NEGOCIO_COMPLEMENTOS: {
            return {
                ...state,
                rex_data_paises_complementos : action.payload,
            }
        }
        case SELECCIONAR_PAIS_NEGOCIO_COMPLEMENTOS: {
            return {
                ...state,
                rex_seleccionar_pais_complementos : action.payload
            }
        }

        case SELECCIONAR_CANAL_NEGOCIO_COMPLEMENTOS: {
            return {
                ...state,
                rex_seleccionar_canal_complementos : action.payload
            }
        }
        default:
            return state;
    }
}
