import config from "../../../../config";
import {
    OBTENER_DATOS_TPUS_ADMINISTRADOR_USUARIOS,
    AGREGAR_TIPO_USUARIO_ADMINISTRADOR_USUARIOS,
    FILTRAR_DATOS_TPUS_ADMINISTRADOR_USUARIOS,
} from "../../../../Constantes/Administrador/TipoUsuarios/TipoUsuarios";
import {
    exitoNotificacion,
    errorNotificacion,
    alertaNotificacion
} from "../../../../Funciones/Notificaciones/Notificaciones";
import { estadoRequestReducer } from "../../Comunes/EstadoRequest";

export const AgregarTipoUsuarioAdministradorUsuarioReducer = (tpun) => async (dispatch, getState) => {

    await fetch(config.api + 'agregar-tipo-usuarios',{
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify(
            {
                "tpunombre"     : tpun.tpunombre,
                "tpuprivilegio" : tpun.tpuprivilegio,
            }
        ),
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'api_token'	   : localStorage.getItem('usutoken')
        }
    })
    .then( async res => {

        await dispatch(estadoRequestReducer(res.status))

        return res.json()
    })
    .then( data  => {

        const estadoRequest = getState().estadoRequest.init_request;

        if(estadoRequest == true){

            dispatch({
                type: AGREGAR_TIPO_USUARIO_ADMINISTRADOR_USUARIOS,
                payload: {
                    modalagtpu: false
                }
            })

            dispatch(ObtenerTpusAdministradorUsuariosReducer())
            exitoNotificacion('Tipo de usuario agregado con éxito');

        }else{
            data.Errores.map(err=> errorNotificacion(err));            
        }

    }).catch((error)=> {
        console.log(error)
    });
}

export const EliminarTipoUsuarioAdministradorUsuarioReducer = (record) => async (dispatch, getState) => {

    await fetch(config.api + 'eliminar-tipo-usuarios',
    {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'api-token'	   : localStorage.getItem('usutoken'),
            'api_token'	   : localStorage.getItem('usutoken')
        },
        body: JSON.stringify({
            "tpuid": record.tpuid
        }),
    }
    )
    .then( async res => {

        await dispatch(estadoRequestReducer(res.status))

        return res.json()
    })
    .then( data  => {

        const estadoRequest = getState().estadoRequest.init_request;

        if(estadoRequest == true){

            dispatch(ObtenerTpusAdministradorUsuariosReducer())
            exitoNotificacion(data.Mensaje);

        }else{
            if(data.Errores){
                data.Errores.map((err) => alertaNotificacion(err));
            }else{
                errorNotificacion(data.Mensaje);
            }
        }

    }).catch((error)=> {
        console.log(error)
    });
}

export const GuardarTipoUsuarioAdministradorUsuariosReducer = (key, tpunombre,tpuprivilegio) => async (dispatch, getState) => {
    
    let editado = true;

    await fetch(config.api + 'editar-tipo-usuarios',{
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify(
            {
                "tpuid"         : key,
                "tpunombre"     : tpunombre,
                "tpuprivilegio" : tpuprivilegio,
            }
        ),
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'api_token'	   : localStorage.getItem('usutoken')
        }
    })
    .then( async res => {

        await dispatch(estadoRequestReducer(res.status))

        return res.json()
    })
    .then( data  => {

        const estadoRequest = getState().estadoRequest.init_request;

        if(estadoRequest == true){

            dispatch(ObtenerTpusAdministradorUsuariosReducer())
            exitoNotificacion(data.Mensaje);

        }else{
            editado = false;

            if(data.Errores){
                data.Errores.map(err=> alertaNotificacion(err));
            }else{
                alertaNotificacion(data.Mensaje)
            }
        }

    }).catch((error)=> {
        console.log(error)
    });

    return editado;
}

export const ObtenerTpusAdministradorUsuariosReducer = () => async (dispatch, getState) => {
    await fetch(config.api+'obtener-tpus',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
        }
    )
    .then( async res => {
            await dispatch(estadoRequestReducer(res.status))
            
            return res.json()
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request

        if(estadoRequest == true){

            dispatch({
                type: OBTENER_DATOS_TPUS_ADMINISTRADOR_USUARIOS,
                payload: data.tpus
            })
            dispatch({
                type: FILTRAR_DATOS_TPUS_ADMINISTRADOR_USUARIOS,
                payload: data.tpus
            })
        }else{
            alertaNotificacion(data.mensaje);
        }

    }).catch((error)=> {
        console.log(error)
    });

}

export const ModalAgregarTipoUsuarioAdministradorReducer = ( estado ) => async (dispatch, getState) => {

    dispatch({
        type: AGREGAR_TIPO_USUARIO_ADMINISTRADOR_USUARIOS,
        payload: {
            modalagtpu: estado
        }
    })
}

export const FiltrarTiposUsuariosAdministradorReducer = (txtBuscar) => async (dispatch, getState) => {

    let listaTiposUsuarios  = getState().administradorTipoUsuarios.rex_tipos_usuarios;

    if(txtBuscar != ""){
        let tiposUsuariosFiltrados = listaTiposUsuarios.filter((tpu) => 
            (
                tpu.tpunombre.toLocaleLowerCase().includes(txtBuscar.toLocaleLowerCase())
                || (tpu.tpuprivilegio && tpu.tpuprivilegio.toLocaleLowerCase().includes(txtBuscar.toLocaleLowerCase()))
            )
        )

        dispatch({
            type    : FILTRAR_DATOS_TPUS_ADMINISTRADOR_USUARIOS,
            payload : tiposUsuariosFiltrados
        })

    }else{
        dispatch({
            type    : FILTRAR_DATOS_TPUS_ADMINISTRADOR_USUARIOS,
            payload : listaTiposUsuarios
        })
    }
}