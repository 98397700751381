import config from '../../../config'
import { estadoRequestReducer } from "../Comunes/EstadoRequest"
import {
    CARGANDO_COMPONENTE_SELECCIONAR_NEGOCIO,
    OBTENER_DATA_NEGOCIOS_SELECCIONAR_NEGOCIO,
    SELECCIONAR_NEGOCIO,
    REINICIAR_VARIABLES_ESTADOS_SELECCIONAR_NEGOCIO,
    OCULTAR_MODULO_SELECCIONAR_NEGOCIO
} from '../../../Constantes/Componentes/SeleccionarNegocio'
import {
    ObtenerPaisesComplementosReducer
} from '../Complementos/Complementos'

import {
    OBTENER_DATA_TABLA_CARGA_ARCHIVOS,
} from '../../../Constantes/CargaArchivos/CargaArchivos'
import {
    OBTENER_DATA_PAISES_NEGOCIO_COMPLEMENTOS,
    SELECCIONAR_PAIS_NEGOCIO_COMPLEMENTOS
} from '../../../Constantes/Complementos/Complementos'

export const ObtenerNegociosReducer = () => async (dispatch, getState) => {

    dispatch({
        type : CARGANDO_COMPONENTE_SELECCIONAR_NEGOCIO,
        payload : true
    })

    await fetch(config.api+'complementos/obtener-cuentas',
        {
            mode:'cors',
            method: 'GET',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            }
        }
    )
    .then( async res => {
            await dispatch(estadoRequestReducer(res.status))
            return res.json()
    })
    .then(data => {
            const estadoRequest = getState().estadoRequest.init_request
            if(data.respuesta == true){
                dispatch({
                    type: OBTENER_DATA_NEGOCIOS_SELECCIONAR_NEGOCIO,
                    payload: data.datos
                })
            }

    }).catch((error)=> {
        console.log(error)
        // dispatch(RegistrarErrorReducer(error, config.api+'login', datos))
    });

    dispatch({
        type : CARGANDO_COMPONENTE_SELECCIONAR_NEGOCIO,
        payload : false
    })

}

export const SeleccionarNegocioReducer = (negocio_id) => async (dispatch, getState) => {
    dispatch({
        type: SELECCIONAR_NEGOCIO,
        payload : negocio_id
    })

    dispatch(ObtenerPaisesComplementosReducer())
}

export const ReiniciarVariablesEstadosSeleccionarNegocioReducer = () => async (dispatch, getState) => {

    dispatch({
        type: REINICIAR_VARIABLES_ESTADOS_SELECCIONAR_NEGOCIO,
        payload: []
    })

    dispatch(OcultarModuloSeleccionarNegocioReducer(false))
    dispatch(ObtenerNegociosReducer())

}

export const OcultarModuloSeleccionarNegocioReducer = (accion) => async (dispatch) => {
    dispatch({
        type: OCULTAR_MODULO_SELECCIONAR_NEGOCIO,
        payload: accion
    })
}