import {
    COMUNES_CARGANDO_PAGINA_INICIO,
    COMUNES_CARGANDO_PAGINA
} from "../../../Constantes/Comunes/Comunes";

const INIT_STATE = {
    rex_ComunesCargandoPagina : true,
    rex_ComunesCargandoPaginaInicio : true
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case COMUNES_CARGANDO_PAGINA_INICIO : {
        return {
            ...state,
            rex_ComunesCargandoPaginaInicio : action.payload
        }
    }
    case COMUNES_CARGANDO_PAGINA: {
        return {
            ...state,
            rex_ComunesCargandoPagina : action.payload
        }
    }
    default:
      return state;
  }
}
