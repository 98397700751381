import React, { useState } from 'react'
import Modal from 'antd/lib/modal/Modal'
import { Row, Col, Form, Input, Select, Switch } from 'antd';
import { useSelector, useDispatch} from 'react-redux'
import {
    ModalEditarDtsDistribuidoraAdministradorDistribuidorasReducer,
    EditarDtsDistribuidorasAdministradorDistribuidorasReducer
} from '../../../Redux/Actions/Administrador/DtsDistribuidoras/DtsDistribuidoras';
import '../../../Estilos/Rutas/DtsDistribuidoras/DtsDistribuidoras.css'

const EditarDtsDistribuidoras = () => {

    const dispatch = useDispatch();
    const { Option } = Select;

    const {
        rex_editar_dts_distribuidoras_administrador
    } = useSelector(({administradorDistribuidoras}) => administradorDistribuidoras);

    const {
        rex_cuentas,
        rex_paises,
    } = useSelector(({administradorCuentas}) => administradorCuentas);

    const {
        rex_canales_administrador
    } = useSelector(({administradorCargaArchivos}) => administradorCargaArchivos);

    const [ dtse, setDtse ] = useState({...rex_editar_dts_distribuidoras_administrador})
    const [ editandoDts, setEditandoDts ] = useState(false)
    
    return (
        <Modal
            open={rex_editar_dts_distribuidoras_administrador}
            onCancel = {() => dispatch(ModalEditarDtsDistribuidoraAdministradorDistribuidorasReducer(false, []))}
            onOk = {async () => {
                setEditandoDts(true)
                await dispatch(EditarDtsDistribuidorasAdministradorDistribuidorasReducer(dtse))
                setEditandoDts(false)
            }}
            confirmLoading={editandoDts}
            okText='Guardar'
            width={600}
        >
            <h2 className='Text-Center-Agregar-Cuenta'>Editar Distribuidora</h2>
            <Form
                layout='vertical'
                autoComplete='off'
            >
                <Row>
                    <Col xs={{span:24}} sm={{span:12}} md={{span:12}} lg={{span:12}} xl={{span:12}}>
                        <Form.Item
                            label='Nombre:'
                            className='Input-Usuario'
                            name='dtsclientesubhml'
                            rules={[
                                { required: true, message: 'El nombre es requerido' },
                                { min: 4, message: 'El nombre es muy corto' }
                            ]}
                        >
                            <Input
                                name='dtsclientesubhml'
                                onChange={(e) => setDtse({
                                        ...dtse,[e.target.name]:e.target.value
                                    })
                                }
                                className='Input-Editar'
                                defaultValue={dtse.dtsclientesubhml}
                                size='large'
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={{span:24}} sm={{span:12}} md={{span:12}} lg={{span:12}} xl={{span:12}}>
                        <Form.Item
                            label='Canal:'
                            className='Input-Usuario'
                        >
                            <Select
                                value={dtse.canid}
                                className='Select-Dts-Administrador'
                                onChange={(valor)=> setDtse({...dtse, canid: valor})}
                            >
                                {
                                    rex_canales_administrador.map((can) => (
                                        <Option
                                            key={can.canid}
                                            value={can.canid}
                                        >
                                            {can.cannombre}
                                        </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{span:24}} sm={{span:4}} md={{span:4}} lg={{span:4}} xl={{span:4}}>
                        <Form.Item
                            label='Extractor:'
                            className='Input-Usuario'
                        >
                            <Switch
                                defaultChecked={dtse.dtsextractor}
                                className={dtse.dtsextractor ? 'Circulo-Activo': 'Circulo-Desactivo'}
                                onChange={(valor)=> setDtse({...dtse, dtsextractor: valor})}
                            />

                        </Form.Item>
                    </Col>
                    <Col xs={{span:24}} sm={{span:10}} md={{span:10}} lg={{span:10}} xl={{span:10}}>
                        <Form.Item
                            label='Cuenta:'
                            className='Input-Usuario'
                        >
                            <Select
                                defaultValue={dtse.cueid}
                                className='Select-Dts-Administrador'
                                onChange={(valor)=> setDtse({...dtse, cueid: valor})}
                            >
                                {
                                    rex_cuentas.map((cue) => (
                                        <Option
                                            key={cue.cueid}
                                            value={cue.cueid}
                                        >
                                            {cue.cuenombre}
                                        </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{span:24}} sm={{span:10}} md={{span:10}} lg={{span:10}} xl={{span:10}}>
                        <Form.Item
                            label='Pais:'
                            className='Input-Usuario'
                        >
                            <Select
                                defaultValue={dtse.paiid}
                                className='Select-Dts-Administrador'
                                onChange={(valor)=> setDtse({...dtse, paiid: valor})}
                            >
                                {
                                    rex_paises.map((pai) => (
                                        <Option
                                            key={pai.paiid}
                                            value={pai.paiid}
                                        >
                                            {pai.painombre}
                                        </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default EditarDtsDistribuidoras