
import config from "../../../../config";
import {
    OBTENER_USUARIOS_ADMINISTRADOR_USUARIOS,
    EDITAR_USUARIO_ADMINISTRADOR_USUARIOS,
    CARGANDO_TABLA_USUARIOS_ADMINISTRADOR,
    OCULTAR_MODAL_PERMISOS_ESPECIALES_ADMINISTRADOR_USUARIOS,
    OBTENER_PERMISOS_ESPECIALES_ADMINISTRADOR_USUARIOS,
    FILTRAR_USUARIOS_ADMINISTRADOR_USUARIOS
} from "../../../../Constantes/Administrador/Usuarios/Usuarios";
import { estadoRequestReducer } from "../../Comunes/EstadoRequest";
import { 
    exitoNotificacion, 
    alertaNotificacion, 
} from "../../../../Funciones/Notificaciones/Notificaciones";
import {
    ObtenerTpusAdministradorUsuariosReducer
} from "../TipoUsuarios/TipoUsuarios";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { DescargaDataExcel } from "../../../../Funciones/componentes/DescargaExcel/DescargaData"

export const ObtenerUsuariosAdministradorUsuariosReducer = () => async (dispatch, getState) => {

    dispatch(CargandoTablaUsuariosAdministradorReducer(true))

    await fetch(config.api+'obtener-usuarios',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
        }
    )
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        
        return res.json()
    })
    .then(data => {
        
        const estadoRequest = getState().estadoRequest.init_request;

        if(estadoRequest == true){

            dispatch({
                type: OBTENER_USUARIOS_ADMINISTRADOR_USUARIOS,
                payload: data.usuarios
            })
            dispatch({
                type: FILTRAR_USUARIOS_ADMINISTRADOR_USUARIOS,
                payload: data.usuarios
            })

        }else{
            alertaNotificacion(data.mensaje)
        }
    }).catch((error)=> {
        console.log(error)
    });

    dispatch(CargandoTablaUsuariosAdministradorReducer(false))
}

export const ObtenerPermisosEspecialesUsuariosAdministradorUsuariosReducer = (usuid) => async (dispatch, getState) => {

    await fetch(config.api+'obtener-permisos-especiales-usuarios',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                "usuid" : usuid
            }),
        }
    )
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        
        return res.json()
    })
    .then(data => {
        
        const estadoRequest = getState().estadoRequest.init_request;

        if(estadoRequest == true){
            dispatch({
                type: OBTENER_PERMISOS_ESPECIALES_ADMINISTRADOR_USUARIOS,
                payload: data.permisos
            })
        }else{
            if(data.errores){
                data.errores.map((err) => alertaNotificacion(err));
            }else{
                alertaNotificacion(data.mensaje);
            }
        }

    }).catch((error)=> {
        console.log(error)
    });
}

export const EditarUsuarioAdministradorUsuarioReducer = (usue) => async (dispatch, getState) => {
    

    let cambiacontrasenia = false;
    if(usue.usucontrasenia && usue.usucontrasenia.length > 0){
        cambiacontrasenia = true;
    }
    
    await fetch(config.api+'editar-usuario',{
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify(
            {
                "usuid"             : usue.usuid,
                "usuusuario"        : usue.usuusuario,
                "contrasenia"       : usue.usucontrasenia,
                "cambiacontrasenia" : cambiacontrasenia,
                "usucorreo"         : usue.usucorreo,
                "estado"            : usue.estid,
                "req_nombre"        : usue.pernombre,
                "req_paterno"       : usue.perapellpaterno,
                "req_materno"       : usue.perapellmaterno,
                "perid"             : usue.perid,
                "tpuid"             : usue.tpuid,
                "paisescuentas"     : usue.paisescuentas
            }
        ),
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'api_token'	   : localStorage.getItem('usutoken')
        }
        
    }).then( async res => {

        await dispatch(estadoRequestReducer(res.status))
            
        return res.json()
    })
    .then( data  => {

        const estadoRequest = getState().estadoRequest.init_request;

        if(estadoRequest == true){

            dispatch(ObtenerUsuariosAdministradorUsuariosReducer())
            exitoNotificacion(data.Mensaje);
            dispatch({
                type: EDITAR_USUARIO_ADMINISTRADOR_USUARIOS,
                payload: {
                    usue: {},
                    modalv: false
                }
            })

        }else{
            if(data.Errores){
                data.Errores.map(err=> alertaNotificacion(err));
            }else{
                alertaNotificacion(data.Mensaje);
            }
            return true;
        }

    }).catch((error)=> {
        console.log(error)
    });
}

export const RegistrarUsuariosAdministradorUsuariosReducer = (usudatos) => async (dispatch, getState) => {

    let registrado = false;

    await fetch(config.api + 'crear-usuario',
        {
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify(
                {
                    "usuusuario"        : usudatos.usuario,
                    "contrasenia"       : usudatos.contrasenia,
                    "usucorreo"         : usudatos.correo,
                    "imagen"            : usudatos.imagen,
                    "estado"            : usudatos.estado,
                    "req_nombre"        : usudatos.req_nombre,
                    "req_paterno"       : usudatos.req_paterno,
                    "req_materno"       : usudatos.req_materno,
                    "tpuid"             : usudatos.tpuid,
                    "paisescuentas"     : usudatos.paisescuentas
                }
            ),
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api_token'	   : localStorage.getItem('usutoken')
            }
        }
    )
    .then( async res => {

        await dispatch(estadoRequestReducer(res.status))

        return res.json()
    })
    .then( data  => {

        const estadoRequest = getState().estadoRequest.init_request;
        if(estadoRequest == true){

            dispatch(ObtenerTpusAdministradorUsuariosReducer())
            exitoNotificacion(data.Mensaje);
            registrado = true;

        }else{
            if(data.Errores){
                data.Errores.map(err=> alertaNotificacion(err));            
            }else{
                alertaNotificacion(data.Mensaje);
            }
        }

    }).catch((error)=> {
        console.log(error)
    });

    return registrado;
}

export const EliminaUsuarioAdministradorUsuariosReducer = (record) => async (dispatch, getState) => {

    await fetch(config.api + 'eliminar-usuario',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                "usuid": record.usuid
            }),
        }
    )
    .then( async res => {

        await dispatch(estadoRequestReducer(res.status))

        return res.json()
    })
    .then( data  => {

        const estadoRequest = getState().estadoRequest.init_request;

        if(estadoRequest == true){

            dispatch(ObtenerUsuariosAdministradorUsuariosReducer())
            exitoNotificacion(data.Mensaje);

        }else{
            if(data.Errores){
                data.Errores.map(err=> alertaNotificacion(err));
            }else{
                alertaNotificacion(data.Mensaje);
            }
        }

    }).catch((error)=> {
        console.log(error)
    });
}

export const AgregarPermisosEspecialesAdministradorUsuariosReducer = (usuid, pemids) => async (dispatch, getState) => {

    await fetch(config.api + 'agregar-permisos-especiales-usuarios',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                "usuid" : usuid,
                "pemids": pemids
            }),
        }
    )
    .then( async res => {

        await dispatch(estadoRequestReducer(res.status))

        return res.json()
    })
    .then( data  => {

        const estadoRequest = getState().estadoRequest.init_request;

        if(estadoRequest == true){
            exitoNotificacion(data.mensaje);
            dispatch(ModalPermisosEspecialesAdministradorUsuarioReducer(false))
            dispatch(ObtenerUsuariosAdministradorUsuariosReducer());
        }else{
            if(data.errores){
                data.errores.map((err) => alertaNotificacion(err));
            }else{
                alertaNotificacion(data.mensaje);
            }
        }

    }).catch((error)=> {
        console.log(error)
    });
}

export const DescargarDataUsuariosAdministradorReducer = (tpuid = 0, pais = 0, txtBuscar = '') => async (dispatch, getState) => {

    const { fileType, estilosHeader } = DescargaDataExcel();

    await fetch(config.api+'descargar-data-usuarios',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                "tpuid"      : tpuid,
                "paiid"      : pais,
                "txtBuscar"  : txtBuscar
            }),
        }
    )
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        
        return res.json()
    })
    .then(data => {
        
        const estadoRequest = getState().estadoRequest.init_request;

        if(estadoRequest == true){
            
            const ws = XLSX.utils.json_to_sheet(data.usuarios);

            ws['!cols'] = [];

            ws['!cols'] = [
                {'width' : 20},
                {'width' : 30},
                {'width' : 40},
                {'width' : 40},
                {'width' : 30},
                {'width' : 40},
            ];

            ws["A1"].s = estilosHeader;
            ws["B1"].s = estilosHeader;
            ws["C1"].s = estilosHeader;
            ws["D1"].s = estilosHeader;
            ws["E1"].s = estilosHeader;
            ws["F1"].s = estilosHeader;

            data.usuarios.map((data, index) => {
                ws["A" + (index+2) ].s = { alignment : { horizontal: 'center'} };
                ws["B" + (index+2) ].s = { alignment : { horizontal: 'center'} };
                ws["C" + (index+2) ].s = { alignment : { horizontal: 'center'} };
                ws["D" + (index+2) ].s = { alignment : { horizontal: 'center'} };
                ws["E" + (index+2) ].s = { alignment : { horizontal: 'center'} };
                ws["F" + (index+2) ].s = { alignment : { horizontal: 'center'} };
            });    

            const wb = { Sheets: { 'Usuarios':ws }, SheetNames: ['Usuarios'] };
            const excelBuffer = XLSX.write(wb, { bookType:'xlsx', type:'array'});
    
            const dataExcel = new Blob([excelBuffer], { type: fileType});
    
            FileSaver.saveAs(dataExcel, 'Usuarios.xlsx')

        }else{
            alertaNotificacion('Error al descargar la data')
        }

    }).catch((error)=> {
        console.log(error)
    });
}

export const DescargarActividadUsuariosAdministradorReducer = ( fechaActividad ) => async (dispatch, getState) => {

    const { fileType, estilosHeader } = DescargaDataExcel();

    await fetch(config.api+'descargar-actividad-usuarios',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                "fecha" : fechaActividad,
            }),
        }
    )
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        
        return res.json()
    })
    .then(data => {
        
        const estadoRequest = getState().estadoRequest.init_request;

        if(estadoRequest == true){

            const ws = XLSX.utils.json_to_sheet(data.actividad);

            ws['!cols'] = [];

            ws['!cols'] = [
                {'width' : 10},
                {'width' : 40},
                {'width' : 30},
                {'width' : 20},
            ];

            ws["A1"].s = estilosHeader;
            ws["B1"].s = estilosHeader;
            ws["C1"].s = estilosHeader;
            ws["D1"].s = estilosHeader;

            data.actividad.map((data, index) => {
                ws["A" + (index+2) ].s = { alignment : { horizontal: 'center'} };
                ws["B" + (index+2) ].s = { alignment : { horizontal: 'center'} };
                ws["C" + (index+2) ].s = { alignment : { horizontal: 'center'} };
                ws["D" + (index+2) ].s = { alignment : { horizontal: 'center'} };
            });

            const wb = { Sheets: { 'Actividad':ws }, SheetNames: ['Actividad'] };
            const excelBuffer = XLSX.write(wb, { bookType:'xlsx', type:'array'});
    
            const dataExcel = new Blob([excelBuffer], { type: fileType});
    
            FileSaver.saveAs(dataExcel, 'Actividad-Usuarios.xlsx')

        }else{
            if(data.errores){
                data.errores.map((err) => alertaNotificacion(err));
            }else{
                alertaNotificacion(data.mensaje);
            }
        }

    }).catch((error)=> {
        console.log(error)
    });
}

export const ModalPermisosEspecialesAdministradorUsuarioReducer = (estado) => async (dispatch, getState) => {

    dispatch({
        type: OCULTAR_MODAL_PERMISOS_ESPECIALES_ADMINISTRADOR_USUARIOS,
        payload: estado
    })
}

export const ModalEditarUsuariosAdministradorReducer = ( usu, estado ) => async ( dispatch, getState) => {

    dispatch({
        type: EDITAR_USUARIO_ADMINISTRADOR_USUARIOS,
        payload: {
            usue    : usu,
            modalv  : estado
        }
    })
}

export const CargandoTablaUsuariosAdministradorReducer = ( estado ) => async ( dispatch, getState ) => {

    dispatch({
        type: CARGANDO_TABLA_USUARIOS_ADMINISTRADOR,
        payload: estado
    })
}

export const EditarPermisosEspecialesUsuariosAdministradorReducer = (tppIndex, valor) => async (dispatch, getState) => {

    let listaPermisosEspecialesUsuarios  = getState().administradorUsuarios.rex_permisos_especiales_usuarios;

    listaPermisosEspecialesUsuarios[tppIndex]['checked'] = valor;

    listaPermisosEspecialesUsuarios[tppIndex]['permisos'].map((tip, indexPem) => {
        listaPermisosEspecialesUsuarios[tppIndex]['permisos'][indexPem]['checked'] = valor;
    })

    dispatch({
        type: OBTENER_PERMISOS_ESPECIALES_ADMINISTRADOR_USUARIOS,
        payload: listaPermisosEspecialesUsuarios
    })
}

export const EditarPermisoEspecialUsuariosAdministradorReducer = (tppIndex, pemIndex, valor) => async (dispatch, getState) => {

    let listaPermisosEspecialesUsuarios  = getState().administradorUsuarios.rex_permisos_especiales_usuarios;

    let tamanioPermisos = listaPermisosEspecialesUsuarios[tppIndex]['permisos'].length;
    
    listaPermisosEspecialesUsuarios[tppIndex]['permisos'][pemIndex]['checked'] = valor;

    let listaPermisos = listaPermisosEspecialesUsuarios[tppIndex]['permisos'].filter((tip) => tip.checked == valor);
    
    listaPermisosEspecialesUsuarios[tppIndex]['checked'] = listaPermisos.length == tamanioPermisos && valor;
    
    dispatch({
        type: OBTENER_PERMISOS_ESPECIALES_ADMINISTRADOR_USUARIOS,
        payload: listaPermisosEspecialesUsuarios
    })
}

export const GuardarPermisosEspecialesUsuariosAdministradorReducer = ( usuid ) => async (dispatch, getState) => {

    let listaPermisosEspecialesUsuarios  = getState().administradorUsuarios.rex_permisos_especiales_usuarios;

    let listaPemids = [];

    let pemids = listaPermisosEspecialesUsuarios.map((pem) => pem.permisos.filter((pemid) => pemid.checked == true)).filter((pem) => pem.length > 0);

    pemids.map((pem) => pem.map((pemid) => listaPemids.push(pemid.pemid)));

    await dispatch(AgregarPermisosEspecialesAdministradorUsuariosReducer(usuid, listaPemids))
}

export const FiltrarUsuariosAdministradorReducer = (tpuid, paiid, txtBuscar) => ( dispatch, getState) => {

    let listaUsuarios     = getState().administradorUsuarios.rex_usuarios;
    
    if(txtBuscar || paiid || tpuid ){

        let usuariosFiltrados = listaUsuarios.filter((usu) => 
            (
                (usu.pernombrecompleto.toLowerCase().includes(txtBuscar.toLocaleLowerCase()))
                || (usu.usuusuario.toLowerCase().includes(txtBuscar.toLocaleLowerCase()))
                || (usu.tpunombre.toLowerCase().includes(txtBuscar.toLocaleLowerCase()))
            )
            && (paiid != 0 ? usu.paisescuentas.find(paic => paic.paiid == paiid) : usu.paisescuentas.find(paic => typeof  paic.paiid == 'number'))
            && (tpuid != 0 ? usu.tpuid == tpuid : typeof usu.tpuid == 'number' )
        )
        dispatch({
            type    : FILTRAR_USUARIOS_ADMINISTRADOR_USUARIOS,
            payload : usuariosFiltrados
        })
    }else{

        dispatch({
            type    : FILTRAR_USUARIOS_ADMINISTRADOR_USUARIOS,
            payload : listaUsuarios
        });
    }
}