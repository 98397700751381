import React, { useState } from 'react'
import { Modal, Form, Row, Col, Input, Select, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { 
	EditarTipoAdministradorCargaArchivosReducer,
	MostrarModalEditarTipoCargaCargaArchivos
} from '../../../Redux/Actions/Administrador/CargaArchivos/CargaArchivos';
import '../../../Estilos/Rutas/Administrador/CargaArchivos.css'
import FooterModalCargaArchivos from '../../../Funciones/componentes/FooterModalCargaArchivos';

const EditarTipoCarga = (props) => {

	const dispatch = useDispatch();
	const { Option } = Select;

	const {
		rex_modal_editar_tcatipo_administrador,
		rex_tcatipe_editar_administrador,
		rex_canales_administrador,
		rex_categorias_unicos
    } = useSelector(({administradorCargaArchivos}) => administradorCargaArchivos);
	
	const {
		rex_paises,
		rex_cuentas
    } = useSelector(({administradorCuentas}) => administradorCuentas);

	const [ enviando, setEnviando ] = useState(false);

	const [ tcatipe, setTcatipe ] = useState({...rex_tcatipe_editar_administrador});

	const editarTipoCarga = async () => {
		setEnviando(true);
		await dispatch(EditarTipoAdministradorCargaArchivosReducer(tcatipe));
		props.limpiarFiltro();
		setEnviando(false);
	}

	const cancelar = () => {
		dispatch(MostrarModalEditarTipoCargaCargaArchivos(false,[]))
	}

	return (
		<Modal
			centered={true}
			open={rex_modal_editar_tcatipo_administrador}
			width={700}
			alignItems='center'
			className='ant-modal-content'
			footer={null}
			getContainer="#root"
			onCancel={cancelar}
		>
			<Form
				layout='vertical'
				autoComplete='off'
				onFinish={editarTipoCarga}
			>
				<h2 className='Text-Center-Carga-Archivos'>Editar Tipo Carga</h2>
				<Row>
					<Col
						xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}} xl={{span:12}}
					>
						<Form.Item
							label='Tipo de carga:'
							className='Input-Usuario'
						>
							<Input
                                name='tcanombre'
                                onChange={(e) => setTcatipe({...tcatipe, tcanombre:e.target.value})}
                                className='Input-Editar'
                                value={tcatipe.tcanombre}
                                size='large'
                            />
						</Form.Item>

					</Col>
					<Col
						xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}} xl={{span:12}}
					>
						<Form.Item
							label='Categoria:'
							className='Input-Usuario'
						>
							<Select
								onChange={(valor) => setTcatipe({...tcatipe, ccanombre:valor})}
								size='large'
								placeholder='Seleccionar'
								className='Input-Editar'
								defaultValue={tcatipe.ccanombre}
							>
								{
									rex_categorias_unicos.map((cca) => 
										<Option
										key={cca.ccanombre}
										value={cca.ccanombre}
									>
											{cca.ccanombre}
										</Option>
									)
								}
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col
						xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}}
					>
						<Form.Item
							label='Canal:'
							className='Input-Usuario'
						>
							<Select
								onChange={(valor) => setTcatipe({...tcatipe, canid:valor})}
								defaultValue={tcatipe.canid}
								size='large'
								placeholder='Seleccionar'
							>
								{rex_canales_administrador.map((can)=> (
									<Option
										key={can.canid}
										value={can.canid}
									>
										{can.cannombre}
									</Option>
								))}
							</Select>

						</Form.Item>
					</Col>
					<Col
						xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}}
					>
						<Form.Item
							label='Empresa:'
							className='Input-Usuario'
						>
							<Select
								onChange={(valor) => setTcatipe({...tcatipe, cueid:valor})}
								defaultValue={tcatipe.cueid}
								size='large'
								placeholder='Seleccionar'
							>
								{rex_cuentas.map((cue)=> (
									<Option
										key={cue.cueid}
										value={cue.cueid}
									>
										{cue.cuenombre}
									</Option>
								))}
							</Select>

						</Form.Item>
					</Col>
					<Col
						xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}}
					>
						<Form.Item
							label='Pais:'
							className='Input-Usuario'
						>
							<Select
								onChange={(valor) => setTcatipe({...tcatipe, paiid: valor})}
								defaultValue={tcatipe.paiid}
								size='large'
								placeholder='Seleccionar'
							>
								{rex_paises.map((pai)=> (
									<Option
										key={pai.paiid}
										value={pai.paiid}
									>
										{pai.painombre}
									</Option>
								))}
							</Select>

						</Form.Item>
					</Col>
				</Row>
				<Row>
                    <Col>
                        <Form.Item
                            className='Input-Usuario'
                            label='Estado:'
                        >
                            <Switch
                                defaultChecked = {tcatipe.tcaestado}
								className={tcatipe.tcaestado ? 'Circulo-Activo': 'Circulo-Desactivo'}
                                onChange={(valor) => setTcatipe({...tcatipe, tcaestado: valor})}
                                size='large'
                            />
                        </Form.Item>
                    </Col>
                </Row>
				<FooterModalCargaArchivos cancelar={cancelar} enviando={enviando}/>
			</Form>

		</Modal>
	)
}

export default EditarTipoCarga