import {
    OBTENER_DATOS_TPUS_ADMINISTRADOR_USUARIOS,
    AGREGAR_TIPO_USUARIO_ADMINISTRADOR_USUARIOS,
    FILTRAR_DATOS_TPUS_ADMINISTRADOR_USUARIOS,
} from "../../../../Constantes/Administrador/TipoUsuarios/TipoUsuarios";


const INIT_STATE = {

    rex_tipos_usuarios : [], 
    rex_filtrar_tipos_usuarios : [],
    rex_modal_agregar_tipo_usuario: false,

};

export default (state = INIT_STATE, action) => {

    switch (action.type) {

        case OBTENER_DATOS_TPUS_ADMINISTRADOR_USUARIOS: {

            return {
                ...state,
                rex_tipos_usuarios : action.payload
            }
        }

        case FILTRAR_DATOS_TPUS_ADMINISTRADOR_USUARIOS: {

            return {
                ...state,
                rex_filtrar_tipos_usuarios : action.payload
            }
        }

        case AGREGAR_TIPO_USUARIO_ADMINISTRADOR_USUARIOS: {

            return {
                ...state,
                rex_modal_agregar_tipo_usuario : action.payload.modalagtpu,
            }
        }
        
        default:
            return state;
    }
}