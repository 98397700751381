
export const DescargaDataExcel = () => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const estilosHeader = {
        font: {
            name: 'Calibri',
            sz: 12,
            bold: true,
            color: { rgb: "FFFFFF" }
        },
        fill: {
            bgColor: { rgb: '1E90FF'}
        },
        alignment: {
            horizontal: 'center'
        }, 
    }

    return {
        fileType,
        estilosHeader,
    }
}