import {
    OBTENER_PERMISOS_TIPO_USUARIO_ADMINISTRADOR,
    OBTENER_PERMISOS_ADMINISTRADOR,
    EDITAR_PERPERMISOS_ADMINISTRADOR,
    AGREGAR_PERPERMISOS_ADMINISTRADOR,
    FILTRAR_PERMISOS_ADMINISTRADOR,
    OCULTAR_MODAL_TIPOS_PERMISOS_ADMINISTRADOR,
    OBTENER_TIPOS_PERMISOS_ADMINISTRADOR,
} from "../../../../Constantes/Administrador/Permisos/Permisos";

const INIT_STATE = {

    rex_permisos                            : [],
    rex_filtrar_permisos                    : [],
    rex_editar_permisos                     : [],
    rex_tipos_permisos                      : [],
    rex_permisos_tipo_usuario               : [],
    rex_modal_tipos_permisos                : false,
    rex_modal_editar_permisos               : false,
    rex_modal_agregar_permisos              : false
}

export default (state = INIT_STATE, action) => {

    switch (action.type) {

        case OCULTAR_MODAL_TIPOS_PERMISOS_ADMINISTRADOR: {

            return {
                ...state,
                rex_modal_tipos_permisos : action.payload,
            }

        }

        case OBTENER_PERMISOS_TIPO_USUARIO_ADMINISTRADOR: {

            return {
                ...state,
                rex_permisos_tipo_usuario : action.payload
            }
        }

        case FILTRAR_PERMISOS_ADMINISTRADOR: {
            
            return {
                ...state,
                rex_filtrar_permisos : action.payload

            }
        }

        case OBTENER_PERMISOS_ADMINISTRADOR: {

            return {
                ...state,
                rex_permisos          : action.payload.permisos,
            }
        }

        case EDITAR_PERPERMISOS_ADMINISTRADOR: {

            return {
                ...state,
                rex_editar_permisos                 : action.payload.permisose,
                rex_modal_editar_permisos            : action.payload.modaleditarpermisos,
            }
        }

        case OBTENER_TIPOS_PERMISOS_ADMINISTRADOR: {
            return {
                ...state,
                rex_tipos_permisos: action.payload
            }
        }

        case AGREGAR_PERPERMISOS_ADMINISTRADOR: {

            return {
                ...state,
                rex_modal_agregar_permisos    : action.payload,
            }
        }

        default:
            return state;
    }

}