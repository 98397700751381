import React, { useEffect, useState } from 'react'
import { Table, Tooltip, Button, Input, Spin, Popconfirm, Switch, Row, Col, Pagination, Modal } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { 
	ObtenerCanalesAdministradorCargaArchivosReducer,
	EditarCanalAdministradorCargaArchivos,
	FiltrarCanalAdministradorCargaArchivoReducer,
	MostrarModalAgregarCanalCargaArchivos,
	EliminarCanalAdministradorCargaArchivosReducer
} from '../../../Redux/Actions/Administrador/CargaArchivos/CargaArchivos'
import AgregarCanales from './AgregarCanales'
import Moment from 'moment'
import { 
	EditOutlined,
	CloseCircleOutlined,
	CheckCircleOutlined,
	SearchOutlined,
	DeleteOutlined,
	ExclamationCircleOutlined,
	ReloadOutlined,
	PlusCircleOutlined
} from '@ant-design/icons'
import { antIcon } from '../../../Funciones/componentes/antd'
import '../../../Estilos/Rutas/Administrador/CargaArchivos.css'

const Canales = () => {

	const { confirm } = Modal;

	const dispatch = useDispatch();
    const [ cargando, setCargando ] = useState(false);
	const [ pageTableCanal, setPageTableCanal ] = useState(1)
    const [ pageSizeTableCanal, setPageSizeTableCanal ] = useState(10);
	const [ enviando, setEnviando ] = useState(false)
	const [ canEditar, setCanEditar ] = useState({});
	const [ txtBuscar, setTxtBuscar ] = useState('')

	useEffect(() => {
		obtenerCanalesAdministrador();
	}, []);

	const {
		rex_modal_agregar_canal_administrador,
		rex_filtro_canal
    } = useSelector(({administradorCargaArchivos}) => administradorCargaArchivos);

	const guardarCanal = async (cane) => {
        
		setEnviando(true);
		let respuesta = await dispatch(EditarCanalAdministradorCargaArchivos(cane));
		setEnviando(false);
		setTxtBuscar('')
		respuesta && setCanEditar({})
    };

	const obtenerCanalesAdministrador = async () => {
		setCargando(true);
		await dispatch(ObtenerCanalesAdministradorCargaArchivosReducer());
		setCargando(false);
	}

	const confirmarEliminarCanal = ( {canid, cannombre} ) => {
        confirm({
            title: `¿Eliminar canal ${cannombre}?`,
            icon: <ExclamationCircleOutlined />,
            okText: 'Eliminar',
            content: <div>Se eliminará toda la información incluyendo:<br/><br/>
			- Registro con alguna cuenta<br/>
			- Registro con alguna categoria<br/>
			- Registro con algún tipo de archivo<br/>
			- Registro con algún archivo<br/>
		</div>,
        
            async onOk() {
                
                return await dispatch(EliminarCanalAdministradorCargaArchivosReducer(canid));
            },
        
            onCancel() {},
        });
    }

	const columns = [
		{
			title: 'Item',
			dataIndex: 'canid',
			key: 'canid',
			align: 'center',
			width:'80px',
			render: (text, record, index) => (pageTableCanal - 1) * pageSizeTableCanal + index + 1,
		},
		{
			title: 'Estado',
			key: 'canestado',
			align: 'center',
			width:'80px',
			render: (_, record) => (
				
			record.canid == canEditar.canid

				? <Switch 
						defaultChecked={record.canestado}
						className={canEditar.canestado ? 'Circulo-Activo': 'Circulo-Desactivo'}
						onChange={(valor)=> setCanEditar({...canEditar, canestado:valor})}
					/>

				: <div style={{display:'flex', justifyContent:'center'}}>
					<Tooltip placement="top" title={record.canestado ? 'Activado':'Desactivado'}>
						<div className={`Circulo ${record.canestado? 'Circulo-Activo': 'Circulo-Desactivo'}`}>
						</div>
					</Tooltip>

				</div>
			)
		},
		{
			title: 'Canal',
			key: 'cannombre',
			align: 'center',
			render:(_, record) => (

				record.canid == canEditar.canid
				? <Input
					style={{textAlign:'center'}}
					defaultValue={record.cannombre}
					onChange={(e) => setCanEditar({...canEditar, cannombre:e.target.value})}
				/>
				: <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
					{record.cannombre}
				  </div>
			)
		},
		{
			title: 'Fecha de creación',
			key: 'created_at',
			align: 'center',
			width:'220px',
			render: (_, record) => (
				<>{record.created_at?Moment(record.created_at).format('LLL'):'Sin registro'}</>
			),
		},
		{
			title: 'Fecha de actualización',
			key: 'updated_at',
			align: 'center',
			width:'220px',
			render: (_, record) => (
				<>{record.updated_at?Moment(record.updated_at).format('LLL'):'Sin registro'}</>
			),
		},
		{
			title: 'Acción',
			key: 'editar',
			align: 'center',
			width: '100px',
			render: (_,record ) => (

				record.canid == canEditar.canid
				? enviando
					? <Spin indicator={antIcon}/>

					: <div className='Contenedor-Acciones-Iconos'>
						<Tooltip placement='top' title='Guardar'>
							<Button
								className='Boton-Accion-Editar'
								onClick={() => guardarCanal(canEditar)}
							>
								<CheckCircleOutlined
									className='Icono-Accion-Editar'
								/>
							</Button>
						</Tooltip>
						<Tooltip placement='top' title='Cancelar'>
							<Popconfirm title="Cancelar la edición?" onConfirm={()=> setCanEditar({})}>
								<Button
									className='Boton-Accion-Eliminar'
									danger
								>
									<CloseCircleOutlined/>
								</Button>
							</Popconfirm>
						</Tooltip>
					 </div>
				
				: (
					<div className='Contenedor-Acciones-Iconos'>
						<Tooltip placement="top" title='Editar'>
							<Button
								className='Boton-Accion-Editar'
								onClick={
									() => setCanEditar({
										...canEditar,
										canid: record.canid, 
										canestado: record.canestado,
										cannombre: record.cannombre
								})}
							>
								<EditOutlined
									className='Icono-Accion-Editar'
								/>
							</Button>
						</Tooltip>
						<Tooltip placement='top' title='Eliminar'>
							<Button
								className='Boton-Accion-Eliminar'
								onClick={()=> confirmarEliminarCanal(record)}
							>
								<DeleteOutlined
									className='Icono-Accion-Eliminar'
								/>
							</Button>
						</Tooltip>
					</div>
				)
			)
		},
	];

	return (
		<div
			className='Contenedor-Carga-Archivos-Tabla-Canales'
		>
			<Row className='Contenedor-Fila-Canales'>
				<Col>
					<Button
						className='Boton-Crear-Carga-Archivos'
						onClick={() => dispatch(MostrarModalAgregarCanalCargaArchivos(true))}
					>Crear<PlusCircleOutlined/></Button>
				</Col>
			</Row>

			<Row className='Contenedor-Filtro-Carga-Archivos'>
				<Col>
					<Row>
						<Col span={20}>
							<Input
								suffix={<SearchOutlined className='Icono-Input-Buscador' />}
								size='large'
								className='Input-Buscador-Carga-Archivos'
								placeholder='Buscar'
								value={txtBuscar}
								onChange={(e) => {
									setTxtBuscar(e.target.value)
									dispatch(FiltrarCanalAdministradorCargaArchivoReducer(e.target.value))
								}}
							/>
						</Col>
						<Col span={4} style={{display:'flex',alignItems:'center'}}>
							<Tooltip title='Actualizar'>
								<div
									className='Boton-Actualizar'
									onClick={ async ()=> {
											setCargando(true);
											await dispatch(ObtenerCanalesAdministradorCargaArchivosReducer())
											setCargando(false);
									}}
								>
									<ReloadOutlined
										className='Icono-Actualizar'
									/>
								</div>
							</Tooltip>
						</Col>
					</Row>
				</Col>
				<Col>
					<Pagination
						defaultCurrent={pageTableCanal}
						current={pageTableCanal}
						total={rex_filtro_canal.length}
						onChange={(page, pageSize) => {
							setPageTableCanal(page == 0? 1 : page)
							setPageSizeTableCanal(pageSize)
						}}
					/>
				</Col>
			</Row>
			<Table
				loading={cargando}
				dataSource={rex_filtro_canal} 
				columns={columns}
				size='small'
				pagination={{
					defaultCurrent: pageTableCanal,
					current: pageTableCanal,
					pageSize: pageSizeTableCanal,
					position: ['topRight'],
				}}
			/>

			{rex_modal_agregar_canal_administrador && <AgregarCanales setTxtBuscar={setTxtBuscar}/> }
		</div>
	)
}

export default Canales