import React from 'react'
import Top from '../Componentes/Top/Top'
import CargaArchivos from './CargaArchivos/CargaArchivos'
import Status from './Status/Status'
import DataDrivenPerformance from './DataDrivenPerformance/DataDrivenPerformance'
import SpiderWeb from './SpiderWeb/SpiderWeb'
import {Route, Routes, Navigate } from "react-router-dom";
import RegistroUsuarios from '../Componentes/Administrador/Usuarios/RegistroUsuarios'
import Usuarios from './Administrador/Usuarios/Usuarios'
import TipoUsuarios from './Administrador/TipoUsuarios/TipoUsuarios'
import Permisos from './Administrador/Permisos/Permisos'
import PermisosTipos from './Administrador/Permisos/PermisosTipos'
import CuentasPaises from './Administrador/Cuentas/CuentasPaises'
import CargaArchivosAdministrador from './Administrador/CargaArchivos/CargaArchivosAdministrador'
import DtsDistribuidoras from './Administrador/DtsDistribuidoras/DtsDistribuidoras'
import StatusDtKc from './StatusDtKc/StatusDtKc'
import ControlPanel from './ControlPanel/ControlPanel'
import EditarSubTipo from '../Componentes/Administrador/CargaArchivos/EditarSubTipo'

const Rutas = () => {
    return (
        <div
            style={{
                position:'relative',
            }}
        >

            <Top  
            />
            <Routes>
                <Route exact path='/usuarios' element={<Usuarios/>}></Route>
                <Route exact path='/cuentas-paises' element={<CuentasPaises/>}></Route>
                <Route exact path='/tipo-usuarios' element={<TipoUsuarios/>}></Route>
                <Route exact path='/permisos' element={<PermisosTipos/>}></Route>
                <Route exact path="/permisos/:tpuid/:tpunombre" element={<Permisos/>}></Route>
                <Route exact path='/registro-usuarios' element={<RegistroUsuarios/>}></Route>
                <Route exact path='/administrador/carga-archivos' element={<CargaArchivosAdministrador/>}></Route>
                <Route exact path='/administrador/carga-archivos/editar' element={<EditarSubTipo/>}></Route>
                <Route exact path='/spider-web' element={<SpiderWeb/>}/>
                {/* <Route exact path='/data-driven-performance' element={<DataDrivenPerformance/>}/> */}
                <Route exact path='/status-dt-kc' element={<StatusDtKc/>}/>
                <Route exact path='/carga-archivos' element={<CargaArchivos/>}/>
                <Route exact path='/administrador/dts' element={<DtsDistribuidoras/>}/>
                <Route exact path='/home' element={<Status/>}/>
                <Route exact path='/panel-control' element={<ControlPanel/>}/>
                <Route path="*" element={<Navigate replace to="/home" />} />
            </Routes>

        </div>
    )
}

export default Rutas