import {
    OBTENER_DATA_MODAL_DTS_CARGA_ARCHIVOS
} from '../../..//Constantes/CargaArchivos/CargaArchivos'
import config from '../../../config'
import { estadoRequestReducer } from "../Comunes/EstadoRequest"
import { notification } from 'antd'

export const ObtenerDistribuidorasDisponiblesPaisCuentaReducer = (canal, stcid, pacid, canid) => async (dispatch, getState) => {

    const rex_seleccionar_pais_complementos = getState().complementos.rex_seleccionar_pais_complementos

    await fetch(config.api+'carga-archivos/mostrar-maestra-distribuidoras',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                "req_pacid": pacid ? pacid : rex_seleccionar_pais_complementos.pacid ,
                "req_canal": canal,
                "req_stcid": stcid,
                "req_canid": canid
            }),
        }
    )
    .then( async res => {
            await dispatch(estadoRequestReducer(res.status))
            return res.json()
    })
    .then(data => {
        const estadoRequest = getState().estadoRequest.init_request
        
        if(estadoRequest == true){
            
            if(data.respuesta == true){
                dispatch({
                    type : OBTENER_DATA_MODAL_DTS_CARGA_ARCHIVOS,
                    payload : data.datos
                })
            }else{
                dispatch({
                    type : OBTENER_DATA_MODAL_DTS_CARGA_ARCHIVOS,
                    payload : data.datos
                })
                notification.error({
                    message: `Notificación`,
                    description: data.mensaje,
                    placement:'topRight',
                });
            }

        }

    }).catch((error)=> {
        console.log(error)
        let res = JSON.parse(error.request.response)
        notification.error({
            message: `Notificación`,
            description: res.mensaje,
            placement:'topRight',
        });
        // dispatch(RegistrarErrorReducer(error, config.api+'login', datos))
    });

}

export const SeleccionarDtCargaArchivoReducer = (posicion) => async (dispatch, getState) => {
    
    let rex_data_modal_dts_carga_archivos = getState().modalDtsCargaArchivos.rex_data_modal_dts_carga_archivos

    rex_data_modal_dts_carga_archivos.map((data, pos) => {
        if(pos == posicion){
            rex_data_modal_dts_carga_archivos[pos]['seleccionado'] = true
        }else{
            rex_data_modal_dts_carga_archivos[pos]['seleccionado'] = false
        }
    })

    dispatch({
        type : OBTENER_DATA_MODAL_DTS_CARGA_ARCHIVOS,
        payload : rex_data_modal_dts_carga_archivos
    })

}