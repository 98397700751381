export const OBTENER_DATA_CARGA_ARCHIVOS = 'OBTENER_DATA_CARGA_ARCHIVOS'
export const OBTENER_DATA_TABLA_CARGA_ARCHIVOS = 'OBTENER_DATA_TABLA_CARGA_ARCHIVOS'
export const ACTUALIZAR_COLUMNAS_TABLA_CARGA_ARCHIVOS = 'ACTUALIZAR_COLUMNAS_TABLA_CARGA_ARCHIVOS'
export const SELECCIONAR_FILA_DATA_TABLA_CARGA_ARCHIVOS = 'SELECCIONAR_FILA_DATA_TABLA_CARGA_ARCHIVOS'
export const MOSTRAR_MODAL_CONFIRMACION_TABLA_CARGA_ARCHIVOS = 'MOSTRAR_MODAL_CONFIRMACION_TABLA_CARGA_ARCHIVOS'
export const MOSTRAR_MODAL_SELECCIONAR_DT_TABLA_CARGA_ARCHIVOS = 'MOSTRAR_MODAL_SELECCIONAR_DT_TABLA_CARGA_ARCHIVOS'
export const PETICION_GUARDANDO_PYTHON_CARGA_ARCHIVOS = 'PETICION_GUARDANDO_PYTHON_CARGA_ARCHIVOS'
export const PETICION_OBTENIDA_PYTHON_CARGA_ARCHIVOS = 'PETICION_OBTENIDA_PYTHON_CARGA_ARCHIVOS'
export const MOSTRAR_MODAL_NOTIFICACION_TABLA_CARGA_ARCHIVOS = 'MOSTRAR_MODAL_NOTIFICACION_TABLA_CARGA_ARCHIVOS'
export const MOSTRAR_MODAL_GUARDANDO_ARCHIVOS_PYTHON_TABLA_CARGA_ARCHIVOS = 'MOSTRAR_MODAL_GUARDANDO_ARCHIVOS_PYTHON_TABLA_CARGA_ARCHIVOS'
export const MOSTRAR_MODAL_TIEMPO_PETICION_TABLA_CARGA_ARCHIVOS = 'MOSTRAR_MODAL_TIEMPO_PETICION_TABLA_CARGA_ARCHIVOS'
export const MOSTRAR_MODAL_RANGO_TIEMPO_INVALIDO_CARGA_ARCHIVOS = 'MOSTRAR_MODAL_RANGO_TIEMPO_INVALIDO_CARGA_ARCHIVOS'
export const HORARIOS_MODAL_RANGO_TIEMPO_INVALIDO_CARGA_ARCHIVOS = 'HORARIOS_MODAL_RANGO_TIEMPO_INVALIDO_CARGA_ARCHIVOS'

export const OBTENER_DATA_MODAL_DTS_CARGA_ARCHIVOS = 'OBTENER_DATA_MODAL_DTS_CARGA_ARCHIVOS'