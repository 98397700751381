import React, { useState } from 'react'
import { Modal, Form, Row, Col, Select, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { 
	EditarCanalCuentaAdministradorCargaArchivos,
    MostrarModalEditarCanalCuentaCargaArchivos
} from '../../../Redux/Actions/Administrador/CargaArchivos/CargaArchivos';
import '../../../Estilos/Rutas/Administrador/CargaArchivos.css'
import FooterModalCargaArchivos from '../../../Funciones/componentes/FooterModalCargaArchivos';

const EditarCanalesCuentas = (props) => {

    const dispatch = useDispatch();
    
    const {
		rex_modal_editar_cancue_administrador,
        rex_cancue_editar_administrador,
        rex_canales_administrador,
    } = useSelector(({administradorCargaArchivos}) => administradorCargaArchivos);

    const {
        rex_cuentas
    } = useSelector(({administradorCuentas}) => administradorCuentas);

    const [ enviando, setEnviando ] = useState(false);
    const [ cancuee, setCancuee ] = useState({...rex_cancue_editar_administrador});

    const { Option } = Select;

    const editarCanalCuenta = async () => {
        setEnviando(true);
        await dispatch(EditarCanalCuentaAdministradorCargaArchivos(cancuee));
        props.limpiarFiltro();
        setEnviando(false);
    }

    const cancelar = () => {
        dispatch(MostrarModalEditarCanalCuentaCargaArchivos(false,[]))
    }

    return (
        <Modal
            centered={true}
            open={rex_modal_editar_cancue_administrador}
            onCancel = {cancelar}
            width={700}
            alignItems='center'
            className='ant-modal-content'
            getContainer="#root"
            footer={null}
        >
            <Form
                autoComplete="off"
                layout="vertical"
                onFinish={editarCanalCuenta}
            >
                <h2 className='Text-Center-Carga-Archivos'>Editar Canal Cuenta</h2>

                <Row>
                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}} xl={{span:12}}
                    >
                        <Form.Item
                            label='Empresa:'
                            className='Input-Usuario'
                        >
                            <Select
                                onChange={(valor) => setCancuee({...cancuee, cueid:valor})}
                                size='large'
                                defaultValue={cancuee.cueid}
                            >
                                {rex_cuentas.map((cue)=> (
                                    <Option
                                        key={cue.cueid}
                                        value={cue.cueid}
                                    >
                                        {cue.cuenombre}
                                    </Option>
                                ))}
                            </Select>

                        </Form.Item>
                    </Col>
                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}} xl={{span:12}}
                    >
                        <Form.Item
                            label='Canal:'
                            className='Input-Usuario'
                        >
                            <Select
                                onChange={(valor) => setCancuee({...cancuee, canid: valor})}
                                size='large'
                                defaultValue={cancuee.canid}
                            >
                                {rex_canales_administrador.map((can)=> (
                                    <Option
                                        key={can.canid}
                                        value={can.canid}
                                    >
                                        {can.cannombre}
                                    </Option>
                                ))}
                            </Select>

                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:24}} lg={{span:24}} xl={{span:24}}
                    >
                        <Form.Item
                            className='Input-Usuario'
                            label='Estado:'
                        >
                            <Switch
                                defaultChecked = {cancuee.cacestado}
                                className={cancuee.cacestado ? 'Circulo-Activo': 'Circulo-Desactivo'}
                                onChange={(valor) => setCancuee({...cancuee, cacestado: valor })}
                                size='large'
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <FooterModalCargaArchivos cancelar={cancelar} enviando={enviando}/>
            </Form>

        </Modal>
    )
}

export default EditarCanalesCuentas