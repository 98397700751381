import {
    OBTENER_DATA_NEGOCIOS_SELECCIONAR_NEGOCIO,
    CARGANDO_COMPONENTE_SELECCIONAR_NEGOCIO,
    SELECCIONAR_NEGOCIO,
    REINICIAR_VARIABLES_ESTADOS_SELECCIONAR_NEGOCIO,
    OCULTAR_MODULO_SELECCIONAR_NEGOCIO
} from "../../../Constantes/Componentes/SeleccionarNegocio";

const INIT_STATE = {
    rex_data_negocios_componentes : [],
    rex_cargando_componente_seleccionar_negocio : false,
    rex_negocio_seleccionado : null,
    rex_ocultar_modulo_seleccionar_negocio : false
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case OBTENER_DATA_NEGOCIOS_SELECCIONAR_NEGOCIO : {
        return {
            ...state,
            rex_data_negocios_componentes : action.payload
        }
    }
    case CARGANDO_COMPONENTE_SELECCIONAR_NEGOCIO : {
        return {
            ...state,
            rex_cargando_componente_seleccionar_negocio : action.payload
        }
    }
    case SELECCIONAR_NEGOCIO : {
        return {
            ...state,
            rex_negocio_seleccionado : action.payload
        }
    }
    case REINICIAR_VARIABLES_ESTADOS_SELECCIONAR_NEGOCIO: {
        return {
            ...state,
            rex_data_negocios_componentes : [],
            rex_cargando_componente_seleccionar_negocio : false,
            rex_negocio_seleccionado : null,
            rex_ocultar_modulo_seleccionar_negocio : false
        }
    }
    case OCULTAR_MODULO_SELECCIONAR_NEGOCIO: {
        return {
            ...state,
            rex_ocultar_modulo_seleccionar_negocio : action.payload
        }
    }
    default:
      return state;
  }
}
