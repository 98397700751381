import config from '../../../config'
import { estadoRequestReducer } from "../Comunes/EstadoRequest"
import {RegistrarErrorReducer} from '../Comunes/ManejoErrores'
import {
    OBTENER_DATOS_USUARIO_LOGIN,
    CARGANDO_BTN_LOGIN
} from '../../../Constantes/Login/Login'
import { notification } from "antd";

export const LoginReducer = (datos) => async (dispatch, getState) => {

    dispatch({
        type : CARGANDO_BTN_LOGIN,
        payload : true
    })

    await fetch(config.api+'login',
        {
            mode:'cors',
            method: 'POST',
            body: JSON.stringify(datos),
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api-token'	   : localStorage.getItem('usutoken'),
                'api_token'	   : localStorage.getItem('usutoken')
            }
        }
    )
    .then( async res => {
            await dispatch(estadoRequestReducer(res.status))
            return res.json()
    })
    .then(data => {
            const estadoRequest = getState().estadoRequest.init_request
            if(estadoRequest == true){
                if(data.respuesta == true){

                    let datos = data.datos

                    dispatch({
                        type: OBTENER_DATOS_USUARIO_LOGIN,
                        payload: {
                            token : data.token,
                            usuario : datos.usuusuario
                        }
                    })

                    data.mensaje.map((mensaje) => {
                        notification.success({
                            message: `Notificación`,
                            description: mensaje,
                            placement: 'topRight',
                            duration: 5
                        })
                    })

                    localStorage.setItem('usutoken', data.token)
                    localStorage.setItem('token', data.token)


                }else{
                    data.mensaje.map((mensaje) => {
                        notification.error({
                            message: `Notificación`,
                            description: mensaje,
                            placement: 'topRight',
                        })
                    })
                }
            }else{
                data.mensaje.map((mensaje) => {
                    notification.warning({
                        message: `Notificación`,
                        description: mensaje,
                        placement: 'topRight',
                        duration: 5
                    })
                })
            }

    }).catch((error)=> {
        console.log(error)
        // dispatch(RegistrarErrorReducer(error, config.api+'login', datos))
    });

    dispatch({
        type : CARGANDO_BTN_LOGIN,
        payload : false
    })

}